package matekit.ui

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import matekit.ui.theme.white
import matekit.ui.theme.black
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import matekit.matekit.utilityfunctions.DrawableDot
import matekit.matekit.utilityfunctions.DrawableLine
import matekit.matekit.utilityfunctions.fromAndroidCanvasCoordinate
import matekit.matekit.utilityfunctions.toAndroidCanvasCoordinate
import matekit.ui.theme.matekitcolor
@Composable
fun koordinátarendszer(
    gridColor: Color,
    textcolor: Color,
    linePoints: List<List<Float>>?=null,
    szélesség: Int = 12,
    onTap: (List<Float>) -> Unit,
    dots: List<DrawableDot>? = null,
    quadraticCoeffs: List<Float>? = null,
    drawableLines:List<DrawableLine>?=null,
) {
    val modifier = Modifier
        .aspectRatio(1f)
        .fillMaxHeight()
        .animateContentSize()
    val density = LocalDensity.current
    val textMeasurer = rememberTextMeasurer()

    var cw by remember { mutableStateOf(100f) }
    val unit by derivedStateOf { cw / szélesség }

    val currentUnit = rememberUpdatedState(unit)
    val currentwidth = rememberUpdatedState(szélesség)

    Canvas(modifier = modifier
        .padding(12.dp)
        .clipToBounds()
        /* .pointerInteropFilter {
            var myoffset = listOf<Float>(it.x,it.y)
            var coordinates = myoffset.toAndroidCanvasCoordinate(unit, szélesség)
            onTap(coordinates)
            // println("(${it.x};${it.y})")
            true
        }*/
        .pointerInput(Unit) {
            detectTapGestures(onTap = { offset ->
                var myoffset = listOf<Float>(offset.x, offset.y)
                var coordinates = myoffset.fromAndroidCanvasCoordinate(currentUnit.value, currentwidth.value)
                onTap(coordinates)
                // todo adja vissza az érintés pontját
                //   println("TAP!")

            })
        }

    ) {
        cw = size.width
        val ch = size.height //unused
        // val unit = cw / szélesség
        drawLine(//x tengely
            start = Offset(x = 0f, y = szélesség / 2.toFloat() * unit),
            end = Offset(x = szélesség.toFloat() * unit, y = szélesség / 2.toFloat() * unit),
            color = gridColor.copy(1f)
        )
        drawLine(//y tengely
            start = Offset(x = szélesség / 2.toFloat() * unit, y = 0f),
            end = Offset(x = szélesség / 2.toFloat() * unit, y = szélesség.toFloat() * unit),
            color = gridColor.copy(1f)
        )
        var arrowstarts = listOf<List<Float>>(
            listOf(-0.125f, szélesség / 2 - 0.25f), //x tengely
            listOf(0.125f, szélesség / 2 - 0.25f),//x tengely
            listOf(szélesség / 2 - 0.25f, 0.125f),//y tengely
            listOf(szélesség / 2 - 0.25f, -0.125f),//y tengely
            /* szorzással felírva (nem működik)
            listOf(-0.01f*szélesség, 0.48f*szélesség), //x tengely
            listOf(0.01f*szélesség, 0.48f*szélesség),//x tengely
            listOf(0.48f*szélesség, 0.01f*szélesség),//y tengely
            listOf(0.48f*szélesség, -0.01f*szélesség),//y tengely*/
            /*
            korábbi koordináták nem paraméteres felírással:
                listOf(-0.125f, 5.75f), //x tengely
            listOf(0.125f, 5.75f),//x tengely
            listOf(5.75f, 0.125f),//y tengely
            listOf(5.75f, -0.125f),//y tengely
            */

//            listOf(0.25f, -5.5f),
//            listOf(-0.25f, -5.5f),
//            listOf(-5.5f, -0.25f),
//            listOf(-5.5f, 0.25f)
        )
        var arrowends = listOf<List<Float>>(
            listOf(0f, szélesség / 2.toFloat()),
            listOf(0f, szélesség / 2.toFloat()),
            listOf(szélesség / 2.toFloat(), 0f),
            listOf(szélesség / 2.toFloat(), 0f),
            /* korábbi koordiníták, nem paraméteresen felírva
             listOf(0f, 6f),
             listOf(0f, 6f),
             listOf(6f, 0f),
             listOf(6f, 0f),
             */
//            listOf(0f, -6f),
//            listOf(0f, -6f),
//            listOf(-6f, -0f),
//            listOf(-6f, -0f)
        )
        arrowstarts.forEachIndexed { index, list ->
            var start = list.toAndroidCanvasCoordinate(unit, szélesség)
            var end = arrowends[index].toAndroidCanvasCoordinate(unit, szélesség)
            drawLine(
                start = Offset(x = start[0], y = start[1]),
                end = Offset(x = end[0], y = end[1]),
                color = gridColor.copy(1f)
            )
        }

        for (i in 0..szélesség) {
            //ha 12*12-nél nagyobb, akkor csak az elsőt, meg a tízeseket írjuk ki

            val kiirandoxy = if (szélesség < 24) {
                (-szélesség..szélesség).toList()
            } else {
                listOf(
                    0,
                    1,
                ) + (10..szélesség).filter { it % 10 == 0 } + (-szélesség..-10).filter { it % 10 == 0 }
            }

            drawLine(
                start = Offset(x = i * unit, y = 0f),
                end = Offset(x = i * unit, y = szélesség * unit),
                color = gridColor.copy(0.8f)
            )
            drawLine(
                start = Offset(x = 0f, y = i * unit),
                end = Offset(x = szélesség * unit, y = i * unit),
                color = gridColor.copy(0.8f)
            )

            var koordináta =
                listOf((i - szélesség / 2).toFloat(), 0f).toAndroidCanvasCoordinate(unit, szélesség)

            if (i - szélesség / 2 in kiirandoxy) {

                drawContext.canvas.nativeCanvas.apply {
                    drawText(  //todo debuggolni
                        textMeasurer = textMeasurer, text = buildAnnotatedString {
                            withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                append("${i - szélesség / 2}")
                            }
                        }, topLeft = Offset(
                            koordináta[0],
                            koordináta[1],
                        )
                    )
                }

            }

            if ((-szélesség / 2 + i) * -1 in kiirandoxy) {
                drawContext.canvas.nativeCanvas.apply {
                    if (i != szélesség / 2) {
                        drawText(
                            textMeasurer = textMeasurer, text = buildAnnotatedString {
                                withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                    append("${(-szélesség / 2 + i) * -1}")
                                }
                            }, topLeft = Offset(
                                koordináta[1] + 10,
                                koordináta[0],
                            )
                        )
                    }
                }
            }


        }
        var topy = listOf(0.35f, szélesség / 2 - 0.35f).toAndroidCanvasCoordinate(
            unit,
            szélesség
        ) //(0.35f,5.65f)
        var rightx = listOf(szélesség / 2 - 0.5f, 0.25f).toAndroidCanvasCoordinate(
            unit,
            szélesség
        ) //(5.5f,0.25f)
        drawContext.canvas.nativeCanvas.apply {
            drawText(
                textMeasurer = textMeasurer, text = buildAnnotatedString {
                    withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                        append("x")
                    }
                }, topLeft = Offset(
                    rightx[0],
                    rightx[1],
                )
            )
            drawText(
                textMeasurer = textMeasurer, text = buildAnnotatedString {
                    withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                        append("y")
                    }
                }, topLeft = Offset(
                    topy[0],
                    topy[1],
                )
            )
        }
        linePoints?.let { linePoints->
            var ALinepoint = linePoints[0].toAndroidCanvasCoordinate(unit)
            var BLinepoint = linePoints[1].toAndroidCanvasCoordinate(unit)

            drawLine(
                start = Offset(x = ALinepoint[0], ALinepoint[1]),
                end = Offset(x = BLinepoint[0], BLinepoint[1]),
                color = Color.Red,
                strokeWidth = 3f
            )
        }

        dots?.let{
                dots->
            dots.forEachIndexed { index, drawableDot ->
                val coordinates = drawableDot.position.toAndroidCanvasCoordinate(unit, szélesség)

                drawCircle(
                    center = Offset(coordinates.first, coordinates.second),
                    color = drawableDot.color,
                    radius = drawableDot.radius
                )
            }

        }

        /* try {//deprecated lambda
             val dotDrawCoordinates = dotCoordinates.toAndroidCanvasCoordinate(unit, szélesség)
             // val x = dotDrawCoordinates[0]
             //  val y = dotDrawCoordinates[1]
             // var offset = (Offset(x=dotDrawCoordinates[0],y=dotDrawCoordinates[1]))
             // drawCircle(center = offset,color= matekitcolor, radius = 2f )
             // drawCircle(center =Offset(0f,0f),color= matekitcolor, radius = 25f)

             drawCircle(
                 center = Offset(dotDrawCoordinates[0], dotDrawCoordinates[1]),
                 color = matekitcolor,
                 radius = 20f
             )
         } catch (e: java.lang.Exception) {
         }*/
        /*var b = listOf(5.5f,0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )
        b = listOf(5.5f,-0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )*/

        quadraticCoeffs?.let { coeffs ->
            // coefficient interpretation: coeffs[0] = a, coeffs[1] = b, coeffs[2] = c
            val a = coeffs[0]
            val b = coeffs[1]
            val c = coeffs[2]
            val samples = 100
            val quadPoints = mutableListOf<List<Float>>()
            // Sample x values from -szélesség/2 to +szélesség/2
            val totalRange = szélesség.toFloat()
            val dx = totalRange / (samples - 1)
            for (i in 0 until samples) {
                val x = i * dx - szélesség / 2f
                val y = a * x * x + b * x + c
                quadPoints.add(listOf(x, y).toAndroidCanvasCoordinate(unit, szélesség))
            }
            // Draw the quadratic curve by connecting consecutive points
            for (i in 0 until quadPoints.size - 1) {
                drawLine(
                    start = Offset(quadPoints[i][0], quadPoints[i][1]),
                    end = Offset(quadPoints[i + 1][0], quadPoints[i + 1][1]),
                    color = matekitcolor,
                    strokeWidth = 3f
                )
            }
        }

        drawableLines?.let { lines ->
            lines.forEach {line->

                var ALinepoint =  listOf(line.start.first,line.start.second).toAndroidCanvasCoordinate(unit,szélesség)
                var BLinepoint =  listOf(line.end.first,line.end.second).toAndroidCanvasCoordinate(unit,szélesség)

                drawLine(
                    start = Offset(x = ALinepoint[0], ALinepoint[1]),
                    end = Offset(x = BLinepoint[0], BLinepoint[1]),
                    color = line.color,
                    strokeWidth = line.width
                )
            }
        }
    }
}
