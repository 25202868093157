package matekit.ui

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp/*
import com.multiplatform.webview.web.WebContent
import com.multiplatform.webview.web.WebView
import com.multiplatform.webview.web.WebViewState
import com.multiplatform.webview.web.rememberWebViewNavigator
import com.multiplatform.webview.web.rememberWebViewStateWithHTMLData*/
import kotlinx.browser.document
import kotlinx.coroutines.delay
import matekit.ui.theme.matekitcolor







@Composable
fun webviewkmm(
    LaTeX: String,
    modifier: Modifier=Modifier,
    mathEnvironmentwrap:Boolean=true,
    extrapadding :Boolean = false,
    transparent:Boolean=false
){//a webes verzióban annyit teszünk, hogy manipuláljuk azt a bizonyos divet
    val html =mutableStateOf("$$" + LaTeX + "$$",)
    if(mathEnvironmentwrap==false) html .value = LaTeX
    var height =  (basicfontsize*3.5).dp//2.2 szorzó it is what it is
   // if(extrapadding) height = (height.value*1.6).dp
    val color = if(transparent) Color.Transparent else Color.White
    Surface(
        Modifier.fillMaxWidth().height(height),
        color=color
    ){

    }

LaunchedEffect(
    html.value
){
    val mathContentDiv = document.getElementById("mathContent")
    mathContentDiv?.innerHTML = html.value
    js("MathJax.Hub.Queue(['Typeset', MathJax.Hub, mathContentDiv]);")
}
}
/*
@Composable
fun webviewkmm(LaTeX: String, modifier: Modifier = Modifier.fillMaxWidth().heightIn(min = 200.dp),mathEnvironmentwrap:Boolean=true) {
  /*  val navigator = rememberWebViewNavigator()

    val html =mutableStateOf(mathjaxsimple("$$" + LaTeX + "$$", false))
    var webViewState = rememberWebViewStateWithHTMLData(html.value)
    /* var webViewState = remember{ mutableStateOf(
         WebViewState(
             WebContent.Data(
                 html.value,null,"utf-8",null,null)
         )
     )}*/
    /* val state = remember {
         mutableStateOf(
             WebViewState(
                 WebContent.Data(
                     html.value, null, "utf-8", null, null
                 )
             )
         )
     }*/
   /* LaunchedEffect(Unit) {
        delay(1000)//it does not load immediately so a little bit of delay is necessary
        html.value = mathjaxsimple("$$" + LaTeX + "$$", false)
        navigator.loadHtml(html.value, null, "utf-8", null, null)
    }*/


    LaunchedEffect(html.value) {
        delay(600)

        if(mathEnvironmentwrap){
            html.value = mathjaxsimple("$$" + LaTeX + "$$", false)
        }else{
            html.value = mathjaxsimple( LaTeX , false)
        }
        navigator.loadHtml(html.value, null, "utf-8", null, null)
        println("LaTeX.value:${LaTeX}")
        println("html.value:${html.value}")
        println("content = ${webViewState.content}")
    }
    Column(modifier) {
        WebView(
            state = webViewState, modifier = modifier, navigator = navigator
        )
    }
}
*/*/
fun mathjaxsimple(//webes verzió
    input: String, darkmode: Boolean, simplefontsize: Int = basicfontsize.toInt(), textalign: String = "center"
): String {//a fontsize-t pixelben értelmezi
    var background = if (darkmode) "#1B1C1F" //"#303030"
    else "#FAFAFA"
    var textcolor = if (darkmode) "#FFFFFF" else "#000000"
    var string =
        "<!DOCTYPE html>\n" + "<html>\n" + "<head>\n" + "<meta charset=\"utf-8\">\n" + "  <meta name=\"viewport\" content=\"width=device-width\">" + "<title>MathJax TeX Test Page</title>\n" + "<script type=\"text/x-mathjax-config\">\n" + "  MathJax.Hub.Config({" + "tex2jax: {inlineMath: [['\$','\$'], ['\\\\(','\\\\)']]}" + "   \"fast-preview\": {disabled: true},\n" + " tex2jax: {preview: \"none\"}  " + "    displayAlign: \"left\"" + "  CommonHTML: { linebreaks: { automatic: true } },\n" + "  \"HTML-CSS\": { linebreaks: { automatic: true } },\n" + "         SVG: { linebreaks: { automatic: true } }\n" + "   });\n" +

                "</script>\n" +
                // "<script type=\"text/javascript\" async src=\"file:///android_asset/es5/tex-chtml.js\"\n" +
                "<script type=\"text/javascript\" async src=\"https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js\"\n" +

                // "<script type=\"text/javascript\" async\n" +
                //  "  src=\"https://example.com/mathjax/MathJax.js?config=TeX-AMS_CHTML\">\n" +
//file:///android_asset/katex/ átírva katexre
                "</script>\n" + "<script>" +/*    "MathJax = {\n" +
                "  options: {\n" +
                "    enableMenu: false" + "}\n" +
                "};"+*/
                "</script>" + "</head>\n" + "<body style=\"background-color:" + background.toString() + "; color: ${textcolor.toString()} ; font-size: ${simplefontsize}px; text-align:$textalign; \">"

    val p2 = "</body>\n" + "</html>"
    var input = if (darkmode) input.replace("black", "white") else input.replace("white", "black")
    val fullMathText = string + input + p2
    return fullMathText

}