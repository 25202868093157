package matekit.ui

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.navigation.NavController
import kotlinx.browser.document

@Composable
fun backButton(navController: NavController, onback: () -> Unit={}) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            //.padding(bottom = 56.dp)
    ) {
        FloatingActionButton(
            modifier = Modifier.align(Alignment.BottomStart),
            onClick = {
                navController.popBackStack()

                val mathContentDiv = document.getElementById("mathContent") //töröljük a mathdivet
                mathContentDiv?.innerHTML = ""
                js("MathJax.Hub.Queue(['Typeset', MathJax.Hub, mathContentDiv]);")
                onback()
            },
            containerColor = Color.Gray
        ) {
            Icon(
                imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                contentDescription = "Back"
            )
        }
    }
}