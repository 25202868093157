package matekit.matekit.taskgenerators

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.DrawableDot
import matekit.matekit.utilityfunctions.DrawableLine
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
//import matekit.matekit.OrientationChecker
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.fromAndroidCanvasCoordinate
import matekit.matekit.utilityfunctions.toAndroidCanvasCoordinate
import matekit.ui.LatexLogButton
import matekit.ui.SurfaceWBorder
import matekit.ui.backButton
import matekit.ui.basicfontsizeTextView
import matekit.ui.koordinátarendszer
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.NewTask
import matekit_multiplatform.composeapp.generated.resources.ReadTheCoordinatesOfThePoint
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.justWrong
import matekit_multiplatform.composeapp.generated.resources.koppinstráFeladat
import matekit_multiplatform.composeapp.generated.resources.markThePoint
import matekit_multiplatform.composeapp.generated.resources.readPoint
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource

const val LEOLVASÁS = 1
const val KOPPINTSRÁ = 2


class CoordinateSystemIntroduction(val tag: String = "CoordinateSystemIntroduction", val navController: NavHostController, ratios:ratios) {
    val controller = navController
    val ratios = mutableStateOf(ratios)
    val mytag = tag
    @Composable
    fun coordinateSystemIntroduction(
     //   navController: NavHostController = controller,
      //  tag: String = mytag,
        orientation: MutableState<String>
    ) {

        var taskindex = rememberSaveable { mutableStateOf(0) }
        var usertipp = remember { mutableStateOf(listOf<Float>()) }
        var taskType = rememberSaveable { mutableStateOf(KOPPINTSRÁ) }
        var numberoftasks = rememberSaveable { mutableStateOf(0) }

        var lastsolution = rememberSaveable { mutableStateOf("") }
        var correctsolution = rememberSaveable { mutableStateOf(false) }

        val size = rememberSaveable{mutableStateOf(6)}

        var taskOffset = rememberSaveable {
            mutableStateOf(
                listOf<Float>(
                    0f, 0f
                )
            )
        }
        var előzőoffset = rememberSaveable {
            mutableStateOf(
                listOf<Float>(
                    0f, 0f
                )
            )
        }




        var stopforrevision= rememberSaveable { mutableStateOf(false) }
        val dots = remember{ mutableStateOf<List<DrawableDot>?> ( listOf(DrawableDot(Pair(taskOffset.value[0],taskOffset.value[1]),color= matekitcolor))) }

        var tasktext = remember { mutableStateOf("") }
        LaunchedEffect(Unit) {
            generate(taskOffset,dots,taskindex,size.value)
            tasktext.value =                 if (taskType.value == LEOLVASÁS) {
                getString(Res.string.readPoint)
            } else {//akkor KOPPINTSRÁ
                getString(
                    Res.string.koppinstráFeladat,
                    "(${taskOffset.value[0].toInt()};${taskOffset.value[1].toInt()})"
                )
            }
        }


        LaunchedEffect(taskindex.value) {
            if (LEOLVASÁS == taskType.value) {
                dots.value = listOf(
                    DrawableDot(
                        Pair(taskOffset.value[0], taskOffset.value[1]),
                        color = matekitcolor
                    )
                )
            } else {
                dots.value = null
            }
            stopforrevision.value = false


            tasktext.value =                 if (taskType.value == LEOLVASÁS) {
                    getString(Res.string.readPoint)
                } else {//akkor KOPPINTSRÁ
                    getString(
                        Res.string.koppinstráFeladat,
                        "(${taskOffset.value[0].toInt()};${taskOffset.value[1].toInt()})"
                    )
                }


        }

        // A surface container using the 'background' color from the theme
        Surface(
            modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
        ) {

            backButton(navController = navController)




                Column(
                    modifier =Modifier.fillMaxSize() ,
                    verticalArrangement = Arrangement.SpaceAround,
                    horizontalAlignment = Alignment.CenterHorizontally
                ){
                    SurfaceWBorder(content = {
                        basicfontsizeTextView(
                            tasktext.value,
                            modifier = Modifier.padding(5.dp)
                        )
                    }, matekitcolor)
                    /*  Column(
                          modifier = Modifier/*.verticalScroll(rememberScrollState())*//*.fillMaxSize()*/,
                          verticalArrangement = Arrangement.SpaceAround,
                          horizontalAlignment = Alignment.CenterHorizontally
                      ) {*/

                    val cs = rememberCoroutineScope()
                    if (orientation.value == "Portrait") {
                        //  Column( Modifier.fillMaxHeight(0.8f)/*.verticalScroll(rememberScrollState())*/) {
                        leolvasásBevitel(taskType,taskOffset,usertipp,tasktext.value,
                            lastsolution,
                            correctsolution,
                            numberoftasks,előzőoffset,dots,stopforrevision,taskindex)

                        koordinátarendszer(
                            gridColor = MaterialTheme.colorScheme.onBackground,
                            textcolor = MaterialTheme.colorScheme.onPrimary,
                            linePoints = null,
                            onTap = {
                                if (taskType.value == KOPPINTSRÁ) {
                                    usertipp.value = it
                                  cs.launch {   check(
                                        taskType.value,
                                        usertipp,
                                        taskOffset,
                                        tasktext.value,
                                        lastsolution,
                                        correctsolution,
                                        numberoftasks,előzőoffset,dots,stopforrevision,taskindex
                                    )}
                            }
                            },
                            szélesség = size.value,
                            dots = dots.value
                        )
                        // }
                        adjustSize(size)
                        beállításokLog(taskType,taskOffset,stopforrevision,dots,taskindex,size,tag,navController)
                    } else {
                        Row(modifier=Modifier.fillMaxWidth(0.9f)/*.wrapContentHeight()*/, horizontalArrangement = Arrangement.SpaceAround) {

                            Column(modifier=Modifier
                                .fillMaxWidth(0.5f)
                                .fillMaxHeight()){
                                koordinátarendszer(
                                    gridColor = MaterialTheme.colorScheme.onBackground,
                                    textcolor = MaterialTheme.colorScheme.onPrimary,
                                    linePoints = null,
                                    onTap = {
                                        if (taskType.value == KOPPINTSRÁ) {
                                            usertipp.value = it
                                            cs.launch {   check(
                                                taskType.value,
                                                usertipp,
                                                taskOffset,
                                                tasktext.value,
                                                lastsolution,
                                                correctsolution,
                                                numberoftasks,előzőoffset,dots,stopforrevision,taskindex
                                            )}
                                        }
                                    },
                                    szélesség = size.value,

                                    dots = dots.value
                                )

                            }
                            Column(modifier=Modifier
                          .fillMaxWidth()
                                .fillMaxHeight(), verticalArrangement = Arrangement.SpaceAround, horizontalAlignment = Alignment.CenterHorizontally){
                                leolvasásBevitel(taskType,taskOffset,usertipp,tasktext.value,
                                    lastsolution,
                                    correctsolution,
                                    numberoftasks,előzőoffset,dots,stopforrevision,taskindex)
                                adjustSize(size)

                                beállításokLog(taskType,taskOffset,stopforrevision,dots,taskindex,size,tag,navController)
                            }
                        }
                    }
                    // }

                }

        }

        responseToast(
            correctsolution.value,
            numberofsolved = numberoftasks.value,
            solution = lastsolution.value,
            nosolution = true
        )
    }
}


@Composable
fun adjustSize(value: MutableState<Int>,valuerange:ClosedFloatingPointRange<Float> = 6f..20f){
    Column(horizontalAlignment = Alignment.CenterHorizontally, modifier=Modifier      .widthIn(200.dp, 900.dp)){
        Text("Koordinátarendszer mérete: ${value.value}")//todo stringresource
        Slider(
            value = value.value.toFloat(), onValueChange = {
                value.value = it.roundToNextEven()//diszkrét lépésekkel nem akart működni, ezért most párosra kerekítjük az értéket
            },
            valueRange = valuerange, // Define the range of the slider
           // steps = ((valuerange.endInclusive-valuerange.start)/2-1).toInt() // Define the number of steps (10 steps for even numbers from 0 to 20)

        )
    }
}
fun Number.roundToNextEven(): Int {
    return ((kotlin.math.ceil(this.toDouble()) + 1) / 2).toInt() * 2
}

    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun leolvasásBevitel(
        taskType:MutableState<Int>,
        taskOffset:MutableState<List<Float>>,
        usertipp:MutableState<List<Float>>,
        tasktext:String,
        lastsolution: MutableState<String>,
        correctsolution: MutableState<Boolean>,
        numberoftasks: MutableState<Int>,
        előzőoffset:MutableState<List<Float>>,
        dots: MutableState<List<DrawableDot>?>,
        stopforrevision:MutableState<Boolean>,
        taskindex: MutableState<Int>) {
        val cs = rememberCoroutineScope()
        if (taskType.value == LEOLVASÁS) { //leolvasás beviteli mezők
            var userinputX by rememberSaveable { mutableStateOf("") }
            var userinputY by rememberSaveable { mutableStateOf("") }
            val focusRequesterX = remember { FocusRequester() }
            val focusRequesterY = remember { FocusRequester() }

            Row(
                modifier = Modifier.widthIn(100.dp, 300.dp)
                    .fillMaxWidth()
                    .wrapContentHeight(),//Modifier.wrapContentHeight().navigationBarsWithImePadding()
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                OutlinedTextField(
                    value = userinputX,
                    onValueChange = { userinputX = it },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number,
                        imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(
                        onDone = {
                            if (userinputY == "") focusRequesterY.requestFocus()
                            else {
                                try {
                                    usertipp.value = listOf(
                                        userinputX.toFloat(),
                                        userinputY.toFloat()
                                    )
                                    cs.launch {   check(
                                        taskType.value,
                                        usertipp,
                                        taskOffset,
                                        tasktext,
                                        lastsolution,
                                        correctsolution,
                                        numberoftasks,előzőoffset,dots,stopforrevision,taskindex
                                    )}
                                    userinputX = ""
                                    userinputY = ""
                                } catch (e: Exception) {
                                    return@KeyboardActions
                                }

                            }
                        }
                    ),
                    modifier = Modifier
                        .width(80.dp)
                        .focusRequester(focusRequesterX),
                    placeholder = { Text("X") }
                )
                OutlinedTextField(
                    value = userinputY,
                    onValueChange = { userinputY = it },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number,
                        imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(
                        onDone = {
                            if (userinputX == "") focusRequesterX.requestFocus()
                            else {
                                try {
                                    usertipp.value = listOf(
                                        userinputX.toFloat(),
                                        userinputY.toFloat()
                                    )
                                    cs.launch {   check(
                                        taskType.value,
                                        usertipp,
                                        taskOffset,
                                        tasktext,
                                        lastsolution,
                                        correctsolution,
                                        numberoftasks,előzőoffset,dots,stopforrevision,taskindex
                                    )}
                                    userinputX = ""
                                    userinputY = ""
                                    focusRequesterX.requestFocus()
                                } catch (e:Exception) {
                                    return@KeyboardActions
                                }

                            }
                        }
                    ),
                    modifier = Modifier
                        .width(80.dp)
                        .focusRequester(focusRequesterY),
                    placeholder = { Text("Y") }
                )
                Button(onClick = {
                    try {
                        usertipp.value = listOf(
                            userinputX.toFloat(),
                            userinputY.toFloat()
                        )
                        cs.launch {   check(
                            taskType.value,
                            usertipp,
                            taskOffset,
                            tasktext,
                            lastsolution,
                            correctsolution,
                            numberoftasks,előzőoffset,dots,stopforrevision,taskindex
                        )}
                        userinputX = ""
                        userinputY = ""
                        focusRequesterX.requestFocus()
                    } catch (e:Exception) {
                        return@Button
                    }
                },
                    enabled = !stopforrevision.value
                )
                { Text(stringResource(Res.string.Check)) }
            }

        }
    }

    @Composable
    fun beállításokLog(
        taskType:MutableState<Int>,
        taskOffset:MutableState<List<Float>>,
        stopforrevision:MutableState<Boolean>,
        dots: MutableState<List<DrawableDot>?>,
        taskindex: MutableState<Int>,
        size:MutableState<Int>,
        tag:String,
        navController: NavHostController
    ) {
        // Box(modifier=Modifier.fillMaxWidth(), contentAlignment = Alignment.Center){
        Column(horizontalAlignment = Alignment.CenterHorizontally,modifier=Modifier.wrapContentHeight()) {//beállítások, log
            if(stopforrevision.value){
                if(taskType.value==KOPPINTSRÁ)Text("helyes megoldás: zöld\nválasz:piros",modifier=Modifier.padding(6.dp),textAlign = TextAlign.Center)
                if(taskType.value==LEOLVASÁS)Text("leolvasandó pont: zöld\nválaszként megadott koordináták: piros",modifier=Modifier.padding(6.dp),textAlign = TextAlign.Center)
            }

            if(stopforrevision.value){
                Button(
                    onClick = {
                        stopforrevision.value=false
                        generate(taskOffset,dots,taskindex,size.value)
                    }
                ) {
                    Text(stringResource(Res.string.NewTask))
                }
            }

            Button(onClick = {
                taskType.value = KOPPINTSRÁ
                generate(offset = taskOffset,dots,taskindex,size.value)
            }) {
                Text(stringResource(Res.string.markThePoint))
            }
            Button(onClick = {
                taskType.value = LEOLVASÁS
                generate(offset = taskOffset,dots, taskindex ,size.value)
            }) {
                Text(stringResource(Res.string.ReadTheCoordinatesOfThePoint))
            }
            LatexLogButton(navController,tag)
        }

        // }

    }

    suspend fun check(
        tasktype: Int,
        userinput: MutableState<List<Float>>,
        taskOffset: MutableState<List<Float>>,
        displayedTask: String,
        lastsolution: MutableState<String>,
        correctsolution: MutableState<Boolean>,
        numberoftasks: MutableState<Int>,
        előzőoffset:MutableState<List<Float>>,
        dots: MutableState<List<DrawableDot>?>,
        stopforrevision:MutableState<Boolean>,
        taskindex: MutableState<Int>
    ) {
        if(stopforrevision.value) return

        előzőoffset.value=taskOffset.value
        var helyesMO: Boolean
        var showSolutionIfUserWrong: String
        var saveuserinput: String
        var showsolution: String
      //  println("koppintás koordinátái: ${userinput.toString()}")

        var tűrésrangeX = (taskOffset.value[0] - 0.33..taskOffset.value[0] + 0.33)
        var tűrésrangeY = (taskOffset.value[1] - 0.33..taskOffset.value[1] + 0.33)
        var saveuserX = try {
            userinput.value[0].formatToDecimals(2)
        } catch (e: Exception) {
            return
        }
        var saveuserY = try {
            userinput.value[1].formatToDecimals(2)
        } catch (e: Exception) {
            return
        }
        saveuserinput = "($saveuserX;$saveuserY)"
        if (
            userinput.value[0] in tűrésrangeX && userinput.value[1] in tűrésrangeY
        ) {//jó a megoldás
            correctsolution.value = true
            helyesMO = true
            showSolutionIfUserWrong = ""
        } else {//rossz a megoldás
            showSolutionIfUserWrong =
                if (tasktype == KOPPINTSRÁ) getString(Res.string.justWrong) else {
                    getString(
                        Res.string.WrongSolution,
                        "(${taskOffset.value[0].formatToDecimals(2)};${taskOffset.value[1].formatToDecimals(2)})"
                    )
                }
            correctsolution.value = false

            helyesMO = false
        }
        //}

       // println("helyesmo:${correctsolution.value}")
        /* var text =
         if (helyesMO) getString(R.string.CorrectSolution) else getString(R.string.justWrong)
     var color = if (helyesMO) R.style.CorrectToast else R.style.WrongToast
     ShowToast.showToast(color, text, context ,true)*/ //todo toast
var tag = "CoordinateSystemIntroduction"
        LaTexLogWriteDesktop(
            helyesMO,
            saveuserinput,
            showSolutionIfUserWrong,
            tag,
            displayedTask,
            taskLaTexWrap = false
        )
        numberoftasks.value++
        if(!helyesMO) {
            stopforrevision.value=true

            dots.value = listOf(
                DrawableDot(
                    Pair(előzőoffset.value[0], előzőoffset.value[1]),
                    color = Color.Green
                ),
                DrawableDot(
                    Pair(userinput.value[0], userinput.value[1]),
                    color = Color.Red
                )

            )
        }

        if(helyesMO)  generate(taskOffset,dots, taskindex = taskindex)//csak akkor generálunk új feladatot, ha helyes volt a megoldás
    }

    fun generate(
        offset: MutableState<List<Float>>,
        dots: MutableState<List<DrawableDot>?>,
        taskindex:MutableState<Int> ,
        size: Int = 6) {
        val n =size/2
        val range = -n..n
        offset.value = listOf<Float>(range.random().toFloat(), range.random().toFloat())
        taskindex.value++
    }




