package matekit.matekit.masodfokufv


import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import matekit.ui.basicfontsize


class Masodfokufv (val TAG :String = "MasodfokuTAG") {
 @Composable
 fun masodfokufv(navHostController: NavHostController) {
     Column(Modifier.fillMaxSize()) {
         masodfokuParent( TAG,  basicfontsize, navController =navHostController)
     }
 }

}

