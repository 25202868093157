package matekit.ui

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Fill
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import matekit_multiplatform.composeapp.generated.resources.NotoSans_Regular
import matekit_multiplatform.composeapp.generated.resources.Res
import androidx.compose.ui.text.font.FontWeight
import matekit_multiplatform.composeapp.generated.resources.JetBrainsMono_Regular
import org.jetbrains.compose.resources.Font

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun indexedInputField(
    indexSymbol: String,
    index: Int,
    userinput: SnapshotStateList<String>,
    row: Boolean = true,
    értékelve: Boolean = false,
    correct: Boolean = false,
    label: String = "",
    keyboardtype:Int = 3,//számot gyakrabban kérünk, ezért legyen az a default
    jetbrainsfont:Boolean = false
) {

    var keyboardtype = if(keyboardtype==1){
        KeyboardType.Text
    }else{
        KeyboardType.Number
    }
val fontFamily =if(jetbrainsfont) FontFamily(Font(Res.font.JetBrainsMono_Regular, FontWeight.Normal)) else FontFamily.Serif

    if (row) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(bottom = 4.dp)
        ) {
             Text(text = "$indexSymbol: ", fontFamily = fontFamily, fontSize = 30.sp)
            OutlinedTextField(
                value = userinput[index],
                onValueChange = { userinput[index] = it },
                keyboardOptions = KeyboardOptions.Default.copy(
                    keyboardType = keyboardtype
                ))
        }
    } else {
        Column(//csak az oszloposat frissítettem, mert egyelőre csak arra van szükség, //todo átdolgozni a row verziót is
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.padding(bottom = 4.dp)
        ) {
            Text(text = "$indexSymbol",fontFamily=fontFamily ,fontSize = 30.sp, textAlign = TextAlign.Center)
            OutlinedTextField(
                value = userinput[index],
                onValueChange = { userinput[index] = it },
                label = { Text(label.toString(),
                    color =
                    if(értékelve) { if (correct) Color.Green else Color.Red } else Color.Unspecified
                ) },

              /*  colors = if (értékelve) TextFieldDefaults.outlinedTextFieldColors( //todo fix this
                    focusedBorderColor = if (correct) Color.Green else Color.Red,
                    unfocusedBorderColor = if (correct) Color.Green else Color.Red
                ) else TextFieldDefaults.outlinedTextFieldColors(),
                keyboardOptions = KeyboardOptions.Default.copy(
                    keyboardType = keyboardtype
                )*/

            )


        }

    }

}