package matekit.matekit.utilityfunctions

import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource

data class Paramstr (
    var stringResource: StringResource,
    var params:List<Any> = emptyList(), )

@Composable
fun Paramstr. getparamstr ():String{
 return  stringResource(this.stringResource, *this.params.toTypedArray())

}

suspend fun Paramstr.getstring():String{
    return getString(this.stringResource,*this.params.toTypedArray())

}