package matekit.matekit.taskgenerators

import androidx.compose.runtime.*
import androidx.lifecycle.ViewModel
import kotlin.math.absoluteValue
import kotlin.random.Random

import androidx.compose.foundation.layout.*
import androidx.compose.foundation.verticalScroll
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

import androidx.compose.ui.text.input.*
import androidx.compose.ui.text.intl.Locale
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.Rational
import matekit.matekit.toLaTeX
import matekit.matekit.utilityfunctions.*
import matekit.ui.LatexLogButton


import matekit.ui.backButton
import matekit.ui.generatorSettings
import matekit.ui.paramField
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.universalCheckField
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.FractionMinMax
import matekit_multiplatform.composeapp.generated.resources.FractionsAccurateIn
import matekit_multiplatform.composeapp.generated.resources.GeneratedNumerators
import matekit_multiplatform.composeapp.generated.resources.IntMinMax
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.denominator
import matekit_multiplatform.composeapp.generated.resources.easy
import matekit_multiplatform.composeapp.generated.resources.from
import matekit_multiplatform.composeapp.generated.resources.hard
import matekit_multiplatform.composeapp.generated.resources.integer
import matekit_multiplatform.composeapp.generated.resources.medium
import matekit_multiplatform.composeapp.generated.resources.megoldokulcs
import matekit_multiplatform.composeapp.generated.resources.mixedFractions
import matekit_multiplatform.composeapp.generated.resources.numerator
import matekit_multiplatform.composeapp.generated.resources.to
import matekit_multiplatform.composeapp.generated.resources.v_letlenszer_tagsz_m
import matekit_multiplatform.composeapp.generated.resources.veryHard
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource


@Composable
fun FractionScreen(
    navController: NavHostController,
    ratios:ratios,
    tag:String="tort",
    viewModel: Tortek2ViewModel = viewModel {Tortek2ViewModel(tag)}
) {
    val uiState by viewModel.uiState.collectAsState()
    backButton(navController)
    val coroutineScope = rememberCoroutineScope()

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(start=16.dp, end = 16.dp,top =16.dp, bottom=60.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
       // responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution) //nem ide

        // Equation Display
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .heightIn(min = 200.dp)
                .padding(16.dp),
            contentAlignment = Alignment.Center
        ) {
            // Use your LaTeX rendering composable here
            // For example:
            webviewkmm(LaTeX = uiState.LaTeXFormule)
        }

        // User Input Fields
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(vertical = 8.dp).wrapContentSize(),
        ) {
            universalCheckField(
                userInput = uiState.userResponseInt,
                enterText = { viewModel.updateUserResponseInt(it) },
                check = { },
                label = Res.string.integer,

            )
            Spacer(modifier = Modifier.width(8.dp))
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
            ) {
                universalCheckField(
                    userInput = uiState.userResponseN,
                    enterText = { viewModel.updateUserResponseN(it) },
                    check={}
                    ,
                    label = Res.string.numerator,

                )
                universalCheckField(
                    userInput = uiState.userResponseD,
                    enterText = { viewModel.updateUserResponseD(it) },

                   check={}      ,
                    label = Res.string.denominator,
                )
            }
        }

        // Check Button

        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxWidth()
        ){
            Button(
                onClick = { coroutineScope.launch { viewModel.checkAnswer() } },
            ) {
                Text(stringResource(Res.string.Check))
            }
            Spacer(Modifier.size(4.dp))
            LatexLogButton(navController, tag)
        }


        // Feedback Message
        if (uiState.feedbackMessage.isNotEmpty()) {
            Text(
                text = uiState.feedbackMessage,
                color = if (uiState.isCorrect) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.error,
                modifier = Modifier
                    .padding(16.dp)
                    .align(Alignment.CenterHorizontally)
            )
        }

        // Settings Panel
        Spacer(modifier = Modifier.height(16.dp))
        FractionSettingsPanel(viewModel, uiState)
    }
    responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution) //weben ide

}

@Composable
fun FractionSettingsPanel(viewModel: Tortek2ViewModel, uiState: TortekUIState) {
    // We can make the settings panel collapsible if needed

    generatorSettings {
        var alpha1 =.09f
        var alpha2 = .175f
        var uifont= 18.sp
        var opsize=24

        Column(modifier = Modifier/*.verticalScroll(rememberScrollState())*/){//ez nem tud sajnos tovább gördülni, remélhetőleg kijavítják ezt a hibát
            settingListItem(content = {
                Column(horizontalAlignment = Alignment.CenterHorizontally,/* modifier = Modifier.wrapContentHeight()*/){
                    val buttonspacer = Modifier.width(5.dp)

                    Row(){
                        Button(onClick = {
                            viewModel.setMinI(0)
                            viewModel.setMaxI(0)
                            viewModel.setMin(1)
                            viewModel.setMax(12)
                            viewModel.setHosszMin(2)
                            viewModel.setHosszMax(4)
                            viewModel.setMixedFractionsB(false)
                            viewModel.setSmallerThanOne(true)
                            viewModel.setRandomTagszam(false)
                            viewModel.setOperatorFilter(0, true)
                            viewModel.setOperatorFilter(1, true)
                            viewModel.setOperatorFilter(2, false)
                            viewModel.setOperatorFilter(3, false)
                            // Call generate to update the problem
                            viewModel.generate()
                        }) {
                            Text(stringResource(Res.string.easy))
                        }
                        Spacer(modifier = buttonspacer)
                        Button(onClick = {
                            viewModel.setMinI(0)
                            viewModel.setMaxI(0)
                            viewModel.setMin(1)
                            viewModel.setMax(20)
                            viewModel.setHosszMin(2)
                            viewModel.setHosszMax(3)
                            viewModel.setRandomTagszam(true)
                            viewModel.setMixedFractionsB(false)
                            viewModel.setSmallerThanOne(true)
                            viewModel.setOperatorFilter(0, true)
                            viewModel.setOperatorFilter(1, true)
                            viewModel.setOperatorFilter(2, true)
                            viewModel.setOperatorFilter(3, false)
                            // Call generate to update the problem
                            viewModel.generate()
                        }) {
                            Text(stringResource(Res.string.medium))
                        }
                    }
                    Row(){
                        Button(onClick = {
                            viewModel.setMinI(0)
                            viewModel.setMaxI(0)
                            viewModel.setMin(-20)
                            viewModel.setMax(20)
                            viewModel.setHosszMin(2)
                            viewModel.setHosszMax(5)
                            viewModel.setRandomTagszam(true)
                            viewModel.setMixedFractionsB(true) //ez azért igaz, hogy a generált törtek kisebbek egynél esetben abszolút értékben is kisebek legyenek ezek a törtek egynél
                            viewModel.setSmallerThanOne(true)
                            viewModel.setOperatorFilter(0, true)
                            viewModel.setOperatorFilter(1, true)
                            viewModel.setOperatorFilter(2, true)
                            viewModel.setOperatorFilter(3, true)
                            // Call generate to update the problem
                            viewModel.generate()
                        }) {
                            Text(stringResource(Res.string.hard))
                        }
                        Spacer(modifier = buttonspacer)
                        Button(onClick = {
                            viewModel.setMinI(0)
                            viewModel.setMaxI(12)
                            viewModel.setMin(-25)
                            viewModel.setMax(25)
                            viewModel.setHosszMin(2)
                            viewModel.setHosszMax(5)
                            viewModel.setRandomTagszam(true)
                            viewModel.setMixedFractionsB(true)
                            viewModel.setSmallerThanOne(true)
                            viewModel.setOperatorFilter(0, true)
                            viewModel.setOperatorFilter(1, true)
                            viewModel.setOperatorFilter(2, true)
                            viewModel.setOperatorFilter(3, true)
                            // Call generate to update the problem
                            viewModel.generate()
                        }) {
                            Text(stringResource(Res.string.veryHard))
                        }
                    }
                }
            }, alpha1)

            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically){
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(
                            checked = uiState.operatorfilter[0],
                            onCheckedChange = { viewModel.setOperatorFilter(0, it) }
                        )
                        Text(text = "+", fontSize = opsize.sp, textAlign = TextAlign.Center)
                    }
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(
                            checked = uiState.operatorfilter[1],
                            onCheckedChange = { viewModel.setOperatorFilter(1, it) }
                        )
                        Text(text = "-", fontSize = opsize.sp, textAlign = TextAlign.Center)
                    }
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(
                            checked = uiState.operatorfilter[2],
                            onCheckedChange = { viewModel.setOperatorFilter(2, it) }
                        )
                        Text(text = "×", fontSize = opsize.sp, textAlign = TextAlign.Center)
                    }
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(
                            checked = uiState.operatorfilter[3],
                            onCheckedChange = { viewModel.setOperatorFilter(3, it) }
                        )
                        Text(text = "/", fontSize = opsize.sp, textAlign = TextAlign.Center)
                    }
                }
            }, alpha =alpha2
            )
            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically)
                {
                    Text(
                        text = stringResource(Res.string.IntMinMax),
                        modifier = Modifier
                            .padding(8.dp)
                            .weight(1f, fill = false),
                        fontSize = uifont
                    )
                    Spacer(modifier = Modifier.width(4.dp))

                    paramField(uiState.minI.toString(), enterText = {viewModel.setMinI(it)}, stringResource(Res.string.from))
                    Spacer(modifier = Modifier.width(4.dp))

                    paramField(uiState.maxI,  enterText = {viewModel.setMaxI(it)} ,stringResource(Res.string.to))
                }
            }, alpha =alpha1 )

           settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically)
                {
                    Text(
                        text = stringResource(Res.string.FractionMinMax),
                        modifier = Modifier
                            .padding(8.dp)
                            .weight(1f, fill = false),
                        fontSize = uifont
                    )
                    Spacer(modifier = Modifier.width(4.dp))

                    paramField(uiState.min, enterText = {viewModel.setMin(it)}, label = stringResource(Res.string.from))
                    Spacer(modifier = Modifier.width(4.dp))


                    paramField(uiState.max, enterText = {viewModel.setMax(it)}, label = stringResource(Res.string.to))
                }

            }, alpha =alpha2 )

            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically)
                {
                    Text(
                        text = stringResource(Res.string.v_letlenszer_tagsz_m),
                        modifier = Modifier
                            .padding(8.dp)
                            .weight(1f, fill = false),
                        fontSize = uifont
                    )
                    Spacer(modifier = Modifier.width(4.dp))

                    Switch(
                        checked = uiState.randomTagszam,
                        onCheckedChange = { viewModel.setRandomTagszam(it) })
                    Spacer(modifier = Modifier.width(4.dp))

                    var from  =stringResource(Res.string.from)
                    val termsfrom =
                        derivedStateOf {
                            if (uiState.randomTagszam)  from
                            else ""
                        }

                    paramField(uiState.hosszMin, enterText = {viewModel.setHosszMin(it)}, label = termsfrom.value,width=60)

                    if (uiState.randomTagszam) {
                        paramField(
                          uiState.hosszMax, enterText = {viewModel.setHosszMax(it)},
                            label = stringResource(Res.string.to) ,
                            width =60
                        )
                    }
                }

            }, alpha =alpha1)
            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically)
                {
                    Text(
                        stringResource(Res.string.mixedFractions),
                        modifier = Modifier.padding(8.dp),
                        fontSize = uifont
                    )
                    Spacer(modifier = Modifier.width(4.dp))

                    Switch(
                        checked =uiState. mixedFractionsB,
                        onCheckedChange = {viewModel.setMixedFractionsB(it) })
                }
            }, alpha =alpha2 )
            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically)
                {
                    Text(
                        stringResource(Res.string.GeneratedNumerators),
                        modifier = Modifier
                            .padding(8.dp)
                            .weight(1f, fill = false), //todo keresni egy workaroundot erre a bugra, a következő beállítás sor se teszi tönkre a görgetést
                        fontSize = uifont
                    )
                    Spacer(modifier = Modifier.width(4.dp))

                    Switch(
                        checked =uiState. smallerThanOne,
                        onCheckedChange = { viewModel.setSmallerThanOne(it) })

                }
            }, alpha =alpha1 )
            settingListItem(content = {
                Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.SpaceAround, modifier = Modifier.wrapContentHeight())
                {
                    //  Column(Modifier.wrapContentHeight()) {
                    Text(
                        stringResource(Res.string.FractionsAccurateIn),
                        modifier = Modifier
                            .padding(8.dp)
                            .weight(1f, fill = false),
                        fontSize = uifont
                    )
                    // }
                    Spacer(modifier = Modifier.width(4.dp))
                    Switch(
                        checked = uiState.törtPontosInB,
                        onCheckedChange = { viewModel.setTörtPontosInB(it) },
                        modifier = Modifier.wrapContentSize())
                }

            }, alpha =alpha2 )


        }

    }
}

@Composable
fun OperatorCheckbox(label: String, checked: Boolean, onCheckedChange: (Boolean) -> Unit) {
    Row(verticalAlignment = Alignment.CenterVertically, modifier = Modifier.padding(end = 8.dp)) {
        Checkbox(checked = checked, onCheckedChange = onCheckedChange)
        Text(text = label, fontSize = 24.sp)
    }
}

data class TortekUIState(
    val expression: List<Any> = emptyList(),
    val ints: List<Int> = emptyList(),
    val számlálók: List<Int> = emptyList(),
    val nevezők: List<Int> = emptyList(),
    val operators: List<String> = emptyList(),
    val task: String = "",
    val newint: Int = 0,
    val newszámláló: Int = 0,
    val newnevező: Int = 0,
    val newoperator: String = "",
    val LaTeXFormule: String = "",
    // Settings variables
    val osszead: Boolean = false,
    val kivon: Boolean = false,
    val szorzas: Boolean = false,
    val osztas: Boolean = false,
    val tagszam: Int = 2,
    val hosszMin: String = "2",
    val hosszMax: String = "4",
    val randomTagszam: Boolean = false,
    val min: String = "1",
    val max: String = "12",
    val minI: String = "0",
    val maxI: String = "1",
    val mixedFractionsB: Boolean = false,
    val smallerThanOne: Boolean = false,
    val törtPontosInB: Boolean = false,
    val operatorfilter: List<Boolean> = listOf(true, true, false, false),
    // Answer variables
    val userResponseInt: String = "",
    val userResponseN: String = "",
    val userResponseD: String = "",
    // Feedback
    val feedbackMessage: String = "",
    val isCorrect: Boolean = false,
    // Other variables
    val solution: Rational = Rational(0,1),
    val numberofsolved:Int=0,
    val showsolution :String=""
)

class Tortek2ViewModel(tag:String) : ViewModel() {
    val tag =tag
    // Exposed UI state
    private val _uiState = MutableStateFlow(TortekUIState())
    val uiState: StateFlow<TortekUIState> = _uiState.asStateFlow()

    private val operatorstrings = listOf("+", "-", "*", "/")

    // Initialization
  //  val state = uiState.value //todo rájönni, hogy ez miért nem működik

    var ints = mutableListOf<Int>()
    var számlálók = mutableListOf<Int>()
    var nevezők = mutableListOf<Int>()
    var operators = mutableListOf<String>()
    var expression = mutableListOf<Any>()
    var LaTeXFormule = ""
    var task = ""





    init {
        generate()
    }

    // Functions
    fun generate() {
        // Get the current UI state
        val hosszMin = uiState.value.hosszMin.toIntOrNull() ?: 2
        val hosszMax = uiState.value.hosszMax.toIntOrNull() ?: 4



        if (operatorstrings.filterIndexed { index, _ -> uiState.value.operatorfilter[index] }.isEmpty()) {
            _uiState.update { it.copy(feedbackMessage = "No operator selected.") }
            return
        }

         ints = mutableListOf<Int>()
         számlálók = mutableListOf<Int>()
         nevezők = mutableListOf<Int>()
         operators = mutableListOf<String>()
         expression = mutableListOf<Any>()
         LaTeXFormule = ""
         task = ""
        // Reset variables


        // Generate parameters
        val tagszam = if (uiState.value.randomTagszam) {
            Random.nextInt(/*hosszMin*/ 3, hosszMax + 1)
        } else {
            hosszMin
        }

        // Generate first term
        val newint = newInt()//todo ott folytatni, hogy közepes nehézségnél index out of range error 2 out of 2
        ints.add(newint)
        val newszámláló = newnum(1, 0)
        számlálók.add(newszámláló)
        val newnevező = newnum(2, 0)
        nevezők.add(newnevező)
        val newoperator = operatorstrings.filterIndexed { index, _ -> uiState.value.operatorfilter[index] }.random()
        operators.add(newoperator)
        task = "$newint + ($newszámláló / $newnevező)"
        expression.add(Rational(newszámláló + newint * newnevező, newnevező))
        LaTeXFormule = if (newint != 0) {
            "$newint\\frac{$newszámláló}{$newnevező}"
        } else {
            "\\frac{$newszámláló}{$newnevező}"
        }

        // Generate remaining terms
        for (i in 1 until tagszam) {
            val newintLoop = newInt()
            ints.add(newintLoop)
            val newszámlálóLoop = newnum(1, i)
            számlálók.add(newszámlálóLoop)
            val newnevezőLoop = newnum(2, i )
            nevezők.add(newnevezőLoop)
            val newoperatorLoop = operatorstrings.filterIndexed { index, _ -> uiState.value.operatorfilter[index] }.random()
            operators.add(newoperatorLoop)
            task += "$newoperatorLoop($newintLoop + ($newszámlálóLoop / $newnevezőLoop))"
            expression.add(newoperatorLoop)
            expression.add(Rational(newszámlálóLoop + newintLoop * newnevezőLoop, newnevezőLoop))
            LaTeXFormule += if (newintLoop != 0) {
                "$newoperatorLoop$newintLoop\\frac{$newszámlálóLoop}{$newnevezőLoop}"
            } else {
                "$newoperatorLoop\\frac{$newszámlálóLoop}{$newnevezőLoop}"
            }
        }

        LaTeXFormule = LaTeXFormule.replace("--", "+")
        LaTeXFormule = LaTeXFormule.replace("+-", "-")

        // Calculate the solution
        val solution: Rational = expression.evaluate()

        // Update the UI state
        _uiState.update {
            it.copy(
                expression = expression,
                ints = ints,
                számlálók = számlálók,
                nevezők = nevezők,
                operators = operators,
                task = task,
                newint = newint,
                newszámláló = newszámláló,
                newnevező = newnevező,
                newoperator = newoperator,
                LaTeXFormule = LaTeXFormule,
                tagszam = tagszam,
                feedbackMessage = "",
                userResponseInt = "",
                userResponseN = "",
                userResponseD = "",
                solution = solution
            )
        }
    }

    fun newInt():Int{
        val minI = uiState.value.minI.toIntOrNull() ?: 0
        val maxI = uiState.value.maxI.toIntOrNull() ?: 0

        if(uiState.value.mixedFractionsB) return  (minI..maxI).random()
        else return 0
    }

    fun newnum(IND:Int,kör:Int): Int { //Integer/Numerator/Denominator
        //1= számláló
        //2=nevező
        val min = uiState.value.min.toIntOrNull() ?: 1 //ezek azért kerültek ide, mert a függvény hívásakor frissül a változók értéke. ha a viewmodelben lennének, akkor nem változtnának meg
        val max = uiState.value.max.toIntOrNull() ?: 6
        var new:Int =0

        if(IND==1){//ha számláló
            new = (min..max-1).filter { it !=0 }.random()
            if(uiState.value.mixedFractionsB) new = new.absoluteValue
        }

        if(IND==2) {//ha nevező
            if(uiState.value.smallerThanOne){
                new = try {
                    (számlálók[kör]+1..max).filter { it !=0 }.random()
                } catch (e:Exception){ 2}
            }else{
                new = (min..max).filter { it !=0 }.random()
            }

            if(uiState.value.mixedFractionsB) new = new.absoluteValue
        }

        return new
    }

    suspend fun checkAnswer() {
        //todo eldönteni, hogy milyen üres inputokat ne fogadjunk el
        val state = _uiState.value
        val solution = state.solution

        val userFraction = try {

            if(state.userResponseInt.isEmpty()&& state.userResponseN.isEmpty()&& state.userResponseD.isEmpty()) return//ha mind üres akkor return

            val intPart = if (state.userResponseInt.isEmpty()) 0 else state.userResponseInt.toInt()//egyébként ezek legyenek az alapértékek
            val numPart = if (state.userResponseN.isEmpty()) 0 else state.userResponseN.toInt()
            val denomPart = if (state.userResponseD.isEmpty()) 1 else  state.userResponseD.toInt()
            Rational(intPart * denomPart + numPart, denomPart)
        } catch (e: Exception) {
           // _uiState.update { it.copy(feedbackMessage = "Invalid input.") } //todo display message
            return
        }

        if (state.törtPontosInB) {
            try {
                var n1 = if (state.userResponseN.isEmpty()) 0 else state.userResponseN.toInt()
                var n2 = if (state.userResponseD.isEmpty()) 1 else  state.userResponseD.toInt()

                if(n1>n2) return@checkAnswer
                if (n1 != 0 && n2 != 0) {
                    n1 = if (n1 > 0) n1 else -n1
                    n2 = if (n2 > 0) n2 else -n2
                    while (n1 != n2) {
                        if (n1 > n2)
                            n1 -= n2
                        else
                            n2 -= n1
                    }
                    if (n1 > 1) return@checkAnswer

                }
            } catch (e: Exception) {
                return return@checkAnswer
            }

            // Simplify the fraction and check that numerator and denominator are coprime
           /* val gcdValue = gcd(userFraction.numerator.abs().toInt(), userFraction.denominator.abs().toInt())
            if (gcdValue > 1) {
                _uiState.update { it.copy(feedbackMessage = "Please simplify your fraction.") }
                return
            }*/
        }

        if (userFraction == solution) {
            _uiState.update {
                it.copy(
                    feedbackMessage = "Correct!",
                    isCorrect = true,
                    numberofsolved = it.numberofsolved+1,
                    showsolution = solution.toString()
                )
            }
            // Log correct attempt if needed
        } else {
            val correctAnswer = solution.toString()
            _uiState.update {
                it.copy(
                    feedbackMessage = "Incorrect. The correct solution is $correctAnswer.",
                    isCorrect = false,
                    numberofsolved = it.numberofsolved+1,
                    showsolution = correctAnswer
                )
            }
            // Log incorrect attempt if needed
        }
        LaTexLogWriteDesktop(
            helyesMO = _uiState.value.isCorrect,
            userinput = userFraction.toLaTeX(),
            showSolutionIfUserWrong ="\\text{${getString(Res.string.megoldokulcs)} } ${_uiState.value.solution.toLaTeX()}".LaTeXWrap() ,
            activityType = tag,
            displayedTask = LaTeXFormule,
            answerLaTeXWrap = true
        )

        // Generate new problem
        generate()
    }

    private fun Rational.toMixedString(): String {//ezt hol használjuk?
        val intPart = this.numerator / this.denominator // Integer part
        val fracPart = this-(Rational(intPart,1))//todo ellenőrizni hogy ez jó-e
        return if (fracPart.numerator == 0) {
            intPart.toString()
        } else {
            if (intPart == 0) {
                "${fracPart.numerator}/${fracPart.denominator}"
            } else {
                "$intPart ${fracPart.numerator}/${fracPart.denominator}"
            }
        }
    }

    private fun gcd(a: Int, b: Int): Int {
        return if (b == 0) a else gcd(b, a % b)
    }

    // Functions to update user inputs
    fun updateUserResponseInt(value: String) {
        _uiState.update { it.copy(userResponseInt = value) }
    }

    fun updateUserResponseN(value: String) {
        _uiState.update { it.copy(userResponseN = value) }
    }

    fun updateUserResponseD(value: String) {
        _uiState.update { it.copy(userResponseD = value) }
    }

    // Functions to update settings
    fun setOperatorFilter(index: Int, value: Boolean) {
        val newOperatorFilter = _uiState.value.operatorfilter.toMutableList()
        newOperatorFilter[index] = value
        _uiState.update { it.copy(operatorfilter = newOperatorFilter) }
    }

    fun setMinI(value: Int) {
        _uiState.update { it.copy(minI = value.toString()) }
       // generate()
    }

    fun setMaxI(value: Int) {
        _uiState.update { it.copy(maxI = value.toString()) }
      //  generate()
    }

    fun setMin(value: Int) {
        _uiState.update { it.copy(min = value.toString()) }
       // generate()
    }

    fun setMax(value: Int) {
        _uiState.update { it.copy(max = value.toString()) }
      //  generate()
    }


    fun setRandomTagszam(value: Boolean) {
        _uiState.update { it.copy(randomTagszam = value) }
      //  generate()
    }

    fun setHosszMin(value: Int) {
        _uiState.update { it.copy(hosszMin = value.toString()) }
      //  generate()
    }

    fun setHosszMax(value: Int) {
        _uiState.update { it.copy(hosszMax = value.toString()) }
      //  generate()
    }

    fun setMinI(value: String) {
        _uiState.update { it.copy(minI = value) }
      //  generate()
    }

    fun setMaxI(value: String) {
        _uiState.update { it.copy(maxI = value) }
      //  generate()
    }

    fun setMin(value: String) {
        _uiState.update { it.copy(min = value) }
       // generate()
    }

    fun setMax(value: String) {
        _uiState.update { it.copy(max = value) }
       // generate()
    }

    fun setHosszMin(value: String) {
        _uiState.update { it.copy(hosszMin = value) }
       // generate()
    }

    fun setHosszMax(value: String) {
        _uiState.update { it.copy(hosszMax = value) }
       // generate()
    }




    fun setMixedFractionsB(value: Boolean) {
        _uiState.update { it.copy(mixedFractionsB = value) }
      //  generate()
    }

    fun setSmallerThanOne(value: Boolean) {
        _uiState.update { it.copy(smallerThanOne = value) }
      //  generate()
    }

    fun setTörtPontosInB(value: Boolean) {
        _uiState.update { it.copy(törtPontosInB = value) }
    }

    // Extension function to evaluate the expression
    private fun MutableList<Any>.evaluate(): Rational {
        var iterator = this.listIterator()
        while (iterator.hasNext()){
            var current =iterator.next()
            var nextindex = iterator.nextIndex()
            var lastindex = iterator.previousIndex()-1
            var muldiv = false
            if(current=="*"){
                muldiv = true
                iterator.set(this[lastindex] as Rational*this[nextindex] as Rational)
            }
            else if(current=="/"){
                muldiv = true
                iterator.set(this[lastindex] as Rational/this[nextindex] as Rational)
            }
            if (muldiv){
                iterator.previous()
                var last = iterator.previous()
                iterator.remove()
                iterator.next()
                iterator.next()
               // println("next is "+iterator.next())
               // println("next is "+iterator.next())
                iterator.remove()
            }
        }
        iterator = this.listIterator()
        while (iterator.hasNext()){
            var next = iterator.next()
            var nextindex = iterator.nextIndex()
            var lastindex = iterator.previousIndex()-1
            var sumsub= false
            if(next=="+"){
                sumsub = true
                iterator.set(this[lastindex] as Rational+this[nextindex] as Rational)
            }else if (next=="-"){
                sumsub = true
                iterator.set(this[lastindex] as Rational-this[nextindex] as Rational)
            }
            if (sumsub){
                iterator.previous()
                var last = iterator.previous()
                iterator.remove()
                iterator.next()
                iterator.next()
             //   println("next is "+iterator.next())
                //println("next is "+iterator.next())
                iterator.remove()
            }
        }
        return this[0] as Rational
    }
}