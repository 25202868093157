package matekit.matekit.taskgenerators

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Slider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.getstring
import matekit.matekit.utilityfunctions.removetrailingzeros
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.generatorSettings
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.MaxDistanceOfUnits
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.area
import matekit_multiplatform.composeapp.generated.resources.atvaltasTask
import matekit_multiplatform.composeapp.generated.resources.length
import matekit_multiplatform.composeapp.generated.resources.liquidMeasure
import matekit_multiplatform.composeapp.generated.resources.mass
import matekit_multiplatform.composeapp.generated.resources.volume
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.abs
import kotlin.random.Random

@Composable
fun UnitConverterScreen(navcontroller: NavController, viewModel: UnitConverterViewModel = viewModel{
    UnitConverterViewModel()
}) {
    val uiState by viewModel.uiState.collectAsState()
    val task = uiState.currentTask
  //  val showSettings by viewModel.showSettings.collectAsState() //ez lehet csak UI-ban tárolt érték
    val quantityNames = listOf(
        stringResource(Res.string.mass),
        stringResource(Res.string.length),
        stringResource(Res.string.area),
        stringResource(Res.string.volume),
        stringResource(Res.string.liquidMeasure)
    )
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(Unit) {
        viewModel.generateNewTask()
    }

   //  responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution) //weben ez nem ide jön
    backButton(navcontroller)
    Column(
        modifier = Modifier.fillMaxSize().padding(bottom=60.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        if (task != null) {
            var userInput by rememberSaveable { mutableStateOf("") }

            Column(
               // modifier = Modifier.weight(1f),
                verticalArrangement = Arrangement.SpaceAround,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Column(Modifier.wrapContentSize(), horizontalAlignment = Alignment.CenterHorizontally) {
                    borderedbasicfontsizeTextView(tasktext = uiState.taskText.getparamstr())
                    Spacer(modifier = Modifier.height(10.dp))
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        universalCheckField(
                            userInput = userInput,
                            check = { coroutineScope.launch { viewModel.checkSolution(userInput)
                                userInput = ""
                            } },
                            enterText = { userInput = it }
                        )
                        Spacer(Modifier.width(8.dp))
                        Button(
                            onClick = {
                                coroutineScope.launch {
                                    viewModel.checkSolution(userInput)
                                    userInput = ""
                                }

                            }
                        ) {
                            Text(stringResource(Res.string.Check))
                        }
                        Spacer(Modifier.width(8.dp))

                        LatexLogButton(navcontroller,"atvaltas")
                    }
                }
            }

            SettingsView(viewModel, quantityNames)

        } else {
            Text("Loading...")
        }
    }
    responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution) //weben ide
}
@Composable
fun SettingsView(viewModel: UnitConverterViewModel, quantityNames: List<String>) {
    val unitFilter by viewModel.unitFilter.collectAsState()
    val unitNames = viewModel.unitNames
    val unitFilters = viewModel.unitFilters.map { it.collectAsState() }
    val maxDistance by viewModel.maxDistance.collectAsState()

    Column(
        modifier = Modifier/*.verticalScroll(rememberScrollState())*/.widthIn(200.dp,600.dp)
    ) {
        generatorSettings() {
            settingListItem(content = {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                Text(text = stringResource(Res.string.MaxDistanceOfUnits)+": $maxDistance")

                Slider(
                    value = maxDistance.toFloat(),
                    onValueChange = { viewModel.setMaxDistance(it.toInt()) },
                    valueRange = 1f..5f,
                    steps = 3
                )}
            }, alpha = alpha2)

        // Unit Groups
        unitNames.forEachIndexed { index, units ->
            settingListItem(content = {

            UnitGroupOption(
                name = quantityNames[index],
                enabled = unitFilter[index],
                unitNames = unitNames[index],
                unitsEnabled = unitFilters[index].value,
                onGroupEnabledChange = { viewModel.setUnitGroupEnabled(index, it) },
                onUnitEnabledChange = { unitIndex, enabled ->
                    viewModel.setUnitEnabled(index, unitIndex, enabled)
                }
            )
            }, alpha = (if (index % 2 == 0) alpha1 else alpha2))

        }}
    }
}

@Composable
fun UnitGroupOption(
    name: String,
    enabled: Boolean,
    unitNames: List<String>,
    unitsEnabled: List<Boolean>,
    onGroupEnabledChange: (Boolean) -> Unit,
    onUnitEnabledChange: (Int, Boolean) -> Unit
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,
        modifier = Modifier.padding(8.dp)
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = enabled,
                onCheckedChange = { onGroupEnabledChange(it) }
            )
            Text(text = name)
        }
        AnimatedVisibility(visible = enabled) {
            Row(
                modifier = Modifier.horizontalScroll(rememberScrollState()),
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                unitNames.forEachIndexed { index, unitName ->
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Checkbox(
                            checked = unitsEnabled[index],
                            onCheckedChange = { onUnitEnabledChange(index, it) }
                        )
                        Text(text = unitName)
                    }
                }
            }
        }
    }
}
class UnitConverterViewModel : ViewModel() {
    private val _uiState = MutableStateFlow(UnitConverterUIState())
    val uiState: StateFlow<UnitConverterUIState> = _uiState.asStateFlow()

    private val _showSettings = MutableStateFlow(false)
    //val showSettings: StateFlow<Boolean> = _showSettings.asStateFlow() //ez olyan állapot, amit elég a felhasználói felületnek ismernie

    private val _maxDistance = MutableStateFlow(2)
    val maxDistance: StateFlow<Int> = _maxDistance.asStateFlow()

    // Quantity names
    val quantityNames = listOf("Mass", "Length", "Area", "Volume", "Liquid Measure")

    // Unit names and shift numbers for each quantity
    val unitNames = listOf(
        listOf("mg", "g", "dkg", "kg", "t"), // Mass
        listOf("mm", "cm", "dm", "m", "km"), // Length
        listOf("mm²", "cm²", "dm²", "m²", "ha", "km²"), // Area
        listOf("mm³", "cm³", "dm³", "m³", "km³"), // Volume
        listOf("ml", "cl", "dl", "l", "hl")  // Liquid Measure
    )
    val shiftNumbers = listOf(
        listOf(1000, 10, 100, 1000, 1), // Mass
        listOf(10, 10, 10, 1000, 1), // Length
        listOf(100, 100, 100, 10000, 100, 1), // Area
        listOf(1000, 1000, 1000, 1000000000, 1), // Volume
        listOf(10, 10, 10, 100, 1) // Liquid Measure
    )

    // Filters for unit groups and individual units
    private val _unitFilter = MutableStateFlow(List(unitNames.size) { true })
    val unitFilter: StateFlow<List<Boolean>> = _unitFilter.asStateFlow()

    // Filters for individual units within each unit group
    val unitFilters = unitNames.map { units ->
        MutableStateFlow(List(units.size) { true })
    }

    init {
        generateNewTask()
    }

    fun toggleSettings() {
        _showSettings.value = !_showSettings.value
    }

    fun setMaxDistance(value: Int) {
        _maxDistance.value = value
    }

    fun setUnitGroupEnabled(index: Int, enabled: Boolean) {
        val unitFilterList = _unitFilter.value.toMutableList()
        unitFilterList[index] = enabled
        _unitFilter.value = unitFilterList

        // If group is disabled, disable all units in the group
        if (!enabled) {
            unitFilters[index].value = List(unitFilters[index].value.size) { false }
        } else {
            // If group is enabled, enable all units in the group
            unitFilters[index].value = List(unitFilters[index].value.size) { true }
        }
    }

    fun setUnitEnabled(groupIndex: Int, unitIndex: Int, enabled: Boolean) {
        val unitsEnabled = unitFilters[groupIndex].value.toMutableList()
        unitsEnabled[unitIndex] = enabled
        unitFilters[groupIndex].value = unitsEnabled
    }

    fun generateNewTask() {
        // Filter unit groups based on whether they are enabled
        val enabledUnitGroups = unitNames.indices.filter { _unitFilter.value[it] }
        if (enabledUnitGroups.isEmpty()) {
            // Handle the case where no unit groups are enabled
            // Maybe display a message or default to a specific group
            return
        }

        // Randomly select a unit group
        val groupIndex = enabledUnitGroups.random()
        val units = unitNames[groupIndex]
        val shifts = shiftNumbers[groupIndex]
        val unitsEnabled = unitFilters[groupIndex].value

        // Filter units within the selected group
        val filteredUnits = units.withIndex().filter { unitsEnabled[it.index] }.map { it.value }
        val filteredShifts = shifts.withIndex().filter { unitsEnabled[it.index] }.map { it.value }

        if (filteredUnits.size < 2) {
            // Not enough units to create a task, try generating a new task
            // Or handle accordingly
            //todo itt ellenőrizni, hogy lehet-e még új feladatot generálni
            generateNewTask()
            return
        }

        // Randomly select two different units
        val indexOfFirstUnit = Random.nextInt(filteredUnits.size)
        var indexOfSecondUnit = Random.nextInt(filteredUnits.size - 1)
        if (indexOfSecondUnit >= indexOfFirstUnit) {
            indexOfSecondUnit += 1
        }

        // Check if units are within max distance
        val distance = abs(indexOfFirstUnit - indexOfSecondUnit)
        if (distance > _maxDistance.value) {
            // Units are too far apart, try generating a new task
            generateNewTask()
            return
        }

        // Generate a random value to convert
        val valueToConvert = when (groupIndex) {
            0 -> Random.nextInt(1, 1000).toDouble() // Mass
            1 -> Random.nextInt(1, 500).toDouble()  // Length
            2 -> Random.nextInt(1, 1000).toDouble() // Area
            3 -> Random.nextInt(1, 1000).toDouble() // Volume
            4 -> Random.nextInt(1, 100).toDouble() // Liquid Measure
            else -> Random.nextInt(1, 500).toDouble()
        }

        // Calculate the conversion factor
        var conversionFactor = 1.0
        val start = minOf(indexOfFirstUnit, indexOfSecondUnit)
        val end = maxOf(indexOfFirstUnit, indexOfSecondUnit)
        for (i in start until end) {
            conversionFactor *= filteredShifts[i]
        }

        // Calculate the solution
        val solution = if (indexOfFirstUnit < indexOfSecondUnit) {
            valueToConvert * conversionFactor
        } else {
            valueToConvert / conversionFactor
        }

        // Build the task text
        val taskText = Paramstr(Res.string.atvaltasTask,
            listOf(filteredUnits[indexOfFirstUnit],valueToConvert.removetrailingzeros(),filteredUnits[indexOfSecondUnit])
        )


            "Convert ${valueToConvert.removetrailingzeros()} ${filteredUnits[indexOfFirstUnit]} to ${filteredUnits[indexOfSecondUnit]}" //todo stringresource

        // Update the UI state with the new task details
        _uiState.update {
            it.copy(
                currentTask = UnitConversion(
                    unitNames = filteredUnits,
                    shiftNumbers = filteredShifts
                ),
                valueToConvert = valueToConvert,
                firstUnit = filteredUnits[indexOfFirstUnit],
                secondUnit = filteredUnits[indexOfSecondUnit],
                solution = solution,
                taskText = taskText
            )
        }
    }

    suspend fun checkSolution(userInput: String) {
        val input = try {
            userInput.replace(",", ".").toDouble()//ha a kotlinnak . a tizedes elválasztó, akkor legyen az
        }catch (e:Exception){return@checkSolution}
        val isCorrect = input ==_uiState.value.solution

        val feedback = if (isCorrect) {
            "Correct Solution"
        } else {
            "Wrong Solution: ${_uiState.value.solution}"
        }


        _uiState.update {
            it.copy(isCorrect = isCorrect, numberofsolved = it.numberofsolved + 1, showsolution = it.solution.removetrailingzeros())
        }
        // Handle feedback display (e.g., Toast or Snackbar)
        // For now, we'll just print the feedback
        //todo use our toastlibrary
     //   println(feedback)
        var showsolution = getString(Res.string.WrongSolution, _uiState.value.solution.formatToDecimals(24), )
        LaTexLogWriteDesktop(isCorrect,userInput,showsolution,"atvaltas",Paramstr(Res.string.atvaltasTask,
            listOf(uiState.value.firstUnit,uiState.value.valueToConvert.removetrailingzeros(),uiState.value.secondUnit)
        ).getstring(), taskLaTexWrap = false)

        generateNewTask()
    }
}

data class UnitConverterUIState(
    val currentTask: UnitConversion? = null,
    val valueToConvert: Double = 0.0,
    val firstUnit: String = "",
    val secondUnit: String = "",
    val userInput: String = "",
    val solution: Double = 0.0,
    val taskText: Paramstr = Paramstr(Res.string.atvaltasTask, listOf(0,"",0)),
    val isCorrect:Boolean=false,
    val numberofsolved:Int=0,
    var showsolution:String=""
)

data class UnitConversion(
    val unitNames: List<String>,
    val shiftNumbers: List<Int>
)

fun convertUnits(value: Double, fromIndex: Int, toIndex: Int, shiftNumbers: List<Int>): Double {
    var conversionFactor = 1.0
    val start = minOf(fromIndex, toIndex)
    val end = maxOf(fromIndex, toIndex)
    for (i in start until end) {
        conversionFactor *= shiftNumbers[i]
    }
    return if (fromIndex < toIndex) value * conversionFactor else value / conversionFactor
}