package matekit.matekit.games.szamkiraly


import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import androidx.compose.foundation.border
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.interaction.collectIsPressedAsState
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.*

import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString

import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewmodel.compose.viewModel
//import matekit.matekit.OrientationChecker
import matekit.matekit.taskgenerators.evaluateExpression
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.randomNotZero
import matekit.ui.ratios
import matekit.ui.theme.matekitcolor
import matekit.ui.widthtype
import matekit_multiplatform.composeapp.generated.resources.Blue
import matekit_multiplatform.composeapp.generated.resources.Red
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.Tie
import matekit_multiplatform.composeapp.generated.resources.alapmuveletekMaradék
import matekit_multiplatform.composeapp.generated.resources.bluewontheround
import matekit_multiplatform.composeapp.generated.resources.denominator
import matekit_multiplatform.composeapp.generated.resources.ic_backbt
import matekit_multiplatform.composeapp.generated.resources.ic_enterbt
import matekit_multiplatform.composeapp.generated.resources.ic_restart
import matekit_multiplatform.composeapp.generated.resources.integer
import matekit_multiplatform.composeapp.generated.resources.numerator
import matekit_multiplatform.composeapp.generated.resources.redwontheround
import matekit_multiplatform.composeapp.generated.resources.won
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import kotlin.math.absoluteValue

//import kotlinx.serialization.Serializable

@Composable
fun MathKingGameScreen(//a kód ezen részét nem nagyon érinti ha változtatunk a navigáción
    gameSettings: MathKingGameSettings,
    onGameEnd: () -> Unit,
    ratios: ratios,
    viewModel: MathKingGameViewModel = viewModel { MathKingGameViewModel(gameSettings, ratios) },
    orientation: MutableState<String>,
) {
    val uiState by viewModel.uiState.collectAsState()
    //val orientation =ratios.orientation //OrientationChecker().checkOrientation()
    //println("számkirály orientation = $orientation")
    val coroutineScope = rememberCoroutineScope()

    if (orientation.value == "Landscape") {
        Row(
            modifier = Modifier.fillMaxSize(),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically
        ) {
            // Red Player Section
            PlayerSection(
                uiState = uiState,
                player = "Red",//todo ez legyen string resource
                taskText = uiState.currentTask,
                playerInput = uiState.redInput,
                inputSelection = uiState.redSelection,
                inputType = uiState.redInputType,
                onInputChanged = viewModel::onRedInputChanged,
                onCheck = { coroutineScope.launch { viewModel.checkRedAnswer() } },
                backgroundColor = Color.Red,// Color(0xFFFFCDD2),
                modifier = Modifier.weight(1f),
                ratios,
                onTextFieldClick = viewModel::onTextFieldClick
            )
            // Divider(color = Color.Black)
            // Blue Player Section
            PlayerSection(
                uiState = uiState,
                player = "Blue",
                taskText = uiState.currentTask,
                inputSelection = uiState.blueSelection,
                playerInput = uiState.blueInput,
                inputType = uiState.blueInputType,
                onInputChanged = viewModel::onBlueInputChanged,
                onCheck = { coroutineScope.launch { viewModel.checkBlueAnswer() } },
                backgroundColor = Color(0xFF03A9F4), //Color(0xFFBBDEFB),
                modifier = Modifier.weight(1f),
                ratios=ratios,
                onTextFieldClick = viewModel::onTextFieldClick

            )
        }
    } else {//todo
        Column(modifier = Modifier.fillMaxSize()) {
            // Red Player Section
            PlayerSection(
                uiState = uiState,
                player = "Red",//todo ez legyen string resource
                taskText = uiState.currentTask,
                playerInput = uiState.redInput,
                inputSelection = uiState.redSelection,
                inputType = uiState.redInputType,
                onInputChanged = viewModel::onRedInputChanged,
                onCheck = { coroutineScope.launch { viewModel.checkRedAnswer() } },
                backgroundColor = Color.Red,
                modifier = Modifier.weight(1f).rotate(180f),
                ratios=ratios,
                onTextFieldClick = viewModel::onTextFieldClick
            )
            Divider(color = Color.Black)
            // Blue Player Section
            PlayerSection(
                uiState = uiState,
                player = "Blue",
                taskText = uiState.currentTask,
                playerInput = uiState.blueInput,
                inputSelection = uiState.blueSelection,
                inputType = uiState.blueInputType,
                onInputChanged = viewModel::onBlueInputChanged,
                onCheck = { coroutineScope.launch { viewModel.checkBlueAnswer() } },
                backgroundColor =  Color(0xFF03A9F4),
                modifier = Modifier.weight(1f),
                ratios=ratios,

                onTextFieldClick = viewModel::onTextFieldClick

            )
        }
    }



    if (uiState.showResultsButton) {
        ResultDialog(
            gameResult = uiState.gameResult,
            onRestart = viewModel::restartGame,
            onExit = onGameEnd,
            roundsList = uiState.roundsList,
            redScore = uiState.redscore,
            blueScore = uiState.bluescore

        )
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PlayerSection(
    uiState: MathKingGameUiState,
    player: String,
    taskText: String,
    playerInput: PlayerInput,
    inputSelection: playerinputselection,
    inputType: String,
    onInputChanged: (PlayerInput) -> Unit,
    onCheck: () -> Unit,
    backgroundColor: Color,
    modifier: Modifier = Modifier,
    ratios:ratios,
    onTextFieldClick: (playerinputselection, String) -> Unit
) {
    val modifier = modifier
        .fillMaxSize()
        // .background(backgroundColor)
        .padding(ratios.smallpadding)

    Column(
        modifier,
        verticalArrangement = Arrangement.SpaceBetween,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {



        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {

            Column(
                modifier = Modifier
                    .fillMaxSize()
                    // .background(backgroundColor) //ne a háttér legyen olyan színű, hanem a gombok
                    .padding(16.dp),
                verticalArrangement = Arrangement.SpaceBetween,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
val extraheight = if(ratios.widthtype==widthtype.small){0} else 100
                Surface(
                    modifier = Modifier.fillMaxWidth().wrapContentHeight().heightIn(min = (60+extraheight).dp).padding(8.dp),
                    shape = Shapes().large,
                    color = backgroundColor,


                    ) {
                    Box(
                        modifier = Modifier.fillMaxWidth()
                            .wrapContentHeight(),
                        contentAlignment = Alignment.Center // Center the content
                    ) {
                        Text(
                            text = taskText,
                            style = MaterialTheme.typography.titleLarge,
                            color = Color.White, // Set text color to white
                            fontSize = ratios.szamkiralyLargeTitle,
                        )
                    }

                }


                if (uiState.roundResult.isNotEmpty()) {
                    Text(
                        text = uiState.roundResult,
                        // style = MaterialTheme.typography.subtitle1,
                        fontSize = ratios.szamkiralymedium,
                        modifier = Modifier.align(Alignment.CenterHorizontally)
                    )
                }

                val remaindersrc = remember { MutableInteractionSource() }
                if (remaindersrc.collectIsPressedAsState().value) {
                    onTextFieldClick(playerinputselection.REMAINDER, player)
                    //println("remainder clicked")
                }
                val integersrc = remember { MutableInteractionSource() }
                if (integersrc.collectIsPressedAsState().value) {
                    onTextFieldClick(playerinputselection.INTEGER, player)
                }
                val numeratorsrc = remember { MutableInteractionSource() }
                if (numeratorsrc.collectIsPressedAsState().value) {
                    onTextFieldClick(playerinputselection.NUMERATOR, player)
                }
                var denominatorsrc = remember { MutableInteractionSource() }
                if (denominatorsrc.collectIsPressedAsState().value) {
                    onTextFieldClick(playerinputselection.DENOMINATOR, player)
                }


                // Inputs
                when (inputType) {
                    "simple" -> {
                        Text(
                            playerInput.integerPart,
                            modifier = Modifier.fillMaxWidth().wrapContentHeight()
                                .border(BorderStroke(6.dp, Color.Green)).padding(ratios.padding),
                            style = MaterialTheme.typography.headlineLarge
                        )
                    }

                    "accurate" -> {//todo ezzel később foglalkozni
                        Row(verticalAlignment = Alignment.CenterVertically, horizontalArrangement = Arrangement.Center) {
                            OutlinedTextField(
                                value = playerInput.integerPart,
                                onValueChange = {/* onInputChanged("int", it)*/ },
                                label = { Text(stringResource(Res.string.integer)) },
                                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                /*modifier = Modifier.onClick {
                                    onTextFieldClick(playerinputselection.INTEGER,player)
                                }*/
                                interactionSource = integersrc,
                            )

                            Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center) {
                                OutlinedTextField(
                                    value = playerInput.numerator,
                                    onValueChange = {/* onInputChanged("numerator", it)*/ },
                                    label = { Text(stringResource(Res.string.numerator)) },
                                    keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                    modifier = Modifier/*.weight(1f).onClick {
                                    onTextFieldClick(playerinputselection.NUMERATOR,player)
                                }*/,
                                    readOnly = true,
                                    interactionSource = numeratorsrc
                                )
                                Spacer(modifier = Modifier.height(4.dp))
                                OutlinedTextField(
                                    value = playerInput.denominator,
                                    onValueChange = {/* onInputChanged("denominator", it)*/ },
                                    modifier = Modifier/*.weight(1f).onClick
                                {
                                    onTextFieldClick(playerinputselection.DENOMINATOR,player)
                                }*/,
                                    label = { Text(stringResource(Res.string.denominator)) },
                                    keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),

                                    readOnly = true,
                                    interactionSource = denominatorsrc
                                )
                            }
                        }
                    }

                    "simplemaradek" -> {
                        OutlinedTextField(
                            value = playerInput.integerPart,
                            onValueChange = {/* onInputChanged("int", it) */ },
                            label = { Text(stringResource(Res.string.integer)) },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            readOnly = true,
                            /*modifier = Modifier.onClick {
                                onTextFieldClick(playerinputselection.INTEGER,player)
                            }*/
                            interactionSource = integersrc


                        )
                        OutlinedTextField(
                            value = playerInput.remainder,
                            onValueChange = {/* onInputChanged("denominator", it)*/ },
                            label = { Text(stringResource(Res.string.alapmuveletekMaradék)) },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            readOnly = true,
                            /* modifier = Modifier.onClick {
                                  onTextFieldClick(playerinputselection.REMAINDER, player)
                                  println("remainder clicked")
                              }*/
                            interactionSource = remaindersrc
                        )
                    }
                }
                SzamkiralyKeyboard(
                    playerInput,
                    inputSelection,
                    onInputChanged,
                    onCheck,
                    color = backgroundColor,
                    ratios
                )
//todo a submit button legyen a billentyűzet része
// Display Round Result
            }
val countersize = if(ratios.widthtype==widthtype.small){
    150
}else {300}
            if (uiState.counter > 0) {
                Surface(
                    modifier = Modifier.size(countersize.dp),
                    shape = CircleShape,
                    color = matekitcolor.copy(alpha = 0.85f),
                    border = BorderStroke(2.dp, matekitcolor)
                ) {}
                Text(uiState.counter.toString(), fontSize = 128.sp, textAlign = TextAlign.Center)
            }
        }
    }


}

@Composable
fun SzamkiralyKeyboard(
    playerInput: PlayerInput,
    inputSelection: playerinputselection,
    onButtonClick: (PlayerInput) -> Unit,
    onCheck: () -> Unit,
    color: Color,
    ratios: ratios
) {
    val buttonwidth = when(ratios.widthtype){
        widthtype.small->70
            widthtype.medium->100
                widthtype.large->120
    }
    val buttonheight = when(ratios.widthtype){
        widthtype.small->50
        widthtype.medium->100
        widthtype.large->120
    }


    val keebsizemodifier = if(ratios.widthtype==widthtype.small){Modifier.fillMaxWidth(.8f).fillMaxHeight().padding(4.dp)} else{
        Modifier.width((120 * 3 + 160).dp).wrapContentHeight().padding(8.dp)
    }

    Row(
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        val coroutineScope = rememberCoroutineScope()
        Column(
            modifier = keebsizemodifier) {
            val buttons = listOf(
                listOf("1", "2", "3"),
                listOf("4", "5", "6"),
                listOf("7", "8", "9"),
                listOf("-", "0", ","),
            )
            val rowModifier = if(ratios.widthtype==widthtype.small){Modifier.fillMaxWidth().height(buttonheight.dp)} else{
                Modifier.width((120 * 3).dp).wrapContentHeight()
            }

            buttons.forEach { row ->
                Row(
                    modifier = rowModifier,
                    horizontalArrangement = Arrangement.Start
                ) {
                    row.forEach { label ->
                        Button(
                            onClick = {
                                val newInput = when (inputSelection) {
                                    playerinputselection.INTEGER -> playerInput.copy(integerPart = playerInput.integerPart + label)
                                    playerinputselection.NUMERATOR -> playerInput.copy(numerator = playerInput.numerator + label)
                                    playerinputselection.DENOMINATOR -> playerInput.copy(denominator = playerInput.denominator + label)
                                    playerinputselection.REMAINDER -> playerInput.copy(remainder = playerInput.remainder + label)
                                }
                                onButtonClick(newInput)
                            },
                            modifier = if(ratios.widthtype==widthtype.small){Modifier.weight(1f)} else {Modifier.width(buttonwidth.dp).height(buttonheight.dp)}
                              //  .size(buttonsize.dp)
                                .padding(4.dp),
                            colors = ButtonDefaults.buttonColors(
                                containerColor = color // Set the button color here
                            ),
                            shape = RoundedCornerShape(10)
                        ) {
                            Text(
                                text = label,
                                fontSize = ratios.szamkiralyLargeTitle,
                                textAlign = TextAlign.Center,
                                color = Color.White
                            )
                        }
                    }

                }
            }


        }
        Column(modifier = Modifier.wrapContentSize()) {
            Button(
                onClick = {
                    val newInput = when (inputSelection) {
                        playerinputselection.INTEGER -> playerInput.copy(
                            integerPart = playerInput.integerPart.dropLast(
                                1
                            )
                        )//todo mi történik empty stringgel
                        playerinputselection.NUMERATOR -> playerInput.copy(
                            numerator = playerInput.numerator.dropLast(
                                1
                            )
                        )

                        playerinputselection.DENOMINATOR -> playerInput.copy(
                            denominator = playerInput.denominator.dropLast(
                                1
                            )
                        )

                        playerinputselection.REMAINDER -> playerInput.copy(
                            remainder = playerInput.remainder.dropLast(
                                1
                            )
                        )
                    }
                    onButtonClick(newInput)
                }, Modifier.size(buttonwidth.dp),
                colors = ButtonDefaults.buttonColors(
                    containerColor = color // Set the button color here
                )
            )
            {
                Icon(painterResource(Res.drawable.ic_backbt), contentDescription = "Check")
            }
            Button(
                onClick = { onCheck() }, Modifier
                    .size(buttonwidth.dp),
                colors = ButtonDefaults.buttonColors(
                    containerColor = color // Set the button color here
                )
            ) {
                Icon(painterResource(Res.drawable.ic_enterbt), contentDescription = "Check")
            }
        }
    }
}

@Composable
fun ResultDialog(
    gameResult: String,
    onRestart: () -> Unit,
    onExit: () -> Unit,
    roundsList: List<Round>,
    redScore: Int,
    blueScore: Int
) {
    AlertDialog(
        onDismissRequest = {},
        title = { Text(text = gameResult) },
        text = {
            val scrollState = rememberScrollState()

            Box(
                modifier = Modifier.fillMaxWidth().fillMaxHeight(0.8f),
                contentAlignment = Alignment.Center // Center the Column within the Box

            ) { // Adjust the size as needed
                Column(
                    modifier = Modifier.padding(16.dp).fillMaxWidth().verticalScroll(scrollState),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {

                    Text(
                        text = buildAnnotatedString {
                            withStyle(style = SpanStyle(color = Color.Red)) {
                                append("$redScore")
                            }
                            append(" - ")
                            withStyle(style = SpanStyle(color = Color.Blue)) {
                                append("$blueScore")
                            }
                        },
                        style = MaterialTheme.typography.bodyLarge, fontSize = 48.sp
                    )

                    roundsList.forEach { round ->
                        Text(
                            text = "${round.taskDisplay}\n" +
                                    "${stringResource(Res.string.Solution)}: ${round.solutionDisplay}\n" +
                                    "${stringResource(Res.string.Blue)}:" + "${round.blueInputDisplay}\n" +
                                    "${stringResource(Res.string.Red)}:" + "${round.redInputDisplay}\n" +
                                    "${round.result}\n",

                            style = MaterialTheme.typography.bodyMedium,
                            modifier = Modifier.padding(bottom = 8.dp),
                            textAlign = TextAlign.Center,
                            fontSize = 24.sp,
                            lineHeight = 32.sp
                        )
                    }
                }
            }
        },
        /*  confirmButton = {
        Button(onClick = onRestart, modifier = Modifier.size(200.dp)) {
        Icon(
        painter = painterResource(Res.drawable.ic_restart),
        contentDescription = "Restart"
        )
        }
        },
        dismissButton = {
        Button(onClick = onExit, modifier = Modifier.size(200.dp)) {
        Icon(
        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
        contentDescription = "Back"
        )
        }
        },*/
        confirmButton = {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(16.dp),
                horizontalArrangement = Arrangement.SpaceEvenly,
                verticalAlignment = Alignment.CenterVertically
            ) {
                IconButton(
                    onClick = onExit,
                    modifier = Modifier.size(64.dp).padding(start = 8.dp)
                ) {
                    Icon(
                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                        contentDescription = "Back",
                        tint = Color.Black,
                        modifier = Modifier.size(64.dp)
                    )
                }
                IconButton(
                    onClick = onRestart,
                    modifier = Modifier.size(64.dp).padding(end = 8.dp)
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_restart),
                        contentDescription = "Restart",
                        tint = Color.Black,
                        modifier = Modifier.size(64.dp)
                    )
                }

            }
        }
    )
}
/*
class MathKingGameViewModelFactory(
private val gameSettings: MathKingGameSettings
) : ViewModelProvider.Factory {
@Suppress("UNCHECKED_CAST")
override fun <T : ViewModel> create(modelClass: Class<T>): T {
if (modelClass.isAssignableFrom(MathKingGameViewModel::class.java)) {
return MathKingGameViewModel(gameSettings) as T
}
throw IllegalArgumentException("Unknown ViewModel class")
}

// For newer versions, you might also need to override the new create method
@Suppress("UNCHECKED_CAST")
override fun <T : ViewModel> create(modelClass: Class<T>, extras: CreationExtras): T {
return create(modelClass)
}
}*/

class MathKingGameViewModel(
    val gameSettings: MathKingGameSettings,//a viewmodel paraméterként kapja meg a játék beállításait
    val ratios:ratios
) : ViewModel() {

    private val _uiState = MutableStateFlow(
        MathKingGameUiState(//mert maga a uiState is paraméterként kapja a beállításokat
            gameSettings = gameSettings,
            totalRounds = gameSettings.korszam
        )
    )
    val uiState: StateFlow<MathKingGameUiState> = _uiState

    // Game Variables
    private var szamok = mutableListOf<Int>()
    private var operationsToChooseFrom = mutableListOf<String>()
    private var operationsInTask = mutableListOf<String>()
    private var task = ""
    private var solution = 0.0
    private var solutionRemainder = 0
      private var roundsList = mutableListOf<Round>()

    init {
        setupOperations()
        startGame()
    }

    private fun setupOperations() {
        val settings = gameSettings
        if (settings.osszead) operationsToChooseFrom.add("+")
        if (settings.kivon) operationsToChooseFrom.add("-")
        if (settings.szorzas) operationsToChooseFrom.add("*")
        if (settings.osztas) operationsToChooseFrom.add("/")
    }

    fun onTextFieldClick(
        playerinputselection: playerinputselection,
        player: String
    ) {
        _uiState.update {
            if (player == "Red") {
                it.copy(
                    redSelection = playerinputselection,
                )
            } else {
                it.copy(
                    blueSelection = playerinputselection,
                )

            }
        }
    }

    private fun startGame() {
        _uiState.update {
            it.copy(
                bluescore = 0,
                redscore = 0,
                ties = 0,
                roundsCompleted = 0,
                isRoundRunning = false,
                showResultsButton = false,
                gameResult = "",
                roundResult = "",
                roundsList = emptyList()
            )
        }
        startRoundWithDelay()
    }

    private fun startRoundWithDelay() {

        _uiState.update {
            it.copy(
                counter = gameSettings.delay
            )
        }

        viewModelScope.launch {
            while (uiState.value.counter > 0) {
                delay(1000L) // Wait for 1 second
                _uiState.update {
                    it.copy(
                        counter = it.counter - 1
                    )
                }
                //println("counter = ${uiState.value.counter}")
            }
            startRound()
        }


    }

    private suspend fun startRound() {
        _uiState.update {
            it.copy(
                isRoundRunning = true,
                blueSent = false,
                redSent = false,
                blueInput = PlayerInput(),
                redInput = PlayerInput(),
                roundResult = ""
            )
        }
        generateTask()
    }

    private suspend fun generateTask() {
        szamok.clear()
        task = ""
        operationsInTask.clear()
        solutionRemainder = 0

        val settings = gameSettings
        val tagszam = if (settings.randomTagszam) {
            (settings.tagSzamMin..settings.tagSzamMax).random()
        } else {
            settings.tagszam
        }

        repeat(tagszam) { newNum() }

        repeat(tagszam - 1) {
            val operation = operationsToChooseFrom.random()
            operationsInTask.add(operation)
        }

// Adjust task based on recommended modifiers
        if (settings.recommendedModifiers && tagszam > 2 &&
            (operationsToChooseFrom.contains("+") || operationsToChooseFrom.contains("-"))
        ) {
            val muls = operationsInTask.count { it == "*" }
            val divs = operationsInTask.count { it == "/" }
            if ((muls + divs) > operationsInTask.size / 2) {
                generateTask()//todo ezt már kicsit okosabban kellene
                return
            }
        }
        if (tagszam == 2 && operationsInTask.contains("/") && (szamok[0] <= (szamok[1]).absoluteValue) || szamok[1] == 1) {//maradékos osztásnál nagyobb legyen amit osztunk az osztónál
            generateTask()
            return
        }

        task += szamok[0].toString()
        operationsInTask.forEachIndexed { index, operator ->
            task += operator
            val nextNum = szamok[index + 1]
            if ((operator == "+" || operator == "-") && nextNum < 0) {
                task += "(${nextNum})"
            } else {
                task += nextNum.toString()
            }
        }

// Calculate solution
        try {
            solution = evaluateExpression(task)
            if (operationsInTask.contains("/") && tagszam == 2) {//Akkor ez egy maradékos osztás
                solutionRemainder = szamok[0] % szamok[1]
                solution =
                    (szamok[0] / szamok[1]).toInt().toDouble()//a hányadosuk egészrészét vegyük
            }
        } catch (e: Exception) {
            generateTask()
            return
        }

// Determine recommended input type
        var recommendedInput = when {
            operationsInTask.contains("/") && tagszam == 2 -> "simplemaradek"
            operationsInTask.contains("/") -> "accurate"
            else -> "simple"
        }
        if(ratios.widthtype==widthtype.small){//kis telefonon ne legyen másik. ilyen formában úgyse fogják használni
            recommendedInput = "simple"
        }

        /* val startinput = when(
             recommendedInput
         ){
             "simple" -> playerinputselection.INTEGER
             "accurate" -> playerinputselection.INTEGER
             "simplemaradek" -> playerinputselection.INTEGER
         }*/

        val newRound = Round(
            taskDisplay = task.replace("*", "×"),
            blueInputDisplay = "",
            redInputDisplay = "",
            result = "",
            solutionDisplay = if (recommendedInput == "simplemaradek")
                "${solution.toInt()} ${getString(Res.string.alapmuveletekMaradék)} $solutionRemainder"//todo string resourcet használjunk
            else
                solution.formatToDecimals(8),
            index = _uiState.value.roundsCompleted + 1
        )
        roundsList = uiState.value.roundsList.toMutableList()
        roundsList.add(newRound)

        _uiState.update {
            it.copy(
                currentTask = newRound.taskDisplay,
                solution = solution,
                solutionRemainder = solutionRemainder,
                recommendedInput = recommendedInput,
                blueInputType = recommendedInput,//ezt lehetne egyszerűsíteni, de ha valaha bonyolítani akarjuk az opciókat, akkor jobb így
                redInputType = recommendedInput,
                roundsList = roundsList,
                blueSelection = playerinputselection.INTEGER,
                redSelection = playerinputselection.INTEGER
            )
        }

        //println("it.currentTask = ${uiState.value.currentTask}")

    }

    private fun newNum() {
        val min = gameSettings.min
        val max = gameSettings.max
        var newNum = (min..max).randomNotZero()
        szamok.add(newNum)
    }

    fun onBlueInputChanged(input: PlayerInput) {
        /* val updatedInput = when (inputField) {
        "int" -> _uiState.value.blueInput.copy(integerPart = value)
        "numerator" -> _uiState.value.blueInput.copy(numerator = value)
        "denominator" -> _uiState.value.blueInput.copy(denominator = value)
        else -> _uiState.value.blueInput
        }*/
        _uiState.update { it.copy(blueInput = input) }
    }

    fun onRedInputChanged(input: PlayerInput) {
        /* val updatedInput = when (inputField) {
        "int" -> _uiState.value.redInput.copy(integerPart = value)
        "numerator" -> _uiState.value.redInput.copy(numerator = value)
        "denominator" -> _uiState.value.redInput.copy(denominator = value)
        else -> _uiState.value.redInput
        }*/
        _uiState.update { it.copy(redInput = input) }
    }

    suspend fun checkBlueAnswer() {
        if (!_uiState.value.blueSent && _uiState.value.isRoundRunning) {
            checkAnswer("Blue", _uiState.value.blueInput)
        }
    }

    suspend fun checkRedAnswer() {
        if (!_uiState.value.redSent && _uiState.value.isRoundRunning) {
            checkAnswer("Red", _uiState.value.redInput)
        }
    }

    private suspend fun checkAnswer(player: String, input: PlayerInput) {
        val uiState = _uiState.value
        val isBlue = player == "Blue"//todo ha stringresource akkor itt is stringresource
        val inputType = if (isBlue) uiState.blueInputType else uiState.redInputType

        var tipp = 0.0
        val integerPart = input.integerPart.toDoubleOrNull()
            ?: 0.0 //ha tizedestörtként írta be, akkor ez lehet hogy nem egész szám valójában
        val numerator = input.numerator.toDoubleOrNull() ?: 0.0
        val denominator = input.denominator.toDoubleOrNull() ?: 1.0//nulla/1 az nulla lesz
        val remainder = input.remainder.toIntOrNull() ?: 0//maradék ne legyen tört szám

        //println("inputType =$inputType")

        when (inputType) {
            "simple" -> tipp = integerPart
            "accurate" -> {
                tipp = integerPart + (numerator / denominator)
            }

            "simplemaradek" -> {
                tipp = integerPart
//a maradékot külön fogjuk leellenőrizni ezesetben
            }
        }

        val isCorrect = when (inputType) {
            "simple", "accurate" -> tipp == uiState.solution
            "simplemaradek" -> {
                integerPart == uiState.solution && remainder == uiState.solutionRemainder
            }

            else -> false
        }

        val updatedInputDisplay =
            if (inputType == "simplemaradek") {
                "${tipp.toInt()} ${getString(Res.string.alapmuveletekMaradék)} ${input.remainder}"
            } else {
                tipp.formatToDecimals(8)
            }

// Update the roundsList with player input
        val updatedRounds = uiState.roundsList.toMutableList()//ez lesz amit updatelünk
        val currentRoundIndex = uiState.roundsCompleted //ahol most tartunk
        val currentRound =
            updatedRounds.getOrNull(currentRoundIndex)//ez a jelenlegi kör, ami lehet hogy nem üres adat
        if (currentRound == null) {
           // println("its null")
            return
        }

        var bluesent = uiState.blueSent
        var redsent = uiState.redSent

        if (isBlue) {
            updatedRounds[currentRoundIndex] =
                currentRound.copy(blueInputDisplay = updatedInputDisplay)//jelenlegi kört updateljük a bevitt válasszal
            bluesent = true
            _uiState.update { it.copy(blueSent = true, roundsList = updatedRounds) }

        } else {
            updatedRounds[currentRoundIndex] =
                currentRound.copy(redInputDisplay = updatedInputDisplay)
            redsent = true
            _uiState.update { it.copy(redSent = true, roundsList = updatedRounds) }
        }
       // println("uiState.blueSent && uiState.redSent ${uiState.blueSent && uiState.redSent}")



        if (isCorrect) {
            val resultMessage =
                if (player == "Blue") getString(Res.string.bluewontheround) else getString(Res.string.redwontheround)  //"$player won the round!"
            val updatedScore = if (isBlue) uiState.bluescore + 1 else uiState.redscore + 1
            if (isBlue) {
                _uiState.update {
                    it.copy(
                        bluescore = updatedScore,
                        roundResult = resultMessage,
                        roundsList = updatedRounds,
                        redSent = redsent,
                        blueSent = bluesent
                    )
                }
            } else {
                _uiState.update {
                    it.copy(
                        redscore = updatedScore,
                        roundResult = resultMessage,
                        roundsList = updatedRounds,
                        redSent = redsent,
                        blueSent = bluesent
                    )
                }
            }

            /*  updatedRounds[currentRoundIndex] = currentRound.copy()
            _uiState.update { it.copy( roundsList = updatedRounds) }*/

            endRound(resultMessage)
        } else if (bluesent && redsent) {
         //   println("uiState.blueSent && uiState.redSent")
// Both players have sent incorrect answers
            val resultMessage = getString(Res.string.Tie)//"$player won the round!"
            _uiState.update {
                it.copy(
                    ties = uiState.ties + 1,
                    roundResult = resultMessage,
                    roundsList = updatedRounds,
                    redSent = redsent,
                    blueSent = bluesent,
                )
            }
            endRound(resultMessage)
        }
    }

    private suspend fun endRound(resultMessage: String) {
        delay(200)
        val uiState = _uiState.value
        val updatedRounds = uiState.roundsList.toMutableList()
        val currentRoundIndex = uiState.roundsCompleted
        val currentRound = updatedRounds.getOrNull(currentRoundIndex) ?: return



        updatedRounds[currentRoundIndex] = currentRound.copy(result = resultMessage)
        _uiState.update {
            it.copy(
                isRoundRunning = false,
                roundsCompleted = uiState.roundsCompleted + 1,
                roundsList = updatedRounds,//a kód ezen pontján még jó //TODO megoldani hogy ez az érték ne vesszen el
            )
        }

        if (uiState.roundsCompleted + 1 >= uiState.totalRounds) {//todo ezt ellenőrizni
            endGame()
        } else if (uiState.bluescore > (uiState.totalRounds - uiState.ties) / 2 || uiState.redscore > (uiState.totalRounds - uiState.ties) / 2) {//ha már nem tudja megfordítani az állást
            endGame()
        } else {
            startRoundWithDelay()
        }

      //  println("uiState.roundsCompleted + 1 = ${uiState.roundsCompleted + 1}")
      //  println("uiState.totalRounds = ${uiState.totalRounds}")
    }

    private suspend fun endGame() {
        val uiState = _uiState.value
        val gameResult = when {
            uiState.bluescore > uiState.redscore -> getString(Res.string.Blue) + " " + getString(Res.string.won) + "!"
            uiState.redscore > uiState.bluescore -> getString(Res.string.Red) + " " + getString(Res.string.won) + "!"
            else -> getString(Res.string.Tie)
        }
        _uiState.update {
            it.copy(
                isRoundRunning = false,
                isGameRunning = false,
                gameResult = gameResult,
                showResultsButton = true
            )
        }
    }

    fun restartGame() {
        roundsList.clear()
        startGame()
    }
}


data class MathKingGameUiState(// játék közbeni változók osztálya
    val currentTask: String = "",
    val blueInput: PlayerInput = PlayerInput(),
    val blueSelection: playerinputselection = playerinputselection.INTEGER,
    val redInput: PlayerInput = PlayerInput(),
    val redSelection: playerinputselection = playerinputselection.INTEGER,
    val isRoundRunning: Boolean = false,
    val isGameRunning: Boolean = true,
    val bluescore: Int = 0,
    val redscore: Int = 0,
    val ties: Int = 0,
    val roundsCompleted: Int = 0,
    val totalRounds: Int = 3,
    val gameSettings: MathKingGameSettings,
    val blueSent: Boolean = false,
    val redSent: Boolean = false,
    val solution: Double = 0.0,
    val solutionRemainder: Int = 0,
    val recommendedInput: String = "simple",
    val blueInputType: String = "simple",
    val redInputType: String = "simple",
    val roundResult: String = "",
    val gameResult: String = "",
    val roundsList: List<Round> = emptyList(),
    val showResultsButton: Boolean = false,
    val counter: Int = 0
)

data class PlayerInput(// játékos által megadott értékek, minden eshetőséget lefed az osztály
    val integerPart: String = "",
    val numerator: String = "",
    val denominator: String = "",
    val remainder: String = ""
)


//@Serializable
data class MathKingGameSettings(//a beállítások oldalon megadott paraméterek
    val randomTagszam: Boolean,
    val osszead: Boolean,
    val kivon: Boolean,
    val szorzas: Boolean,
    val osztas: Boolean,
    val tagszam: Int,
    val tagSzamMin: Int,
    val tagSzamMax: Int,
    val korszam: Int,
    val min: Int,
    val max: Int,
    val delay: Int,
    val recommendedModifiers: Boolean,
    val feladatszam: Int = 0
)

data class Round(//kör végén elmentett értékek
    val taskDisplay: String,
    val blueInputDisplay: String,
    val redInputDisplay: String,
    val result: String,
    val solutionDisplay: String,
    val index: Int
)

enum class playerinputselection {
    INTEGER,
    NUMERATOR,
    DENOMINATOR,
    REMAINDER,

}
/*
@Serializable
data class SzamkiralyRoute(
val settings :MathKingGameSettings
)*/