package matekit.ui

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import matekit.ui.theme.white
import matekit.ui.theme.black
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import matekit.ui.theme.matekitcolor


@Composable
fun koordinátarendszer(mClor: Color, textcolor: Color, linePoints: List<List<Float>>, szélesség:Int = 12, onTap :(List<Float>)->Unit, dotCoordinates:List<Float> = listOf()){
    val textMeasurer = rememberTextMeasurer()

    val modifier = Modifier
        .aspectRatio(1f)
        .fillMaxHeight()
        .animateContentSize()
    val density = LocalDensity.current
    val textPaint = remember(density) {
        Paint().apply {
            color = if (textcolor == white) {
               white
            } else black
          //  textAlign = Paint.Align.CENTER //todo update it to kmm
           // textSize = density.run { 14.sp.toPx() }
        }
    }
    var cw by remember{mutableStateOf(100f)}
    val unit by derivedStateOf { cw / szélesség }
    Canvas(modifier = modifier
        .padding(12.dp)
        .clipToBounds()
        /* .pointerInteropFilter {
            var myoffset = listOf<Float>(it.x,it.y)
            var coordinates = myoffset.toAndroidCanvasCoordinate(unit, szélesség)
            onTap(coordinates)
            // println("(${it.x};${it.y})")
            true
        }*/
        .pointerInput(Unit) {
            detectTapGestures(
                onTap = { offset ->
                    var myoffset = listOf<Float>(offset.x, offset.y)
                    var coordinates = myoffset.fromAndroidCanvasCoordinate(unit, szélesség)
                    onTap(coordinates)
                    // todo adja vissza az érintés pontját
                    //   println("TAP!")

                }
            )
        }

    ) {
        cw = size.width
        val ch = size.height //unused
        // val unit = cw / szélesség
        drawLine(//x tengely
            start = Offset(x = 0f, y = szélesség/2.toFloat() * unit),
            end = Offset(x =  szélesség.toFloat() * unit, y =  szélesség/2.toFloat() * unit),
            color = mClor.copy(1f)
        )
        drawLine(//y tengely
            start = Offset(x = szélesség/2.toFloat()  * unit, y = 0f),
            end = Offset(x = szélesség/2.toFloat()  * unit, y = szélesség.toFloat()* unit),
            color = mClor.copy(1f)
        )
        var arrowstarts = listOf<List<Float>>(
            listOf(-0.125f, szélesség/2-0.25f), //x tengely
            listOf(0.125f, szélesség/2-0.25f),//x tengely
            listOf(szélesség/2-0.25f, 0.125f),//y tengely
            listOf(szélesség/2-0.25f, -0.125f),//y tengely
            /* szorzással felírva (nem működik)
            listOf(-0.01f*szélesség, 0.48f*szélesség), //x tengely
            listOf(0.01f*szélesség, 0.48f*szélesség),//x tengely
            listOf(0.48f*szélesség, 0.01f*szélesség),//y tengely
            listOf(0.48f*szélesség, -0.01f*szélesség),//y tengely*/
            /*
            korábbi koordináták nem paraméteres felírással:
                listOf(-0.125f, 5.75f), //x tengely
            listOf(0.125f, 5.75f),//x tengely
            listOf(5.75f, 0.125f),//y tengely
            listOf(5.75f, -0.125f),//y tengely
            */

//            listOf(0.25f, -5.5f),
//            listOf(-0.25f, -5.5f),
//            listOf(-5.5f, -0.25f),
//            listOf(-5.5f, 0.25f)
        )
        var arrowends = listOf<List<Float>>(
            listOf(0f, szélesség/2.toFloat()),
            listOf(0f, szélesség/2.toFloat()),
            listOf(szélesség/2.toFloat(), 0f),
            listOf(szélesség/2.toFloat(), 0f),
            /* korábbi koordiníták, nem paraméteresen felírva
             listOf(0f, 6f),
             listOf(0f, 6f),
             listOf(6f, 0f),
             listOf(6f, 0f),
             */
//            listOf(0f, -6f),
//            listOf(0f, -6f),
//            listOf(-6f, -0f),
//            listOf(-6f, -0f)
        )
        arrowstarts.forEachIndexed { index, list ->
            var start = list.toAndroidCanvasCoordinate(unit,szélesség)
            var end = arrowends[index].toAndroidCanvasCoordinate(unit,szélesség)
            drawLine(
                start = Offset(x = start[0], y = start[1]),
                end = Offset(x = end[0], y = end[1]),
                color = mClor.copy(1f)
            )
        }

        for (i in 0..szélesség) {
            drawLine(
                start = Offset(x = i * unit, y = 0f),
                end = Offset(x = i * unit, y = szélesség * unit),
                color = mClor.copy(0.8f)
            )
            drawLine(
                start = Offset(x = 0f, y = i * unit),
                end = Offset(x = szélesség * unit, y = i * unit),
                color = mClor.copy(0.8f)
            )

            var koordináta = listOf((i - szélesség/2).toFloat(), 0f).toAndroidCanvasCoordinate(unit,szélesség)
            drawContext.canvas.nativeCanvas.apply {
//                println("koordináta I = $i")
//                println("koordinátax = ${koordináta[0]}")
//                println("koordinátaY = ${koordináta[1]}")

                drawText(
                    textMeasurer = textMeasurer,
                    text = buildAnnotatedString {
                        withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                            append("${i - szélesség / 2}")
                        }
                    },
                    topLeft = Offset(koordináta[0] /*+ 10*/, koordináta[1] /*+ 20*/)//valószínű azért adtam hozzá tízet és húszat, mert androidon úgy jött ki, viszont desktopon nem működik. todo vagy megtartani így, vagy kettéválasztani a desktop és android szöveget ezen a ponton
                )

                if(i!=szélesség/2){
                    drawText(
                        textMeasurer = textMeasurer,
                        text = buildAnnotatedString {
                            withStyle(ParagraphStyle(textAlign = TextAlign.Start)) {
                                append("${(-szélesség/2 + i) * -1}")
                            }
                        },
                        topLeft = Offset(koordináta[1]+5 /*+ 10*/, koordináta[0] /*+ 20*/)//valószínű azért adtam hozzá tízet és húszat, mert androidon úgy jött ki, viszont desktopon nem működik. todo vagy megtartani így, vagy kettéválasztani a desktop és android szöveget ezen a ponton
                    )
                }
              /*  drawText(

                    "${i - szélesség/2}",
                    koordináta[0] + 10,
                    koordináta[1] + 20,
                    textPaint
                )*/
            }
            drawContext.canvas.nativeCanvas.apply {
                if (i != szélesség/2) {
                    /*drawText(
                        "${(-szélesség/2 + i) * -1}",
                        koordináta[1] + 10,
                        koordináta[0],
                        textPaint
                    )*/
                }
            }
        }
        var topy = listOf(0.35f,szélesség/2-0.35f).toAndroidCanvasCoordinate(unit,szélesség) //(0.35f,5.65f)
        var rightx =  listOf(szélesség/2-0.5f,0.25f).toAndroidCanvasCoordinate(unit,szélesség) //(5.5f,0.25f)
        drawContext.canvas.nativeCanvas.apply {
            drawText(
                textMeasurer = textMeasurer,
                text = buildAnnotatedString {
                    withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                        append("x")
                    }
                },
                topLeft = Offset(rightx[0], rightx[1])
            )
            drawText(
                textMeasurer = textMeasurer,
                text = buildAnnotatedString {
                    withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                        append("y")
                    }
                },
                topLeft = Offset(topy[0], topy[1])
            )
          /*  drawText(
                "x",
                rightx[0],
                rightx[1],
                textPaint
            )
            drawText(
                "y",
                topy[0],
                topy[1],
                textPaint
            )*/
        }
        try {
            var ALinepoint = linePoints[0].toAndroidCanvasCoordinate(unit)
            var BLinepoint = linePoints[1].toAndroidCanvasCoordinate(unit)

            drawLine(
                start = Offset(x = ALinepoint[0], ALinepoint[1]),
                end = Offset(x = BLinepoint[0], BLinepoint[1]),
                color = Color.Red,
                strokeWidth = 3f
            )
        }catch (e:Exception){}

        try{
            val dotDrawCoordinates  = dotCoordinates.toAndroidCanvasCoordinate(unit,szélesség)
            // val x = dotDrawCoordinates[0]
            //  val y = dotDrawCoordinates[1]
            // var offset = (Offset(x=dotDrawCoordinates[0],y=dotDrawCoordinates[1]))
            // drawCircle(center = offset,color= matekitcolor, radius = 2f )
            // drawCircle(center =Offset(0f,0f),color= matekitcolor, radius = 25f)

            drawCircle(center =Offset(dotDrawCoordinates[0],dotDrawCoordinates[1]),color= matekitcolor, radius = 20f)
        }
        catch (e:Exception){
            //whatever
        }
        /*var b = listOf(5.5f,0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )
        b = listOf(5.5f,-0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )*/
    }
}

fun List<Float>.toAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): List<Float> { //lapközéppontos koordinátarendszerből átrakja bal felső középpontosba, és még megszorozza a koordinátát a unit együtthatóval.
    var újX = (this[0] + szélesség/2) * unit
    var újY = (szélesség/2 - this[1]) * unit
    return listOf(újX, újY)
}
fun List<Float>.fromAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): List<Float> {
    var újX = this[0]  / unit-szélesség/2
    var újY = szélesség/2 - this[1] / unit
    return listOf(újX, újY)
}

fun Pair<Float,Float>.toAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): Pair<Float,Float> {
    var újX = (this.first + szélesség/2) * unit
    var újY = (szélesség/2 - this.second) * unit
    return Pair(újX, újY)
}
fun Pair<Float,Float>.fromAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): Pair<Float,Float>{
    var újX = this.first  / unit-szélesség/2
    var újY = szélesség/2 - this.second / unit
    return Pair(újX, újY)
}