package matekit.matekit

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import matekit.matekit.utilityfunctions.getlog
import matekit.ui.SurfaceWBorder
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Clear
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.lastCorrects
import org.jetbrains.compose.resources.stringResource
//import java.io.File

@Composable
fun simpleLogUI(navController: NavHostController, type:String){
    val name = "user1"
    // val type ="tortek"//todo ez a navigationból származzon

    var logs= remember{ mutableStateOf(getlog(name, type))}
    var szoveg = remember { mutableStateOf("")}

    var reversed  = remember{ mutableStateOf(logs.value.reversed())}

    //println("reversed:${logs.value.toString()}" )
    var i = 1
    reversed.value.forEach {//egy feladat 2 sorból áll. maga a feladat, és a kiértékelés. ezért minden harmadik sorhoz adunk két sortörést, hogy átláthatóbb legyen a napló
        // Log.d(TAG,"ez egy elem: $it" )
        szoveg.value+=it//+"<br>"
        if(i %3 ==0) szoveg.value+="<br> ------------------------------------------------ <br>"
        i++
    }
    //Log.d(TAG,"szoveg: ${szoveg.toString()}" )
    var n = 10
    var countwrongs:List<String>
    try {
        countwrongs  =   reversed.value .subList(0,30)
    }catch (e:Exception)
    {
        countwrongs =       reversed.value .subList(0,reversed.value.size)
        n = reversed.value.size/3
    }

    var corrects = 0

    countwrongs.forEach {
        // var words = it.split(" ")
        //   var valid = words.count{it=="✓"}==1 ez most már nem szükséges feltétel
        if(it.contains("✓")/*&&valid*//*&&it.count("Helyes")==1*/) corrects++
    }


    // backButton(navController)

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.SpaceBetween,
        modifier = Modifier.fillMaxSize()
    ) {

        SurfaceWBorder(content = {
            Column(modifier = Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                Text(text= stringResource(Res.string.lastCorrects,n,corrects),fontSize=20.sp, textAlign = TextAlign.Center,modifier= Modifier.padding(4.dp),)
            }

        }, borderColor = matekitcolor )



        Column(
            Modifier
                .fillMaxHeight(0.9f)
                .fillMaxWidth() ) {
            Text(
             szoveg.value.replace("<br>","\n")
            )
        }

        Button(onClick = {
            val userName="user1"
            val userIndex=1 //todo ez a navigationból származzon
            val logDirectory = "logs"//ezt a nevet adtuk neki. akár maradhat is
            /* this@LaTeXLog.openFileOutput(filename, Context.MODE_PRIVATE).use {
                 it.write("".toByteArray())
             }*/
            val name = "${userName}_$userIndex"
            val filename = "$logDirectory/${name}_$type.txt"
           // File(filename).writeText("") //todo read tasklog

        }) {
            Text(stringResource(Res.string.Clear))
        }
    }
    FloatingActionButton(//todo ez legyen vagy bal lent, vagy legyen kisebb az a sárga keret
        //     modifier = Modifier.align(Alignment.BottomStart),
        onClick = { navController.popBackStack() },
        containerColor = Color.Gray
    ) {
        Icon(
            imageVector = Icons.AutoMirrored.Filled.ArrowBack,
            contentDescription = "Back"
        )
    }

}

