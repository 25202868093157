package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.InstrumentalCase
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.getstring
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Proportionality1
import matekit_multiplatform.composeapp.generated.resources.Proportionality2
import matekit_multiplatform.composeapp.generated.resources.Proportionality3
import matekit_multiplatform.composeapp.generated.resources.Proportionality4
import matekit_multiplatform.composeapp.generated.resources.Proportionality5
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.aquarium_fish
import matekit_multiplatform.composeapp.generated.resources.basket_nuts
import matekit_multiplatform.composeapp.generated.resources.biologists
import matekit_multiplatform.composeapp.generated.resources.car_parking
import matekit_multiplatform.composeapp.generated.resources.chocolate_distribution
import matekit_multiplatform.composeapp.generated.resources.cinderella_beans
import matekit_multiplatform.composeapp.generated.resources.circus_performers
import matekit_multiplatform.composeapp.generated.resources.csokik
import matekit_multiplatform.composeapp.generated.resources.dinosaur_park
import matekit_multiplatform.composeapp.generated.resources.dog_treat_distribution
import matekit_multiplatform.composeapp.generated.resources.ecologist
import matekit_multiplatform.composeapp.generated.resources.flower_distribution
import matekit_multiplatform.composeapp.generated.resources.fodrasz_hajvagas
import matekit_multiplatform.composeapp.generated.resources.fruit_basket
import matekit_multiplatform.composeapp.generated.resources.fruit_distribution
import matekit_multiplatform.composeapp.generated.resources.futar_csomagszallitas
import matekit_multiplatform.composeapp.generated.resources.garden_flowers
import matekit_multiplatform.composeapp.generated.resources.gep_gyartas
import matekit_multiplatform.composeapp.generated.resources.hallgatok
import matekit_multiplatform.composeapp.generated.resources.hary_janos
import matekit_multiplatform.composeapp.generated.resources.instagram_friends
import matekit_multiplatform.composeapp.generated.resources.konyvkoto_kotes
import matekit_multiplatform.composeapp.generated.resources.kutyaeledel
import matekit_multiplatform.composeapp.generated.resources.labor
import matekit_multiplatform.composeapp.generated.resources.library
import matekit_multiplatform.composeapp.generated.resources.macskaeledel
import matekit_multiplatform.composeapp.generated.resources.menu_order_distribution
import matekit_multiplatform.composeapp.generated.resources.menyasszonyiauto
import matekit_multiplatform.composeapp.generated.resources.menyasszonyitorta
import matekit_multiplatform.composeapp.generated.resources.nagymama_himezes
import matekit_multiplatform.composeapp.generated.resources.nyulak
import matekit_multiplatform.composeapp.generated.resources.pirate_treasures
import matekit_multiplatform.composeapp.generated.resources.potion_brewing
import matekit_multiplatform.composeapp.generated.resources.programozo_kodiras
import matekit_multiplatform.composeapp.generated.resources.rabbit_distribution
import matekit_multiplatform.composeapp.generated.resources.robot_furat
import matekit_multiplatform.composeapp.generated.resources.takarito_takaritas
import matekit_multiplatform.composeapp.generated.resources.tanar_dolgozatjavitas
import matekit_multiplatform.composeapp.generated.resources.viraghagymak
import matekit_multiplatform.composeapp.generated.resources.viragkoto_csokorkeszites
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random

@Composable
fun ProportionalityScreen(
    navController: NavHostController,
    viewModel: ProportionalityViewModel = viewModel { ProportionalityViewModel() }
) {
    val uiState by viewModel.uiState.collectAsState()

    val coroutineScope = rememberCoroutineScope()

    backButton(navController)
    Column(
        modifier = Modifier.fillMaxSize().padding(16.dp),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        // Task Display
        borderedbasicfontsizeTextView(uiState.paramstr.getparamstr())
        // User Input Section
        Row{
            universalCheckField(
                userInput = uiState.userInput,
                enterText = { viewModel.updateUserInput(it) },
                check = { coroutineScope.launch { viewModel.checkSolution() } }
            )
            Spacer(Modifier.width(8.dp))

            // Check Button
            Button(
                onClick = { coroutineScope.launch { viewModel.checkSolution() } },
                modifier = Modifier.padding(vertical = 8.dp)
            ) {
                Text(stringResource(Res.string.Check))
            }
            Spacer(Modifier.width(8.dp))
            LatexLogButton(navController, "aranyossag")
        }

        responseToast(correct = uiState.isCorrect, numberofsolved = uiState.numberofsolved, solution = uiState.showsolution)


        // Whiteboard (if needed)
        // whiteboard(activity = ..., context = ..., darkmode = ...)
    }
}

data class ProportionalityUiState(
    val userInput: String = "",
    var numberofsolved: Int = 0,
    var showsolution: String = "",
    var taskText: String = "",
    var isCorrect: Boolean = false,
    var solution: String = "",
    var paramstr: Paramstr= Paramstr(Res.string.Proportionality1)
) //: UIstateClass()

class ProportionalityViewModel : ViewModel() {

    private val _uiState = MutableStateFlow(ProportionalityUiState())
    val uiState: StateFlow<ProportionalityUiState> = _uiState

    private lateinit var currentTask: AranyossagTask
    private var currentIndex: Int = 0

    init {
        generateNewTask()
    }


    fun generateNewTask() {
        currentTask = TaskGenerator().currenttask
        _uiState.update {
            it.copy(
                paramstr = Paramstr(currentTask.stringresourceID,currentTask.stringparams)
            )
        }
    }

    suspend fun checkSolution() {
        val userInput = _uiState.value.userInput.toIntOrNull()//ez mindaddig jó, amíg egész szám minden megoldás. itt még jó esetben az
        val correctSolution = currentTask.solution

        if (userInput == null) {
            return
        }

        val isCorrect = userInput == correctSolution


        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                userInput = "",
                numberofsolved = it.numberofsolved + 1,
                showsolution = correctSolution.toString()
            )
        }
        val showSolution = getString(Res.string.WrongSolution,correctSolution)

        LaTexLogWriteDesktop(isCorrect, userInput.toString(), showSolution,"aranyossag",Paramstr(currentTask.stringresourceID, currentTask.stringparams).getstring(), taskLaTexWrap = false)

        generateNewTask()
    }

    fun updateUserInput(input: String) {
        _uiState.update { it.copy(userInput = input) }
    }

    class TaskGenerator {
        private val list = generateCoprimes()
        private val a = list[0]
        private val b = list[1]
        private val k = (2..40).random()

        lateinit var currenttask:AranyossagTask

        val tasks = listOf<() -> AranyossagTask>(
            {
                val tasktext =
                    listOf(
                        Res.string.Proportionality1,
                        Res.string.menyasszonyitorta,
                        Res.string.macskaeledel,
                        Res.string.viraghagymak,
                        Res.string.labor,
                        Res.string.library
                    ).random()
                val solution = a * k

                return@listOf AranyossagTask(tasktext, listOf(a, b, (a + b) * k), solution)
            },

            {//ez az első feladattípus, az Andi-Bandi komplementere
                val tasktext =
                    listOf(
                        Res.string.menyasszonyiauto,
                        Res.string.kutyaeledel,
                        Res.string.csokik,
                        Res.string.nyulak
                    ).random()
                val solution = b * k

                return@listOf AranyossagTask(tasktext, listOf(a, b, (a + b) * k), solution)
            },


            {
                val tasktext = listOf(Res.string.Proportionality2,//Cili-Dani
                    Res.string.flower_distribution,
                    Res.string.dog_treat_distribution,
                    Res.string.chocolate_distribution,
                    Res.string.rabbit_distribution,
                    Res.string.menu_order_distribution,
                    Res.string.fruit_distribution,
                    Res.string.hallgatok,
                    Res.string.biologists,
                    Res.string.pirate_treasures,
                    Res.string.dinosaur_park,
                    Res.string.circus_performers,
                    Res.string.potion_brewing,
                    Res.string.hary_janos

                ).random()

                val solution = b * k * 10

                return@listOf AranyossagTask(tasktext, listOf(a, b, (a * k * 10)), solution)
            },
            {
                val tasktext =
                    listOf( Res.string.Proportionality3,//like dislike
                        Res.string.instagram_friends,
                        Res.string.basket_nuts,
                        Res.string.cinderella_beans,
                        Res.string.fruit_basket,
                        Res.string.garden_flowers,
                        Res.string.car_parking,
                        Res.string.aquarium_fish
                    ).random()
                val solution = b * k

                return@listOf AranyossagTask(tasktext, listOf(a, b, InstrumentalCase((a - b) * k)), solution)
            },
            {
                val tasktext = listOf(Res.string.Proportionality4 , //Samu típus
                    Res.string.nagymama_himezes,
                    Res.string.gep_gyartas,
                    Res.string.tanar_dolgozatjavitas,
                    Res.string.fodrasz_hajvagas,
                    Res.string.konyvkoto_kotes,
                    Res.string.ecologist,
                    Res.string.programozo_kodiras,
                    Res.string.viragkoto_csokorkeszites,
                    Res.string.takarito_takaritas,
                    Res.string.futar_csomagszallitas
                ).random()
                val solution = b * k

                return@listOf AranyossagTask(tasktext, listOf(a, (a * k), b), solution)
            },
            {
                val tasktext = listOf(Res.string.Proportionality5,Res.string.robot_furat).random()
                val solution = b

                return@listOf AranyossagTask(tasktext,  listOf(a, (a * k), b * k), solution)
            },
        )
        init{
            currenttask = tasks.random()()
        }

    }
}

 fun generateCoprimes(min: Int = 1, max: Int = 10): List<Int> {
    val a = (min..max).random()
    val b = (min..max).random()
    return if (coprime(a, b) && a != b && a > b) {
        listOf(a, b)
    } else {
        generateCoprimes(min,max)//eredeti paraméterekkel hívjuk meg rekurzívan
    }
}

 fun coprime(a: Int, b: Int): Boolean {
    if (a == 1 || b == 1) return true
    val min = if (a < b) a else b
    val max = if (a > b) a else b
    for (i in 2..max) {
        if (min % i == 0 && max % i == 0) {
            return false
        }
    }
    return true
}

data class AranyossagTask(
    val stringresourceID: StringResource,
    val stringparams:List<Any>,
    val solution: Int = 0
)
