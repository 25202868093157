package matekit.matekit.taskgenerators

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.CE
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.randomNotZero
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.drawButton
import matekit.ui.indexedInputField
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.expand_less
import matekit_multiplatform.composeapp.generated.resources.expand_more
import matekit_multiplatform.composeapp.generated.resources.t_pusv_laszt_s
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

//import java.math.BigDecimal
import kotlin.math.absoluteValue
import androidx.compose.ui.text.font.Font
import androidx.compose.ui.text.font.FontWeight
class AlgebraiAzonossagok(val navController: NavHostController, val TAG: String = "Algebraiazonossagok") {



  /*  private fun BigDecimal.CE(): String {
        return if (this.equals(1)) "" else this.toString()
    }*/
//weben a kört kicseréltem egy másik körre, ami van a jetbrains mono-ban

    val indexSymbols = listOf("■", "●", "▲", "⬢", "A", "B", "C", "D", "E", "F", "G", "H")
    fun List<String>.toSymbolString(): String {
        var mystring = ""
        this.forEachIndexed { index, number ->
            mystring += "${indexSymbols[index]}=${number.toString()}; "
        }
        return mystring.substring(0, mystring.length - 2)
    }

    fun List<Number>.NumbertoSymbolString(): String {
        var mystring = ""
        this.forEachIndexed { index, number ->
            mystring += "${indexSymbols[index]}=${number.toString()}; "
        }
        return mystring.substring(0, mystring.length - 2)
    }
    val font = FontFamily.Serif
    //■ ● ▲ ⬢ működő karakterek

            @Composable
            fun UI(){
                val coroutineScope = rememberCoroutineScope()
               val isCorrect = rememberSaveable { mutableStateOf(false) }
               val numberofsolved = rememberSaveable { mutableStateOf(0) }
               val feedback = rememberSaveable { mutableStateOf("") }


                val whiteboard = rememberSaveable { mutableStateOf(false) }
                // A surface container using the 'background' color from the theme
                Surface(
                    modifier = Modifier.fillMaxSize(),
                    color = MaterialTheme.colorScheme.background
                ) {
                    backButton(navController)
                    var igaz = true
                    var hamis = false

                    var exampleText =
                        "\\text{ ■  ${
                            if (igaz) {
                                "  ez egy kör"
                            } else {
                                ""
                            }
                        }● ▲ ⬢ Lorem ipsum dolor sit amet, consectetur adipisicing elit, \n" +
                                "sed do} x^● eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco  laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

                    val tasktype = rememberSaveable { mutableStateOf("A") }
                    val currentlevel = rememberSaveable { mutableStateOf(0) }
                    var userinputList = remember {//todo megoldani, hogy saveable legyen
                        mutableStateListOf<String>(
                            "", "", "", "", "", "", "", "", "", "", ""
                        )
                    }
                    lateinit var task: MutableState<Task>

                    task = rememberSaveable {
                        mutableStateOf(
                            newtask(
                                type = tasktype.value,
                                level = currentlevel.value
                            )
                        )
                    } // megoldani hogy savable legyen


                    Column(Modifier.fillMaxSize().padding(bottom = 60.dp)) {
                        webviewkmm(
                            LaTeX = task.value.tasktext(userinputList).replace("+-", "-")
                                .replace("-+", "-"),
                        )
                        Surface(
                            modifier = Modifier
                                .fillMaxWidth()
                                .wrapContentHeight()
                                .padding(8.dp),
                            color = MaterialTheme.colorScheme.onSurface.copy(.09f),
                            shape = RoundedCornerShape(12.dp)

                        ) {
                            Column(
                                Modifier
                                    .fillMaxWidth()
                                    .padding(4.dp),
                                horizontalAlignment = Alignment.CenterHorizontally
                            ) {
                                val collapsed = rememberSaveable { mutableStateOf(false) }
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    if (!whiteboard.value) {
                                        Text(stringResource(Res.string.t_pusv_laszt_s))
                                    }

                                    IconButton(onClick = {
                                        collapsed.value = !collapsed.value
                                    }, modifier = Modifier.size(60.dp)) {
                                        Icon(
                                            painter = if (collapsed.value) painterResource(Res.drawable.expand_more) else painterResource(Res.drawable.expand_less),
                                            contentDescription = null // decorative element
                                        )
                                    }
                                }
                                //A típus
                                //   AnimatedVisibility(visible = !collapsed.value) {


                                var alpha = (if (whiteboard.value && !collapsed.value) 1f else 0f)

                             //   val drawcontroller = rememberDrawController()
                                //    Column (Modifier.alpha(alpha)){}
                             /*   AnimatedVisibility(whiteboard.value && !collapsed.value) { //todo rajztábla
                                    matekit.matekit.whiteboard(
                                        activity = this@AlgebraiAzonossagok,
                                        context = context,
                                        darkmode = darkmode,
                                        fill = false,
                                        drawController = drawcontroller
                                    )
                                }*/


                                if (!whiteboard.value) {//szintek száma
                                    mainTaskType(
                                        collapsed,
                                        tasktype,
                                        "A",
                                        currentlevel,
                                        8//todo ha visszarakjuk a harmadik feladatot, akkor lesz megint 9 szintünk
                                    )//tasklistA.size
                                    mainTaskType(collapsed, tasktype, "B", currentlevel, 5)
                                    mainTaskType(collapsed, tasktype, "C", currentlevel, 3)
                                    mainTaskType(collapsed, tasktype, "D", currentlevel, 3)
                                }

                            }
                            //  }
                        }
                        Spacer(modifier = Modifier.height(4.dp))
                        Surface(
                            modifier = Modifier
                                .fillMaxWidth()
                                .wrapContentHeight()
                                .padding(8.dp),
                            color = MaterialTheme.colorScheme.onSurface.copy(.09f),
                            shape = RoundedCornerShape(12.dp)
                        ) {
                            Column(
                                Modifier
                                    .padding(8.dp)
                                    .fillMaxWidth(),
                                horizontalAlignment = Alignment.CenterHorizontally,
                            ) {
                                for (i in 0 until task.value.unknowns) {
                                    indexedInputField(indexSymbols[i], i, userinputList, jetbrainsfont = true)
                                }

                                Row(
                                    horizontalArrangement = Arrangement.SpaceAround,
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(top = 4.dp),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {
                                    drawButton(drawboard = whiteboard)
                                    Button(onClick = {
                                        coroutineScope.launch{
                                            check(
                                                task.value.solution,
                                                userinputList.toList(),
                                                task.value,
                                                isCorrect,
                                                numberofsolved,
                                                feedback
                                            ) {
                                                task.value = newtask(
                                                    type = tasktype.value,
                                                    level = currentlevel.value
                                                )
                                                for (i in 0..10) {
                                                    userinputList[i] = ""
                                                }
                                            }
                                        }
                                    }) {
                                        Text(text = stringResource(Res.string.Check))
                                    }
                                    LatexLogButton(navController = navController, value = TAG)
                                }

                            }

                        }
                    }
                }
                responseToast(isCorrect.value, numberofsolved.value, useCompleteFeedbackMessage = true ,completeResponse =  feedback.value, jetbrainsfont = true)

            }


    suspend fun check(solution: List<Number>, userinput: List<String>, task: Task,isCorrect: MutableState<Boolean>, numberofsolved: MutableState<Int>, feedback: MutableState<String> ,function: () -> Unit, ) {
        var solutiontostring = solution.map { it.toString() }
        var userinput = userinput.subList(0, task.unknowns)


        var showSolutionIfUserWrong = ""
        var helyesMO: Boolean
        if (userinput == solutiontostring) {
            helyesMO = true
        } else {
            helyesMO = false
            showSolutionIfUserWrong = getString(
                Res.string.WrongSolution,
                task.solution.NumbertoSymbolString()//todo ezt formázni
            )
        }
        var text = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
        feedback.value = text;
        numberofsolved.value++;
        isCorrect.value = helyesMO;
       // var color = if (helyesMO) Res.style.CorrectToast else Res.style.WrongToast
     //   ShowToast.showToast(color, text, context, true)
       LaTexLogWriteDesktop(
            helyesMO,
            userinput.toSymbolString(),//todo ezt formázni
            showSolutionIfUserWrong,
            TAG,
            task.staticTaskText.replace("+-", "-").replace("-+", "-"),
           // userDocRef = userDocRef, modulID =19
        )
        function()

    }

    fun newtask(type: String, level: Int): Task {
        lateinit var task: Task
        when (type) {
            "A" -> {
                task = tasklistA[level]()//levonunk egyet, ha az első szintet 1-gyel indexeljük.
                return task
            }

            "B" -> {
                task = tasklistB[level]()//levonunk egyet, ha az első szintet 1-gyel indexeljük.
                return task
            }

            "C" -> {
                task = tasklistC[level]()
                return task
            }

            "D" -> {
                task = tasklistD[level]()
                return task
            }
        }
        return task
    }

    val tasklistA = listOf<() -> Task>(
        {//0. szint
            var a = 1
         //  println("numbers are generated")
            var b = (1..10).toList().filter { it != 0 }.random()
            var type =
                (1..2).random()//ne legyen nulla, mert az X együtthatóját nem akarjuk megkérdezni itt

            var coefficients = listOf<Int>(a * a, 2 * a * b, b * b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a * a).CE()}"
                        }
                    }x^2+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${2 * a * b}"
                        }
                    }x+${
                        if (type == 2) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (type == 0) {
                        "■"

                    } else {
                        "${(a * a).CE()}"
                    }
                }x^2+${
                    if (type == 1) {
                        "■"

                    } else {
                        "${2 * a * b}"
                    }
                }x+${
                    if (type == 2) {
                        "■"

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = listOf<Int>(coefficients[type]),
                unknowns = 1
            )
            return@listOf task//ellenőrizve
        },
        {//1. szint
            var a = 1
           //println("numbers are generated")
            var b = (-10..10).toList().filter { it != 0 }.random()
            var type =
                (1..2).random()//ne legyen nulla, mert az X együtthatóját nem akarjuk megkérdezni itt

            var coefficients = listOf<Int>(a * a, 2 * a * b, b * b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a * a).CE()}"
                        }
                    }x^2+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${2 * a * b}"
                        }
                    }x+${
                        if (type == 2) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (type == 0) {
                        "■"

                    } else {
                        "${(a * a).CE()}"
                    }
                }x^2+${
                    if (type == 1) {
                        "■"

                    } else {
                        "${2 * a * b}"
                    }
                }x+${
                    if (type == 2) {
                        "■"

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = listOf<Int>(coefficients[type]),
                unknowns = 1
            )
            return@listOf task//ellenőrizve
        },

        {//2. szint
            var a = (2..10).random()
         //  println("numbers are generated")
            var b = (-10..10).toList().filter { it != 0 }.random()
            var type = (0..2).random()

            var coefficients = listOf<Int>(a * a, 2 * a * b, b * b)

            var task = Task(//ellenőrizve
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a * a}"
                        }
                    }x^2+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${2 * a * b}"
                        }
                    }x+${
                        if (type == 2) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (type == 0) {

                        "■"

                    } else {
                        "${a * a}"
                    }
                }x^2+${
                    if (type == 1) {

                        "■"

                    } else {
                        "${2 * a * b}"
                    }
                }x+${
                    if (type == 2) {

                        "■"

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = listOf<Int>(coefficients[type]),
                unknowns = 1
            )
            return@listOf task
        },
      /*  {//3. szint  //todo visszarakni a harmadik szintet
            var a = BigDecimal((2..10).random())
            println ("numbers are generated")
            var b: BigDecimal = BigDecimal(
                "${if ((0..1).random() == 0) "" else "-"}0.${
                    (1..9).toList().filter { it != 0 }.random()
                }"
            )
            var type = (0..2).random()

            var coefficients = listOf<BigDecimal>(a * a, (a * b).multiply(BigDecimal(2)), b * b)

            var task = Task(//ellenőrizve
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a * a}"
                        }
                    }x^2+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a * b).multiply(BigDecimal(2)).stripTrailingZeros()}"
                        }
                    }x+${
                        if (type == 2) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (type == 0) {

                        "■"

                    } else {
                        "${a * a}"
                    }
                }x^2+${
                    if (type == 1) {

                        "■"

                    } else {
                        "${(a * b).multiply(BigDecimal(2)).stripTrailingZeros()}"
                    }
                }x+${
                    if (type == 2) {
                        "■"

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = listOf<Number>(coefficients[type]),
                unknowns = 1
            )
            return@listOf task
        },*/
        {//4. de nem az eredeti terv szerinti negyedik
            var kérdezettekindexei = (0..2).toList().shuffled().subList(0, 2)
                .sorted()//három szám megkeverve és abból az első kettő, pl 0,2 //todo eldönteni hogy kell-e a sorted
            var a = (2..10).random()
          // println("numbers are generated")
            var b = (-10..10).toList().filter { it != 0 }.random()
            var feladatszámai = listOf<Int>(a * a, 2 * a * b, b * b)


            var task = Task(//ellenőrizve
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (0 in kérdezettekindexei) {
                            if (parameter[0] == "") {
                                "■"//ha a nulla benne van a listában, akkor mindenképpen ő az első, mert 0 a lehető legkisebb index
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a * a}"
                        }
                    }x^2+${
                        if (1 in kérdezettekindexei) {
                            if (parameter[kérdezettekindexei.indexOf(1)] == "") {//ha a beviteli mezők között az annyiadik üres, ahanyadik a listában az első
                                indexSymbols[kérdezettekindexei.indexOf(1)] //ide kell az a szimbólum, ahanyadik szám az 1 a listában. Mivel ha az egyes benne van a listában, akkor még lehet, hogy ő a második szám a listában (0-t és 1-et kérdezte meg a program)
                            } else {
                                parameter[kérdezettekindexei.indexOf(1)]
                            }
                        } else {
                            "${2 * a * b}"
                        }
                    }x+${
                        if (2 in kérdezettekindexei) {
                            if (parameter[kérdezettekindexei.indexOf(2)] == "") {
                                indexSymbols[kérdezettekindexei.indexOf(2)]//ő mindenképpen az utolsó. de ha 4 féle dolgot lehetne megkérdezni, akkor a kettes index lehetne az első amit megkérdeztünk, és utána megkérdezhetnénk még a hármas indexű számot is
                            } else {
                                parameter[kérdezettekindexei.indexOf(2)]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (0 in kérdezettekindexei) {
                        "■"//ha a nulla benne van a listában, akkor mindenképpen ő az első, mert 0 a lehető legkisebb index

                    } else {
                        "${a * a}"
                    }
                }x^2+${
                    if (1 in kérdezettekindexei) {
                        indexSymbols[kérdezettekindexei.indexOf(1)] //ide kell az a szimbólum, ahanyadik szám az 1 a listában. Mivel ha az egyes benne van a listában, akkor még lehet, hogy ő a második szám a listában (0-t és 1-et kérdezte meg a program)

                    } else {
                        "${2 * a * b}"
                    }
                }x+${
                    if (2 in kérdezettekindexei) {

                        indexSymbols[kérdezettekindexei.indexOf(2)]//ő mindenképpen az utolsó. de ha 4 féle dolgot lehetne megkérdezni, akkor a kettes index lehetne az első amit megkérdeztünk, és utána megkérdezhetnénk még a hármas indexű számot is

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = feladatszámai.filterIndexed { index, i -> index in kérdezettekindexei },//listOf<Int>(coefficients[type]),
                unknowns = 2
            )
            return@listOf task

        },
        {//5.//ellenőrizve
            var kérdezettekindexei = listOf<Int>(0, 1, 2)//mindhármat megkérdezzük
            var a = (2..10).random()
          // println("$TAG numbers are generated")
            var b = (-10..10).toList().filter { it != 0 }.random()
            var feladatszámai = listOf<Int>(a * a, 2 * a * b, b * b)


            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})^2 = ${
                        if (0 in kérdezettekindexei) {
                            if (parameter[0] == "") {
                                "■"//ha a nulla benne van a listában, akkor mindenképpen ő az első, mert 0 a lehető legkisebb index
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a * a}"
                        }
                    }x^2+${
                        if (1 in kérdezettekindexei) {
                            if (parameter[kérdezettekindexei.indexOf(1)] == "") {//ha a beviteli mezők között az annyiadik üres, ahanyadik a listában az első
                                indexSymbols[kérdezettekindexei.indexOf(1)] //ide kell az a szimbólum, ahanyadik szám az 1 a listában. Mivel ha az egyes benne van a listában, akkor még lehet, hogy ő a második szám a listában (0-t és 1-et kérdezte meg a program)
                            } else {
                                parameter[kérdezettekindexei.indexOf(1)]
                            }
                        } else {
                            "${2 * a * b}"
                        }
                    }x+${
                        if (2 in kérdezettekindexei) {
                            if (parameter[kérdezettekindexei.indexOf(2)] == "") {
                                indexSymbols[kérdezettekindexei.indexOf(2)]//ő mindenképpen az utolsó. de ha 4 féle dolgot lehetne megkérdezni, akkor a kettes index lehetne az első amit megkérdeztünk, és utána megkérdezhetnénk még a hármas indexű számot is
                            } else {
                                parameter[kérdezettekindexei.indexOf(2)]
                            }
                        } else {
                            "${b * b}"
                        }
                    } ".replace("+-", "-").replace("-+", "-")
                },
                staticTaskText = "(${a.CE()}x+${b})^2 = ${
                    if (0 in kérdezettekindexei) {

                        "■"//ha a nulla benne van a listában, akkor mindenképpen ő az első, mert 0 a lehető legkisebb index

                    } else {
                        "${a * a}"
                    }
                }x^2+${
                    if (1 in kérdezettekindexei) {
                        indexSymbols[kérdezettekindexei.indexOf(1)] //ide kell az a szimbólum, ahanyadik szám az 1 a listában. Mivel ha az egyes benne van a listában, akkor még lehet, hogy ő a második szám a listában (0-t és 1-et kérdezte meg a program)

                    } else {
                        "${2 * a * b}"
                    }
                }x+${
                    if (2 in kérdezettekindexei) {
                        indexSymbols[kérdezettekindexei.indexOf(2)]//ő mindenképpen az utolsó. de ha 4 féle dolgot lehetne megkérdezni, akkor a kettes index lehetne az első amit megkérdeztünk, és utána megkérdezhetnénk még a hármas indexű számot is

                    } else {
                        "${b * b}"
                    }
                } ".replace("+-", "-").replace("-+", "-"),

                solution = feladatszámai.filterIndexed { index, i -> index in kérdezettekindexei },//listOf<Int>(coefficients[type]),
                unknowns = 3
            )
            return@listOf task

        },
        {
            var a = (2..10).random()
            var b = (-10..10).toList().filter { it != 0 }.random()
            var type = (0..1).random()
            var tasknumbers = listOf<Int>(a, b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a.CE()}"
                        }
                    }x+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    })^2 = ${(a * a).CE()}x^2+${2 * a * b}+${b * b} ".replace("+-", "-")
                        .replace("-+", "-")
                },
                staticTaskText = "(${
                    if (type == 0) {

                        "■"

                    } else {
                        "${a.CE()}"
                    }
                }x+${
                    if (type == 1) {
                        "■"

                    } else {
                        "${b * b}"
                    }
                })^2 = ${(a * a).CE()}x^2+${2 * a * b}+${b * b} ".replace("+-", "-")
                    .replace("-+", "-"),

                solution = listOf<Int>(tasknumbers[type]),
                unknowns = 1
            )
            return@listOf task
        },
        {
            var a = (2..10).random()
            var b = (-10..10).randomNotZero()//toList().filter { it!=0 }.random()
            // var type = (0..1).random()
            var tasknumbers = listOf<Int>(a, b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${

                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }

                    }x+${

                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }

                    })^2 = ${(a * a).CE()}x^2+${2 * a * b}x+${b * b} ".replace("+-", "-")
                        .replace("-+", "-")
                },
                staticTaskText = "(${


                    "■"

                }x+${

                    "●"


                })^2 = ${(a * a).CE()}x^2+${2 * a * b}x+${b * b} ".replace("+-", "-")
                    .replace("-+", "-"),

                solution = tasknumbers,
                unknowns = 2
            )
            return@listOf task
        },
        {
            val a = (2..6).random()
            val c = (2..6).random()
            val d = (2..6).random()
            val b = (-10..10).randomNotZero()


            val solution = listOf<Int>(2 * c, c + d, 2 * d)
            val task = Task(//ellenőrizve
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x^{${c}}+${b.CE()}x^{${d}})^2=${(a * a).CE()}x^{${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]//2c
                        }
                    }}+${2 * a * b}x^{${
                        if (parameter[1] == "") {
                            indexSymbols[1]
                        } else {
                            parameter[1]
                        }
                    }}+${b*b}x^{${
                        if (parameter[2] == "") {
                            indexSymbols[2]
                        } else {
                            parameter[2]
                        }
                    }}"
                },
                solution = solution,
                staticTaskText = "(${a.CE()}x^{${c}}+${b.CE()}x^{${d}})^2=${(a * a).CE()}x^{${

                    "■"

                }}+${2 * a * b}x^{${
                    indexSymbols[1]

                }}+${b*b}x^{${
                    indexSymbols[2]

                }}",
                unknowns = 3
            )
            return@listOf task
        }

    )

    val tasklistB = listOf<() -> Task>(//(ax+b)(ax-b)=a^2*x^2 -b^2
        {//1. szint
            val a = 1
            val b = (1..15).random()
            val tasknumbers = listOf<Int>(a * a, b * b)
            val type = 1

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})(${a.CE()}x-${b})=${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a * a).CE()}"
                        }
                    }x^2-${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b * b}"
                        }
                    }"
                },
                staticTaskText = "(${a.CE()}x+${b})(${a.CE()}x-${b})=${
                    if (type == 0) {

                        "■"

                    } else {
                        "${(a * a).CE()}"
                    }
                }x^2-${
                    if (type == 1) {

                        "■"

                    } else {
                        "${b * b}"
                    }
                }",

                solution = listOf<Int>(tasknumbers[type]),
                unknowns = 1
            )
            return@listOf task
        },
        {//2. szint
            val a = (2..10).random()
            val b = (1..15).random()
            val tasknumbers = listOf<Int>(a * a, b * b)
            val type = (0..1).random()//a-t vagy b-t kérdezzük meg

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${a.CE()}x+${b})(${a.CE()}x-${b})=${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a * a).CE()}"
                        }
                    }x^2-${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(b * b)}"
                        }
                    }"
                },
                staticTaskText = "(${a.CE()}x+${b})(${a.CE()}x-${b})=${
                    if (type == 0) {

                        "■"

                    } else {
                        "${(a * a).CE()}"
                    }
                }x^2-${
                    if (type == 1) {

                        "■"

                    } else {
                        "${(b * b)}"
                    }
                }",

                solution = listOf<Int>(tasknumbers[type]),
                unknowns = 1
            )
            return@listOf task
        },

        {//5. szint kb
            val a = (2..10).random()
            val b = (1..15).random()
            val tasknumbers = listOf<Int>(a, b)
            val type = (0..1).random()//a-t vagy b-t kérdezzük meg

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a.CE()}"
                        }
                    }x+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b}"
                        }
                    })(${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a.CE()}"
                        }
                    }x-${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${b}"
                        }
                    })=${(a * a).CE()}x^2-${b * b}"
                },
                staticTaskText = "(${
                    if (type == 0) {

                        "■"

                    } else {
                        "${a.CE()}"
                    }
                }x+${
                    if (type == 1) {

                        "■"

                    } else {
                        "${b}"
                    }
                })(${
                    if (type == 0) {

                        "■"

                    } else {
                        "${a.CE()}"
                    }
                }x-${
                    if (type == 1) {

                        "■"

                    } else {
                        "${b}"
                    }
                })=${(a * a).CE()}x^2-${b * b}",

                solution = listOf<Int>(tasknumbers[type]),
                unknowns = 1
            )
            return@listOf task
        },
        {//6. szint kb
            val a = (2..10).random()
            val b = (1..15).random()
            val tasknumbers = listOf<Int>(a , b )
            //  val type = (0..1).random() mindkettőt megkérdezzük itt

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }x+${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }

                    })(${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }x-${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }
                    })=${(a * a).CE()}x^2-${b * b}"
                },
                staticTaskText = "(${

                    "■"

                }x+${

                    "●"


                })(${

                    "■"

                }x-${

                    "●"

                })=${(a * a).CE()}x^2-${b * b}",

                solution = tasknumbers,
                unknowns = 2
            )
            return@listOf task
        },
        {
            val a = (2..10).random()
            val b = (2..10).random()
            val c = (2..10).random()
            val tasknumbers = listOf<Int>(a, c)

            val task = Task(

                tasktext = { parameter: List<String> ->
                    "(${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }x^{${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }
                    }}+${b})(${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }x^{${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }
                    }}-${b})=${(a * a).CE()}x^{${2 * c}}-${b * b}"
                },
                staticTaskText = "(${

                    "■"

                }x^${

                    "●"

                }+${b})(${

                    "■"

                }x^${

                    "●"

                }-${b})=${(a * a).CE()}x^{${2 * c}}-${b * b}",
                solution = tasknumbers,
                unknowns = 2
            )
            return@listOf task

        }

    )

    val tasklistC = listOf<() -> Task>(

        {//1. szint
            val a = (-10..10).toList().filter { it != 0 }.random()
            val b = (-10..10).toList().filter { it != 0 && it.absoluteValue != a.absoluteValue }
                .random()
            val type = (0..1).random() //a+b-t vagy a*b-t kérdezzük meg
            val tasknumbers = listOf<Int>(a + b, a * b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(x+${a})(x+${b})=x^2+${
                        if (type == 0) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${(a + b).CE()}"
                        }
                    }x+${
                        if (type == 1) {
                            if (parameter[0] == "") {
                                "■"
                            } else {
                                parameter[0]
                            }
                        } else {
                            "${a * b}"
                        }
                    }"
                },
                staticTaskText = "(x+${a})(x+${b})=x^2+${
                    if (type == 0) {

                        "■"

                    } else {
                        "${(a + b).CE()}"
                    }
                }x+${
                    if (type == 1) {

                        "■"

                    } else {
                        "${a * b}"
                    }
                }",

                solution = listOf(tasknumbers[type]),
                unknowns = 1
            )
            return@listOf task


        },
        {//2. szint
            val a = (-10..10).toList().filter { it != 0 }.random()
            val b = (-10..10).toList().filter { it != 0 && it.absoluteValue != a.absoluteValue }
                .random()
            val tasknumbers = listOf<Int>(a + b, a * b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(x+${a})(x+${b})=x^2+${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }x+${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }
                    }"
                },
                staticTaskText = "(x+${a})(x+${b})=x^2+${
                    "■"
                }x+${
                    "●"
                }",

                solution = tasknumbers,
                unknowns = 2
            )
            return@listOf task
        },
        {
            val a = (-10..10).toList().filter { it != 0 }.random()
            val b = (-10..10).toList().filter { it != 0 && it.absoluteValue != a.absoluteValue }
                .random()
            val tasknumbers = listOf<Int>(a, b)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "(x+${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    })(x+${
                        if (parameter[1] == "") {
                            "●"
                        } else {
                            parameter[1]
                        }
                    })=x^2+${(a + b).CE()}x+${a * b}"
                },
                staticTaskText = "(x+${
                    "■"
                })(x+${
                    "●"
                })=x^2+${(a + b).CE()}x+${a * b}",

                solution = tasknumbers,
                unknowns = 2
            )
            return@listOf task


        }
    )

    val tasklistD = listOf<() -> Task>(
        {
            //1. szint
            val a = (2..10).random()
            val c = (2..10).random()
            val b = (-10..10).randomNotZero()
            val tasknumbers = listOf<Int>(a)

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "${(a * c).CE()}x+${a * b}=${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }(${c.CE()}x+$b)"
                },
                staticTaskText = "${(a * c).CE()}x+${a * b}=${

                    "■"

                }(${c.CE()}x+$b)",

                solution = tasknumbers,
                unknowns = 1
            )
            return@listOf task

        },
        {
            val a = listOf<Int>(2, 3, 5, 7, 11).random()
            val b = listOf<Int>(2, 3, 5, 7, 11).filter { it != a }.random()
            val n = (3..10).random()
            val k = (2 until n).random()
            val solution = listOf(n - k)
            var task = Task(
                tasktext = { parameter: List<String> ->
                    "${b.CE()}x^{${n}}+${a.CE()}x^${k}=x^{$k}(${b}x^${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }+${a})"
                },
                staticTaskText = "${b.CE()}x^{${n}}+${a.CE()}x^${k}=x^{$k}(${b}x^${

                    "■"

                }+${a})",

                solution = solution,
                unknowns = 1
            )
            return@listOf task

        },
        {
            val a = listOf<Int>(2, 3, 5, 7, 11).random()
            val b = listOf<Int>(2, 3, 5, 7, 11).filter { it != a }.random()
            val n = (3..10).random()
            val k = (2 until n).random()

            var task = Task(
                tasktext = { parameter: List<String> ->
                    "${b}x^{${n.CE()}}+${a}x^{${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }}=x^{${
                        if (parameter[0] == "") {
                            "■"
                        } else {
                            parameter[0]
                        }
                    }}(${b}x^{${(n - k).CE()}}+${a})"
                },
                staticTaskText = "${b}x^{${n.CE()}}+${a}x^{${

                    "■"

                }}=x^{${

                    "■"

                }}(${b}x^{${(n - k).CE()}}+${a})",

                solution = listOf(k),
                unknowns = 1
            )
            return@listOf task

        }

    )


    class Task(
        var tasktext: (List<String>) -> String,
        var staticTaskText: String,
        var solution: List<Number>,
        var unknowns: Int,

        )


    @Composable
    fun mainTaskType(
        collapsed: MutableState<Boolean>,
        tasktype: MutableState<String>,
        betűjel: String,
        currentlevel: MutableState<Int>,
        maxlevels: Int = 4
    ) {
        AnimatedVisibility(visible = !collapsed.value) {
            Surface(
                modifier = Modifier
                    .fillMaxWidth()
                    .wrapContentHeight()
                    .padding(4.dp),
                color = MaterialTheme.colorScheme.onSurface.copy(.175f),
                shape = RoundedCornerShape(8.dp)

            ) {
                var selected = remember { derivedStateOf { tasktype.value == betűjel } }
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    Button(
                        onClick = {
                            tasktype.value = betűjel
                            currentlevel.value = 1
                        },
                        colors = if (selected.value) {
                            androidx.compose.material3.ButtonDefaults.buttonColors(
                                containerColor = matekitcolor
                            )
                        } else {
                            androidx.compose.material3.ButtonDefaults.buttonColors(
                                containerColor = MaterialTheme.colorScheme.onSurface.copy(.175f)
                            )
                        }

                    ) {
                        (Text(text = "$betűjel típus"))
                    }
                    Spacer(modifier = Modifier.height(3.dp))

                    val indexes = (0 until maxlevels).chunked(3)

                    indexes.forEach{
                        chunk->
                        Row {
                            for(i in chunk){
                                AnimatedVisibility(visible = selected.value) {
                                    myToggleIntButton(
                                        currentlevel,
                                        i)}

                        }
                        }
                    }
                   /* for (i in 0 until maxlevels) {
                        AnimatedVisibility(visible = selected.value) {
                            myToggleIntButton(
                                currentlevel,
                                i
                            )
                        }
                    }*/
                }
            }
        }




    }

    @Composable
    fun myToggleIntButton(selectedIndex: MutableState<Int>, index: Int) {

        var selected = remember { derivedStateOf { selectedIndex.value == index } }

        Button(
            onClick = {
                selectedIndex.value = index
            },
            colors = if (selected.value) {
                androidx.compose.material3.ButtonDefaults.buttonColors(
                    containerColor = matekitcolor
                )
            } else {
                androidx.compose.material3.ButtonDefaults.buttonColors(
                    containerColor = MaterialTheme.colorScheme.onSurface.copy(.175f)
                )
            }

        ) {
            (Text(text = "${index + 1}. szint"))
        }
    }
}