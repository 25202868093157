package matekit.matekit

import kotlin.math.absoluteValue

data class Rational(
    var numerator: Int,
    var denominator: Int,
) {
    override fun toString(): String {
        return if (denominator == 1) {
            "$numerator"
        } else {
            "$numerator/$denominator"
        }
    }

    init {//- sign should only be carried by numerator
        require(denominator != 0) { "Denominator cannot be zero" }
        var adjusted = minussign()
        this.numerator = adjusted.numerator
        this.denominator = adjusted.denominator
        val gcd = calculateGCD()
        this.numerator /= gcd
        this.denominator /= gcd


    }

    private fun minussign(): Rational {
        return if (denominator < 0) {
            Rational(-numerator, -denominator)
        } else {
            this
        }
    }

    fun calculateGCD(): Int {
        var num1 = numerator.absoluteValue
        var num2 = denominator.absoluteValue
        while (num2 != 0) {
            val temp = num2
            num2 = num1 % num2
            num1 = temp
        }
        return num1
    }

    fun simplify(): Rational {
        val gcd = calculateGCD()
        return Rational(numerator / gcd, denominator / gcd)
    }

    operator fun plus(b: Rational): Rational {
        val newNumerator = this.numerator * b.denominator + this.denominator * b.numerator
        val newDenominator = this.denominator * b.denominator
        return Rational(newNumerator, newDenominator).simplify()
    }

    operator fun minus(b: Rational): Rational {
        val newNumerator = this.numerator * b.denominator - this.denominator * b.numerator
        val newDenominator = this.denominator * b.denominator
        return Rational(newNumerator, newDenominator).simplify()
    }

    operator fun times(b: Rational): Rational {
        val newNumerator = this.numerator * b.numerator
        val newDenominator = this.denominator * b.denominator
        return Rational(newNumerator, newDenominator).simplify()
    }

    operator fun div(b: Rational): Rational {
        require(b.numerator != 0) { "Cannot divide by zero" }
        val reciprocal = Rational(b.denominator, b.numerator)
        return this.times(reciprocal)
    }
}
fun Rational.toLaTeX():String{
    return if(this.denominator.toInt()!=1){
        "\\frac{${this.numerator}}{${this.denominator}}"
    } else {
        this.numerator.toString()//ha a nevező egy, akkor a számlálót adjuk vissza
    }
}
