package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.simpleLogButton
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.min
import kotlin.math.sqrt

@Composable
fun PitagoraszUI(navController: NavHostController, TAG: String ) {
    Surface(
        modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
    ) {
        val vm = viewModel { PitagoraszViewModel( TAG, ) }
        val uiState by vm.uiState.collectAsState()
        val coroutineScope = rememberCoroutineScope()
        LaunchedEffect(Unit) {
            vm.generateNewTask()
        }
        Column(modifier = Modifier.fillMaxSize(), horizontalAlignment = Alignment.CenterHorizontally) {
            borderedbasicfontsizeTextView(tasktext = uiState.feladat, )
            Text("A kerekítés végett egyszázados eltérést elfogadunk a valós megoldástól.", textAlign = TextAlign.Center, modifier = Modifier.padding(bottom = 6.dp))
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            ) {

                universalCheckField(
                    uiState.userInput,
                    enterText = { vm.entertext(it) },
                    check = {coroutineScope.launch {  vm.check() } }
                )

                Spacer(Modifier.width(8.dp))
                Button(
                    onClick = {coroutineScope.launch {  vm.check() } },
                ) {
                    Text(stringResource(Res.string.Check))
                }
                LatexLogButton(navController, TAG)

            }

        }
        responseToast(uiState.isCorrect,uiState.numberofsolved, completeResponse =  uiState.showsolution, useCompleteFeedbackMessage = true)
        backButton(navController)
    }
}

data class PitagoraszUIState(
    val feladat: String = "",
    val solution: Double = 0.0,
    val showsolution: String = "",
    val numberofsolved: Int = 0,
    val isCorrect: Boolean = false,
    val userInput: String = ""
)

class PitagoraszViewModel(tag: String,) : ViewModel() {
    val tag = tag

    private val _uiState = MutableStateFlow(PitagoraszUIState())
    val uiState: StateFlow<PitagoraszUIState> = _uiState.asStateFlow()


    fun generateNewTask() {

        val tasks = listOf<() -> pitagoraszfeladat>(
            {//1
                val c = (200..550 step 10).toList().random()
                val a = (c / 5..c / 2).random()
                val szöveg = "Egy falnak döntött $c centiméter hosszú létra talapzatának a fal tövétől mért távolsága $a centiméter. A fal tövétől hány centiméter magasan van a létra teteje?"
                val b = sqrt(((c * c - a * a).toDouble()))
                return@listOf pitagoraszfeladat(szöveg, b)
            },
            {//2
                val a = (3..16).random()
                val b = (3..16).filter { it != a }.random()

                val c = sqrt((a * a + b * b).toDouble())
                val szöveg = "Egy téglalap oldalai $a és $b cm hosszúak. Hány cm hosszú az átlója?"
                return@listOf pitagoraszfeladat(szöveg, c)
            },
            {//3
                val a = (3..19).random()
                val c = ((a + 1)..25).random()
                val b = sqrt((c * c - a * a).toDouble())//todo a és b számot megbeszélni
                val szöveg =
                    "Egy téglalap egyik oldala $a, átlója $c cm hosszú. Hány cm hosszú a másik oldala?"
                return@listOf pitagoraszfeladat(szöveg, b)
            },
            {
//4
                val a = (3..20).random()
                val c = (a..24).random() //c nagyobb a-nál, de akkor c lehessen nagyobb a max értéknél todo
                val b = sqrt((c * c - a * a).toDouble())
                val szöveg =
                    "Egy ${(c * 1.0 / 2).formatToDecimals(2)} cm sugarú körbe írt téglalap egyik oldala $a cm. Hány cm hosszú a másik oldala?"
                return@listOf pitagoraszfeladat(szöveg, b)
            },
            {//5
                val a = (2..30).random()
                val c = sqrt(2.0 * a * a)
                val szöveg = "Egy négyzet oldalai $a cm hosszúak. Hány cm hosszú az átlója?"
                return@listOf pitagoraszfeladat(szöveg, c)
            },
            {
                //6
                val c = (2..36).random()
                val szöveg = "Egy négyzet átlója $c cm hosszú. Hány cm hosszú az oldala?"
                val a = sqrt((c * c) / 2.0)
                return@listOf pitagoraszfeladat(szöveg, a)
            },
            {
                //7
                val a = (1..10).random()
                val c = (a + 1..a + 10).random()
                val b = sqrt((c * c - a * a).toDouble())
                val szöveg =
                    "Egy egyenlő szárú háromszög szárai $c cm hosszúak, alapja ${2 * a} cm. Hány négyzetcentiméter a területe?"
                val t = b * a //b*2a:2
                return@listOf pitagoraszfeladat(szöveg, t)
            },
            {
                //8
                val a = (2..10).random()
                val b = (2..10).random()
                val c = sqrt((a * a + b * b).toDouble())
                val szöveg =
                    "Egy egyenlő szárú háromszög  alapja ${2 * a} cm, magassága $b cm. Hány centiméter hosszú a szára?"
                return@listOf pitagoraszfeladat(szöveg, c)
            },
            {
                //10
                val c = (5..20).random()
                val a = (1 until c).random()
                val b = 2.0 * sqrt((c * c - a * a).toDouble())
                val szöveg =
                    "Milyen hosszú az a húr, amely a ${2 * c} cm átmérőjű kör középpontjától $a cm távolságra van?"
                return@listOf pitagoraszfeladat(szöveg, b)
            },
            {
                //11
                val a = (2..4).random()
                val b = (6..12).random()
                val átló = sqrt((4.0 * a * a + b * b).toDouble())
                val szívószálhossz = átló.toInt() + (1..8).random()
                val kilóg = szívószálhossz * 1.0 - átló
                val szöveg =
                    "Egy henger alakú $a cm sugarú, $b cm magas pohárba egy $szívószálhossz cm hosszú szívószálat teszünk. Legalább hány centiméterre lóg ki a szívószál a pohárból?"
                return@listOf pitagoraszfeladat(szöveg, kilóg)
            },
            {//12
                val a = (2..20).random()
                val b = (2..20).filter { it != a }.random()
                val c = (2..20).filter { it != a && it != b }.random()

                val átló = sqrt((a * a + b * b + c * c).toDouble())
                val szöveg = "Egy téglatest élei $a, $b és $c cm hosszúak. Hány cm hosszú a testátlója?"
                return@listOf pitagoraszfeladat(szöveg, átló)
            },
            {//13
                val (m, n) = generateCoprimes()//listát ad vissza, a kotlin a lista első és második komponensével deklarálja m és n változókat
                val c = (10..50).random()
                val k = sqrt((c * c) / (m * m + n * n).toDouble())
                val kisebbik = min(k * m, k * n)
                val szöveg =
                    "Egy téglalap átlója $c cm, oldalainak aránya $m:$n. Hány cm hosszú a kisebbik oldala?"
                return@listOf pitagoraszfeladat(szöveg, kisebbik)
            }
        )

        val task = tasks.random()()
        _uiState.update {
            it.copy(
                feladat = task.feladat,
                solution = task.solution
            )
        }
    }

    fun entertext(enteredText: String) {
        _uiState.update {
            it.copy(
                userInput = enteredText.replace(",",".")
            )
        }
    }

    suspend fun check() {
        val solution = uiState.value.solution.formatToDecimals(6)
        val userInput =try {
            uiState.value.userInput.toDouble()
        } catch (e:Exception){
            return@check //akkor ne ellenőrizzük le a feladatot
        }
        val isCorrect: Boolean
        if (userInput in (uiState.value.solution - 0.01)..(uiState.value.solution + 0.01) ) {
            isCorrect = true
        } else {
            isCorrect = false
        }
        val showSolutionIfUserWrong = getString(Res.string.WrongSolution, "~$solution")
        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = uiState.value.numberofsolved + 1,
                showsolution = showSolutionIfUserWrong
            )
        }
       // var text = if (isCorrect) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong

        LaTexLogWriteDesktop(
            isCorrect,
            _uiState.value.userInput.toString(),
            showSolutionIfUserWrong,
            tag,
            _uiState.value.feladat,
            taskLaTexWrap = false
        )
        generateNewTask()
        entertext("")
    }
}

data class pitagoraszfeladat(
    val feladat: String,
    val solution: Double
)