package matekit.matekit


import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.grid.GridCells
import androidx.compose.foundation.lazy.grid.LazyVerticalGrid
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.mapSaver
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.ui.backButton
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.howmuch
import org.jetbrains.compose.resources.getString
import kotlin.math.pow


@Composable
fun Hatvanyozás(navController: NavHostController, ratios:ratios, orientation: MutableState<String>,) {
    val coroutineScope = rememberCoroutineScope()

    @OptIn(ExperimentalFoundationApi::class)


    var orientation = orientation//OrientationChecker().checkOrientation()

    val TeXdisplay = rememberSaveable { mutableStateOf("3x+6=18") }
    var numberofsolved = rememberSaveable { mutableStateOf(0) }
    val base = rememberSaveable { mutableStateOf(0) }
    val exponent = rememberSaveable { mutableStateOf(0) }
    val tippLista = rememberSaveable { mutableStateOf(mutableListOf(0, 0, 0, 0, 0, 0, 0)) }
    val baseRange = rememberSaveable(stateSaver = RangeSaver) { mutableStateOf(2..12) }
    val exponentRange =
        rememberSaveable(stateSaver = RangeSaver) { mutableStateOf(2..10) } //kell, ha egyéni feladatot készítünk
    val indexOfSolution = rememberSaveable { mutableStateOf(0) }
    var hatványData by remember {
        mutableStateOf(
            HatványData(
                TeXdisplay,
                base,
                exponent,
                tippLista,
                baseRange,
                exponentRange,
                indexOfSolution,
            )
        )
    }

    // A surface container using the 'background' color from the theme
    Surface(
        modifier = Modifier.fillMaxSize(),
        color = MaterialTheme.colorScheme.background
    ) {
        val isCorrect = rememberSaveable { mutableStateOf(false) }
        val lastSolution = rememberSaveable { mutableStateOf(0) }
        backButton(navController)
        //   responseToast(isCorrect.value,numberofsolved.value,lastSolution.value) //nemide
        Column(Modifier.padding(bottom = 60.dp)) {//weben kell 60.dp a visszagomb miatt lent
            webviewkmm(hatványData.TeXdisplay.value)

            Column(
                modifier = Modifier
                    .fillMaxHeight(0.6f)
                    .weight(1f)
            ) {
                val ButtonModifier = Modifier
                    .fillMaxSize()
                    .weight(1f)
                    .padding(10.dp)

                if (orientation.value == "Landscape") {
                    LazyVerticalGrid(
                        columns = GridCells.Fixed(3),
                        contentPadding = PaddingValues(8.dp)
                    ) {
                        tippLista.value.forEachIndexed { index, i ->
                            item {
                                Button(modifier = ButtonModifier,
                                    colors = ButtonDefaults.buttonColors(matekitcolor.copy(alpha = 0.86f)),
                                    onClick = {
                                        coroutineScope.launch {
                                            check(
                                                index,
                                                hatványData,
                                                isCorrect,
                                                numberofsolved,
                                                lastSolution
                                            )
                                        }

                                    }
                                ) { Text(text = i.toString(), fontSize = 30.sp) }

                            }
                        }

                    }
                } else {
                    Column() {
                        tippLista.value.forEachIndexed { index, i ->
                            Button(modifier = ButtonModifier,
                                colors = ButtonDefaults.buttonColors(matekitcolor.copy(alpha = 0.86f)),
                                onClick = {
                                    coroutineScope.launch {
                                        check(
                                            index,
                                            hatványData,
                                            isCorrect,
                                            numberofsolved,
                                            lastSolution
                                        )
                                    }
                                }
                            ) { Text(text = i.toString(), fontSize = 30.sp) }
                        }
                    }

                }
            }

        }
        LaunchedEffect(Unit) {
            generate(hatványData)
        }
        // generate(alap = alap, setAlap = {alap})
        responseToast(isCorrect.value, numberofsolved.value, lastSolution.value) //weben ide

    }


}


suspend fun generate(hatványData: HatványData) {
    //println("generating new task...")
    hatványData.alap.value = hatványData.alaprange.value.random()
    // var r = (1..30).random()
    // if(r==30)   hatványData.kitevő.value =0 ezt majd később, vagy egyáltalán ne

    hatványData.kitevő.value =
        when (hatványData.alap.value) {
            2 -> (2..28).random()
            3 -> (2..18).random()
            4 -> (2..14).random()
            5 -> (2..12).random()
            6 -> (2..11).random()
            7 -> (2..10).random()
            8 -> (2..9).random()
            9 -> (2..9).random()
            11 -> (2..8).random() //tíz felett már nem olyan meredek
            12 -> (2..8).random()
            13 -> (2..7).random()
            14 -> (2..7).random()
            15 -> (2..7).random()
            16 -> (2..7).random()
            17 -> (2..7).random()
            18 -> (2..7).random()
            19 -> (2..7).random()
            else -> {
                5
            }
        }
    hatványData.kitevő.value = (2..4).random()

    // var legfeljebb =if(5-hatványData.kitevő.value<0) 5-hatványData.kitevő.value else 0
    var legfeljebb = if (hatványData.kitevő.value <= 5) hatványData.kitevő.value else 5
    hatványData.hanyadikaMegoldás.value = (1..legfeljebb).random() //egytől sorszámozzuk
    hatványData.tippLista.value =
        mutableListOf<Int>() //összeállítjuk a listát, aztán megkeverjük
    for (i in 1 until hatványData.hanyadikaMegoldás.value) {
        hatványData.tippLista.value.add(
            hatványData.alap.value.toDouble().pow(hatványData.kitevő.value - i).toInt()
        )
    }
    for (i in 0..5 - hatványData.hanyadikaMegoldás.value) {//az első magát a számot adja hozzá
        hatványData.tippLista.value.add(
            hatványData.alap.value.toDouble().pow(hatványData.kitevő.value + i).toInt()
        )
    }
    hatványData.tippLista.value.add(hatványData.alap.value * hatványData.kitevő.value)
    hatványData.tippLista.value =
        hatványData.tippLista.value.shuffled() as MutableList<Int> //ezzel véletlenszerűvé is tettük, most sorban ráírjuk a gombokra
    hatványData.tippLista.value = hatványData.tippLista.value.toMutableSet().toMutableList()
    //until helyett .., így magát az értéket is hozzáadjuk
    hatványData.TeXdisplay.value =
        "\\text{${getString(Res.string.howmuch)} }${hatványData.alap.value}^{${hatványData.kitevő.value}}\\text{?}"
    // println(" hatványData.TeXdisplay.value:${ hatványData.TeXdisplay.value}")
  /*  println(
        "solution =${
            hatványData.alap.value.toDouble().pow(hatványData.kitevő.value).toInt()
        } "
    )*/
}

suspend fun check(
    buttonindex: Int,
    hatványData: HatványData,
    isCorrect: MutableState<Boolean>,
    numberofsolved: MutableState<Int>,
    lastSolution: MutableState<Int>
) {

    var diáktipp = hatványData.tippLista.value[buttonindex]
    var megoldás = hatványData.alap.value.toDouble().pow(hatványData.kitevő.value).toInt()
    var helyesMO = diáktipp == megoldás
    var showSolutionIfUserWrong = getString(Res.string.WrongSolution, megoldás.toString())
    var text = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
    //var color=if(helyesMO) Res.style.CorrectToast else R.style.WrongToast
    //ShowToast.showToast(color, text, context,true, )

    isCorrect.value = helyesMO
    numberofsolved.value++
    lastSolution.value = megoldás

    generate(hatványData)
//todo innen folytatni: ellenőrizni az egyenlőséget, paraméterként felvenni az adatosztályt, logolni

}

val RangeSaver = run {
    val firstKey = "first"
    val lastKey = "last"
    mapSaver(
        save = { mapOf(firstKey to it.first, lastKey to it.last) },
        restore = { IntRange(it[firstKey] as Int, it[lastKey] as Int) }
    )
}

data class HatványData(
    var TeXdisplay: MutableState<String>,
    var alap: MutableState<Int>,
    var kitevő: MutableState<Int>,
    var tippLista: MutableState<MutableList<Int>>,
    var alaprange: MutableState<IntRange>,
    var kitevőrange: MutableState<IntRange>,
    var hanyadikaMegoldás: MutableState<Int>,
)

var textcolor = "black"
