package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.getstring
import matekit.ui.LatexLogButton
import matekit.ui.SurfaceWBorder
import matekit.ui.backButton
import matekit.ui.basicfontsize
import matekit.ui.basicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.done
import matekit_multiplatform.composeapp.generated.resources.findPrimeFactorsfor
import matekit_multiplatform.composeapp.generated.resources.ic_backbt
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import kotlin.math.sqrt


val szorzasjel = "·"//ez egy másik karakter mint androidon.

@Composable
fun PrimeFactorScreen(
    navController: NavHostController,
    tag:String="PrimeFactors"
)  {

    val coroutineScope = rememberCoroutineScope()
        // A surface container using the 'background' color from the theme
        Surface(
            modifier = Modifier.fillMaxSize(),
            color = MaterialTheme.colorScheme.background
        ) {
            backButton( navController)
            var number = rememberSaveable { mutableStateOf((1..2000).random()) }
            var prímtényezősFelbontás = rememberSaveable{ mutableStateOf(mutableListOf<String>()) }
            var tasktextref = rememberSaveable {
                mutableStateOf(
                    Paramstr(Res.string.findPrimeFactorsfor,listOf(86))
                )
            }
            var isCorrect = rememberSaveable { mutableStateOf(false) }
            var numberofsolved = rememberSaveable { mutableStateOf(0) }
            var showsolution = rememberSaveable { mutableStateOf("") }




            LaunchedEffect(Unit){

                coroutineScope.launch{
                    primeGenerate(number ,prímtényezősFelbontás,tasktextref)
                }


            }
            Column(modifier = Modifier.fillMaxSize(), verticalArrangement = Arrangement.SpaceAround, horizontalAlignment = Alignment.CenterHorizontally){
                val userinput = rememberSaveable{ mutableStateOf("") }

                SurfaceWBorder(content = {basicfontsizeTextView(tasktextref.value.getparamstr(),modifier = Modifier.padding(5.dp))},modifier=Modifier.wrapContentWidth(), borderColor =  matekitcolor)

                SurfaceWBorder(content = { Text(text=userinput.value,fontSize=basicfontsize.sp, modifier = Modifier.padding(5.dp)) },modifier = Modifier.padding(6.dp).wrapContentWidth().widthIn(min=300.dp,max=600.dp),borderColor= Color.Green)

                Row(modifier= Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.Center){

                    HatvanyNumPad(userinput = userinput) {

                        coroutineScope.launch{
                            primeCheck(tasktextref.value,isCorrect,numberofsolved,showsolution,prímtényezősFelbontás,userinput) { //todo toast
                                primeGenerate(
                                    number,
                                    prímtényezősFelbontás,
                                    tasktextref)
                            }

                        }


                    }
                   LatexLogButton(navController, tag)
                }

                // whiteboard(activity = this@PrimeFactors, context = context, darkmode = darkmode)
            }
            responseToast(isCorrect.value, numberofsolved = numberofsolved.value, showsolution.value)

        }

    }
suspend fun primeCheck(tasktext: Paramstr, isCorrect:MutableState<Boolean>,numberofsolved:MutableState<Int>,showsolution:MutableState<String>,prímtényezősFelbontás : MutableState<MutableList<String>>, userinput:MutableState<String>, primeGenerate:()->Unit){
 val   tag:String="PrimeFactors"

    if(userinput.value.isEmpty()) return
    var showSolutionIfUserWrong =""
    var helyesMO:Boolean
    var felbontás =prímtényezősFelbontás.value.toSet()
    var diákfelbontáslista= userinput.value.split(szorzasjel).toMutableList()
    diákfelbontáslista.forEachIndexed { index, s ->  if(!s.contains("^")) diákfelbontáslista[index] ="$s^1"  }

    var diákfelbontása = diákfelbontáslista.toSet()
    if(felbontás==diákfelbontása){
        isCorrect.value=true
    }
    else {
        isCorrect.value=false
        showSolutionIfUserWrong= getString(
            Res.string.WrongSolution,
            prímtényezősFelbontás.value.toString().drop(1).dropLast(1)
        )
    }
  //  var text=if(helyesMO) getString(R.string.CorrectSolution) else showSolutionIfUserWrong.replace(".0","")
    //var color=if(helyesMO) R.style.CorrectToast else R.style.WrongToast
    //ShowToast.showToast(color, text, context,true, )//todo toast
   /* SaveUserInput.simplelog(
        this@PrimeFactors,
        helyesMO,
        diákfelbontása .toString().replace(".0", "").drop(1).dropLast(1),
        showSolutionIfUserWrong,
        TAG,
        tasktext.value,
        userDocRef, modulID =15
    )*/
    showsolution.value= prímtényezősFelbontás.value.toString().drop(1).dropLast(1)

    numberofsolved.value++


    LaTexLogWriteDesktop(isCorrect.value,userinput.value,showSolutionIfUserWrong,tag, tasktext.getstring(), taskLaTexWrap = false)

    userinput.value=""
    primeGenerate()
}



fun felbont(szam:MutableState<Int>):MutableList<String> {
    var index: Int
    var szam= szam.value //Integer.parseInt(input.getText().toString());
    var primes = mutableListOf<Int>()
    var kitevők = mutableListOf<Int>()
    var gyök = sqrt(szam.toDouble()).toInt()
    for(i in 2..gyök){
        while(szam%i==0){
            szam/=i
            if(i !in primes){
                primes.add(i)
                kitevők.add(1)
            }else{
                index=primes.indexOf(i)
                kitevők[index]++
            }
        }
    }
    if(szam!=1) {
        primes.add(szam)
        kitevők.add(1)
    }
    var felbontás = mutableListOf<String>()
    primes.forEachIndexed { index, i ->
        felbontás.add ("$i^${kitevők[index]}")
    }
    return felbontás

}

@Composable
fun HatvanyNumPad(userinput: MutableState<String>, done:()->Unit ){
    Column(modifier= Modifier
        .width(240.dp)
        .height(240.dp)){
        var RowModifier =Modifier
        val buttonmodifier = Modifier
            .width(60.dp)
            .height(60.dp)
        Row(modifier = RowModifier){
            for (i in 7..9){
                numPadButton(userinput = userinput, key = "$i",buttonmodifier)
            }
            numPadButton(userinput = userinput, key = "^",buttonmodifier)
        }
        Row(modifier = RowModifier){
            for (i in 4..6){
                numPadButton(userinput = userinput, key = "$i",buttonmodifier)
            }
            numPadButton(userinput = userinput, key = szorzasjel,buttonmodifier)
        }
        Row(modifier = RowModifier){
            for (i in 1..3){
                numPadButton(userinput = userinput, key = "$i",buttonmodifier)
            }
            IconButton(onClick = { userinput.value=userinput.value.dropLast(1)},
                modifier=buttonmodifier,
            )
            {   Icon(
                painter = painterResource(  Res.drawable.ic_backbt),
                contentDescription ="delete button icon",
                modifier = Modifier.size(60.dp))
            }
        }
        Row(modifier = RowModifier){
            numPadButton(userinput = userinput, key ="0" , modifier = Modifier
                .width(120.dp)
                .height(60.dp))
            Button(onClick = {done() }, modifier = Modifier
                .width(120.dp)
                .height(60.dp)) {
                Text(stringResource( Res.string.done), fontSize = 20.sp)
            }
        }
    }
}

@Composable
fun numPadButton(userinput: MutableState<String>, key:String, modifier:Modifier){
    Button(onClick = { userinput.value+=key},shape = CircleShape, modifier = modifier) {
        Text(key, fontSize = 28.sp,textAlign = TextAlign.Center,  // horizontal center of the text
            modifier = Modifier.align(Alignment.Top) //vertical center of the Text composable
        )
    }
}

fun primeGenerate(number: MutableState<Int>, prímtényezősFelbontás : MutableState<MutableList<String>>, tasktextref : MutableState<Paramstr>){
    number.value =(1..2000).random()
    prímtényezősFelbontás.value =felbont(number)

    tasktextref.value= Paramstr(Res.string.findPrimeFactorsfor,listOf(number.value))
//"Add meg a prímtényezős felbontását az alábbi számnak: ${number.value}"
}

