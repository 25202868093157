package matekit.matekit.utilityfunctions

import kotlin.math.pow
import kotlin.math.sqrt

fun Int.factorial():Int{//         negatív számokat nem kezeltük, de mindig pozitív számok faktoriálisát fogjuk számolni
    var a =1
    for (i in 1..this){
        a *=i
    }
    return a
}
fun Int.choose(K:Int):Int{ //"n choose k" //n alatt a k
    return this.factorial()/(K.factorial()*(this-K).factorial())
}

fun IntRange.randomNotZero(): Int {
    var number = this.toList().filter { it != 0 }
        .random()//ezt még lehetett volna tovább bonyolítani azzal, hogy "és egyéb feltételek, paraméterként az _it" majd az it-et hivatkozni a függvény paraméterei közt, pl nem nulla és nem öt, vagy nem _a
    return number
}

fun kisGauss(n:Int):Int{
    var a =0
    for (i in 1..n){
        a+=i
    }
    return a
}

fun pointdistance(x1: Double, y1: Double, x2: Double, y2: Double): Double {
    return sqrt((x2-x1).pow(2)+(y2-y1).pow(2))
}

fun perimeter(points:List<Pair<Double, Double>>): Double {
    var perimeter = 0.0
    for (i in 0 until points.size-1) {
        perimeter += pointdistance(
            points[i].first,
            points[i].second,
            points[i + 1].first,
            points[i+1].second,
        )
    }
    perimeter += pointdistance(
        points.first().first,points.first().second,
        points.last().first,points.last().second,
    )
    return perimeter
}