package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.generatorSettings
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.beginner1
import matekit_multiplatform.composeapp.generated.resources.beginner2
import matekit_multiplatform.composeapp.generated.resources.easy
import matekit_multiplatform.composeapp.generated.resources.fractions1
import matekit_multiplatform.composeapp.generated.resources.fractions2
import matekit_multiplatform.composeapp.generated.resources.fractions3
import matekit_multiplatform.composeapp.generated.resources.hard
import matekit_multiplatform.composeapp.generated.resources.identity
import matekit_multiplatform.composeapp.generated.resources.medium
import matekit_multiplatform.composeapp.generated.resources.parentheses1
import matekit_multiplatform.composeapp.generated.resources.parentheses2
import matekit_multiplatform.composeapp.generated.resources.wasIdentitiy
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random

@Composable
fun EquationGeneratorScreen(
    navController: NavHostController,
    ratios:ratios,
    tag:String="Equation",
   viewModel: EquationViewModel = viewModel {EquationViewModel(tag)},
) {
    val uiState by viewModel.uiState.collectAsState()

    // For LaTeX rendering

    val coroutineScope = rememberCoroutineScope()

    backButton(navController)
    Column(
        modifier = Modifier.fillMaxSize(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        webviewkmm(LaTeX = uiState.equationDisplay)//ezt weben áttesszük felülre az egyszerűség kedvéért
       // responseToast(correct = uiState.isCorrect, numberofsolved = uiState.numberofsolved, useCompleteFeedbackMessage = true, completeResponse = uiState.showsolution) //weben ez nem jó itt
        // User Input Section
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center,
            modifier = Modifier.padding(16.dp).fillMaxWidth()
        ) {
            Button(
                onClick = {
                    coroutineScope.launch { viewModel.checkSolution() }


                          },
                modifier = Modifier.padding(end = 8.dp)
            ) {
                Text(stringResource(Res.string.Check))
            }

            LatexLogButton(navController,tag)

            OutlinedTextField(
                value = uiState.userInput,
                onValueChange = { viewModel.updateUserInput(it) },
                label = { Text(stringResource(Res.string.Solution)) },
                keyboardOptions = KeyboardOptions.Default.copy(
                    keyboardType = KeyboardType.Number,
                    imeAction = ImeAction.Done
                ),
                modifier = Modifier.widthIn(max = 200.dp).onPreviewKeyEvent { event ->
                    if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                        coroutineScope.launch { viewModel.checkSolution() }
                        true // Indicate that the event was handled
                    } else {
                        false // Indicate that the event was not handled
                    }}
            )

            Button(
                onClick = {coroutineScope.launch{viewModel.onIdentityClicked()}  },
                modifier = Modifier.padding(start = 8.dp)
            ) {
                Text(stringResource(Res.string.identity))
            }
        }

        // Equation Display
      /*  Box(
            modifier = Modifier
                .fillMaxWidth()
                .heightIn(min = 200.dp)
                .padding(16.dp),
            contentAlignment = Alignment.Center
        ) {
            webviewkmm(LaTeX = uiState.equationDisplay) //todo rájönni, hogy miért nem működik
        }*/



        // Settings Toggle Button and Settings Panel
        SettingsPanel(
            onSetTypeIndex = { viewModel.setTypeIndex(it) },
            onSetDifficulty = { chance, min, max, maxTerms, minX, maxX ->
                viewModel.setDifficulty(chance, min, max, maxTerms, minX, maxX)
            }
        )
    }
    responseToast(correct = uiState.isCorrect, numberofsolved = uiState.numberofsolved, useCompleteFeedbackMessage = true, completeResponse = uiState.showsolution) //weben ide kell helyezni

}

@Composable
fun SettingsPanel(
    onSetTypeIndex: (Int) -> Unit,
    onSetDifficulty: (Int, Int, Int, Int, Int, Int) -> Unit
) {
   generatorSettings {         // Equation Types Buttons
       settingListItem(
           content={
               EquationTypeButtons(onSetTypeIndex)
           },
           alpha1
       )
       settingListItem(
           content= {
               DifficultyButtons(onSetDifficulty)
           },
           alpha2
       )
   }

}

@Composable
fun EquationTypeButtons(//todo beállítások composable
    onSetTypeIndex: (Int) -> Unit
) {
    Column(modifier = Modifier.padding(16.dp), horizontalAlignment = Alignment.CenterHorizontally) {
        Row(horizontalArrangement = Arrangement.SpaceAround) {
            Button(onClick = { onSetTypeIndex(1) }) { Text(stringResource(Res.string.beginner1)) }
            Button(onClick = { onSetTypeIndex(2) }) { Text(stringResource(Res.string.beginner2)) }
        }
        Row(horizontalArrangement = Arrangement.SpaceAround) {
            Button(onClick = { onSetTypeIndex(3) }) { Text(stringResource(Res.string.parentheses1)) }
            Button(onClick = { onSetTypeIndex(4) }) { Text(stringResource(Res.string.parentheses2)) }
        }
        Row(horizontalArrangement = Arrangement.SpaceAround) {
            Button(onClick = { onSetTypeIndex(5) }) { Text(stringResource(Res.string.fractions1)) }
            Button(onClick = { onSetTypeIndex(6) }) { Text(stringResource(Res.string.fractions2)) }
            Button(onClick = { onSetTypeIndex(7) }) { Text(stringResource(Res.string.fractions3)) }
        }
    }
}

@Composable
fun DifficultyButtons(
    onSetDifficulty: (Int, Int, Int, Int, Int, Int) -> Unit
) {
    Column(modifier = Modifier.padding(16.dp)) {
        Row(horizontalArrangement = Arrangement.SpaceAround) {
            Button(onClick = { onSetDifficulty(2, 2, 12, 2, 1, 12) }) { Text(stringResource(Res.string.easy)) }
            Button(onClick = { onSetDifficulty(3, -20, 20, 3, 1, 20) }) { Text(stringResource(Res.string.medium)) }
            Button(onClick = { onSetDifficulty(4, -50, 50, 3, 1, 50) }) { Text(stringResource(Res.string.hard)) }
        }
    }
}

data class EquationUiState(
    val equationDisplay: String = "",
    val userInput: String = "",
    val isIdentity: Boolean = false,
    val feedbackMessage: String = "",
    val isCorrect: Boolean = false,
    val settingsVisible: Boolean = true,
    // Settings parameters
    val typeIndex: Int = 1,
    val chance: Int = 3,
    val min: Int = 2,
    val max: Int = 12,
    val hosszMax: Int = 2,
    val minX: Int = 1,
    val maxX: Int = 12,
    val numberofsolved :Int=0,
    var showsolution:String=""
    // Additional state variables as needed
)

class EquationViewModel(tag:String) : ViewModel() {

    var tag = tag

    private val _uiState = MutableStateFlow(EquationUiState())
    val uiState: StateFlow<EquationUiState> = _uiState.asStateFlow()

    // Variables for equation generation logic
    private var X = 0
    private var sumXcoefficient = 0
    private var sumConstant = 0
    private var azonosság = false
    val min = uiState.value.min
    val max = uiState.value.max
    val minX = uiState.value.minX
    val maxX = uiState.value.maxX
    val hosszMax = uiState.value.hosszMax
    var bal = mutableListOf<String>()
    var jobb = mutableListOf<String>()
    var sumleft=0
    var sumKonstans =0
    var konstans = 0
    var sumright = 0
    var együttható = 0
    var sumLast = 0
    var újegyüttható =0
    var jobbra = true
    var újraJobbra = true
    var showbal = ""
    var showjobb = ""
    var ellentétesOldal = true
    var egyenlet = ""
    var display = ""
    var együttható2=0
    var konstans2=0
    var újZárójelesTag =""
    var nevező = 0
    var számláló = 0
    var számláló2 = 0
    var osztó = 0
    var chance = 3
    var nevező1=0
    var nevező2 =0
    var tipp=0
    var tesztegyenlet = ""
    var tagszám = 0


    init {
        generateEquation()
    }

    fun generateEquation() {
        resetState()
        when (_uiState.value.typeIndex) {
            1->kezdő()
            2->másodikszint()
            3->harmadikszint()
            4->negyedikszint()
            5->ötödikszint()
            6->hatodikszint()
            7->hetedikszint()
        }
        // Update the equation display
      //  println("new equation: $egyenlet")
        _uiState.update { it.copy(equationDisplay = display) }
    }

    private fun resetState() {
        X =0
        bal = mutableListOf<String>()
        jobb = mutableListOf<String>()
        együttható = 0
        sumLast = 0
        újegyüttható =0
        konstans = 0
        showbal = ""
        showjobb = ""
        egyenlet = ""
        display = ""
        együttható2=0
        konstans2=0
        sumleft = 0
        sumright = 0
        újZárójelesTag =""
        nevező = 0
        számláló = 0
        számláló2 = 0
        osztó = 0
        sumXcoefficient = 0
        sumKonstans =0
        azonosság = false
        nevező1=0
        nevező2 =0
        tipp=0
    }

    // Level 1: Beginner Level 1
    private fun kezdő() {
        val uiState = _uiState.value
        X = Random.nextInt(uiState.minX, uiState.maxX + 1)
         együttható = (uiState.min..uiState.max).filter { it != 0 }.random()
        sumXcoefficient = együttható
         konstans = Random.nextInt(uiState.min, uiState.max + 1)
         sumLast = együttható * X + konstans
        display = "${együttható}X + $konstans = $sumLast".replace("+-","-")

        if (sumXcoefficient == 0) azonosság = true
    }

    // Level 2: Beginner Level 2
    private fun másodikszint() {
        X = Random.nextInt(minX, maxX)
        együttható= (min..max).filter{it!=0}.random()
        konstans = Random.nextInt(min, max)
        egyenlet = "${együttható}X+$konstans="
        együttható2 = Random.nextInt(min, max)
        sumLast = együttható*X+konstans
        konstans2 = sumLast-X*együttható2
        egyenlet += "${együttható2}X+$konstans2"
        display = egyenlet.replace("+-","-")
       // println(display)
        sumXcoefficient = együttható-együttható2
        sumKonstans =konstans-konstans2
        if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true
        tesztegyenlet=display
    }

   private fun harmadikszint(){//itt lehet azonosság, ha a zárójel felbontása után ugyan annyi az Xes tagok együtthatója a két oldalon. Viszont vigyázni kell, nehogy ellentmondás legyen
        X = Random.nextInt(minX, maxX)                      //legeneráljuk X értékét
       X = Random.nextInt(minX, maxX)                      //legeneráljuk X értékét
       együttható =(min..max).filter{it!=0}.random()   //zárójel együtthatója
       együttható2=(min..max).filter{it!=0}.random()   //X együtthatója a zárójelen belül
       konstans=(min..max).filter{it!=0}.random()      //konstans a zárójelben
       egyenlet = "$együttható(${együttható2}X+$konstans)="    //az egyenlet bal oldala
       sumXcoefficient+=együttható*együttható2                            //egyenlet bal oldalán az Xes tag együtthatója
       sumleft+=együttható*(együttható2*X+konstans)            //bal oldal értéke (X és konstans együtt)
       sumKonstans+=együttható*konstans                        //bal oldalon a konstans értéke
       együttható = if((1..30).random()==1) sumXcoefficient else (min..max).filter { it != 0 }.random()   //új együttható, az Xes tagnak az egyenlet jobb oldalán. 1/30+1/144 eséllyel azonosság
       sumXcoefficient-=együttható                                //  ezt levonjuk az összes együtthatójából az X-nek, ha a kettő különbsége nulla, akkor ez egy azonosság
       konstans = sumleft-együttható*X                             //mert a jobb oldali konstans, az az együtthatószor X levonva a bal oldal teljes értékéből
       sumKonstans-=konstans                                       //egyenlet jobb oldala a megmaradt együttható szor X, meg az új konstans
       egyenlet+="${együttható}X+$konstans"
       display = egyenlet.replace("+-","-")
      // println(display)
       if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true
    }

    private      fun negyedikszint(){
        X = Random.nextInt(minX, maxX)
        tagszám = Random.nextInt(0,hosszMax)
        for(i in 0..tagszám){                                     //tagszámszor generálunk együttható-zárójel párokat
            együttható=(min..max).filter{it!=0}.random()
            együttható2=(min..max).filter{it!=0}.random()
            konstans=(min..max).filter{it!=0}.random()
            sumleft += együttható*(együttható2*X+konstans)
            újZárójelesTag = "$együttható*(${együttható2}X+$konstans)"
            bal.add(újZárójelesTag)
            sumXcoefficient+=együttható*együttható2
            konstans+=együttható*konstans
        }
        tagszám = Random.nextInt(0,hosszMax
        )
        for(i in 0..tagszám){ // ugyan ezt megcsináljuk a jobb oldalon is
            együttható=(min..max).filter{it!=0}.random()
            együttható2=(min..max).filter{it!=0}.random()
            konstans=(min..max).filter{it!=0}.random()
            sumright += együttható*(együttható2*X+konstans)
            újZárójelesTag = "$együttható*(${együttható2}X+$konstans)"
            jobb.add(újZárójelesTag)
            sumXcoefficient-=együttható*együttható2
            konstans-=együttható*konstans
        }
        //azt lehetne csinálni, hogy .. helyett until tagszám, majd az utolsónál 1/30-ad eséllyel pont olyan együtthatót adunk meg, hogy azonosság jöjjön ki belőle
        jobb.add((sumleft-sumright).toString())
        konstans-=-(sumleft-sumright)
        bal.forEach{
            egyenlet+="$it+"
        }
        egyenlet+="="
        jobb.forEach{
            egyenlet+="+$it"
        }
        //println("egyenlet: $egyenlet")
        display = egyenlet.replace("+-","-")
        display = display.replace("+=","=")
        display = display.replace("=+","=")
       // println(display)

        if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true
        tesztegyenlet=display
    }
    private fun ötödikszint(){
        X = Random.nextInt(minX, maxX)
        együttható=(min..max).filter{it!=0}.random()
        //  if(együttható==0)
        konstans=(min..max).filter{it!=0}.random()
        számláló = együttható*X+konstans
        if (!osztókeresés(számláló)) {
            ötödikszint()
            return
        }
        sumleft = számláló/nevező
        egyenlet= "\\frac{${együttható}X+$konstans}{$nevező}=$sumleft"
        display = egyenlet.replace("+-","-")
       // println(display)
        sumXcoefficient=együttható
        sumKonstans=konstans-sumleft*nevező
        if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true
        tesztegyenlet="(${együttható}X+$konstans)/$nevező=$sumleft"
    }
   private fun osztókeresés(osztandó:Int):Boolean {
        osztó =  (min..max).filter{it!=0}.random()
        var továbbkeres = false
        for(i in min..max){
            if(i!=0&&i!=1&&i!=-1&&osztandó%i==0){
                továbbkeres = true
            }//ezt még kijjebb lehet vinni
        }
        try {
            if(osztandó%osztó==0&&osztó!=1&&osztó!=-1&&osztó!=0) {
                nevező=osztó
            } else {
                if(!továbbkeres){
                    nevező=1
                    return false
                }else{
                    osztókeresés(osztandó)
                    return true}
            }
        } catch (e: Exception){
            osztókeresés(osztandó)
            return true
        }
        return false
    }
  private  fun hatodikszint(){
        X = Random.nextInt(minX, maxX)
        együttható=(min..max).filter{it!=0}.random()
        //  if(együttható==0)
        konstans=(min..max).filter{it!=0}.random()
        számláló = együttható*X+konstans
        if (!osztókeresés(számláló)) {
            hatodikszint()
            return
        }
        együttható2 = (min..max).filter{it!=0}.random()
        sumleft = számláló/nevező+együttható2*X

        egyenlet= "\\frac{${együttható}X+$konstans}{$nevező}+${együttható2}X=$sumleft"
        display = egyenlet.replace("+-","-")
      //  println(display)
        sumXcoefficient=együttható+együttható2*nevező
        sumKonstans=konstans-sumleft*nevező
        if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true
        tesztegyenlet="(${együttható}X+$konstans)/$nevező+${együttható2}X=$sumleft"
    }
  private  fun hetedikszint(){
        X = Random.nextInt(minX, maxX)
        var műveletInt= Random.nextInt(0,chance)
        var művelet=if(műveletInt==0){
            "+"
        } else{
            "-"
        }
        együttható= (min..max).filter{it!=0}.random()
        konstans=(min..max).filter{it!=0}.random()
        számláló = együttható*X+konstans
        if (!osztókeresés(számláló)) {
            hetedikszint()
            return
        }
        nevező1=nevező
        sumleft = számláló/nevező1
        egyenlet="\\frac{${együttható}X+$konstans}{$nevező1}" //"(${együttható}X+$konstans)/$nevező1"
        tesztegyenlet ="(${együttható}X+$konstans)/$nevező1"
        együttható2=(min..max).filter{it!=0}.random()
        konstans2=(min..max).filter{it!=0}.random()
        számláló2 = együttható2*X+konstans2
        if (!osztókeresés(számláló2)) {
            hetedikszint()
            return
        }
        nevező2=nevező
        if(nevező2==nevező1) {
            hetedikszint()
            return
        }
        if(műveletInt==0){
            sumleft += számláló2/nevező2
        }
        else{sumleft -= számláló2/nevező2}
        egyenlet+="$művelet\\frac{${együttható2}X+$konstans2}{$nevező2}"//
        egyenlet+="=$sumleft"
        tesztegyenlet +="$művelet(${együttható2}X+$konstans2)/$nevező2=$sumleft"
        sumKonstans=if(műveletInt==0){konstans*nevező2+konstans2*nevező1-sumleft*nevező1*nevező2}
        else{konstans*nevező2-konstans2*nevező1-sumleft*nevező1*nevező2}

        display = egyenlet.replace("+-","-")
       // println(display)
        sumXcoefficient = if(műveletInt==0){
            együttható*nevező2+együttható2*nevező1
        } else{
            együttható*nevező2-együttható2*nevező1
        }
        if(sumXcoefficient ==0&&sumKonstans==0) azonosság =true

    }
    // Implement other levels similarly...

    // Function to check the user's input
    suspend fun checkSolution() {
        val uiState = _uiState.value
        val userInput = uiState.userInput.toIntOrNull()
        if(userInput==null) return@checkSolution
        if (azonosság) { //ha azonosság az egyenlet
            if (uiState.isIdentity) {//és azonosságot is válaszolt (ami itt nem fog bekövetkezni, mert a checksolution az X értékét ellenőrzi
                _uiState.update { it.copy(isCorrect = true, feedbackMessage = getString(Res.string.CorrectSolution)) }
            } else {//de a diák nem azt válaszolta hogy azonosság
                _uiState.update { it.copy(isCorrect = false, feedbackMessage = getString(Res.string.wasIdentitiy)) }
            }
        } else {//nem azonosságra vezet az egyenlet
            if (userInput == X) { //a diák helyesen oldotta meg
                _uiState.update { it.copy(isCorrect = true, feedbackMessage = getString(Res.string.CorrectSolution)) }
            } else {
                _uiState.update { it.copy(isCorrect = false, feedbackMessage = getString(Res.string.WrongSolution, X)) }
            }
        }

        LaTexLogWriteDesktop(_uiState.value.isCorrect,_uiState.value.userInput,_uiState.value.feedbackMessage,tag, _uiState.value.equationDisplay)//_uiState.value. újbóli hivatkozása adja vissza az aktuális értékeket.

        // Optionally reset the user input and generate a new equation
        _uiState.update { it.copy(userInput = "", numberofsolved =it.numberofsolved+1, showsolution = it.feedbackMessage,) }

        generateEquation()
    }

    // Function to handle the identity button click
    suspend fun onIdentityClicked() {
        val uiState = _uiState.value
        if (azonosság) {
            _uiState.update { it.copy(isCorrect = true, feedbackMessage = getString(Res.string.CorrectSolution)) }
        } else {
            _uiState.update { it.copy(isCorrect = false, feedbackMessage = getString(Res.string.WrongSolution, X)) }
        }
        // Optionally reset the user input and generate a new equation

        LaTexLogWriteDesktop(_uiState.value.isCorrect,getString(Res.string.identity),_uiState.value.feedbackMessage,tag, _uiState.value.equationDisplay)

        _uiState.update { it.copy(userInput = "", numberofsolved =it.numberofsolved+1, showsolution = it.feedbackMessage) }
        generateEquation()
    }

    // Functions to update settings
    fun toggleSettingsVisibility() {
        _uiState.update { it.copy(settingsVisible = !_uiState.value.settingsVisible) }
    }

    fun setTypeIndex(index: Int) {
        _uiState.update { it.copy(typeIndex = index) }
        generateEquation()
    }

    fun setDifficulty(chance: Int, min: Int, max: Int, maxTerms: Int, minX: Int, maxX: Int) {
        _uiState.update {
            it.copy(
                chance = chance,
                min = min,
                max = max,
                hosszMax = maxTerms,
                minX = minX,
                maxX = maxX
            )
        }
        generateEquation()
    }

    fun updateUserInput(input: String) {
        _uiState.update { it.copy(userInput = input) }
    }

    fun setIdentitySelected(selected: Boolean) {
        _uiState.update { it.copy(isIdentity = selected) }
    }

    // Implement the remaining levels and logic as per your original code...

}





