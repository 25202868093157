package matekit.matekit

import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.unit.dp
import kotlinx.browser.localStorage
import kotlinx.browser.window
import matekit.matekit.ui.theme.desktopMateKIT
/*
import kotlinx.coroutines.flow.map
import matekit.matekit.utilityfunctions.DATA_STORE_FILE_NAME
import matekit.matekit.utilityfunctions.createDataStore*/
import matekit.ui.basicfontsize
import matekit.ui.mediumdeviceratios
import matekit.ui.ratios
import matekit.ui.smalldeviceratios

val mColor = compositionLocalOf { Color.Unspecified }

@Composable
fun App() {

basicfontsize = localStorage.getItem("basicfontsize")?.toFloat() ?: 32f
    updateMathJaxFontSize(basicfontsize)

    desktopMateKIT {
        var mColorb = MaterialTheme.colorScheme.onBackground
       // val orientation = ScreenOrientation().getOrientation()


        val density = LocalDensity.current
        val windowWidthPx = window.innerWidth
        val widthDp = with(density) { windowWidthPx.toDp() }
        val w = remember { mutableStateOf(widthDp) }
     //   val height = remember { mutableStateOf(window.innerHeight) }
        val ratios by derivedStateOf {
            if (w.value < 600.dp) {
                smalldeviceratios
            } else if (w.value >= 600.dp && w.value < 1400.dp) {
                mediumdeviceratios

            } else {
                ratios()
            }
        }
        val orientation : MutableState<String> = remember { mutableStateOf("undefined") }


        //println("ratios  widthtype = ${ratios.widthtype}")



        BoxWithConstraints {
            mainmenu(ratios,orientation)
            w.value = maxWidth
            val h = remember { mutableStateOf(maxHeight) }
            LaunchedEffect(w.value){
                orientation .value=  if (w .value< h.value) {//ha keskenyebb mint amilyen magas
                    "Portrait"
                } else {
                    "Landscape"
                }
            }
         //   println("orientation (box)= ${orientation.value}")
            }
        }
    }


/*
 class ScreenOrientation {  //ez nem működött weben, így maradunk a brute-force megoldásnál
    @Composable
    fun getOrientation(): MutableState<String> {


        val width = remember{ mutableStateOf(window.innerWidth) }
        val height = remember{ mutableStateOf(window.innerHeight) }
        var orientation = remember{ mutableStateOf("undefined") }
         orientation .value=  if (width .value< height.value) {//ha keskenyebb mint amilyen magas
            "Portrait"
        } else {
            "Landscape"
        }
       // println("orientation = $orientation")
LaunchedEffect(
    width.value
){
    orientation .value=  if (width .value< height.value) {//ha keskenyebb mint amilyen magas
        "Portrait"
    } else {
        "Landscape"
    }

   // println("orientation = $orientation")
}

        return orientation
    }

 }*/
