package matekit.matekit.utilityfunctions
/*
import java.io.BufferedReader
import java.io.File
import java.io.FileInputStream
import java.io.FileReader
import java.io.IOException
import java.io.InputStreamReader
import java.nio.charset.StandardCharsets*/

fun getlog(userName: String, activityType: String, userIndex:Int=1): List<String> {
    var logs = mutableListOf<String>()
    var logDirectory = "logs"
    val name = "${userName}_$userIndex"
    val filename = "$logDirectory/${name}_$activityType.txt"


   /* val file = File(filename)

    if (!file.exists()) {
        // Return an empty list if the file does not exist
        return logs
    }

    try {
        BufferedReader(FileReader(file, StandardCharsets.UTF_8)).use { reader ->
            var line = reader.readLine()
            while (line != null) {
                logs.add(line)
                line = reader.readLine()
            }

            return logs

        }
    } catch (e: IOException) {
        // Handle the exception, e.g., log it or print a message
        e.printStackTrace()
    }*/
    return logs


}