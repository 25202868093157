package matekit.matekit.utilityfunctions

import kotlin.math.pow
import kotlin.math.round

fun Double.formatToDecimals(decimals: Int=4): String {
    val factor = 10.0.pow(decimals.toDouble())
    val roundedValue = round(this * factor) / factor
    //println("rounded value = $roundedValue")
    if(roundedValue.toString().contains(".")||roundedValue.toString().contains(",")) return roundedValue.toString().trimEnd { it == '0' }.trimEnd { it == '.' }//eddig enélkül is működött.
    else return roundedValue.toString()
}
fun Float.formatToDecimals(decimals: Int=4): String {
    val factor = 10.0.pow(decimals.toDouble())
    val roundedValue = round(this * factor) / factor
    return roundedValue.toString().trimEnd { it == '0' }.trimEnd { it == '.' }
}

fun Double. removetrailingzeros():String{
    if(this.toString().contains(".")) return this .toString().trimEnd { it == '0' }.trimEnd { it == '.' }//hogyha van benne tizedes vessző, akkor vegyük le a nullákat
   else return this.toString()
}
fun Float. removetrailingzeros():String{
    if(this.toString().contains(".")) return this .toString().trimEnd { it == '0' }.trimEnd { it == '.' }
    else return this.toString()
}


fun Int.CE(): String {//együttható vagy hatványkitevő (coefficient) //a reciprok hatványkitevő is "-" jelet ad vissza //todo ezt lehet egy másik fájlba kellene helyezni
    return (if (this == 1) {
        ""
    } else if (this == -1) {
        "-"
    } else {
        "$this"
    })
}