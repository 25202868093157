package matekit.matekit.taskgenerators
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.choose
import matekit.matekit.utilityfunctions.factorial
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.getstring
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_0_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_0_2
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_III_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_II_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_II_2
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_II_3
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_IV_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_I_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_I_2
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_I_3
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_VI_1
import matekit_multiplatform.composeapp.generated.resources.Kombinatorika_V_1
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.agota_question_1
import matekit_multiplatform.composeapp.generated.resources.agota_question_2
import matekit_multiplatform.composeapp.generated.resources.agota_question_3
import matekit_multiplatform.composeapp.generated.resources.agota_question_4
import matekit_multiplatform.composeapp.generated.resources.bokszolo_question
import matekit_multiplatform.composeapp.generated.resources.jozsiba_question_1
import matekit_multiplatform.composeapp.generated.resources.jozsiba_question_2
import matekit_multiplatform.composeapp.generated.resources.szamuraj_question_1
import matekit_multiplatform.composeapp.generated.resources.szamuraj_question_2
import matekit_multiplatform.composeapp.generated.resources.tanchaz_question
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.pow

@Composable
fun KombinatorikaScreen( //todo UI, toast, drawboard
    navController: NavHostController,
    tag:String="Kombinatorika",
    viewModel: KombinatorikaViewModel = viewModel{ KombinatorikaViewModel(tag) }
) {
    val uiState by viewModel.uiState.collectAsState()

    val coroutineScope = rememberCoroutineScope()
backButton(navController)
    Column(
        modifier = Modifier.fillMaxSize(),
        //verticalArrangement = Arrangement.SpaceAround, //todo rajztáblával egyeztetni
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
       //  responseToast(uiState.isCorrect,uiState.numberofsoleved,uiState.showsolution) //weben ez itt nincs jó helyen
        Column(Modifier.wrapContentSize(), horizontalAlignment = Alignment.CenterHorizontally) {
          borderedbasicfontsizeTextView(uiState.taskTextref.getparamstr())
            Spacer(modifier = Modifier.height(10.dp))
            Row(
                modifier = Modifier.wrapContentWidth(),
                horizontalArrangement = Arrangement.SpaceAround,
                verticalAlignment = Alignment.CenterVertically
            ) {
                CheckNumberField(
                    userInput = uiState.userInput,
                    onValueChange = { viewModel.updateUserInput(it) },
                    onCheck = { coroutineScope.launch { viewModel.checkSolution(it) } }
                )
                Spacer(modifier = Modifier.width(10.dp))
                Button(
                    onClick = {
                        coroutineScope.launch { viewModel.checkSolution(uiState.userInput) }
                    }
                ) {
                    Text(stringResource(Res.string.Check))
                }
                LatexLogButton(navController,tag)
                // Add log button if needed
            }
        }
        // Add whiteboard if needed
    }
    responseToast(uiState.isCorrect,uiState.numberofsoleved,uiState.showsolution) //weben itt lesz jó helyen

}

@Composable
fun CheckNumberField(
    userInput: String,
    onValueChange: (String) -> Unit,
    onCheck: (String) -> Unit
) {
    OutlinedTextField(
        value = userInput,
        onValueChange = onValueChange,
        label = { Text(stringResource(Res.string.Solution)) },
        modifier = Modifier.width(160.dp) .onPreviewKeyEvent { event ->
            if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                onCheck(userInput)
                true // Indicate that the event was handled
            } else {
                false // Indicate that the event was not handled
            }
        },
        keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Number, imeAction = ImeAction.Done),
        keyboardActions = KeyboardActions(
            onDone = {
                onCheck(userInput)
            }
        )
    )
}



data class KombinatorikaUiState(
    val taskTextref:Paramstr = Paramstr(Res.string.Kombinatorika_0_1, listOf(3)),
    val userInput: String = "",
    val feedbackMessage: String = "",
    val isCorrect: Boolean = false,
    val numberofsoleved:Int=0,
    val showsolution:String="",

)

class KombinatorikaViewModel(tag: String) : ViewModel() {
val tag = tag
    private val _uiState = MutableStateFlow(KombinatorikaUiState())
    val uiState: StateFlow<KombinatorikaUiState> = _uiState.asStateFlow()

    private var currentTask: Tasks = Tasks()

    init {
        generateTask()
    }

    fun generateTask() {
        currentTask = Tasks()
        _uiState.update { it.copy(taskTextref = currentTask.taskTextref, userInput = "", feedbackMessage = "") }
    }

    suspend fun checkSolution(userInput: String) {
        if(userInput.isEmpty()) return
        val userAnswer = userInput.toIntOrNull()
        val isCorrect = userAnswer == currentTask.solution
        val feedbackMessage = if (isCorrect) {
            "Correct Solution!"
        } else {
            "Wrong Solution: ${currentTask.solution}"
        }


        //println(feedbackMessage)
        val showSolutionIfUserWrong = getString(Res.string.WrongSolution, uiState.value.showsolution)

        LaTexLogWriteDesktop(isCorrect,userInput, showSolutionIfUserWrong ,tag, uiState.value.taskTextref.getstring(), taskLaTexWrap = false)

        _uiState.update {
            it.copy(
                showsolution = currentTask.solution.toString(),
                numberofsoleved = it.numberofsoleved+1,
                feedbackMessage = feedbackMessage,
                isCorrect = isCorrect,
                userInput = ""

            )
        }


        generateTask()
    }



    fun updateUserInput(input: String) {
        _uiState.update { it.copy(userInput = input) }
    }
}
class Tasks(){
    var tasktypenumber = (1..125).random() //súlyozzuk a feladatokat


    var tasktypeIndex = when (tasktypenumber) {
        in 1..14 -> 0
        in 15..28 -> 1
        in 29..42 -> 2
        in 43..56 -> 3
        in 57..72 -> 4
        in 73..86 -> 5
        in 87..100 -> 6
        in 101..105 -> 7//szamuráj : ezeknek elég 5
        in 106..110 -> 8//józsibá
        in 111..115 -> 9//bokszoló
        in 116..120 -> 10//ágota
        in 121..125->11//táncház
        else -> -1 // Default case, should not be reached
    } //hatos indexig van lefordítva
    val n = (3..6).random()
    val k =(2 until n).random()
    var l = (2..4).random()

    val p = (3..6).random()
    val q = (3..6).random()
    val r= (3..6).random()

    val letters ="ABCDEFWXQYZ".toList() //consonants, so they do not accidentally form a word
    val numbers ="0123456789".toList()
    val base = listOf<List<Char>>(letters,numbers)

    var elements:List<Char> = base.shuffled()[0].shuffled().subList(0,3) // 123 valami ilyet ad vissza
    var előfordulás = listOf<Int>((1..2).random(),(2..2).random(),(0..2).random())
    var nIII = 0

    var listOfKs = mutableListOf<List<Char>>()

    init {
        elements.forEachIndexed { index, c ->
            var newset = mutableListOf<Char>()
            for( i in 1.. előfordulás[index]){
                newset.add(c)
                nIII++
            }
            listOfKs.add(newset)
        }
    }
    fun MutableList<List<Char>>.printcards(): String {
        var string =""
        this.forEachIndexed { index, chars ->
            chars.forEachIndexed { index, c ->
                string+= "[$c] "
            }
        }
        return string
    }
    var type0 = Pair(listOf( //kivettem az n alatt a k-ból, mert más logika kell hozzá, mert a k itt mindig 2
        Pair(Res.string.Kombinatorika_0_1,listOf(n)),
        Pair(Res.string.Kombinatorika_0_2,listOf(n)),
        /*"Egy $n fős társaságban mindenki kezet fog mindenkivel. Hány kézfogás történik összesen?", //k itt 2,
        "Hányféleképpen lehet $n emberből egy titkárt és egy elnököt választani? (nem lehet ugyan az)",//n>2 k itt 2*/
    ),
        n.choose(2)
    )
    var typeI = Pair(listOf(//n alatt a k, ismétlés nélküli kombináció

        Pair(Res.string.Kombinatorika_I_1,listOf(n,k)),
        Pair(Res.string.Kombinatorika_I_2,listOf(n,k)),
        Pair(Res.string.Kombinatorika_I_3,listOf(n,k)),

        /*"Hányféleképpen lehet $n gyerekből $k gyereket választani?",
        "Hányféleképpen lehet $n különféle szelet sütiből $k szelet sütit választani?",
        "Hányféleképpen lehet $n malacból $k malacot levágni?",*/
    ),
        n.choose(k)
    )
    var typeII = Pair(listOf(//sorbarendezés, azaz ismétlés nélküli permutáció
        Pair(Res.string.Kombinatorika_II_1,listOf(n)),
        Pair(Res.string.Kombinatorika_II_2,listOf(n)),
        Pair(Res.string.Kombinatorika_II_3,listOf(n)),
        /*
        "Hányféleképpen lehet $n gyereket sorbaállítani?",
        "$n jó barát egyszerre érkezett meg az iskola kapujához. Hányféle sorrendben tudnak belépni a kapun?",
        "Hányféle sorrendben lehet $n lókötőt egymás melletti börtöncellákba bezárni?"*/
        //n$ jó barát (fiúk) egyszerre értek az iskola kapujához k lánnyal együtt. Hányféle sorrendben tudnak bemenni, ha a fiúk előreengedik a lányokat?
    ),
        n.factorial()
    )
    var typeIII = Pair(listOf(
        // "Hányféleképp lehet az ${listOfKs.printcards()} számkártyákból $nIII jegyű számot készíteni?", //generálunk 2-4 számot, vagy betűt.
        Pair(Res.string.Kombinatorika_III_1,listOf(listOfKs.printcards())),
        //"Hányféleképp lehet a következő kártyákat sorbarendezni? ${listOfKs.printcards()}"

    ),
        ( nIII.factorial()/(előfordulás[0].factorial()*előfordulás[1].factorial()*előfordulás[2].factorial())).toInt()
    )
    var typeIV= Pair(listOf(
        Pair(Res.string.Kombinatorika_IV_1,listOf(n)),
        //"Hányféleképp lehet $n lovagot egy kerekasztalhoz leültetni?"
    ), (n-1).factorial())
    var typeV = Pair(listOf(
        Pair(Res.string.Kombinatorika_V_1,listOf(n,k)),
        // "Egy cukrászdában $n féle fagylalt kapható. Hányféleképp lehet tölcsérbe $k gombócos fagyit kérni?"
    ), n.toDouble().pow(k).toInt())
    var typeVI = Pair(listOf(
        Pair(Res.string.Kombinatorika_VI_1,listOf(n+1)),
        // "Hány háromszöget határozhat meg ${n+1} darab pont, amelyek közül semelyik kettő sem esik egy egyenesre?",
    ),
        (n+1).choose(3)
    )

    var szamurájos = Pair(listOf(
  Pair(Res.string.szamuraj_question_1, listOf(n)),
        Pair(Res.string.szamuraj_question_2, listOf(n)),

        ),
        2.0.pow(n).toInt()
    )

    var józsibá = Pair(
        listOf(
          Pair(Res.string.jozsiba_question_1, listOf(n)),
            Pair(Res.string.jozsiba_question_2, listOf(n)),

        ),
        3.0.pow(n).toInt()
    )
    var bokszoló = Pair(
        listOf(
            Pair(Res.string.bokszolo_question, listOf(l))
        ),
        6.0.pow(l).toInt()
    )

    var ágota = Pair(
        listOf(
            Pair(Res.string.agota_question_1, listOf(p,q,r)),
            Pair(Res.string.agota_question_2, listOf(p,q,r)),
            Pair(Res.string.agota_question_3, listOf(p,q,r)),
            Pair(Res.string.agota_question_4, listOf(p,q,r)),

        ),
        p*q*r
    )

    var táncház = Pair(
        listOf(
            Pair(Res.string.tanchaz_question, listOf(n,k)),
        ),
        n.choose(k)*n.choose(k)
    )


    var taskLists = listOf(type0,typeI,typeII,typeIII,typeIV,typeV,typeVI, szamurájos,józsibá,bokszoló,ágota,táncház)
    var picktask = taskLists[tasktypeIndex].first.random()
    var taskTextref = Paramstr(picktask.first,picktask.second)
    var solution = taskLists[tasktypeIndex].second //solveEachType(tasktypeIndex,n,k,)
    /*class typeN (){
         var type : Pair<List<String>, (Int, Int) -> Int> =
     }*/
    init {
        //println("kombinatorika megoldás,${solution.toString()}")
    }
}
