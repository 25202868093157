package matekit.matekit.masodfokufv


import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import matekit.matekit.utilityfunctions.DrawableLine
import matekit.matekit.utilityfunctions.LogWriteAltalanosKerdeslistas
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.theme.matekitsquash1
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.justWrong
import org.jetbrains.compose.resources.getString
import kotlin.random.Random

class MasodfokuViewModel( tag: String, ) : ViewModel() {

    val tag = tag


    private val _uiState = MutableStateFlow(MasodfokuUIState())
    val uiState: StateFlow<MasodfokuUIState> = _uiState.asStateFlow()


    suspend fun check() {//todo megfelelő input és megoldókulcs összehasonlítás

       // val format = DecimalFormat("0.########")

        var isCorrect = true//kezdésnek legyen igaz

        var resultlist = uiState.value.results.toMutableList()

        var x1 = if (uiState.value.x1 != null) {
            uiState.value.x1!!.formatToDecimals()
        } else {
            ""
        }


        var x2 = if (uiState.value.x2 != null) {
            uiState.value.x2!!.formatToDecimals()
        } else {
            ""
        }

        var mosolygós = if (uiState.value.a > 0) {
            "minimum"//ha a>0, akkor a minimum helytől mindkét irányba növekszik a függvény értéke
        } else {
            "maximum"
        }

        var solutions = mutableListOf<String>()
        var responses = uiState.value.userInputs.map { it.replace(".",",") }


        //bejegyzés változók
        var tasktext =""
        var logmegoldókulcs= mutableListOf<String>()//nem ugyanaz, mint a solutions és responses, mert formázzuk egy kicsit
        var logválaszok=mutableListOf<String>()
        var logresultlist=mutableListOf<Boolean>()



        when (uiState.value.tasktype) {
            masodfokutasktype.A -> {
                tasktext="Függvényhez tartozó kérdések:"
                solutions.add(uiState.value.roots.size.toString())//ez egész szám, ezt nem kell formázni

                if (responses[0].replace(".",",") == uiState.value.roots.size.toString()) {
                    // isCorrect=true
                    resultlist[0] = true
                } else {
                    isCorrect = false
                    resultlist[0] = false
                }

                solutions.add(x1)
                if ((responses[1] == x1)) {//index 1,2
                    // isCorrect=true
                    resultlist[1] = true

                } else {
                    resultlist[1] = false
                    isCorrect = false
                }
                solutions.add(x2)
                if ((responses[2] == x2)) {//index 1,2
                    // isCorrect=true
                    resultlist[2] = true

                } else {
                    resultlist[2] = false
                    isCorrect = false
                }
                solutions.add(mosolygós)
                if (responses[3] == mosolygós) {
                    //   isCorrect=true
                    resultlist[3] = true
                } else {
                    resultlist[3] = false
                    isCorrect = false

                }

                solutions.add(uiState.value.szélsőérték.first.formatToDecimals())
                if (responses[4] == uiState.value.szélsőérték.first.formatToDecimals()) {
                    // isCorrect=true
                    resultlist[4] = true

                } else {
                    resultlist[4] = false
                    isCorrect = false
                }
                solutions.add(uiState.value.szélsőérték.second.formatToDecimals())
                if (responses[5] == uiState.value.szélsőérték.second.formatToDecimals()) {
                    // isCorrect=true
                    resultlist[5] = true
                } else {
                    resultlist[5] = false
                    isCorrect = false
                }


                logmegoldókulcs.add(uiState.value.roots.size.toString())
                logmegoldókulcs.add("$x1,$x2")
                logmegoldókulcs.add(mosolygós)
                logmegoldókulcs.add("(${uiState.value.szélsőérték.first.formatToDecimals()};${uiState.value.szélsőérték.second.formatToDecimals()})")

                logválaszok.add(responses[0])
                logválaszok.add("${responses[1]},${responses[2]}")
                logválaszok.add(responses[3])
                logválaszok.add("(${responses[4]};${responses[5]})")

                logresultlist.add(resultlist[0])
                logresultlist.add(resultlist[1]&&resultlist[2])
                logresultlist.add(resultlist[3])
                logresultlist.add(resultlist[4]&&resultlist[5])



            }


            masodfokutasktype.C -> {
                tasktext="Függvényhez tartozó kérdések:"


                solutions.add(x1)
             //   println.d(tag, "<${uiState.value.userInputs[0]}>=?$x1")
                if ((responses[0].replace(".",",") == x1)) {//index 1,2
                    // isCorrect=true
                    resultlist[0] = true
                } else {
                    resultlist[0] = false
                    isCorrect = false
                }

                solutions.add(x2)
                if ((responses[1] == x2)) {//index 1,2
                    // isCorrect=true
                    resultlist[1] = true

                } else {
                    resultlist[1] = false
                    isCorrect = false
                }

                solutions.add(mosolygós)
                if (responses[2] == mosolygós) {
                    // isCorrect=true
                    resultlist[2] = true

                } else {
                    resultlist[2] = false
                    isCorrect = false
                }
                solutions.add(uiState.value.szélsőérték.first.formatToDecimals())

                if (responses[3] == uiState.value.szélsőérték.first.formatToDecimals()) {
                    // isCorrect=true
                    resultlist[3] = true

                } else {
                    resultlist[3] = false
                    isCorrect = false
                }

                solutions.add(uiState.value.szélsőérték.second.formatToDecimals())
                if (responses[4] == uiState.value.szélsőérték.second.formatToDecimals()) {
                    // isCorrect=true
                    resultlist[4] = true

                } else {
                    resultlist[4] = false
                    isCorrect = false
                }



                logmegoldókulcs.add("$x1,$x2")
                logmegoldókulcs.add(mosolygós)
                logmegoldókulcs.add("(${uiState.value.szélsőérték.first.formatToDecimals()};${uiState.value.szélsőérték.second.formatToDecimals()})")


                logválaszok.add("${responses[0]},${responses[1]}")
                logválaszok.add(responses[2])
                logválaszok.add("(${responses[3]};${responses[4]})")

                logresultlist.add(resultlist[0]&&resultlist[1])
                logresultlist.add(resultlist[2])
                logresultlist.add(resultlist[3]&&resultlist[4])





            }

            masodfokutasktype.B1 -> {//abc együtthatós alakban kérjük az egyenletet
                tasktext="Egészítsd ki a függvény képletét!"


                solutions.add(uiState.value.a.formatToDecimals())
                solutions.add(uiState.value.b.formatToDecimals())
                solutions.add(uiState.value.c.formatToDecimals())

                var usera = uiState.value.userInputs[0].replace(".",",")
                if(usera=="-") usera = "-1"
                if(usera =="") usera="1"

                var userb = uiState.value.userInputs[1].replace(".",",")
                if(userb=="-") userb = "-1"
                if(userb =="") usera="1"

                var userc = uiState.value.userInputs[2].replace(".",",")
                if(userc=="") userc = "0"

            //    Log.d(tag,"${uiState.value.userInputs[0]} =? ${uiState.value.a.formatToDecimals()}")
                if ( usera!= uiState.value.a.formatToDecimals()) {
                    resultlist[0] = false
                    isCorrect = false
                } else {
                    resultlist[0] = true
                }
                if (userb != uiState.value.b.formatToDecimals()) {
                    resultlist[1] = false
                    isCorrect = false
                } else {
                    resultlist[1] = true
                }
                if (userc != uiState.value.c.formatToDecimals()) {
                    resultlist[2] = false
                    isCorrect = false
                } else {
                    resultlist[2] = true
                }


                logmegoldókulcs.add("F(x) = ${uiState.value.a.formatToDecimals()}·x² + ${uiState.value.b.formatToDecimals()}·x + ${uiState.value.c.formatToDecimals()}")
                logválaszok.add("F(x) = ${usera}·x² + ${userb}·x + ${userc}")
                logresultlist.add(resultlist[0]&&resultlist[1]&&resultlist[2])
            }

            masodfokutasktype.B2 -> {//gyöktényezős formában kérjük az egyenletet
                tasktext="Egészítsd ki a függvény képletét!"


                solutions.add(uiState.value.a.formatToDecimals())
                solutions.add(uiState.value.roots[0].formatToDecimals())
                if(uiState.value.roots.size>1){
                    solutions.add(uiState.value.roots[1].formatToDecimals())  //ha egy gyök van, akkor azt kell kétszer hozzáadni
                }else {
                    solutions.add(uiState.value.roots[0].formatToDecimals())
                }



                var usera =uiState.value.userInputs[0].replace(".",",")
                var userx1=uiState.value.userInputs[1].replace(".",",")
                var userx2=uiState.value.userInputs[2].replace(".",",")

                if(usera =="-") usera="-1"
                if(usera =="") usera="1"
                if(userx1=="") userx1 = "0"
                if(userx2=="") userx2 = "0"

            //    Log.d(tag,"${uiState.value.userInputs[0]} =? ${uiState.value.a.formatToDecimals()}")
                if (usera != solutions[0]) {
                    resultlist[0] = false
                    isCorrect = false
                } else {
                    resultlist[0] = true
                }
                if (userx1 != solutions[1]) {
                    resultlist[1] = false
                    isCorrect = false
                } else {
                    resultlist[1] = true
                }
                if (userx2 != solutions[2]) {
                    resultlist[2] = false
                    isCorrect = false
                } else {
                    resultlist[2] = true
                }


                logmegoldókulcs.add("F(x) = ${solutions[0]}(x-${solutions[1]})(x-${solutions[2]})")
                logválaszok.add("F(x) =  ${usera}(x-${userx1})(x-${userx2})")
                logresultlist.add(resultlist[0]&&resultlist[1]&&resultlist[2])
            }
            masodfokutasktype.B3 ->{//n,k alak
                tasktext="Egészítsd ki a függvény képletét!"

                solutions.add(uiState.value.a.formatToDecimals())
                solutions.add(uiState.value.szélsőérték.first.formatToDecimals())
                solutions.add(uiState.value.szélsőérték.second.formatToDecimals())



                var usera =uiState.value.userInputs[0].replace(".",",")
                var usern=uiState.value.userInputs[1].replace(".",",")
                var userk=uiState.value.userInputs[2].replace(".",",")

                if(usera =="-") usera="-1"
                if(usera =="") usera="1"
                if(usern=="") usern = "0"
                if(userk=="") userk = "0"

              //  Log.d(tag,"${uiState.value.userInputs[0]} =? ${uiState.value.a.formatToDecimals()}")
                if (usera!= uiState.value.a.formatToDecimals()) {
                    resultlist[0] = false
                    isCorrect = false
                } else {
                    resultlist[0] = true
                }
                if (usern!= uiState.value.szélsőérték.first.formatToDecimals()) {
                    resultlist[1] = false
                    isCorrect = false
                } else {
                    resultlist[1] = true
                }
                if (userk != uiState.value.szélsőérték.second.formatToDecimals()) {
                    resultlist[2] = false
                    isCorrect = false
                } else {
                    resultlist[2] = true
                }
                logmegoldókulcs.add("F(x) = ${solutions[0]}(x-${solutions[1]})²+${solutions[2]}")
                logválaszok.add( "F(x) = ${usera}(x-${usern})²+${userk}")
                logresultlist.add(resultlist[0]&&resultlist[1]&&resultlist[2])
            }
        }

      //  Log.d(tag, "isCorrect: $isCorrect")
      //  Log.d(tag, "solutions: $solutions")
       // Log.d(tag, "resultlist: $resultlist")


        logválaszok = logválaszok.map { it. algebrareplace()}.toMutableList()
        logmegoldókulcs = logmegoldókulcs.map { it. algebrareplace() }.toMutableList()

        val showSolutionIfUserWrong = getString(Res.string.justWrong)//következő feladatig megjelenítjük a megoldókulcsot.
        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = uiState.value.numberofsolved + 1,
                showsolution = showSolutionIfUserWrong,
                letnext = true,
                results = resultlist,
                solution = solutions
            )
        }
        var text = if (isCorrect) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
       // var color = if (isCorrect) Res.style.CorrectToast else Res.style.WrongToast

       // ShowToast.showToast(color, text, context, true)

        LogWriteAltalanosKerdeslistas(
            tasktext = tasktext. algebrareplace(),
            questiontexts = uiState.value.questions.map { it.replace("\n","<br>"). algebrareplace() },//nem maradhat benne sortörés
            megoldókulcs = logmegoldókulcs,
            answerscorrect = logresultlist,
            userinputs = logválaszok,
            szovegBejegyzesVegere = "",
            teljesenJóMegoldás = isCorrect,
            TAG = tag,
            moduleID = 28,
        )

    }

    fun String. algebrareplace():String{
        return this.replace("+ -","-").replace( " - 0·x ","").replace( " -0·x ","").replace(" + 0·x","").replace(" +0·x","").replace(" + -0·x","").replace("1(x","(x").replace("(x-)(x-)","x²").replace("(x-0)(x-0)","x²").replace("--","+").replace("+-","-").replace("--","+") .replace("(x-0)","x").replace("(x)","x").replace("xx","x²").replace("Fx","F(x)")
    }

    fun generateTask() {
        //válasszunk típust
        val tasktype = masodfokutasktype.entries.toTypedArray().random()
      //  Log.d(tag, "tasktype: ${tasktype.name}")
        /* var a: Float
         var b: Float
         var c: Float
         var x1: Float?
         var x2: Float?
         var roots:Set<Float> = setOf()*/

       // val format = DecimalFormat("0.#######")
        var quadraticdata: Quadraticdata

        var unknowns = when (tasktype) {
            masodfokutasktype.A -> 6
            masodfokutasktype.C -> 5//todo megszámolni hogy hány ismeretlen lesz
            masodfokutasktype.B1 -> 3
            masodfokutasktype.B2 -> 3
            masodfokutasktype.B3 ->3
        }
        var userinput = List(unknowns) { "" }.toMutableList()



        when (tasktype) {
            masodfokutasktype.A -> {
                if (Random.nextBoolean()) {
                    quadraticdata = gyöktényezősgenerálás()
                } else {
                    quadraticdata = kevesebbmintkétgyökgenerálás()
                }

                userinput[3]="minimum"//itt állítjuk be, hogy alapértelmezetten minimum hely a válasz
            }
            masodfokutasktype.C -> {
                quadraticdata = gyöktényezősgenerálás()
                userinput[2]="minimum"
            }
            masodfokutasktype.B1 -> {
                quadraticdata = gyöktényezősgenerálás()
            }
            masodfokutasktype.B2 -> {
                quadraticdata = gyöktényezősgenerálás()
            }
            masodfokutasktype.B3 -> {
                if (Random.nextBoolean()) {
                    quadraticdata = gyöktényezősgenerálás()
                } else {
                    quadraticdata = kevesebbmintkétgyökgenerálás()
                }
            }
        }

       // Log.d(tag,"feladat adatai: $quadraticdata")

        var questionsA = listOf(
            "Az F(x) = ${quadraticdata.a.formatToDecimals()}·x² + ${quadraticdata.b.formatToDecimals()}·x + ${
              
                    quadraticdata.c.formatToDecimals()
                
            } függvénynek hány közös pontja van az x tengellyel?".algebrareplace().replace("·x +0","").replace("·x + 0","").replace("·x -0","").replace("·x - 0","").replace("·x² -0",""),
            "Melyik x értékeknél? (ha van)",
            "Maximuma vagy minimuma van?",
            "Add meg a szélsőérték koordinátáit!"
        )
        var questionsB = listOf(
            ""//todo
        )

        var x1 = if (quadraticdata.x1 != null) {
            quadraticdata.x1?.formatToDecimals()
        } else {
            ""
        }

        var x2 = if (quadraticdata.x2 != null) {
            quadraticdata.x2?.formatToDecimals()
        } else {
            ""
        }

        var questionsC = listOf(//a gyöktényezős generálás miatt nem lesz null gyök
            "Add meg az F(x) = ${quadraticdata.a.formatToDecimals()}(x-${x1})(x-${x2}) zérushelyeit!\n(Ha csak egy van, akkor a másik mezőt hagyd üresen!)". algebrareplace(),
            "Maximuma vagy minimuma van?",
            "Add meg a szélsőérték koordinátáit!"
        )

        var roots = mutableSetOf<Float>()
        if (quadraticdata.x1 != null) {
            roots.add(quadraticdata.x1!!)
        }
        if (quadraticdata.x2 != null) {
            roots.add(quadraticdata.x2!!)
        }

        /*var mosolygós = if(quadraticdata.a>0){
            "maximum"
        }else "minimum"*/

        //var solution:List<String>

        var vonalak: List<DrawableLine>? = listOf(
            DrawableLine(
                Pair(quadraticdata.szélsőérték.first, quadraticdata.szélsőérték.second),
                Pair(0f, quadraticdata.szélsőérték.second),
                 matekitsquash1,
                2f
            ),
            DrawableLine(
                Pair(quadraticdata.szélsőérték.first, quadraticdata.szélsőérték.second),
                Pair(quadraticdata.szélsőérték.first, 0f),
                matekitsquash1,
                2f
            )
        )

        /*if (tasktype != masodfokutasktype.B1) {
            vonalak = null
        }*/




        _uiState.update {
            it.copy(
                a = quadraticdata.a,
                b = quadraticdata.b,
                c = quadraticdata.c,
                x1 = quadraticdata.x1,
                x2 = quadraticdata.x2,
                n=quadraticdata.szélsőérték.first.toInt(),
                k=quadraticdata.szélsőérték.second.toInt(),
                roots = roots.toList().sorted(),//gyökök halmazából rendezett listát készítünk
                solution = List(unknowns) { "" },
                questions = when (tasktype) {
                    masodfokutasktype.A -> questionsA
                    masodfokutasktype.C -> questionsC
                    masodfokutasktype.B1 -> questionsB//ugyanaz lesz mindhárom
                    masodfokutasktype.B2 -> questionsB
                    masodfokutasktype.B3 -> questionsB
                },
                userInputs =userinput ,
                results = List(unknowns) { false },//lehetne false is, majd ellenőrizzük úgyis ,
                letnext = false,
                tasktype = tasktype,
                szélsőérték = quadraticdata.szélsőérték,
                lines = vonalak
            )
        }
        // Log.d(tag, "$a $b $c")


    }

    /* fun generateABC(): List<Float> {
         var a = (1..3).random()
         var b = (1..3).random()
         var c = (-5..5).random()
         return listOf(a.toFloat(), b.toFloat(), c.toFloat())
     }*/

    fun gyöktényezősgenerálás(): Quadraticdata//A és B típus működik így
    {
        var a = listOf(-2f, -1f, 0.5f, 0.5f, 1f, 2f).random()
        val x1 = (-6..2).random()
        val delta = (0..6).random()
        val x2 = (x1 + delta).coerceAtMost(6)//todo lehet hogy így egyenlőtlen eloszlást kapunk

        if (delta >= 6) {
            a = listOf(-1f, 0.5f, 0.5f, 1f).random()
        }
        val b = -1 * (a * (x1 + x2))
        val c = x1 * x2 * a

        val szélsőértékhelye = (x1 + x2) / 2f
        val szélsőérték = a * szélsőértékhelye * szélsőértékhelye + b * szélsőértékhelye + c


        return Quadraticdata(
            a,
            b,
            c,
            x1.toFloat(),
            x2.toFloat(),
            Pair(szélsőértékhelye, szélsőérték)
        )
    }

    fun kevesebbmintkétgyökgenerálás(): Quadraticdata {//A típus működhet még így
        //1 vagy nulla gyök generálása
        val a = listOf(-2f, -1f, 0.5f, 0.5f, 1f, 2f).random()
        val k: Int
        if (a > 0) {
            k = (0..3).random()
        } else {
            k = (-3..0).random()
        }

        val n = (-3..3).random().toFloat()

        val szélsőérték = Pair(n, k.toFloat())

        val b = -2 * a * n
        val c = a*(n * n) + k//.toFloat()

        var x1: Float? = null
        var x2: Float? = null

        if (k == 0) {
            x1 = n
            x2 = n
        }

        return Quadraticdata(
            a,
            b,
            c,
            x1,
            x2,
            szélsőérték,
            n=n.toInt(),
            k=k
        )

    }

    fun editAnswer(answer: String, index: Int) {
        var userinputs = _uiState.value.userInputs.toMutableList()
        userinputs[index] = answer
        _uiState.value = _uiState.value.copy(userInputs = userinputs)
    }

    fun String.removezeros(): String {
        return this.replace("+ 0·x", "").replace("+ 0", "").replace("+0", "").replace("+0·x", "")
    }
}