package matekit.matekit.masodfokufv

data class Quadraticdata (
    var a :Float,
    var b:Float,
    var c :Float,
    var x1:Float?,//nem biztos hogy van gyöke
    var x2:Float?,
    var szélsőérték:Pair<Float,Float>,
    var n:Int =0,//adunk neki kezdő értéket, ezért ne felejtsük el módosítani, ahol szükséges
    var k:Int= 0
)

