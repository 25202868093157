@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package matekit_multiplatform.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val AccountName: StringResource by 
      lazy { init_AccountName() }

  public val Accuracy: StringResource by 
      lazy { init_Accuracy() }

  public val Addition: StringResource by 
      lazy { init_Addition() }

  public val AirTemperature: StringResource by 
      lazy { init_AirTemperature() }

  public val AirTemperature_a: StringResource by 
      lazy { init_AirTemperature_a() }

  public val AirTemperature_b: StringResource by 
      lazy { init_AirTemperature_b() }

  public val AirTemperature_c: StringResource by 
      lazy { init_AirTemperature_c() }

  public val AirTemperature_d: StringResource by 
      lazy { init_AirTemperature_d() }

  public val AirTemperature_e: StringResource by 
      lazy { init_AirTemperature_e() }

  public val AllTasksSoFar: StringResource by 
      lazy { init_AllTasksSoFar() }

  public val AmountOfTerms: StringResource by 
      lazy { init_AmountOfTerms() }

  public val Apply: StringResource by 
      lazy { init_Apply() }

  public val Arabic: StringResource by 
      lazy { init_Arabic() }

  public val ArabicToRoman: StringResource by 
      lazy { init_ArabicToRoman() }

  public val BackgorundColor: StringResource by 
      lazy { init_BackgorundColor() }

  public val BasicOperations: StringResource by 
      lazy { init_BasicOperations() }

  public val Blue: StringResource by 
      lazy { init_Blue() }

  public val Check: StringResource by 
      lazy { init_Check() }

  public val ChooseAccount: StringResource by 
      lazy { init_ChooseAccount() }

  public val ChooseAtLeastOneOperation: StringResource by 
      lazy { init_ChooseAtLeastOneOperation() }

  public val CircularSector: StringResource by 
      lazy { init_CircularSector() }

  public val CircularSectorHelp: StringResource by 
      lazy { init_CircularSectorHelp() }

  public val CircularSegment: StringResource by 
      lazy { init_CircularSegment() }

  public val Clear: StringResource by 
      lazy { init_Clear() }

  public val ClickAtThisAngle: StringResource by 
      lazy { init_ClickAtThisAngle() }

  public val ClickThis: StringResource by 
      lazy { init_ClickThis() }

  public val Coefficientminmax: StringResource by 
      lazy { init_Coefficientminmax() }

  public val ConvertUnits: StringResource by 
      lazy { init_ConvertUnits() }

  public val CorrectSolution: StringResource by 
      lazy { init_CorrectSolution() }

  public val CorrectlySolvedTasks: StringResource by 
      lazy { init_CorrectlySolvedTasks() }

  public val D: StringResource by 
      lazy { init_D() }

  public val DarkMode: StringResource by 
      lazy { init_DarkMode() }

  public val DefineARange: StringResource by 
      lazy { init_DefineARange() }

  public val DefineRounds: StringResource by 
      lazy { init_DefineRounds() }

  public val DefineTheAmountOfTerms: StringResource by 
      lazy { init_DefineTheAmountOfTerms() }

  public val DifficultySwitch: StringResource by 
      lazy { init_DifficultySwitch() }

  public val DividedByZero: StringResource by 
      lazy { init_DividedByZero() }

  public val Division: StringResource by 
      lazy { init_Division() }

  public val DivisionRemainder: StringResource by 
      lazy { init_DivisionRemainder() }

  public val DunaVizSzint: StringResource by 
      lazy { init_DunaVizSzint() }

  public val DunaVizSzint_a: StringResource by 
      lazy { init_DunaVizSzint_a() }

  public val DunaVizSzint_b: StringResource by 
      lazy { init_DunaVizSzint_b() }

  public val DunaVizSzint_c: StringResource by 
      lazy { init_DunaVizSzint_c() }

  public val DunaVizSzint_d: StringResource by 
      lazy { init_DunaVizSzint_d() }

  public val DunaVizSzint_e: StringResource by 
      lazy { init_DunaVizSzint_e() }

  public val Easy_diff: StringResource by 
      lazy { init_Easy_diff() }

  public val EmeseFelveteli: StringResource by 
      lazy { init_EmeseFelveteli() }

  public val EmeseFelveteli_a: StringResource by 
      lazy { init_EmeseFelveteli_a() }

  public val EmeseFelveteli_b: StringResource by 
      lazy { init_EmeseFelveteli_b() }

  public val EmeseFelveteli_d: StringResource by 
      lazy { init_EmeseFelveteli_d() }

  public val EnableRearrangingPictures: StringResource by 
      lazy { init_EnableRearrangingPictures() }

  public val EquilateralTriangle: StringResource by 
      lazy { init_EquilateralTriangle() }

  public val EquilateralTriangleHelp: StringResource by 
      lazy { init_EquilateralTriangleHelp() }

  public val ExtendedMultiplicationTable: StringResource by 
      lazy { init_ExtendedMultiplicationTable() }

  public val Fakopacs: StringResource by 
      lazy { init_Fakopacs() }

  public val Fakopacs_a: StringResource by 
      lazy { init_Fakopacs_a() }

  public val Fakopacs_b: StringResource by 
      lazy { init_Fakopacs_b() }

  public val Fakopacs_c: StringResource by 
      lazy { init_Fakopacs_c() }

  public val FastInput: StringResource by 
      lazy { init_FastInput() }

  public val FontColor: StringResource by 
      lazy { init_FontColor() }

  public val FontSize: StringResource by 
      lazy { init_FontSize() }

  public val FractionMinMax: StringResource by 
      lazy { init_FractionMinMax() }

  public val FractionWrongSolution: StringResource by 
      lazy { init_FractionWrongSolution() }

  public val FractionsAccurateIn: StringResource by 
      lazy { init_FractionsAccurateIn() }

  public val Friday: StringResource by 
      lazy { init_Friday() }

  public val FunctionReading: StringResource by 
      lazy { init_FunctionReading() }

  public val FunctionTask: StringResource by 
      lazy { init_FunctionTask() }

  public val GeneratedNumerators: StringResource by 
      lazy { init_GeneratedNumerators() }

  public val GeometryAreaSaveTask: StringResource by 
      lazy { init_GeometryAreaSaveTask() }

  public val GeometryAreaTask: StringResource by 
      lazy { init_GeometryAreaTask() }

  public val GeometryBlindMap: StringResource by 
      lazy { init_GeometryBlindMap() }

  public val GeometryBlindMapPictureSize: StringResource by 
      lazy { init_GeometryBlindMapPictureSize() }

  public val GeometryPerimeterSaveTask: StringResource by 
      lazy { init_GeometryPerimeterSaveTask() }

  public val GeometryPerimeterTask: StringResource by 
      lazy { init_GeometryPerimeterTask() }

  public val GeometryRule: StringResource by 
      lazy { init_GeometryRule() }

  public val GeometryTaskGenerator: StringResource by 
      lazy { init_GeometryTaskGenerator() }

  public val GetToKnowTheCoordinateSystem: StringResource by 
      lazy { init_GetToKnowTheCoordinateSystem() }

  public val GuessAngleSubmitAngle: StringResource by 
      lazy { init_GuessAngleSubmitAngle() }

  public val Hard_diff: StringResource by 
      lazy { init_Hard_diff() }

  public val Help: StringResource by 
      lazy { init_Help() }

  public val Hexagon: StringResource by 
      lazy { init_Hexagon() }

  public val HowManyBooksDidKarcsiRead: StringResource by 
      lazy { init_HowManyBooksDidKarcsiRead() }

  public val HowManyBooksDidStudentsReadOnAverageInSummer: StringResource by 
      lazy { init_HowManyBooksDidStudentsReadOnAverageInSummer() }

  public val HowManyMark1: StringResource by 
      lazy { init_HowManyMark1() }

  public val HowManyMark2: StringResource by 
      lazy { init_HowManyMark2() }

  public val HowManyMark3: StringResource by 
      lazy { init_HowManyMark3() }

  public val HowManyMark4: StringResource by 
      lazy { init_HowManyMark4() }

  public val HowManyMark5: StringResource by 
      lazy { init_HowManyMark5() }

  public val HowManyMoonsDoThePlanetsHaveAltogether: StringResource by 
      lazy { init_HowManyMoonsDoThePlanetsHaveAltogether() }

  public val HowManyMoonsDoesNPlanetHas: StringResource by 
      lazy { init_HowManyMoonsDoesNPlanetHas() }

  public val IntMinMax: StringResource by 
      lazy { init_IntMinMax() }

  public val Integer_or: StringResource by 
      lazy { init_Integer_or() }

  public val IsoscelesTrapezoid: StringResource by 
      lazy { init_IsoscelesTrapezoid() }

  public val IsoscelesTrapezoidHelp: StringResource by 
      lazy { init_IsoscelesTrapezoidHelp() }

  public val IsoscelesTriangle: StringResource by 
      lazy { init_IsoscelesTriangle() }

  public val IsoscelesTriangleHelp: StringResource by 
      lazy { init_IsoscelesTriangleHelp() }

  public val KaTeXFontsize: StringResource by 
      lazy { init_KaTeXFontsize() }

  public val KaTeXdescription: StringResource by 
      lazy { init_KaTeXdescription() }

  public val Kite: StringResource by 
      lazy { init_Kite() }

  public val KiteHelp: StringResource by 
      lazy { init_KiteHelp() }

  public val KoMal: StringResource by 
      lazy { init_KoMal() }

  public val KoMal_a: StringResource by 
      lazy { init_KoMal_a() }

  public val KoMal_b: StringResource by 
      lazy { init_KoMal_b() }

  public val KoMal_c: StringResource by 
      lazy { init_KoMal_c() }

  public val KoMal_d: StringResource by 
      lazy { init_KoMal_d() }

  public val Kombinatorika_0_1: StringResource by 
      lazy { init_Kombinatorika_0_1() }

  public val Kombinatorika_0_2: StringResource by 
      lazy { init_Kombinatorika_0_2() }

  public val Kombinatorika_III_1: StringResource by 
      lazy { init_Kombinatorika_III_1() }

  public val Kombinatorika_II_1: StringResource by 
      lazy { init_Kombinatorika_II_1() }

  public val Kombinatorika_II_2: StringResource by 
      lazy { init_Kombinatorika_II_2() }

  public val Kombinatorika_II_3: StringResource by 
      lazy { init_Kombinatorika_II_3() }

  public val Kombinatorika_IV_1: StringResource by 
      lazy { init_Kombinatorika_IV_1() }

  public val Kombinatorika_I_1: StringResource by 
      lazy { init_Kombinatorika_I_1() }

  public val Kombinatorika_I_2: StringResource by 
      lazy { init_Kombinatorika_I_2() }

  public val Kombinatorika_I_3: StringResource by 
      lazy { init_Kombinatorika_I_3() }

  public val Kombinatorika_VI_1: StringResource by 
      lazy { init_Kombinatorika_VI_1() }

  public val Kombinatorika_V_1: StringResource by 
      lazy { init_Kombinatorika_V_1() }

  public val Login: StringResource by 
      lazy { init_Login() }

  public val MathKing: StringResource by 
      lazy { init_MathKing() }

  public val MathKingDescription: StringResource by 
      lazy { init_MathKingDescription() }

  public val MaxDistanceOfUnits: StringResource by 
      lazy { init_MaxDistanceOfUnits() }

  public val Medium_diff: StringResource by 
      lazy { init_Medium_diff() }

  public val MennyivelTöbb_2_1: StringResource by 
      lazy { init_MennyivelTöbb_2_1() }

  public val Menu: StringResource by 
      lazy { init_Menu() }

  public val Mertani_A: StringResource by 
      lazy { init_Mertani_A() }

  public val Mertani_B: StringResource by 
      lazy { init_Mertani_B() }

  public val Mertani_C: StringResource by 
      lazy { init_Mertani_C() }

  public val Molnar: StringResource by 
      lazy { init_Molnar() }

  public val Monday: StringResource by 
      lazy { init_Monday() }

  public val MostFrequentMark: StringResource by 
      lazy { init_MostFrequentMark() }

  public val Multiplication: StringResource by 
      lazy { init_Multiplication() }

  public val NInterval: StringResource by 
      lazy { init_NInterval() }

  public val NewTask: StringResource by 
      lazy { init_NewTask() }

  public val NthPlanetsMoons: StringResource by 
      lazy { init_NthPlanetsMoons() }

  public val NumberOfRounds: StringResource by 
      lazy { init_NumberOfRounds() }

  public val NumberSystemTaskLog: StringResource by 
      lazy { init_NumberSystemTaskLog() }

  public val NumberSystemsTask: StringResource by 
      lazy { init_NumberSystemsTask() }

  public val OkosFeladatGyűjtemény: StringResource by 
      lazy { init_OkosFeladatGyűjtemény() }

  public val OperationChain: StringResource by 
      lazy { init_OperationChain() }

  public val Operations: StringResource by 
      lazy { init_Operations() }

  public val Parallelogram: StringResource by 
      lazy { init_Parallelogram() }

  public val ParallelogramHelp: StringResource by 
      lazy { init_ParallelogramHelp() }

  public val Pentagon: StringResource by 
      lazy { init_Pentagon() }

  public val Percentage_A_1: StringResource by 
      lazy { init_Percentage_A_1() }

  public val Percentage_A_2: StringResource by 
      lazy { init_Percentage_A_2() }

  public val Percentage_A_3: StringResource by 
      lazy { init_Percentage_A_3() }

  public val Percentage_A_4: StringResource by 
      lazy { init_Percentage_A_4() }

  public val Percentage_B_1: StringResource by 
      lazy { init_Percentage_B_1() }

  public val Percentage_B_2: StringResource by 
      lazy { init_Percentage_B_2() }

  public val Percentage_B_3: StringResource by 
      lazy { init_Percentage_B_3() }

  public val Percentage_C_1: StringResource by 
      lazy { init_Percentage_C_1() }

  public val Percentage_D_1: StringResource by 
      lazy { init_Percentage_D_1() }

  public val Percentage_E_1: StringResource by 
      lazy { init_Percentage_E_1() }

  public val Percentage_F_1: StringResource by 
      lazy { init_Percentage_F_1() }

  public val Percentage_G_1: StringResource by 
      lazy { init_Percentage_G_1() }

  public val Percentage_G_2: StringResource by 
      lazy { init_Percentage_G_2() }

  public val Percentage_G_3: StringResource by 
      lazy { init_Percentage_G_3() }

  public val Percentage_G_4: StringResource by 
      lazy { init_Percentage_G_4() }

  public val Percentage_G_5: StringResource by 
      lazy { init_Percentage_G_5() }

  public val Percentage_H_1: StringResource by 
      lazy { init_Percentage_H_1() }

  public val Percentage_H_2: StringResource by 
      lazy { init_Percentage_H_2() }

  public val Percentage_H_3: StringResource by 
      lazy { init_Percentage_H_3() }

  public val Percentage_H_4: StringResource by 
      lazy { init_Percentage_H_4() }

  public val Percentage_H_5: StringResource by 
      lazy { init_Percentage_H_5() }

  public val Percentage_I_1: StringResource by 
      lazy { init_Percentage_I_1() }

  public val Percentage_I_2: StringResource by 
      lazy { init_Percentage_I_2() }

  public val Percentage_I_3: StringResource by 
      lazy { init_Percentage_I_3() }

  public val Percentage_J_1: StringResource by 
      lazy { init_Percentage_J_1() }

  public val Percentage_J_2: StringResource by 
      lazy { init_Percentage_J_2() }

  public val Percentage_J_3: StringResource by 
      lazy { init_Percentage_J_3() }

  public val Periodic_lastDigitApowN: StringResource by 
      lazy { init_Periodic_lastDigitApowN() }

  public val PistiFever_a: StringResource by 
      lazy { init_PistiFever_a() }

  public val PistiFever_b: StringResource by 
      lazy { init_PistiFever_b() }

  public val PistiFever_c: StringResource by 
      lazy { init_PistiFever_c() }

  public val Pistifever: StringResource by 
      lazy { init_Pistifever() }

  public val PiszkosFred: StringResource by 
      lazy { init_PiszkosFred() }

  public val PracticeSolvingEquationSystems: StringResource by 
      lazy { init_PracticeSolvingEquationSystems() }

  public val PracticingEquations: StringResource by 
      lazy { init_PracticingEquations() }

  public val PractisingFractions: StringResource by 
      lazy { init_PractisingFractions() }

  public val PreviousTasks: StringResource by 
      lazy { init_PreviousTasks() }

  public val Proportionality1: StringResource by 
      lazy { init_Proportionality1() }

  public val Proportionality2: StringResource by 
      lazy { init_Proportionality2() }

  public val Proportionality3: StringResource by 
      lazy { init_Proportionality3() }

  public val Proportionality4: StringResource by 
      lazy { init_Proportionality4() }

  public val Proportionality5: StringResource by 
      lazy { init_Proportionality5() }

  public val RangeOfNumbersToBeConverted: StringResource by 
      lazy { init_RangeOfNumbersToBeConverted() }

  public val Rblue: StringResource by 
      lazy { init_Rblue() }

  public val ReadTheCoordinatesOfThePoint: StringResource by 
      lazy { init_ReadTheCoordinatesOfThePoint() }

  public val RecommendedModifiers: StringResource by 
      lazy { init_RecommendedModifiers() }

  public val Rectangle: StringResource by 
      lazy { init_Rectangle() }

  public val RectangleHelp: StringResource by 
      lazy { init_RectangleHelp() }

  public val Red: StringResource by 
      lazy { init_Red() }

  public val Rename: StringResource by 
      lazy { init_Rename() }

  public val Response: StringResource by 
      lazy { init_Response() }

  public val Rhombus: StringResource by 
      lazy { init_Rhombus() }

  public val RhombusHelp: StringResource by 
      lazy { init_RhombusHelp() }

  public val RightAngledTriangle: StringResource by 
      lazy { init_RightAngledTriangle() }

  public val RightAngledTriangleHelp: StringResource by 
      lazy { init_RightAngledTriangleHelp() }

  public val Roman: StringResource by 
      lazy { init_Roman() }

  public val RomanToArabic: StringResource by 
      lazy { init_RomanToArabic() }

  public val Rounds: StringResource by 
      lazy { init_Rounds() }

  public val Rred: StringResource by 
      lazy { init_Rred() }

  public val Saturday: StringResource by 
      lazy { init_Saturday() }

  public val SavePicturesLocation: StringResource by 
      lazy { init_SavePicturesLocation() }

  public val SetPicSize: StringResource by 
      lazy { init_SetPicSize() }

  public val SetPicturesLocation: StringResource by 
      lazy { init_SetPicturesLocation() }

  public val Solution: StringResource by 
      lazy { init_Solution() }

  public val Square: StringResource by 
      lazy { init_Square() }

  public val SquareHelp: StringResource by 
      lazy { init_SquareHelp() }

  public val Subtraction: StringResource by 
      lazy { init_Subtraction() }

  public val Sunday: StringResource by 
      lazy { init_Sunday() }

  public val Szamtani_A: StringResource by 
      lazy { init_Szamtani_A() }

  public val Szamtani_B: StringResource by 
      lazy { init_Szamtani_B() }

  public val Szamtani_C: StringResource by 
      lazy { init_Szamtani_C() }

  public val Szamtani_D: StringResource by 
      lazy { init_Szamtani_D() }

  public val Thursday: StringResource by 
      lazy { init_Thursday() }

  public val Tie: StringResource by 
      lazy { init_Tie() }

  public val TimeBetweenTasks: StringResource by 
      lazy { init_TimeBetweenTasks() }

  public val Timeofmeasurments: StringResource by 
      lazy { init_Timeofmeasurments() }

  public val Totalnumberofcallsduringtheweek: StringResource by 
      lazy { init_Totalnumberofcallsduringtheweek() }

  public val Trapezoid: StringResource by 
      lazy { init_Trapezoid() }

  public val TrapezoidHelp: StringResource by 
      lazy { init_TrapezoidHelp() }

  public val Tuesday: StringResource by 
      lazy { init_Tuesday() }

  public val Variableminmax: StringResource by 
      lazy { init_Variableminmax() }

  public val VeryHard_diff: StringResource by 
      lazy { init_VeryHard_diff() }

  public val Wednesday: StringResource by 
      lazy { init_Wednesday() }

  public val WrongSolution: StringResource by 
      lazy { init_WrongSolution() }

  public val WrongSolutionEquationSystem: StringResource by 
      lazy { init_WrongSolutionEquationSystem() }

  public val WrongSolutionWP: StringResource by 
      lazy { init_WrongSolutionWP() }

  public val YouClickThis: StringResource by 
      lazy { init_YouClickThis() }

  public val _2_tagsz_m: StringResource by 
      lazy { init__2_tagsz_m() }

  public val _3_tagsz_m: StringResource by 
      lazy { init__3_tagsz_m() }

  public val _4_tagsz_m: StringResource by 
      lazy { init__4_tagsz_m() }

  public val _5_tagsz_m: StringResource by 
      lazy { init__5_tagsz_m() }

  public val about: StringResource by 
      lazy { init_about() }

  public val above30: StringResource by 
      lazy { init_above30() }

  public val agegroups: StringResource by 
      lazy { init_agegroups() }

  public val agota_question_1: StringResource by 
      lazy { init_agota_question_1() }

  public val agota_question_2: StringResource by 
      lazy { init_agota_question_2() }

  public val agota_question_3: StringResource by 
      lazy { init_agota_question_3() }

  public val agota_question_4: StringResource by 
      lazy { init_agota_question_4() }

  public val alapmuveletekMaradék: StringResource by 
      lazy { init_alapmuveletekMaradék() }

  public val am7: StringResource by 
      lazy { init_am7() }

  public val angle_estimation: StringResource by 
      lazy { init_angle_estimation() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val apple: StringResource by 
      lazy { init_apple() }

  public val aquarium_fish: StringResource by 
      lazy { init_aquarium_fish() }

  public val area: StringResource by 
      lazy { init_area() }

  public val arithmetical: StringResource by 
      lazy { init_arithmetical() }

  public val atvaltasTask: StringResource by 
      lazy { init_atvaltasTask() }

  public val autogyar: StringResource by 
      lazy { init_autogyar() }

  public val autogyar_a: StringResource by 
      lazy { init_autogyar_a() }

  public val autogyar_b: StringResource by 
      lazy { init_autogyar_b() }

  public val autogyar_c: StringResource by 
      lazy { init_autogyar_c() }

  public val baseNumber: StringResource by 
      lazy { init_baseNumber() }

  public val basket_nuts: StringResource by 
      lazy { init_basket_nuts() }

  public val beginner1: StringResource by 
      lazy { init_beginner1() }

  public val beginner2: StringResource by 
      lazy { init_beginner2() }

  public val bekuldoszam: StringResource by 
      lazy { init_bekuldoszam() }

  public val between15_30: StringResource by 
      lazy { init_between15_30() }

  public val biologists: StringResource by 
      lazy { init_biologists() }

  public val bluewontheround: StringResource by 
      lazy { init_bluewontheround() }

  public val bodyTemperature: StringResource by 
      lazy { init_bodyTemperature() }

  public val bokszolo_question: StringResource by 
      lazy { init_bokszolo_question() }

  public val boys: StringResource by 
      lazy { init_boys() }

  public val car_parking: StringResource by 
      lazy { init_car_parking() }

  public val chairs: StringResource by 
      lazy { init_chairs() }

  public val chocolate_distribution: StringResource by 
      lazy { init_chocolate_distribution() }

  public val cinderella_beans: StringResource by 
      lazy { init_cinderella_beans() }

  public val circus_performers: StringResource by 
      lazy { init_circus_performers() }

  public val classA: StringResource by 
      lazy { init_classA() }

  public val classB: StringResource by 
      lazy { init_classB() }

  public val close: StringResource by 
      lazy { init_close() }

  public val combinatorics: StringResource by 
      lazy { init_combinatorics() }

  public val conversionFromDecimal: StringResource by 
      lazy { init_conversionFromDecimal() }

  public val conversionToDecimal: StringResource by 
      lazy { init_conversionToDecimal() }

  public val correctAngle: StringResource by 
      lazy { init_correctAngle() }

  public val costume: StringResource by 
      lazy { init_costume() }

  public val csokik: StringResource by 
      lazy { init_csokik() }

  public val cube_1: StringResource by 
      lazy { init_cube_1() }

  public val cube_2: StringResource by 
      lazy { init_cube_2() }

  public val cubeoid_1: StringResource by 
      lazy { init_cubeoid_1() }

  public val cubeoid_2: StringResource by 
      lazy { init_cubeoid_2() }

  public val cubeoid_3: StringResource by 
      lazy { init_cubeoid_3() }

  public val cubeoid_4: StringResource by 
      lazy { init_cubeoid_4() }

  public val cubeoid_5: StringResource by 
      lazy { init_cubeoid_5() }

  public val cubeoid_6: StringResource by 
      lazy { init_cubeoid_6() }

  public val cubeoid_7: StringResource by 
      lazy { init_cubeoid_7() }

  public val cubeoid_8: StringResource by 
      lazy { init_cubeoid_8() }

  public val customrange: StringResource by 
      lazy { init_customrange() }

  public val day: StringResource by 
      lazy { init_day() }

  public val days: StringResource by 
      lazy { init_days() }

  public val defineTime: StringResource by 
      lazy { init_defineTime() }

  public val denominator: StringResource by 
      lazy { init_denominator() }

  public val description: StringResource by 
      lazy { init_description() }

  public val difficulty: StringResource by 
      lazy { init_difficulty() }

  public val difficulty2: StringResource by 
      lazy { init_difficulty2() }

  public val difficulty3: StringResource by 
      lazy { init_difficulty3() }

  public val dinosaur_park: StringResource by 
      lazy { init_dinosaur_park() }

  public val dog_treat_distribution: StringResource by 
      lazy { init_dog_treat_distribution() }

  public val dolgozat: StringResource by 
      lazy { init_dolgozat() }

  public val done: StringResource by 
      lazy { init_done() }

  public val drawAngle: StringResource by 
      lazy { init_drawAngle() }

  public val drawAngleDiff: StringResource by 
      lazy { init_drawAngleDiff() }

  public val drawTask: StringResource by 
      lazy { init_drawTask() }

  public val drone: StringResource by 
      lazy { init_drone() }

  public val drone_a: StringResource by 
      lazy { init_drone_a() }

  public val drone_b: StringResource by 
      lazy { init_drone_b() }

  public val drone_c: StringResource by 
      lazy { init_drone_c() }

  public val easy: StringResource by 
      lazy { init_easy() }

  public val ecologist: StringResource by 
      lazy { init_ecologist() }

  public val emptyrange: StringResource by 
      lazy { init_emptyrange() }

  public val emptytermsRange: StringResource by 
      lazy { init_emptytermsRange() }

  public val enter_your_teacher_s_email: StringResource by 
      lazy { init_enter_your_teacher_s_email() }

  public val equationDescription: StringResource by 
      lazy { init_equationDescription() }

  public val equilateralTriangle_1: StringResource by 
      lazy { init_equilateralTriangle_1() }

  public val equilateralTriangle_2: StringResource by 
      lazy { init_equilateralTriangle_2() }

  public val ermedobas: StringResource by 
      lazy { init_ermedobas() }

  public val ermedobasBottom: StringResource by 
      lazy { init_ermedobasBottom() }

  public val ermedobasLeft: StringResource by 
      lazy { init_ermedobasLeft() }

  public val ermedobas_a: StringResource by 
      lazy { init_ermedobas_a() }

  public val ermedobas_b: StringResource by 
      lazy { init_ermedobas_b() }

  public val ermedobas_c: StringResource by 
      lazy { init_ermedobas_c() }

  public val ermedobas_d: StringResource by 
      lazy { init_ermedobas_d() }

  public val exponentiation: StringResource by 
      lazy { init_exponentiation() }

  public val findPrimeFactorsfor: StringResource by 
      lazy { init_findPrimeFactorsfor() }

  public val find_teacher_by_email: StringResource by 
      lazy { init_find_teacher_by_email() }

  public val flower_distribution: StringResource by 
      lazy { init_flower_distribution() }

  public val fodrasz_hajvagas: StringResource by 
      lazy { init_fodrasz_hajvagas() }

  public val fractions1: StringResource by 
      lazy { init_fractions1() }

  public val fractions2: StringResource by 
      lazy { init_fractions2() }

  public val fractions3: StringResource by 
      lazy { init_fractions3() }

  public val from: StringResource by 
      lazy { init_from() }

  public val fruitSurvey: StringResource by 
      lazy { init_fruitSurvey() }

  public val fruitSurvey_a: StringResource by 
      lazy { init_fruitSurvey_a() }

  public val fruitSurvey_b: StringResource by 
      lazy { init_fruitSurvey_b() }

  public val fruitSurvey_b1: StringResource by 
      lazy { init_fruitSurvey_b1() }

  public val fruitSurvey_b2: StringResource by 
      lazy { init_fruitSurvey_b2() }

  public val fruitSurvey_c: StringResource by 
      lazy { init_fruitSurvey_c() }

  public val fruit_basket: StringResource by 
      lazy { init_fruit_basket() }

  public val fruit_distribution: StringResource by 
      lazy { init_fruit_distribution() }

  public val funSlope: StringResource by 
      lazy { init_funSlope() }

  public val futar_csomagszallitas: StringResource by 
      lazy { init_futar_csomagszallitas() }

  public val games: StringResource by 
      lazy { init_games() }

  public val garden_flowers: StringResource by 
      lazy { init_garden_flowers() }

  public val gasPrice: StringResource by 
      lazy { init_gasPrice() }

  public val gasPrice_a: StringResource by 
      lazy { init_gasPrice_a() }

  public val gasPrice_b: StringResource by 
      lazy { init_gasPrice_b() }

  public val gasPrice_c: StringResource by 
      lazy { init_gasPrice_c() }

  public val gasPrice_d: StringResource by 
      lazy { init_gasPrice_d() }

  public val geometrical: StringResource by 
      lazy { init_geometrical() }

  public val geometry: StringResource by 
      lazy { init_geometry() }

  public val gep_gyartas: StringResource by 
      lazy { init_gep_gyartas() }

  public val girls: StringResource by 
      lazy { init_girls() }

  public val guessAngle: StringResource by 
      lazy { init_guessAngle() }

  public val guessAngleDiff: StringResource by 
      lazy { init_guessAngleDiff() }

  public val guessAngleResponse: StringResource by 
      lazy { init_guessAngleResponse() }

  public val guesstask: StringResource by 
      lazy { init_guesstask() }

  public val hallgatok: StringResource by 
      lazy { init_hallgatok() }

  public val hard: StringResource by 
      lazy { init_hard() }

  public val hary_janos: StringResource by 
      lazy { init_hary_janos() }

  public val height: StringResource by 
      lazy { init_height() }

  public val helyettesitesiErtek: StringResource by 
      lazy { init_helyettesitesiErtek() }

  public val hideshow: StringResource by 
      lazy { init_hideshow() }

  public val hours: StringResource by 
      lazy { init_hours() }

  public val howmuch: StringResource by 
      lazy { init_howmuch() }

  public val identity: StringResource by 
      lazy { init_identity() }

  public val igen: StringResource by 
      lazy { init_igen() }

  public val instagram_friends: StringResource by 
      lazy { init_instagram_friends() }

  public val integer: StringResource by 
      lazy { init_integer() }

  public val isoscelesTriangle_1: StringResource by 
      lazy { init_isoscelesTriangle_1() }

  public val isoscelesTriangle_2: StringResource by 
      lazy { init_isoscelesTriangle_2() }

  public val isoscelesTriangle_3: StringResource by 
      lazy { init_isoscelesTriangle_3() }

  public val isoscelesTriangle_4: StringResource by 
      lazy { init_isoscelesTriangle_4() }

  public val jozsiba_question_1: StringResource by 
      lazy { init_jozsiba_question_1() }

  public val jozsiba_question_2: StringResource by 
      lazy { init_jozsiba_question_2() }

  public val justWrong: StringResource by 
      lazy { init_justWrong() }

  public val karcsi: StringResource by 
      lazy { init_karcsi() }

  public val kite_1: StringResource by 
      lazy { init_kite_1() }

  public val kite_2: StringResource by 
      lazy { init_kite_2() }

  public val konk_v_n_gysz_g: StringResource by 
      lazy { init_konk_v_n_gysz_g() }

  public val konkav_otszog: StringResource by 
      lazy { init_konkav_otszog() }

  public val konyvkoto_kotes: StringResource by 
      lazy { init_konyvkoto_kotes() }

  public val koppinstráFeladat: StringResource by 
      lazy { init_koppinstráFeladat() }

  public val kutyaeledel: StringResource by 
      lazy { init_kutyaeledel() }

  public val labor: StringResource by 
      lazy { init_labor() }

  public val lastCorrects: StringResource by 
      lazy { init_lastCorrects() }

  public val learningTime: StringResource by 
      lazy { init_learningTime() }

  public val legfeljebb_egy_konyv_nyaron: StringResource by 
      lazy { init_legfeljebb_egy_konyv_nyaron() }

  public val length: StringResource by 
      lazy { init_length() }

  public val level: StringResource by 
      lazy { init_level() }

  public val library: StringResource by 
      lazy { init_library() }

  public val liquidMeasure: StringResource by 
      lazy { init_liquidMeasure() }

  public val logintxt: StringResource by 
      lazy { init_logintxt() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val macskaeledel: StringResource by 
      lazy { init_macskaeledel() }

  public val mark: StringResource by 
      lazy { init_mark() }

  public val markThePoint: StringResource by 
      lazy { init_markThePoint() }

  public val mass: StringResource by 
      lazy { init_mass() }

  public val matekverseny: StringResource by 
      lazy { init_matekverseny() }

  public val matekverseny_a: StringResource by 
      lazy { init_matekverseny_a() }

  public val matekverseny_b: StringResource by 
      lazy { init_matekverseny_b() }

  public val matekverseny_c: StringResource by 
      lazy { init_matekverseny_c() }

  public val matekversenybottomlabel: StringResource by 
      lazy { init_matekversenybottomlabel() }

  public val matekversenylabelleft: StringResource by 
      lazy { init_matekversenylabelleft() }

  public val matematikadolgozat: StringResource by 
      lazy { init_matematikadolgozat() }

  public val matematikadolgozat_a: StringResource by 
      lazy { init_matematikadolgozat_a() }

  public val matematikadolgozat_b: StringResource by 
      lazy { init_matematikadolgozat_b() }

  public val matematikadolgozat_c: StringResource by 
      lazy { init_matematikadolgozat_c() }

  public val matematikadolgozat_d: StringResource by 
      lazy { init_matematikadolgozat_d() }

  public val max: StringResource by 
      lazy { init_max() }

  public val medium: StringResource by 
      lazy { init_medium() }

  public val megoldokulcs: StringResource by 
      lazy { init_megoldokulcs() }

  public val menu_order_distribution: StringResource by 
      lazy { init_menu_order_distribution() }

  public val menyasszonyiauto: StringResource by 
      lazy { init_menyasszonyiauto() }

  public val menyasszonyitorta: StringResource by 
      lazy { init_menyasszonyitorta() }

  public val min: StringResource by 
      lazy { init_min() }

  public val mixedFractions: StringResource by 
      lazy { init_mixedFractions() }

  public val month: StringResource by 
      lazy { init_month() }

  public val nagymama_himezes: StringResource by 
      lazy { init_nagymama_himezes() }

  public val nem_alatta: StringResource by 
      lazy { init_nem_alatta() }

  public val nem_folotte: StringResource by 
      lazy { init_nem_folotte() }

  public val no: StringResource by 
      lazy { init_no() }

  public val noOperator: StringResource by 
      lazy { init_noOperator() }

  public val noType: StringResource by 
      lazy { init_noType() }

  public val numberOfDays: StringResource by 
      lazy { init_numberOfDays() }

  public val numberofcalls: StringResource by 
      lazy { init_numberofcalls() }

  public val numberofmoons: StringResource by 
      lazy { init_numberofmoons() }

  public val numberofstudents: StringResource by 
      lazy { init_numberofstudents() }

  public val numerator: StringResource by 
      lazy { init_numerator() }

  public val nyulak: StringResource by 
      lazy { init_nyulak() }

  public val onWhichDayDidTheMostCustomersCall: StringResource by 
      lazy { init_onWhichDayDidTheMostCustomersCall() }

  public val `open`: StringResource by 
      lazy { init_open() }

  public val otosok: StringResource by 
      lazy { init_otosok() }

  public val otosokBottom: StringResource by 
      lazy { init_otosokBottom() }

  public val otosok_a: StringResource by 
      lazy { init_otosok_a() }

  public val otosok_b: StringResource by 
      lazy { init_otosok_b() }

  public val otosok_c: StringResource by 
      lazy { init_otosok_c() }

  public val paralellogram_1: StringResource by 
      lazy { init_paralellogram_1() }

  public val paralellogram_2: StringResource by 
      lazy { init_paralellogram_2() }

  public val paralellogram_3: StringResource by 
      lazy { init_paralellogram_3() }

  public val parentheses1: StringResource by 
      lazy { init_parentheses1() }

  public val parentheses2: StringResource by 
      lazy { init_parentheses2() }

  public val peach: StringResource by 
      lazy { init_peach() }

  public val pear: StringResource by 
      lazy { init_pear() }

  public val people: StringResource by 
      lazy { init_people() }

  public val percentage: StringResource by 
      lazy { init_percentage() }

  public val percentageofallenquirers: StringResource by 
      lazy { init_percentageofallenquirers() }

  public val periodic: StringResource by 
      lazy { init_periodic() }

  public val pieces: StringResource by 
      lazy { init_pieces() }

  public val pirate_treasures: StringResource by 
      lazy { init_pirate_treasures() }

  public val planetindex: StringResource by 
      lazy { init_planetindex() }

  public val planets: StringResource by 
      lazy { init_planets() }

  public val play: StringResource by 
      lazy { init_play() }

  public val pm2: StringResource by 
      lazy { init_pm2() }

  public val potion_brewing: StringResource by 
      lazy { init_potion_brewing() }

  public val practiceRomanNumerals: StringResource by 
      lazy { init_practiceRomanNumerals() }

  public val price: StringResource by 
      lazy { init_price() }

  public val primefactors: StringResource by 
      lazy { init_primefactors() }

  public val programozo_kodiras: StringResource by 
      lazy { init_programozo_kodiras() }

  public val proportionality: StringResource by 
      lazy { init_proportionality() }

  public val questions: StringResource by 
      lazy { init_questions() }

  public val rabbit_distribution: StringResource by 
      lazy { init_rabbit_distribution() }

  public val rajtaVanePQ: StringResource by 
      lazy { init_rajtaVanePQ() }

  public val rajtavane: StringResource by 
      lazy { init_rajtavane() }

  public val readPoint: StringResource by 
      lazy { init_readPoint() }

  public val readbooks: StringResource by 
      lazy { init_readbooks() }

  public val rectangle_1: StringResource by 
      lazy { init_rectangle_1() }

  public val rectangle_2: StringResource by 
      lazy { init_rectangle_2() }

  public val rectangle_3: StringResource by 
      lazy { init_rectangle_3() }

  public val rectangle_4: StringResource by 
      lazy { init_rectangle_4() }

  public val rectangle_5: StringResource by 
      lazy { init_rectangle_5() }

  public val rectangle_6: StringResource by 
      lazy { init_rectangle_6() }

  public val redwontheround: StringResource by 
      lazy { init_redwontheround() }

  public val rhombus_1: StringResource by 
      lazy { init_rhombus_1() }

  public val rhombus_2: StringResource by 
      lazy { init_rhombus_2() }

  public val rhombus_3: StringResource by 
      lazy { init_rhombus_3() }

  public val rightAngledTriangle_1: StringResource by 
      lazy { init_rightAngledTriangle_1() }

  public val rightAngledTriangle_2: StringResource by 
      lazy { init_rightAngledTriangle_2() }

  public val rightAngledTriangle_3: StringResource by 
      lazy { init_rightAngledTriangle_3() }

  public val rightAngledTriangle_4: StringResource by 
      lazy { init_rightAngledTriangle_4() }

  public val rightAngledTriangle_5: StringResource by 
      lazy { init_rightAngledTriangle_5() }

  public val robot_furat: StringResource by 
      lazy { init_robot_furat() }

  public val rows: StringResource by 
      lazy { init_rows() }

  public val seconds: StringResource by 
      lazy { init_seconds() }

  public val select_your_class: StringResource by 
      lazy { init_select_your_class() }

  public val slope: StringResource by 
      lazy { init_slope() }

  public val square_1: StringResource by 
      lazy { init_square_1() }

  public val square_2: StringResource by 
      lazy { init_square_2() }

  public val square_3: StringResource by 
      lazy { init_square_3() }

  public val square_4: StringResource by 
      lazy { init_square_4() }

  public val square_5: StringResource by 
      lazy { init_square_5() }

  public val square_6: StringResource by 
      lazy { init_square_6() }

  public val strokeWidth: StringResource by 
      lazy { init_strokeWidth() }

  public val studentlogin: StringResource by 
      lazy { init_studentlogin() }

  public val suit: StringResource by 
      lazy { init_suit() }

  public val szamuraj_question_1: StringResource by 
      lazy { init_szamuraj_question_1() }

  public val szamuraj_question_2: StringResource by 
      lazy { init_szamuraj_question_2() }

  public val szazalek_round: StringResource by 
      lazy { init_szazalek_round() }

  public val szogGameDiff: StringResource by 
      lazy { init_szogGameDiff() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("AccountName", CommonMainString0.AccountName)
  map.put("Accuracy", CommonMainString0.Accuracy)
  map.put("Addition", CommonMainString0.Addition)
  map.put("AirTemperature", CommonMainString0.AirTemperature)
  map.put("AirTemperature_a", CommonMainString0.AirTemperature_a)
  map.put("AirTemperature_b", CommonMainString0.AirTemperature_b)
  map.put("AirTemperature_c", CommonMainString0.AirTemperature_c)
  map.put("AirTemperature_d", CommonMainString0.AirTemperature_d)
  map.put("AirTemperature_e", CommonMainString0.AirTemperature_e)
  map.put("AllTasksSoFar", CommonMainString0.AllTasksSoFar)
  map.put("AmountOfTerms", CommonMainString0.AmountOfTerms)
  map.put("Apply", CommonMainString0.Apply)
  map.put("Arabic", CommonMainString0.Arabic)
  map.put("ArabicToRoman", CommonMainString0.ArabicToRoman)
  map.put("BackgorundColor", CommonMainString0.BackgorundColor)
  map.put("BasicOperations", CommonMainString0.BasicOperations)
  map.put("Blue", CommonMainString0.Blue)
  map.put("Check", CommonMainString0.Check)
  map.put("ChooseAccount", CommonMainString0.ChooseAccount)
  map.put("ChooseAtLeastOneOperation", CommonMainString0.ChooseAtLeastOneOperation)
  map.put("CircularSector", CommonMainString0.CircularSector)
  map.put("CircularSectorHelp", CommonMainString0.CircularSectorHelp)
  map.put("CircularSegment", CommonMainString0.CircularSegment)
  map.put("Clear", CommonMainString0.Clear)
  map.put("ClickAtThisAngle", CommonMainString0.ClickAtThisAngle)
  map.put("ClickThis", CommonMainString0.ClickThis)
  map.put("Coefficientminmax", CommonMainString0.Coefficientminmax)
  map.put("ConvertUnits", CommonMainString0.ConvertUnits)
  map.put("CorrectSolution", CommonMainString0.CorrectSolution)
  map.put("CorrectlySolvedTasks", CommonMainString0.CorrectlySolvedTasks)
  map.put("D", CommonMainString0.D)
  map.put("DarkMode", CommonMainString0.DarkMode)
  map.put("DefineARange", CommonMainString0.DefineARange)
  map.put("DefineRounds", CommonMainString0.DefineRounds)
  map.put("DefineTheAmountOfTerms", CommonMainString0.DefineTheAmountOfTerms)
  map.put("DifficultySwitch", CommonMainString0.DifficultySwitch)
  map.put("DividedByZero", CommonMainString0.DividedByZero)
  map.put("Division", CommonMainString0.Division)
  map.put("DivisionRemainder", CommonMainString0.DivisionRemainder)
  map.put("DunaVizSzint", CommonMainString0.DunaVizSzint)
  map.put("DunaVizSzint_a", CommonMainString0.DunaVizSzint_a)
  map.put("DunaVizSzint_b", CommonMainString0.DunaVizSzint_b)
  map.put("DunaVizSzint_c", CommonMainString0.DunaVizSzint_c)
  map.put("DunaVizSzint_d", CommonMainString0.DunaVizSzint_d)
  map.put("DunaVizSzint_e", CommonMainString0.DunaVizSzint_e)
  map.put("Easy_diff", CommonMainString0.Easy_diff)
  map.put("EmeseFelveteli", CommonMainString0.EmeseFelveteli)
  map.put("EmeseFelveteli_a", CommonMainString0.EmeseFelveteli_a)
  map.put("EmeseFelveteli_b", CommonMainString0.EmeseFelveteli_b)
  map.put("EmeseFelveteli_d", CommonMainString0.EmeseFelveteli_d)
  map.put("EnableRearrangingPictures", CommonMainString0.EnableRearrangingPictures)
  map.put("EquilateralTriangle", CommonMainString0.EquilateralTriangle)
  map.put("EquilateralTriangleHelp", CommonMainString0.EquilateralTriangleHelp)
  map.put("ExtendedMultiplicationTable", CommonMainString0.ExtendedMultiplicationTable)
  map.put("Fakopacs", CommonMainString0.Fakopacs)
  map.put("Fakopacs_a", CommonMainString0.Fakopacs_a)
  map.put("Fakopacs_b", CommonMainString0.Fakopacs_b)
  map.put("Fakopacs_c", CommonMainString0.Fakopacs_c)
  map.put("FastInput", CommonMainString0.FastInput)
  map.put("FontColor", CommonMainString0.FontColor)
  map.put("FontSize", CommonMainString0.FontSize)
  map.put("FractionMinMax", CommonMainString0.FractionMinMax)
  map.put("FractionWrongSolution", CommonMainString0.FractionWrongSolution)
  map.put("FractionsAccurateIn", CommonMainString0.FractionsAccurateIn)
  map.put("Friday", CommonMainString0.Friday)
  map.put("FunctionReading", CommonMainString0.FunctionReading)
  map.put("FunctionTask", CommonMainString0.FunctionTask)
  map.put("GeneratedNumerators", CommonMainString0.GeneratedNumerators)
  map.put("GeometryAreaSaveTask", CommonMainString0.GeometryAreaSaveTask)
  map.put("GeometryAreaTask", CommonMainString0.GeometryAreaTask)
  map.put("GeometryBlindMap", CommonMainString0.GeometryBlindMap)
  map.put("GeometryBlindMapPictureSize", CommonMainString0.GeometryBlindMapPictureSize)
  map.put("GeometryPerimeterSaveTask", CommonMainString0.GeometryPerimeterSaveTask)
  map.put("GeometryPerimeterTask", CommonMainString0.GeometryPerimeterTask)
  map.put("GeometryRule", CommonMainString0.GeometryRule)
  map.put("GeometryTaskGenerator", CommonMainString0.GeometryTaskGenerator)
  map.put("GetToKnowTheCoordinateSystem", CommonMainString0.GetToKnowTheCoordinateSystem)
  map.put("GuessAngleSubmitAngle", CommonMainString0.GuessAngleSubmitAngle)
  map.put("Hard_diff", CommonMainString0.Hard_diff)
  map.put("Help", CommonMainString0.Help)
  map.put("Hexagon", CommonMainString0.Hexagon)
  map.put("HowManyBooksDidKarcsiRead", CommonMainString0.HowManyBooksDidKarcsiRead)
  map.put("HowManyBooksDidStudentsReadOnAverageInSummer",
      CommonMainString0.HowManyBooksDidStudentsReadOnAverageInSummer)
  map.put("HowManyMark1", CommonMainString0.HowManyMark1)
  map.put("HowManyMark2", CommonMainString0.HowManyMark2)
  map.put("HowManyMark3", CommonMainString0.HowManyMark3)
  map.put("HowManyMark4", CommonMainString0.HowManyMark4)
  map.put("HowManyMark5", CommonMainString0.HowManyMark5)
  map.put("HowManyMoonsDoThePlanetsHaveAltogether",
      CommonMainString0.HowManyMoonsDoThePlanetsHaveAltogether)
  map.put("HowManyMoonsDoesNPlanetHas", CommonMainString0.HowManyMoonsDoesNPlanetHas)
  map.put("IntMinMax", CommonMainString0.IntMinMax)
  map.put("Integer_or", CommonMainString0.Integer_or)
  map.put("IsoscelesTrapezoid", CommonMainString0.IsoscelesTrapezoid)
  map.put("IsoscelesTrapezoidHelp", CommonMainString0.IsoscelesTrapezoidHelp)
  map.put("IsoscelesTriangle", CommonMainString0.IsoscelesTriangle)
  map.put("IsoscelesTriangleHelp", CommonMainString0.IsoscelesTriangleHelp)
  map.put("KaTeXFontsize", CommonMainString0.KaTeXFontsize)
  map.put("KaTeXdescription", CommonMainString0.KaTeXdescription)
  map.put("Kite", CommonMainString0.Kite)
  map.put("KiteHelp", CommonMainString0.KiteHelp)
  map.put("KoMal", CommonMainString0.KoMal)
  map.put("KoMal_a", CommonMainString0.KoMal_a)
  map.put("KoMal_b", CommonMainString0.KoMal_b)
  map.put("KoMal_c", CommonMainString0.KoMal_c)
  map.put("KoMal_d", CommonMainString0.KoMal_d)
  map.put("Kombinatorika_0_1", CommonMainString0.Kombinatorika_0_1)
  map.put("Kombinatorika_0_2", CommonMainString0.Kombinatorika_0_2)
  map.put("Kombinatorika_III_1", CommonMainString0.Kombinatorika_III_1)
  map.put("Kombinatorika_II_1", CommonMainString0.Kombinatorika_II_1)
  map.put("Kombinatorika_II_2", CommonMainString0.Kombinatorika_II_2)
  map.put("Kombinatorika_II_3", CommonMainString0.Kombinatorika_II_3)
  map.put("Kombinatorika_IV_1", CommonMainString0.Kombinatorika_IV_1)
  map.put("Kombinatorika_I_1", CommonMainString0.Kombinatorika_I_1)
  map.put("Kombinatorika_I_2", CommonMainString0.Kombinatorika_I_2)
  map.put("Kombinatorika_I_3", CommonMainString0.Kombinatorika_I_3)
  map.put("Kombinatorika_VI_1", CommonMainString0.Kombinatorika_VI_1)
  map.put("Kombinatorika_V_1", CommonMainString0.Kombinatorika_V_1)
  map.put("Login", CommonMainString0.Login)
  map.put("MathKing", CommonMainString0.MathKing)
  map.put("MathKingDescription", CommonMainString0.MathKingDescription)
  map.put("MaxDistanceOfUnits", CommonMainString0.MaxDistanceOfUnits)
  map.put("Medium_diff", CommonMainString0.Medium_diff)
  map.put("MennyivelTöbb_2_1", CommonMainString0.MennyivelTöbb_2_1)
  map.put("Menu", CommonMainString0.Menu)
  map.put("Mertani_A", CommonMainString0.Mertani_A)
  map.put("Mertani_B", CommonMainString0.Mertani_B)
  map.put("Mertani_C", CommonMainString0.Mertani_C)
  map.put("Molnar", CommonMainString0.Molnar)
  map.put("Monday", CommonMainString0.Monday)
  map.put("MostFrequentMark", CommonMainString0.MostFrequentMark)
  map.put("Multiplication", CommonMainString0.Multiplication)
  map.put("NInterval", CommonMainString0.NInterval)
  map.put("NewTask", CommonMainString0.NewTask)
  map.put("NthPlanetsMoons", CommonMainString0.NthPlanetsMoons)
  map.put("NumberOfRounds", CommonMainString0.NumberOfRounds)
  map.put("NumberSystemTaskLog", CommonMainString0.NumberSystemTaskLog)
  map.put("NumberSystemsTask", CommonMainString0.NumberSystemsTask)
  map.put("OkosFeladatGyűjtemény", CommonMainString0.OkosFeladatGyűjtemény)
  map.put("OperationChain", CommonMainString0.OperationChain)
  map.put("Operations", CommonMainString0.Operations)
  map.put("Parallelogram", CommonMainString0.Parallelogram)
  map.put("ParallelogramHelp", CommonMainString0.ParallelogramHelp)
  map.put("Pentagon", CommonMainString0.Pentagon)
  map.put("Percentage_A_1", CommonMainString0.Percentage_A_1)
  map.put("Percentage_A_2", CommonMainString0.Percentage_A_2)
  map.put("Percentage_A_3", CommonMainString0.Percentage_A_3)
  map.put("Percentage_A_4", CommonMainString0.Percentage_A_4)
  map.put("Percentage_B_1", CommonMainString0.Percentage_B_1)
  map.put("Percentage_B_2", CommonMainString0.Percentage_B_2)
  map.put("Percentage_B_3", CommonMainString0.Percentage_B_3)
  map.put("Percentage_C_1", CommonMainString0.Percentage_C_1)
  map.put("Percentage_D_1", CommonMainString0.Percentage_D_1)
  map.put("Percentage_E_1", CommonMainString0.Percentage_E_1)
  map.put("Percentage_F_1", CommonMainString0.Percentage_F_1)
  map.put("Percentage_G_1", CommonMainString0.Percentage_G_1)
  map.put("Percentage_G_2", CommonMainString0.Percentage_G_2)
  map.put("Percentage_G_3", CommonMainString0.Percentage_G_3)
  map.put("Percentage_G_4", CommonMainString0.Percentage_G_4)
  map.put("Percentage_G_5", CommonMainString0.Percentage_G_5)
  map.put("Percentage_H_1", CommonMainString0.Percentage_H_1)
  map.put("Percentage_H_2", CommonMainString0.Percentage_H_2)
  map.put("Percentage_H_3", CommonMainString0.Percentage_H_3)
  map.put("Percentage_H_4", CommonMainString0.Percentage_H_4)
  map.put("Percentage_H_5", CommonMainString0.Percentage_H_5)
  map.put("Percentage_I_1", CommonMainString0.Percentage_I_1)
  map.put("Percentage_I_2", CommonMainString0.Percentage_I_2)
  map.put("Percentage_I_3", CommonMainString0.Percentage_I_3)
  map.put("Percentage_J_1", CommonMainString0.Percentage_J_1)
  map.put("Percentage_J_2", CommonMainString0.Percentage_J_2)
  map.put("Percentage_J_3", CommonMainString0.Percentage_J_3)
  map.put("Periodic_lastDigitApowN", CommonMainString0.Periodic_lastDigitApowN)
  map.put("PistiFever_a", CommonMainString0.PistiFever_a)
  map.put("PistiFever_b", CommonMainString0.PistiFever_b)
  map.put("PistiFever_c", CommonMainString0.PistiFever_c)
  map.put("Pistifever", CommonMainString0.Pistifever)
  map.put("PiszkosFred", CommonMainString0.PiszkosFred)
  map.put("PracticeSolvingEquationSystems", CommonMainString0.PracticeSolvingEquationSystems)
  map.put("PracticingEquations", CommonMainString0.PracticingEquations)
  map.put("PractisingFractions", CommonMainString0.PractisingFractions)
  map.put("PreviousTasks", CommonMainString0.PreviousTasks)
  map.put("Proportionality1", CommonMainString0.Proportionality1)
  map.put("Proportionality2", CommonMainString0.Proportionality2)
  map.put("Proportionality3", CommonMainString0.Proportionality3)
  map.put("Proportionality4", CommonMainString0.Proportionality4)
  map.put("Proportionality5", CommonMainString0.Proportionality5)
  map.put("RangeOfNumbersToBeConverted", CommonMainString0.RangeOfNumbersToBeConverted)
  map.put("Rblue", CommonMainString0.Rblue)
  map.put("ReadTheCoordinatesOfThePoint", CommonMainString0.ReadTheCoordinatesOfThePoint)
  map.put("RecommendedModifiers", CommonMainString0.RecommendedModifiers)
  map.put("Rectangle", CommonMainString0.Rectangle)
  map.put("RectangleHelp", CommonMainString0.RectangleHelp)
  map.put("Red", CommonMainString0.Red)
  map.put("Rename", CommonMainString0.Rename)
  map.put("Response", CommonMainString0.Response)
  map.put("Rhombus", CommonMainString0.Rhombus)
  map.put("RhombusHelp", CommonMainString0.RhombusHelp)
  map.put("RightAngledTriangle", CommonMainString0.RightAngledTriangle)
  map.put("RightAngledTriangleHelp", CommonMainString0.RightAngledTriangleHelp)
  map.put("Roman", CommonMainString0.Roman)
  map.put("RomanToArabic", CommonMainString0.RomanToArabic)
  map.put("Rounds", CommonMainString0.Rounds)
  map.put("Rred", CommonMainString0.Rred)
  map.put("Saturday", CommonMainString0.Saturday)
  map.put("SavePicturesLocation", CommonMainString0.SavePicturesLocation)
  map.put("SetPicSize", CommonMainString0.SetPicSize)
  map.put("SetPicturesLocation", CommonMainString0.SetPicturesLocation)
  map.put("Solution", CommonMainString0.Solution)
  map.put("Square", CommonMainString0.Square)
  map.put("SquareHelp", CommonMainString0.SquareHelp)
  map.put("Subtraction", CommonMainString0.Subtraction)
  map.put("Sunday", CommonMainString0.Sunday)
  map.put("Szamtani_A", CommonMainString0.Szamtani_A)
  map.put("Szamtani_B", CommonMainString0.Szamtani_B)
  map.put("Szamtani_C", CommonMainString0.Szamtani_C)
  map.put("Szamtani_D", CommonMainString0.Szamtani_D)
  map.put("Thursday", CommonMainString0.Thursday)
  map.put("Tie", CommonMainString0.Tie)
  map.put("TimeBetweenTasks", CommonMainString0.TimeBetweenTasks)
  map.put("Timeofmeasurments", CommonMainString0.Timeofmeasurments)
  map.put("Totalnumberofcallsduringtheweek", CommonMainString0.Totalnumberofcallsduringtheweek)
  map.put("Trapezoid", CommonMainString0.Trapezoid)
  map.put("TrapezoidHelp", CommonMainString0.TrapezoidHelp)
  map.put("Tuesday", CommonMainString0.Tuesday)
  map.put("Variableminmax", CommonMainString0.Variableminmax)
  map.put("VeryHard_diff", CommonMainString0.VeryHard_diff)
  map.put("Wednesday", CommonMainString0.Wednesday)
  map.put("WrongSolution", CommonMainString0.WrongSolution)
  map.put("WrongSolutionEquationSystem", CommonMainString0.WrongSolutionEquationSystem)
  map.put("WrongSolutionWP", CommonMainString0.WrongSolutionWP)
  map.put("YouClickThis", CommonMainString0.YouClickThis)
  map.put("_2_tagsz_m", CommonMainString0._2_tagsz_m)
  map.put("_3_tagsz_m", CommonMainString0._3_tagsz_m)
  map.put("_4_tagsz_m", CommonMainString0._4_tagsz_m)
  map.put("_5_tagsz_m", CommonMainString0._5_tagsz_m)
  map.put("about", CommonMainString0.about)
  map.put("above30", CommonMainString0.above30)
  map.put("agegroups", CommonMainString0.agegroups)
  map.put("agota_question_1", CommonMainString0.agota_question_1)
  map.put("agota_question_2", CommonMainString0.agota_question_2)
  map.put("agota_question_3", CommonMainString0.agota_question_3)
  map.put("agota_question_4", CommonMainString0.agota_question_4)
  map.put("alapmuveletekMaradék", CommonMainString0.alapmuveletekMaradék)
  map.put("am7", CommonMainString0.am7)
  map.put("angle_estimation", CommonMainString0.angle_estimation)
  map.put("app_name", CommonMainString0.app_name)
  map.put("apple", CommonMainString0.apple)
  map.put("aquarium_fish", CommonMainString0.aquarium_fish)
  map.put("area", CommonMainString0.area)
  map.put("arithmetical", CommonMainString0.arithmetical)
  map.put("atvaltasTask", CommonMainString0.atvaltasTask)
  map.put("autogyar", CommonMainString0.autogyar)
  map.put("autogyar_a", CommonMainString0.autogyar_a)
  map.put("autogyar_b", CommonMainString0.autogyar_b)
  map.put("autogyar_c", CommonMainString0.autogyar_c)
  map.put("baseNumber", CommonMainString0.baseNumber)
  map.put("basket_nuts", CommonMainString0.basket_nuts)
  map.put("beginner1", CommonMainString0.beginner1)
  map.put("beginner2", CommonMainString0.beginner2)
  map.put("bekuldoszam", CommonMainString0.bekuldoszam)
  map.put("between15_30", CommonMainString0.between15_30)
  map.put("biologists", CommonMainString0.biologists)
  map.put("bluewontheround", CommonMainString0.bluewontheround)
  map.put("bodyTemperature", CommonMainString0.bodyTemperature)
  map.put("bokszolo_question", CommonMainString0.bokszolo_question)
  map.put("boys", CommonMainString0.boys)
  map.put("car_parking", CommonMainString0.car_parking)
  map.put("chairs", CommonMainString0.chairs)
  map.put("chocolate_distribution", CommonMainString0.chocolate_distribution)
  map.put("cinderella_beans", CommonMainString0.cinderella_beans)
  map.put("circus_performers", CommonMainString0.circus_performers)
  map.put("classA", CommonMainString0.classA)
  map.put("classB", CommonMainString0.classB)
  map.put("close", CommonMainString0.close)
  map.put("combinatorics", CommonMainString0.combinatorics)
  map.put("conversionFromDecimal", CommonMainString0.conversionFromDecimal)
  map.put("conversionToDecimal", CommonMainString0.conversionToDecimal)
  map.put("correctAngle", CommonMainString0.correctAngle)
  map.put("costume", CommonMainString0.costume)
  map.put("csokik", CommonMainString0.csokik)
  map.put("cube_1", CommonMainString0.cube_1)
  map.put("cube_2", CommonMainString0.cube_2)
  map.put("cubeoid_1", CommonMainString0.cubeoid_1)
  map.put("cubeoid_2", CommonMainString0.cubeoid_2)
  map.put("cubeoid_3", CommonMainString0.cubeoid_3)
  map.put("cubeoid_4", CommonMainString0.cubeoid_4)
  map.put("cubeoid_5", CommonMainString0.cubeoid_5)
  map.put("cubeoid_6", CommonMainString0.cubeoid_6)
  map.put("cubeoid_7", CommonMainString0.cubeoid_7)
  map.put("cubeoid_8", CommonMainString0.cubeoid_8)
  map.put("customrange", CommonMainString0.customrange)
  map.put("day", CommonMainString0.day)
  map.put("days", CommonMainString0.days)
  map.put("defineTime", CommonMainString0.defineTime)
  map.put("denominator", CommonMainString0.denominator)
  map.put("description", CommonMainString0.description)
  map.put("difficulty", CommonMainString0.difficulty)
  map.put("difficulty2", CommonMainString0.difficulty2)
  map.put("difficulty3", CommonMainString0.difficulty3)
  map.put("dinosaur_park", CommonMainString0.dinosaur_park)
  map.put("dog_treat_distribution", CommonMainString0.dog_treat_distribution)
  map.put("dolgozat", CommonMainString0.dolgozat)
  map.put("done", CommonMainString0.done)
  map.put("drawAngle", CommonMainString0.drawAngle)
  map.put("drawAngleDiff", CommonMainString0.drawAngleDiff)
  map.put("drawTask", CommonMainString0.drawTask)
  map.put("drone", CommonMainString0.drone)
  map.put("drone_a", CommonMainString0.drone_a)
  map.put("drone_b", CommonMainString0.drone_b)
  map.put("drone_c", CommonMainString0.drone_c)
  map.put("easy", CommonMainString0.easy)
  map.put("ecologist", CommonMainString0.ecologist)
  map.put("emptyrange", CommonMainString0.emptyrange)
  map.put("emptytermsRange", CommonMainString0.emptytermsRange)
  map.put("enter_your_teacher_s_email", CommonMainString0.enter_your_teacher_s_email)
  map.put("equationDescription", CommonMainString0.equationDescription)
  map.put("equilateralTriangle_1", CommonMainString0.equilateralTriangle_1)
  map.put("equilateralTriangle_2", CommonMainString0.equilateralTriangle_2)
  map.put("ermedobas", CommonMainString0.ermedobas)
  map.put("ermedobasBottom", CommonMainString0.ermedobasBottom)
  map.put("ermedobasLeft", CommonMainString0.ermedobasLeft)
  map.put("ermedobas_a", CommonMainString0.ermedobas_a)
  map.put("ermedobas_b", CommonMainString0.ermedobas_b)
  map.put("ermedobas_c", CommonMainString0.ermedobas_c)
  map.put("ermedobas_d", CommonMainString0.ermedobas_d)
  map.put("exponentiation", CommonMainString0.exponentiation)
  map.put("findPrimeFactorsfor", CommonMainString0.findPrimeFactorsfor)
  map.put("find_teacher_by_email", CommonMainString0.find_teacher_by_email)
  map.put("flower_distribution", CommonMainString0.flower_distribution)
  map.put("fodrasz_hajvagas", CommonMainString0.fodrasz_hajvagas)
  map.put("fractions1", CommonMainString0.fractions1)
  map.put("fractions2", CommonMainString0.fractions2)
  map.put("fractions3", CommonMainString0.fractions3)
  map.put("from", CommonMainString0.from)
  map.put("fruitSurvey", CommonMainString0.fruitSurvey)
  map.put("fruitSurvey_a", CommonMainString0.fruitSurvey_a)
  map.put("fruitSurvey_b", CommonMainString0.fruitSurvey_b)
  map.put("fruitSurvey_b1", CommonMainString0.fruitSurvey_b1)
  map.put("fruitSurvey_b2", CommonMainString0.fruitSurvey_b2)
  map.put("fruitSurvey_c", CommonMainString0.fruitSurvey_c)
  map.put("fruit_basket", CommonMainString0.fruit_basket)
  map.put("fruit_distribution", CommonMainString0.fruit_distribution)
  map.put("funSlope", CommonMainString0.funSlope)
  map.put("futar_csomagszallitas", CommonMainString0.futar_csomagszallitas)
  map.put("games", CommonMainString0.games)
  map.put("garden_flowers", CommonMainString0.garden_flowers)
  map.put("gasPrice", CommonMainString0.gasPrice)
  map.put("gasPrice_a", CommonMainString0.gasPrice_a)
  map.put("gasPrice_b", CommonMainString0.gasPrice_b)
  map.put("gasPrice_c", CommonMainString0.gasPrice_c)
  map.put("gasPrice_d", CommonMainString0.gasPrice_d)
  map.put("geometrical", CommonMainString0.geometrical)
  map.put("geometry", CommonMainString0.geometry)
  map.put("gep_gyartas", CommonMainString0.gep_gyartas)
  map.put("girls", CommonMainString0.girls)
  map.put("guessAngle", CommonMainString0.guessAngle)
  map.put("guessAngleDiff", CommonMainString0.guessAngleDiff)
  map.put("guessAngleResponse", CommonMainString0.guessAngleResponse)
  map.put("guesstask", CommonMainString0.guesstask)
  map.put("hallgatok", CommonMainString0.hallgatok)
  map.put("hard", CommonMainString0.hard)
  map.put("hary_janos", CommonMainString0.hary_janos)
  map.put("height", CommonMainString0.height)
  map.put("helyettesitesiErtek", CommonMainString0.helyettesitesiErtek)
  map.put("hideshow", CommonMainString0.hideshow)
  map.put("hours", CommonMainString0.hours)
  map.put("howmuch", CommonMainString0.howmuch)
  map.put("identity", CommonMainString0.identity)
  map.put("igen", CommonMainString0.igen)
  map.put("instagram_friends", CommonMainString0.instagram_friends)
  map.put("integer", CommonMainString0.integer)
  map.put("isoscelesTriangle_1", CommonMainString0.isoscelesTriangle_1)
  map.put("isoscelesTriangle_2", CommonMainString0.isoscelesTriangle_2)
  map.put("isoscelesTriangle_3", CommonMainString0.isoscelesTriangle_3)
  map.put("isoscelesTriangle_4", CommonMainString0.isoscelesTriangle_4)
  map.put("jozsiba_question_1", CommonMainString0.jozsiba_question_1)
  map.put("jozsiba_question_2", CommonMainString0.jozsiba_question_2)
  map.put("justWrong", CommonMainString0.justWrong)
  map.put("karcsi", CommonMainString0.karcsi)
  map.put("kite_1", CommonMainString0.kite_1)
  map.put("kite_2", CommonMainString0.kite_2)
  map.put("konk_v_n_gysz_g", CommonMainString0.konk_v_n_gysz_g)
  map.put("konkav_otszog", CommonMainString0.konkav_otszog)
  map.put("konyvkoto_kotes", CommonMainString0.konyvkoto_kotes)
  map.put("koppinstráFeladat", CommonMainString0.koppinstráFeladat)
  map.put("kutyaeledel", CommonMainString0.kutyaeledel)
  map.put("labor", CommonMainString0.labor)
  map.put("lastCorrects", CommonMainString0.lastCorrects)
  map.put("learningTime", CommonMainString0.learningTime)
  map.put("legfeljebb_egy_konyv_nyaron", CommonMainString0.legfeljebb_egy_konyv_nyaron)
  map.put("length", CommonMainString0.length)
  map.put("level", CommonMainString0.level)
  map.put("library", CommonMainString0.library)
  map.put("liquidMeasure", CommonMainString0.liquidMeasure)
  map.put("logintxt", CommonMainString0.logintxt)
  map.put("logout", CommonMainString0.logout)
  map.put("macskaeledel", CommonMainString0.macskaeledel)
  map.put("mark", CommonMainString0.mark)
  map.put("markThePoint", CommonMainString0.markThePoint)
  map.put("mass", CommonMainString0.mass)
  map.put("matekverseny", CommonMainString0.matekverseny)
  map.put("matekverseny_a", CommonMainString0.matekverseny_a)
  map.put("matekverseny_b", CommonMainString0.matekverseny_b)
  map.put("matekverseny_c", CommonMainString0.matekverseny_c)
  map.put("matekversenybottomlabel", CommonMainString0.matekversenybottomlabel)
  map.put("matekversenylabelleft", CommonMainString0.matekversenylabelleft)
  map.put("matematikadolgozat", CommonMainString0.matematikadolgozat)
  map.put("matematikadolgozat_a", CommonMainString0.matematikadolgozat_a)
  map.put("matematikadolgozat_b", CommonMainString0.matematikadolgozat_b)
  map.put("matematikadolgozat_c", CommonMainString0.matematikadolgozat_c)
  map.put("matematikadolgozat_d", CommonMainString0.matematikadolgozat_d)
  map.put("max", CommonMainString0.max)
  map.put("medium", CommonMainString0.medium)
  map.put("megoldokulcs", CommonMainString0.megoldokulcs)
  map.put("menu_order_distribution", CommonMainString0.menu_order_distribution)
  map.put("menyasszonyiauto", CommonMainString0.menyasszonyiauto)
  map.put("menyasszonyitorta", CommonMainString0.menyasszonyitorta)
  map.put("min", CommonMainString0.min)
  map.put("mixedFractions", CommonMainString0.mixedFractions)
  map.put("month", CommonMainString0.month)
  map.put("nagymama_himezes", CommonMainString0.nagymama_himezes)
  map.put("nem_alatta", CommonMainString0.nem_alatta)
  map.put("nem_folotte", CommonMainString0.nem_folotte)
  map.put("no", CommonMainString0.no)
  map.put("noOperator", CommonMainString0.noOperator)
  map.put("noType", CommonMainString0.noType)
  map.put("numberOfDays", CommonMainString0.numberOfDays)
  map.put("numberofcalls", CommonMainString0.numberofcalls)
  map.put("numberofmoons", CommonMainString0.numberofmoons)
  map.put("numberofstudents", CommonMainString0.numberofstudents)
  map.put("numerator", CommonMainString0.numerator)
  map.put("nyulak", CommonMainString0.nyulak)
  map.put("onWhichDayDidTheMostCustomersCall", CommonMainString0.onWhichDayDidTheMostCustomersCall)
  map.put("open", CommonMainString0.`open`)
  map.put("otosok", CommonMainString0.otosok)
  map.put("otosokBottom", CommonMainString0.otosokBottom)
  map.put("otosok_a", CommonMainString0.otosok_a)
  map.put("otosok_b", CommonMainString0.otosok_b)
  map.put("otosok_c", CommonMainString0.otosok_c)
  map.put("paralellogram_1", CommonMainString0.paralellogram_1)
  map.put("paralellogram_2", CommonMainString0.paralellogram_2)
  map.put("paralellogram_3", CommonMainString0.paralellogram_3)
  map.put("parentheses1", CommonMainString0.parentheses1)
  map.put("parentheses2", CommonMainString0.parentheses2)
  map.put("peach", CommonMainString0.peach)
  map.put("pear", CommonMainString0.pear)
  map.put("people", CommonMainString0.people)
  map.put("percentage", CommonMainString0.percentage)
  map.put("percentageofallenquirers", CommonMainString0.percentageofallenquirers)
  map.put("periodic", CommonMainString0.periodic)
  map.put("pieces", CommonMainString0.pieces)
  map.put("pirate_treasures", CommonMainString0.pirate_treasures)
  map.put("planetindex", CommonMainString0.planetindex)
  map.put("planets", CommonMainString0.planets)
  map.put("play", CommonMainString0.play)
  map.put("pm2", CommonMainString0.pm2)
  map.put("potion_brewing", CommonMainString0.potion_brewing)
  map.put("practiceRomanNumerals", CommonMainString0.practiceRomanNumerals)
  map.put("price", CommonMainString0.price)
  map.put("primefactors", CommonMainString0.primefactors)
  map.put("programozo_kodiras", CommonMainString0.programozo_kodiras)
  map.put("proportionality", CommonMainString0.proportionality)
  map.put("questions", CommonMainString0.questions)
  map.put("rabbit_distribution", CommonMainString0.rabbit_distribution)
  map.put("rajtaVanePQ", CommonMainString0.rajtaVanePQ)
  map.put("rajtavane", CommonMainString0.rajtavane)
  map.put("readPoint", CommonMainString0.readPoint)
  map.put("readbooks", CommonMainString0.readbooks)
  map.put("rectangle_1", CommonMainString0.rectangle_1)
  map.put("rectangle_2", CommonMainString0.rectangle_2)
  map.put("rectangle_3", CommonMainString0.rectangle_3)
  map.put("rectangle_4", CommonMainString0.rectangle_4)
  map.put("rectangle_5", CommonMainString0.rectangle_5)
  map.put("rectangle_6", CommonMainString0.rectangle_6)
  map.put("redwontheround", CommonMainString0.redwontheround)
  map.put("rhombus_1", CommonMainString0.rhombus_1)
  map.put("rhombus_2", CommonMainString0.rhombus_2)
  map.put("rhombus_3", CommonMainString0.rhombus_3)
  map.put("rightAngledTriangle_1", CommonMainString0.rightAngledTriangle_1)
  map.put("rightAngledTriangle_2", CommonMainString0.rightAngledTriangle_2)
  map.put("rightAngledTriangle_3", CommonMainString0.rightAngledTriangle_3)
  map.put("rightAngledTriangle_4", CommonMainString0.rightAngledTriangle_4)
  map.put("rightAngledTriangle_5", CommonMainString0.rightAngledTriangle_5)
  map.put("robot_furat", CommonMainString0.robot_furat)
  map.put("rows", CommonMainString0.rows)
  map.put("seconds", CommonMainString0.seconds)
  map.put("select_your_class", CommonMainString0.select_your_class)
  map.put("slope", CommonMainString0.slope)
  map.put("square_1", CommonMainString0.square_1)
  map.put("square_2", CommonMainString0.square_2)
  map.put("square_3", CommonMainString0.square_3)
  map.put("square_4", CommonMainString0.square_4)
  map.put("square_5", CommonMainString0.square_5)
  map.put("square_6", CommonMainString0.square_6)
  map.put("strokeWidth", CommonMainString0.strokeWidth)
  map.put("studentlogin", CommonMainString0.studentlogin)
  map.put("suit", CommonMainString0.suit)
  map.put("szamuraj_question_1", CommonMainString0.szamuraj_question_1)
  map.put("szamuraj_question_2", CommonMainString0.szamuraj_question_2)
  map.put("szazalek_round", CommonMainString0.szazalek_round)
  map.put("szogGameDiff", CommonMainString0.szogGameDiff)
}

internal val Res.string.AccountName: StringResource
  get() = CommonMainString0.AccountName

private fun init_AccountName(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:AccountName", "AccountName",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10, 31),
    )
)

internal val Res.string.Accuracy: StringResource
  get() = CommonMainString0.Accuracy

private fun init_Accuracy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Accuracy", "Accuracy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    54, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    54, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42, 32),
    )
)

internal val Res.string.Addition: StringResource
  get() = CommonMainString0.Addition

private fun init_Addition(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Addition", "Addition",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    91, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    87, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    75, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    75, 32),
    )
)

internal val Res.string.AirTemperature: StringResource
  get() = CommonMainString0.AirTemperature

private fun init_AirTemperature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature", "AirTemperature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    865, 250),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    797, 214),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    861, 266),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    861, 266),
    )
)

internal val Res.string.AirTemperature_a: StringResource
  get() = CommonMainString0.AirTemperature_a

private fun init_AirTemperature_a(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature_a", "AirTemperature_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    120, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    112, 112),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    108, 124),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    108, 124),
    )
)

internal val Res.string.AirTemperature_b: StringResource
  get() = CommonMainString0.AirTemperature_b

private fun init_AirTemperature_b(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature_b", "AirTemperature_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    245, 180),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    225, 160),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    233, 176),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    233, 176),
    )
)

internal val Res.string.AirTemperature_c: StringResource
  get() = CommonMainString0.AirTemperature_c

private fun init_AirTemperature_c(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature_c", "AirTemperature_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    426, 148),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    386, 120),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    410, 152),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    410, 152),
    )
)

internal val Res.string.AirTemperature_d: StringResource
  get() = CommonMainString0.AirTemperature_d

private fun init_AirTemperature_d(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature_d", "AirTemperature_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    575, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    507, 140),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    563, 148),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    563, 148),
    )
)

internal val Res.string.AirTemperature_e: StringResource
  get() = CommonMainString0.AirTemperature_e

private fun init_AirTemperature_e(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:AirTemperature_e", "AirTemperature_e",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    720, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    648, 148),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    712, 148),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    712, 148),
    )
)

internal val Res.string.AllTasksSoFar: StringResource
  get() = CommonMainString0.AllTasksSoFar

private fun init_AllTasksSoFar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:AllTasksSoFar", "AllTasksSoFar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1116, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1012, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1128, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1128, 53),
    )
)

internal val Res.string.AmountOfTerms: StringResource
  get() = CommonMainString0.AmountOfTerms

private fun init_AmountOfTerms(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:AmountOfTerms", "AmountOfTerms",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1158, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1098, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1182, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1182, 45),
    )
)

internal val Res.string.Apply: StringResource
  get() = CommonMainString0.Apply

private fun init_Apply(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Apply", "Apply",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1208, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1152, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1228, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1228, 25),
    )
)

internal val Res.string.Arabic: StringResource
  get() = CommonMainString0.Arabic

private fun init_Arabic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Arabic", "Arabic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1292, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1228, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1296, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1296, 22),
    )
)

internal val Res.string.ArabicToRoman: StringResource
  get() = CommonMainString0.ArabicToRoman

private fun init_ArabicToRoman(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ArabicToRoman", "ArabicToRoman",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1238, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1182, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1254, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1254, 41),
    )
)

internal val Res.string.BackgorundColor: StringResource
  get() = CommonMainString0.BackgorundColor

private fun init_BackgorundColor(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:BackgorundColor", "BackgorundColor",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1319, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1255, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1319, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1319, 43),
    )
)

internal val Res.string.BasicOperations: StringResource
  get() = CommonMainString0.BasicOperations

private fun init_BasicOperations(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:BasicOperations", "BasicOperations",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1367, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1299, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1363, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1363, 43),
    )
)

internal val Res.string.Blue: StringResource
  get() = CommonMainString0.Blue

private fun init_Blue(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Blue", "Blue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1415, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1351, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1407, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1407, 20),
    )
)

internal val Res.string.Check: StringResource
  get() = CommonMainString0.Check

private fun init_Check(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Check", "Check",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1436, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1372, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1428, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1428, 29),
    )
)

internal val Res.string.ChooseAccount: StringResource
  get() = CommonMainString0.ChooseAccount

private fun init_ChooseAccount(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ChooseAccount", "ChooseAccount",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1470, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1398, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1458, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1458, 45),
    )
)

internal val Res.string.ChooseAtLeastOneOperation: StringResource
  get() = CommonMainString0.ChooseAtLeastOneOperation

private fun init_ChooseAtLeastOneOperation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ChooseAtLeastOneOperation", "ChooseAtLeastOneOperation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1528, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1440, 81),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1504, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1504, 81),
    )
)

internal val Res.string.CircularSector: StringResource
  get() = CommonMainString0.CircularSector

private fun init_CircularSector(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:CircularSector", "CircularSector",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1665, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1577, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1645, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1645, 34),
    )
)

internal val Res.string.CircularSectorHelp: StringResource
  get() = CommonMainString0.CircularSectorHelp

private fun init_CircularSectorHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:CircularSectorHelp", "CircularSectorHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1606, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1522, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1586, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1586, 58),
    )
)

internal val Res.string.CircularSegment: StringResource
  get() = CommonMainString0.CircularSegment

private fun init_CircularSegment(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:CircularSegment", "CircularSegment",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1704, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1620, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1680, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1680, 39),
    )
)

internal val Res.string.Clear: StringResource
  get() = CommonMainString0.Clear

private fun init_Clear(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Clear", "Clear",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1744, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1668, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1720, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1720, 25),
    )
)

internal val Res.string.ClickAtThisAngle: StringResource
  get() = CommonMainString0.ClickAtThisAngle

private fun init_ClickAtThisAngle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ClickAtThisAngle", "ClickAtThisAngle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1770, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1690, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1746, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1746, 64),
    )
)

internal val Res.string.ClickThis: StringResource
  get() = CommonMainString0.ClickThis

private fun init_ClickThis(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ClickThis", "ClickThis",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1823, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1775, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1811, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1811, 37),
    )
)

internal val Res.string.Coefficientminmax: StringResource
  get() = CommonMainString0.Coefficientminmax

private fun init_Coefficientminmax(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Coefficientminmax", "Coefficientminmax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1869, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1809, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1849, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1849, 61),
    )
)

internal val Res.string.ConvertUnits: StringResource
  get() = CommonMainString0.ConvertUnits

private fun init_ConvertUnits(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ConvertUnits", "ConvertUnits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1927, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1863, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1911, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1911, 44),
    )
)

internal val Res.string.CorrectSolution: StringResource
  get() = CommonMainString0.CorrectSolution

private fun init_CorrectSolution(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:CorrectSolution", "CorrectSolution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    1976, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1916, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    1956, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    1956, 47),
    )
)

internal val Res.string.CorrectlySolvedTasks: StringResource
  get() = CommonMainString0.CorrectlySolvedTasks

private fun init_CorrectlySolvedTasks(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:CorrectlySolvedTasks", "CorrectlySolvedTasks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2024, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    1964, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2004, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2004, 68),
    )
)

internal val Res.string.D: StringResource
  get() = CommonMainString0.D

private fun init_D(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:D", "D",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3337, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3349, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3333, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3333, 37),
    )
)

internal val Res.string.DarkMode: StringResource
  get() = CommonMainString0.DarkMode

private fun init_DarkMode(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DarkMode", "DarkMode",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2077, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2041, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2073, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2073, 32),
    )
)

internal val Res.string.DefineARange: StringResource
  get() = CommonMainString0.DefineARange

private fun init_DefineARange(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DefineARange", "DefineARange",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2110, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2074, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2106, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2106, 92),
    )
)

internal val Res.string.DefineRounds: StringResource
  get() = CommonMainString0.DefineRounds

private fun init_DefineRounds(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DefineRounds", "DefineRounds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2155, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2167, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2199, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2199, 56),
    )
)

internal val Res.string.DefineTheAmountOfTerms: StringResource
  get() = CommonMainString0.DefineTheAmountOfTerms

private fun init_DefineTheAmountOfTerms(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:DefineTheAmountOfTerms", "DefineTheAmountOfTerms",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2208, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2236, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2256, 94),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2256, 94),
    )
)

internal val Res.string.DifficultySwitch: StringResource
  get() = CommonMainString0.DifficultySwitch

private fun init_DifficultySwitch(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:DifficultySwitch", "DifficultySwitch",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2271, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2327, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2351, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2351, 60),
    )
)

internal val Res.string.DividedByZero: StringResource
  get() = CommonMainString0.DividedByZero

private fun init_DividedByZero(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DividedByZero", "DividedByZero",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2340, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2388, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2412, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2412, 45),
    )
)

internal val Res.string.Division: StringResource
  get() = CommonMainString0.Division

private fun init_Division(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Division", "Division",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2436, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2488, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2508, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2508, 28),
    )
)

internal val Res.string.DivisionRemainder: StringResource
  get() = CommonMainString0.DivisionRemainder

private fun init_DivisionRemainder(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:DivisionRemainder", "DivisionRemainder",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2386, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2434, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2458, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2458, 49),
    )
)

internal val Res.string.DunaVizSzint: StringResource
  get() = CommonMainString0.DunaVizSzint

private fun init_DunaVizSzint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint", "DunaVizSzint",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3076, 260),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3108, 240),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3084, 248),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3084, 248),
    )
)

internal val Res.string.DunaVizSzint_a: StringResource
  get() = CommonMainString0.DunaVizSzint_a

private fun init_DunaVizSzint_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint_a", "DunaVizSzint_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2465, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2517, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2537, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2537, 110),
    )
)

internal val Res.string.DunaVizSzint_b: StringResource
  get() = CommonMainString0.DunaVizSzint_b

private fun init_DunaVizSzint_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint_b", "DunaVizSzint_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2572, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2624, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2648, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2648, 102),
    )
)

internal val Res.string.DunaVizSzint_c: StringResource
  get() = CommonMainString0.DunaVizSzint_c

private fun init_DunaVizSzint_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint_c", "DunaVizSzint_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2683, 142),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2735, 142),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2751, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2751, 126),
    )
)

internal val Res.string.DunaVizSzint_d: StringResource
  get() = CommonMainString0.DunaVizSzint_d

private fun init_DunaVizSzint_d(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint_d", "DunaVizSzint_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2826, 126),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2878, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2878, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2878, 90),
    )
)

internal val Res.string.DunaVizSzint_e: StringResource
  get() = CommonMainString0.DunaVizSzint_e

private fun init_DunaVizSzint_e(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:DunaVizSzint_e", "DunaVizSzint_e",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    2953, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    2989, 118),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    2969, 114),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    2969, 114),
    )
)

internal val Res.string.Easy_diff: StringResource
  get() = CommonMainString0.Easy_diff

private fun init_Easy_diff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Easy_diff", "Easy_diff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3387, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3387, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3371, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3371, 45),
    )
)

internal val Res.string.EmeseFelveteli: StringResource
  get() = CommonMainString0.EmeseFelveteli

private fun init_EmeseFelveteli(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:EmeseFelveteli", "EmeseFelveteli",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3864, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3916, 174),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3840, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3840, 162),
    )
)

internal val Res.string.EmeseFelveteli_a: StringResource
  get() = CommonMainString0.EmeseFelveteli_a

private fun init_EmeseFelveteli_a(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EmeseFelveteli_a", "EmeseFelveteli_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3413, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3441, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3417, 84),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3417, 84),
    )
)

internal val Res.string.EmeseFelveteli_b: StringResource
  get() = CommonMainString0.EmeseFelveteli_b

private fun init_EmeseFelveteli_b(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EmeseFelveteli_b", "EmeseFelveteli_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3506, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3542, 116),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3502, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3502, 96),
    )
)

internal val Res.string.EmeseFelveteli_d: StringResource
  get() = CommonMainString0.EmeseFelveteli_d

private fun init_EmeseFelveteli_d(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EmeseFelveteli_d", "EmeseFelveteli_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    3615, 248),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    3659, 256),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    3599, 240),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    3599, 240),
    )
)

internal val Res.string.EnableRearrangingPictures: StringResource
  get() = CommonMainString0.EnableRearrangingPictures

private fun init_EnableRearrangingPictures(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EnableRearrangingPictures", "EnableRearrangingPictures",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4043, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4091, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4003, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4003, 89),
    )
)

internal val Res.string.EquilateralTriangle: StringResource
  get() = CommonMainString0.EquilateralTriangle

private fun init_EquilateralTriangle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EquilateralTriangle", "EquilateralTriangle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4221, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4309, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4217, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4217, 59),
    )
)

internal val Res.string.EquilateralTriangleHelp: StringResource
  get() = CommonMainString0.EquilateralTriangleHelp

private fun init_EquilateralTriangleHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:EquilateralTriangleHelp", "EquilateralTriangleHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4097, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4185, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4093, 123),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4093, 123),
    )
)

internal val Res.string.ExtendedMultiplicationTable: StringResource
  get() = CommonMainString0.ExtendedMultiplicationTable

private fun init_ExtendedMultiplicationTable(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ExtendedMultiplicationTable", "ExtendedMultiplicationTable",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4281, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4369, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4277, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4277, 71),
    )
)

internal val Res.string.Fakopacs: StringResource
  get() = CommonMainString0.Fakopacs

private fun init_Fakopacs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Fakopacs", "Fakopacs",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4730, 196),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4734, 168),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4650, 204),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4650, 204),
    )
)

internal val Res.string.Fakopacs_a: StringResource
  get() = CommonMainString0.Fakopacs_a

private fun init_Fakopacs_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Fakopacs_a", "Fakopacs_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4361, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4445, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4349, 74),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4349, 74),
    )
)

internal val Res.string.Fakopacs_b: StringResource
  get() = CommonMainString0.Fakopacs_b

private fun init_Fakopacs_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Fakopacs_b", "Fakopacs_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4452, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4512, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4424, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4424, 90),
    )
)

internal val Res.string.Fakopacs_c: StringResource
  get() = CommonMainString0.Fakopacs_c

private fun init_Fakopacs_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Fakopacs_c", "Fakopacs_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4563, 166),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4595, 138),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4515, 134),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4515, 134),
    )
)

internal val Res.string.FastInput: StringResource
  get() = CommonMainString0.FastInput

private fun init_FastInput(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FastInput", "FastInput",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4927, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4903, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4855, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4855, 41),
    )
)

internal val Res.string.FontColor: StringResource
  get() = CommonMainString0.FontColor

private fun init_FontColor(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FontColor", "FontColor",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    4969, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4941, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4897, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4897, 33),
    )
)

internal val Res.string.FontSize: StringResource
  get() = CommonMainString0.FontSize

private fun init_FontSize(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FontSize", "FontSize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5007, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    4979, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4931, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4931, 32),
    )
)

internal val Res.string.FractionMinMax: StringResource
  get() = CommonMainString0.FractionMinMax

private fun init_FractionMinMax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FractionMinMax", "FractionMinMax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5048, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5020, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    4964, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    4964, 70),
    )
)

internal val Res.string.FractionWrongSolution: StringResource
  get() = CommonMainString0.FractionWrongSolution

private fun init_FractionWrongSolution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:FractionWrongSolution", "FractionWrongSolution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5131, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5091, 93),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5035, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5035, 89),
    )
)

internal val Res.string.FractionsAccurateIn: StringResource
  get() = CommonMainString0.FractionsAccurateIn

private fun init_FractionsAccurateIn(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:FractionsAccurateIn", "FractionsAccurateIn",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5225, 103),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5185, 139),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5125, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5125, 99),
    )
)

internal val Res.string.Friday: StringResource
  get() = CommonMainString0.Friday

private fun init_Friday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Friday", "Friday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5329, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5325, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5225, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5225, 26),
    )
)

internal val Res.string.FunctionReading: StringResource
  get() = CommonMainString0.FunctionReading

private fun init_FunctionReading(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FunctionReading", "FunctionReading",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5356, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5348, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5252, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5252, 55),
    )
)

internal val Res.string.FunctionTask: StringResource
  get() = CommonMainString0.FunctionTask

private fun init_FunctionTask(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:FunctionTask", "FunctionTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5396, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5404, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5308, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5308, 80),
    )
)

internal val Res.string.GeneratedNumerators: StringResource
  get() = CommonMainString0.GeneratedNumerators

private fun init_GeneratedNumerators(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeneratedNumerators", "GeneratedNumerators",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5477, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5493, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5389, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5389, 75),
    )
)

internal val Res.string.GeometryAreaSaveTask: StringResource
  get() = CommonMainString0.GeometryAreaSaveTask

private fun init_GeometryAreaSaveTask(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryAreaSaveTask", "GeometryAreaSaveTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5573, 116),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5569, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5465, 100),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5465, 100),
    )
)

internal val Res.string.GeometryAreaTask: StringResource
  get() = CommonMainString0.GeometryAreaTask

private fun init_GeometryAreaTask(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryAreaTask", "GeometryAreaTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5690, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5694, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5566, 88),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5566, 88),
    )
)

internal val Res.string.GeometryBlindMap: StringResource
  get() = CommonMainString0.GeometryBlindMap

private fun init_GeometryBlindMap(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryBlindMap", "GeometryBlindMap",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5863, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5879, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5735, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5735, 56),
    )
)

internal val Res.string.GeometryBlindMapPictureSize: StringResource
  get() = CommonMainString0.GeometryBlindMapPictureSize

private fun init_GeometryBlindMapPictureSize(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryBlindMapPictureSize", "GeometryBlindMapPictureSize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5779, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5779, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5655, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5655, 79),
    )
)

internal val Res.string.GeometryPerimeterSaveTask: StringResource
  get() = CommonMainString0.GeometryPerimeterSaveTask

private fun init_GeometryPerimeterSaveTask(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryPerimeterSaveTask", "GeometryPerimeterSaveTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5792, 105),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5792, 105),
    )
)

internal val Res.string.GeometryPerimeterTask: StringResource
  get() = CommonMainString0.GeometryPerimeterTask

private fun init_GeometryPerimeterTask(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryPerimeterTask", "GeometryPerimeterTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5898, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5898, 97),
    )
)

internal val Res.string.GeometryRule: StringResource
  get() = CommonMainString0.GeometryRule

private fun init_GeometryRule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:GeometryRule", "GeometryRule",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    5916, 620),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    5932, 604),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    5996, 612),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    5996, 612),
    )
)

internal val Res.string.GeometryTaskGenerator: StringResource
  get() = CommonMainString0.GeometryTaskGenerator

private fun init_GeometryTaskGenerator(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GeometryTaskGenerator", "GeometryTaskGenerator",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6537, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6537, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6609, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6609, 69),
    )
)

internal val Res.string.GetToKnowTheCoordinateSystem: StringResource
  get() = CommonMainString0.GetToKnowTheCoordinateSystem

private fun init_GetToKnowTheCoordinateSystem(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GetToKnowTheCoordinateSystem", "GetToKnowTheCoordinateSystem",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6599, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6611, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6679, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6679, 80),
    )
)

internal val Res.string.GuessAngleSubmitAngle: StringResource
  get() = CommonMainString0.GuessAngleSubmitAngle

private fun init_GuessAngleSubmitAngle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:GuessAngleSubmitAngle", "GuessAngleSubmitAngle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6660, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6712, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6760, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6760, 61),
    )
)

internal val Res.string.Hard_diff: StringResource
  get() = CommonMainString0.Hard_diff

private fun init_Hard_diff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Hard_diff", "Hard_diff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6726, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6790, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6822, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6822, 41),
    )
)

internal val Res.string.Help: StringResource
  get() = CommonMainString0.Help

private fun init_Help(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Help", "Help",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6752, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6848, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6864, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6864, 20),
    )
)

internal val Res.string.Hexagon: StringResource
  get() = CommonMainString0.Hexagon

private fun init_Hexagon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Hexagon", "Hexagon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6773, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6869, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6885, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6885, 27),
    )
)

internal val Res.string.HowManyBooksDidKarcsiRead: StringResource
  get() = CommonMainString0.HowManyBooksDidKarcsiRead

private fun init_HowManyBooksDidKarcsiRead(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:HowManyBooksDidKarcsiRead", "HowManyBooksDidKarcsiRead",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6801, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    6897, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    6913, 189),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    6913, 189),
    )
)

internal val Res.string.HowManyBooksDidStudentsReadOnAverageInSummer: StringResource
  get() = CommonMainString0.HowManyBooksDidStudentsReadOnAverageInSummer

private fun init_HowManyBooksDidStudentsReadOnAverageInSummer(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:HowManyBooksDidStudentsReadOnAverageInSummer",
    "HowManyBooksDidStudentsReadOnAverageInSummer",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    6995, 164),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7091, 156),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7103, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7103, 160),
    )
)

internal val Res.string.HowManyMark1: StringResource
  get() = CommonMainString0.HowManyMark1

private fun init_HowManyMark1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:HowManyMark1", "HowManyMark1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7160, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7248, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7264, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7264, 72),
    )
)

internal val Res.string.HowManyMark2: StringResource
  get() = CommonMainString0.HowManyMark2

private fun init_HowManyMark2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:HowManyMark2", "HowManyMark2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7229, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7341, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7337, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7337, 72),
    )
)

internal val Res.string.HowManyMark3: StringResource
  get() = CommonMainString0.HowManyMark3

private fun init_HowManyMark3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:HowManyMark3", "HowManyMark3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7298, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7430, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7410, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7410, 68),
    )
)

internal val Res.string.HowManyMark4: StringResource
  get() = CommonMainString0.HowManyMark4

private fun init_HowManyMark4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:HowManyMark4", "HowManyMark4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7367, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7511, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7479, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7479, 68),
    )
)

internal val Res.string.HowManyMark5: StringResource
  get() = CommonMainString0.HowManyMark5

private fun init_HowManyMark5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:HowManyMark5", "HowManyMark5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7436, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7596, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7548, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7548, 64),
    )
)

internal val Res.string.HowManyMoonsDoThePlanetsHaveAltogether: StringResource
  get() = CommonMainString0.HowManyMoonsDoThePlanetsHaveAltogether

private fun init_HowManyMoonsDoThePlanetsHaveAltogether(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:HowManyMoonsDoThePlanetsHaveAltogether", "HowManyMoonsDoThePlanetsHaveAltogether",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7505, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7689, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7613, 106),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7613, 106),
    )
)

internal val Res.string.HowManyMoonsDoesNPlanetHas: StringResource
  get() = CommonMainString0.HowManyMoonsDoesNPlanetHas

private fun init_HowManyMoonsDoesNPlanetHas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:HowManyMoonsDoesNPlanetHas", "HowManyMoonsDoesNPlanetHas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7608, 98),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7804, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7720, 86),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7720, 86),
    )
)

internal val Res.string.IntMinMax: StringResource
  get() = CommonMainString0.IntMinMax

private fun init_IntMinMax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:IntMinMax", "IntMinMax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7707, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7911, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7807, 49),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7807, 49),
    )
)

internal val Res.string.Integer_or: StringResource
  get() = CommonMainString0.Integer_or

private fun init_Integer_or(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Integer_or", "Integer_or",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7785, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    7957, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7857, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7857, 58),
    )
)

internal val Res.string.IsoscelesTrapezoid: StringResource
  get() = CommonMainString0.IsoscelesTrapezoid

private fun init_IsoscelesTrapezoid(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:IsoscelesTrapezoid", "IsoscelesTrapezoid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7919, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8119, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8019, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8019, 54),
    )
)

internal val Res.string.IsoscelesTrapezoidHelp: StringResource
  get() = CommonMainString0.IsoscelesTrapezoidHelp

private fun init_IsoscelesTrapezoidHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:IsoscelesTrapezoidHelp", "IsoscelesTrapezoidHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7828, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8020, 98),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    7916, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    7916, 102),
    )
)

internal val Res.string.IsoscelesTriangle: StringResource
  get() = CommonMainString0.IsoscelesTriangle

private fun init_IsoscelesTriangle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:IsoscelesTriangle", "IsoscelesTriangle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8132, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8292, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8208, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8208, 65),
    )
)

internal val Res.string.IsoscelesTriangleHelp: StringResource
  get() = CommonMainString0.IsoscelesTriangleHelp

private fun init_IsoscelesTriangleHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:IsoscelesTriangleHelp", "IsoscelesTriangleHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    7978, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8174, 117),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8074, 133),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8074, 133),
    )
)

internal val Res.string.KaTeXFontsize: StringResource
  get() = CommonMainString0.KaTeXFontsize

private fun init_KaTeXFontsize(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KaTeXFontsize", "KaTeXFontsize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8194, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8346, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8274, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8274, 45),
    )
)

internal val Res.string.KaTeXdescription: StringResource
  get() = CommonMainString0.KaTeXdescription

private fun init_KaTeXdescription(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:KaTeXdescription", "KaTeXdescription",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8236, 152),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8400, 128),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8320, 136),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8320, 136),
    )
)

internal val Res.string.Kite: StringResource
  get() = CommonMainString0.Kite

private fun init_Kite(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Kite", "Kite",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8426, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8566, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8498, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8498, 24),
    )
)

internal val Res.string.KiteHelp: StringResource
  get() = CommonMainString0.KiteHelp

private fun init_KiteHelp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KiteHelp", "KiteHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8389, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8529, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8457, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8457, 40),
    )
)

internal val Res.string.KoMal: StringResource
  get() = CommonMainString0.KoMal

private fun init_KoMal(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KoMal", "KoMal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8907, 305),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9031, 277),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8963, 281),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8963, 281),
    )
)

internal val Res.string.KoMal_a: StringResource
  get() = CommonMainString0.KoMal_a

private fun init_KoMal_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KoMal_a", "KoMal_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8451, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8591, 103),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8523, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8523, 91),
    )
)

internal val Res.string.KoMal_b: StringResource
  get() = CommonMainString0.KoMal_b

private fun init_KoMal_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KoMal_b", "KoMal_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8535, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8695, 103),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8615, 111),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8615, 111),
    )
)

internal val Res.string.KoMal_c: StringResource
  get() = CommonMainString0.KoMal_c

private fun init_KoMal_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KoMal_c", "KoMal_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8635, 119),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8799, 107),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8727, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8727, 115),
    )
)

internal val Res.string.KoMal_d: StringResource
  get() = CommonMainString0.KoMal_d

private fun init_KoMal_d(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:KoMal_d", "KoMal_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    8755, 151),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    8907, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    8843, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    8843, 119),
    )
)

internal val Res.string.Kombinatorika_0_1: StringResource
  get() = CommonMainString0.Kombinatorika_0_1

private fun init_Kombinatorika_0_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_0_1", "Kombinatorika_0_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9213, 169),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9309, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    9245, 157),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    9245, 157),
    )
)

internal val Res.string.Kombinatorika_0_2: StringResource
  get() = CommonMainString0.Kombinatorika_0_2

private fun init_Kombinatorika_0_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_0_2", "Kombinatorika_0_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9383, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9471, 209),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    9403, 165),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    9403, 165),
    )
)

internal val Res.string.Kombinatorika_III_1: StringResource
  get() = CommonMainString0.Kombinatorika_III_1

private fun init_Kombinatorika_III_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_III_1", "Kombinatorika_III_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9545, 127),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9681, 115),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    9569, 119),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    9569, 119),
    )
)

internal val Res.string.Kombinatorika_II_1: StringResource
  get() = CommonMainString0.Kombinatorika_II_1

private fun init_Kombinatorika_II_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_II_1", "Kombinatorika_II_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9673, 126),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9797, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    9689, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    9689, 102),
    )
)

internal val Res.string.Kombinatorika_II_2: StringResource
  get() = CommonMainString0.Kombinatorika_II_2

private fun init_Kombinatorika_II_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_II_2", "Kombinatorika_II_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9800, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    9908, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    9792, 210),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    9792, 210),
    )
)

internal val Res.string.Kombinatorika_II_3: StringResource
  get() = CommonMainString0.Kombinatorika_II_3

private fun init_Kombinatorika_II_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_II_3", "Kombinatorika_II_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    9983, 154),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10087, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10003, 146),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10003, 146),
    )
)

internal val Res.string.Kombinatorika_IV_1: StringResource
  get() = CommonMainString0.Kombinatorika_IV_1

private fun init_Kombinatorika_IV_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_IV_1", "Kombinatorika_IV_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10138, 130),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10258, 150),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10150, 114),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10150, 114),
    )
)

internal val Res.string.Kombinatorika_I_1: StringResource
  get() = CommonMainString0.Kombinatorika_I_1

private fun init_Kombinatorika_I_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_I_1", "Kombinatorika_I_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10269, 133),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10409, 125),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10265, 113),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10265, 113),
    )
)

internal val Res.string.Kombinatorika_I_2: StringResource
  get() = CommonMainString0.Kombinatorika_I_2

private fun init_Kombinatorika_I_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_I_2", "Kombinatorika_I_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10403, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10535, 157),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10379, 145),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10379, 145),
    )
)

internal val Res.string.Kombinatorika_I_3: StringResource
  get() = CommonMainString0.Kombinatorika_I_3

private fun init_Kombinatorika_I_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_I_3", "Kombinatorika_I_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10565, 149),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10693, 205),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10525, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10525, 109),
    )
)

internal val Res.string.Kombinatorika_VI_1: StringResource
  get() = CommonMainString0.Kombinatorika_VI_1

private fun init_Kombinatorika_VI_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_VI_1", "Kombinatorika_VI_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10715, 162),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    10899, 162),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10635, 170),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10635, 170),
    )
)

internal val Res.string.Kombinatorika_V_1: StringResource
  get() = CommonMainString0.Kombinatorika_V_1

private fun init_Kombinatorika_V_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Kombinatorika_V_1", "Kombinatorika_V_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    10878, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11062, 173),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10806, 177),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10806, 177),
    )
)

internal val Res.string.Login: StringResource
  get() = CommonMainString0.Login

private fun init_Login(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Login", "Login",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11068, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11236, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    10984, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    10984, 25),
    )
)

internal val Res.string.MathKing: StringResource
  get() = CommonMainString0.MathKing

private fun init_MathKing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:MathKing", "MathKing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12694, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11870, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12522, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12522, 32),
    )
)

internal val Res.string.MathKingDescription: StringResource
  get() = CommonMainString0.MathKingDescription

private fun init_MathKingDescription(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:MathKingDescription", "MathKingDescription",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    11090, 1603),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11258, 611),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    11010, 1511),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    11010, 1511),
    )
)

internal val Res.string.MaxDistanceOfUnits: StringResource
  get() = CommonMainString0.MaxDistanceOfUnits

private fun init_MaxDistanceOfUnits(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:MaxDistanceOfUnits", "MaxDistanceOfUnits",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12727, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11915, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12555, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12555, 82),
    )
)

internal val Res.string.Medium_diff: StringResource
  get() = CommonMainString0.Medium_diff

private fun init_Medium_diff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Medium_diff", "Medium_diff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12802, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    11994, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12638, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12638, 47),
    )
)

internal val Res.string.MennyivelTöbb_2_1: StringResource
  get() = CommonMainString0.MennyivelTöbb_2_1

private fun init_MennyivelTöbb_2_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:MennyivelTöbb_2_1", "MennyivelTöbb_2_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12842, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12054, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12686, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12686, 82),
    )
)

internal val Res.string.Menu: StringResource
  get() = CommonMainString0.Menu

private fun init_Menu(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Menu", "Menu",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12921, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12125, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12769, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12769, 24),
    )
)

internal val Res.string.Mertani_A: StringResource
  get() = CommonMainString0.Mertani_A

private fun init_Mertani_A(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Mertani_A", "Mertani_A",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    12946, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12150, 165),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12794, 125),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12794, 125),
    )
)

internal val Res.string.Mertani_B: StringResource
  get() = CommonMainString0.Mertani_B

private fun init_Mertani_B(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Mertani_B", "Mertani_B",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13108, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12316, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    12920, 157),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    12920, 157),
    )
)

internal val Res.string.Mertani_C: StringResource
  get() = CommonMainString0.Mertani_C

private fun init_Mertani_C(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Mertani_C", "Mertani_C",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13298, 169),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12506, 177),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13078, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13078, 129),
    )
)

internal val Res.string.Molnar: StringResource
  get() = CommonMainString0.Molnar

private fun init_Molnar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Molnar", "Molnar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13468, 270),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12684, 298),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13208, 298),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13208, 298),
    )
)

internal val Res.string.Monday: StringResource
  get() = CommonMainString0.Monday

private fun init_Monday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Monday", "Monday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13739, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    12983, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13507, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13507, 26),
    )
)

internal val Res.string.MostFrequentMark: StringResource
  get() = CommonMainString0.MostFrequentMark

private fun init_MostFrequentMark(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:MostFrequentMark", "MostFrequentMark",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13762, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13006, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13534, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13534, 80),
    )
)

internal val Res.string.Multiplication: StringResource
  get() = CommonMainString0.Multiplication

private fun init_Multiplication(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Multiplication", "Multiplication",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13827, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13103, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13615, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13615, 34),
    )
)

internal val Res.string.NInterval: StringResource
  get() = CommonMainString0.NInterval

private fun init_NInterval(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:NInterval", "NInterval",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13870, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13142, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13650, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13650, 45),
    )
)

internal val Res.string.NewTask: StringResource
  get() = CommonMainString0.NewTask

private fun init_NewTask(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:NewTask", "NewTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13908, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13192, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13696, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13696, 31),
    )
)

internal val Res.string.NthPlanetsMoons: StringResource
  get() = CommonMainString0.NthPlanetsMoons

private fun init_NthPlanetsMoons(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:NthPlanetsMoons", "NthPlanetsMoons",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    13940, 219),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13228, 211),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13728, 219),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13728, 219),
    )
)

internal val Res.string.NumberOfRounds: StringResource
  get() = CommonMainString0.NumberOfRounds

private fun init_NumberOfRounds(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:NumberOfRounds", "NumberOfRounds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14160, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13440, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13948, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13948, 42),
    )
)

internal val Res.string.NumberSystemTaskLog: StringResource
  get() = CommonMainString0.NumberSystemTaskLog

private fun init_NumberSystemTaskLog(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:NumberSystemTaskLog", "NumberSystemTaskLog",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14207, 163),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13499, 163),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    13991, 159),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    13991, 159),
    )
)

internal val Res.string.NumberSystemsTask: StringResource
  get() = CommonMainString0.NumberSystemsTask

private fun init_NumberSystemsTask(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:NumberSystemsTask", "NumberSystemsTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14371, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13663, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14151, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14151, 65),
    )
)

internal val Res.string.OkosFeladatGyűjtemény: StringResource
  get() = CommonMainString0.OkosFeladatGyűjtemény

private fun init_OkosFeladatGyűjtemény(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:OkosFeladatGyűjtemény", "OkosFeladatGyűjtemény",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14433, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13729, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14217, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14217, 63),
    )
)

internal val Res.string.OperationChain: StringResource
  get() = CommonMainString0.OperationChain

private fun init_OperationChain(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:OperationChain", "OperationChain",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14497, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13809, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14281, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14281, 42),
    )
)

internal val Res.string.Operations: StringResource
  get() = CommonMainString0.Operations

private fun init_Operations(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Operations", "Operations",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14544, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13860, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14324, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14324, 34),
    )
)

internal val Res.string.Parallelogram: StringResource
  get() = CommonMainString0.Parallelogram

private fun init_Parallelogram(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Parallelogram", "Parallelogram",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14641, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13961, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14429, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14429, 41),
    )
)

internal val Res.string.ParallelogramHelp: StringResource
  get() = CommonMainString0.ParallelogramHelp

private fun init_ParallelogramHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ParallelogramHelp", "ParallelogramHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14579, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    13895, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14359, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14359, 69),
    )
)

internal val Res.string.Pentagon: StringResource
  get() = CommonMainString0.Pentagon

private fun init_Pentagon(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Pentagon", "Pentagon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14683, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14003, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14471, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14471, 28),
    )
)

internal val Res.string.Percentage_A_1: StringResource
  get() = CommonMainString0.Percentage_A_1

private fun init_Percentage_A_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_A_1", "Percentage_A_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14712, 162),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14036, 206),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14500, 150),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14500, 150),
    )
)

internal val Res.string.Percentage_A_2: StringResource
  get() = CommonMainString0.Percentage_A_2

private fun init_Percentage_A_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_A_2", "Percentage_A_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    14875, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14243, 198),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14651, 174),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14651, 174),
    )
)

internal val Res.string.Percentage_A_3: StringResource
  get() = CommonMainString0.Percentage_A_3

private fun init_Percentage_A_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_A_3", "Percentage_A_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15070, 222),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14442, 210),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    14826, 210),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    14826, 210),
    )
)

internal val Res.string.Percentage_A_4: StringResource
  get() = CommonMainString0.Percentage_A_4

private fun init_Percentage_A_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_A_4", "Percentage_A_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15293, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14653, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15037, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15037, 62),
    )
)

internal val Res.string.Percentage_B_1: StringResource
  get() = CommonMainString0.Percentage_B_1

private fun init_Percentage_B_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_B_1", "Percentage_B_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15364, 238),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14724, 234),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15100, 186),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15100, 186),
    )
)

internal val Res.string.Percentage_B_2: StringResource
  get() = CommonMainString0.Percentage_B_2

private fun init_Percentage_B_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_B_2", "Percentage_B_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15603, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    14959, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15287, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15287, 62),
    )
)

internal val Res.string.Percentage_B_3: StringResource
  get() = CommonMainString0.Percentage_B_3

private fun init_Percentage_B_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_B_3", "Percentage_B_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15678, 270),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15034, 218),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15350, 202),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15350, 202),
    )
)

internal val Res.string.Percentage_C_1: StringResource
  get() = CommonMainString0.Percentage_C_1

private fun init_Percentage_C_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_C_1", "Percentage_C_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    15949, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15253, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15553, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15553, 66),
    )
)

internal val Res.string.Percentage_D_1: StringResource
  get() = CommonMainString0.Percentage_D_1

private fun init_Percentage_D_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_D_1", "Percentage_D_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16020, 190),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15316, 146),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15620, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15620, 166),
    )
)

internal val Res.string.Percentage_E_1: StringResource
  get() = CommonMainString0.Percentage_E_1

private fun init_Percentage_E_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_E_1", "Percentage_E_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16211, 266),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15463, 246),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    15787, 242),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    15787, 242),
    )
)

internal val Res.string.Percentage_F_1: StringResource
  get() = CommonMainString0.Percentage_F_1

private fun init_Percentage_F_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_F_1", "Percentage_F_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16478, 298),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15710, 262),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16030, 274),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16030, 274),
    )
)

internal val Res.string.Percentage_G_1: StringResource
  get() = CommonMainString0.Percentage_G_1

private fun init_Percentage_G_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_G_1", "Percentage_G_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    16777, 234),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    15973, 198),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16305, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16305, 166),
    )
)

internal val Res.string.Percentage_G_2: StringResource
  get() = CommonMainString0.Percentage_G_2

private fun init_Percentage_G_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_G_2", "Percentage_G_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17012, 206),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    16172, 190),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16472, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16472, 158),
    )
)

internal val Res.string.Percentage_G_3: StringResource
  get() = CommonMainString0.Percentage_G_3

private fun init_Percentage_G_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_G_3", "Percentage_G_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17219, 150),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    16363, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16631, 154),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16631, 154),
    )
)

internal val Res.string.Percentage_G_4: StringResource
  get() = CommonMainString0.Percentage_G_4

private fun init_Percentage_G_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_G_4", "Percentage_G_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17370, 166),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    16542, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16786, 174),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16786, 174),
    )
)

internal val Res.string.Percentage_G_5: StringResource
  get() = CommonMainString0.Percentage_G_5

private fun init_Percentage_G_5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_G_5", "Percentage_G_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17537, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    16725, 166),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    16961, 154),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    16961, 154),
    )
)

internal val Res.string.Percentage_H_1: StringResource
  get() = CommonMainString0.Percentage_H_1

private fun init_Percentage_H_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_H_1", "Percentage_H_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17732, 222),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    16892, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17116, 170),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17116, 170),
    )
)

internal val Res.string.Percentage_H_2: StringResource
  get() = CommonMainString0.Percentage_H_2

private fun init_Percentage_H_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_H_2", "Percentage_H_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    17955, 158),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17087, 190),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17287, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17287, 158),
    )
)

internal val Res.string.Percentage_H_3: StringResource
  get() = CommonMainString0.Percentage_H_3

private fun init_Percentage_H_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_H_3", "Percentage_H_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18114, 186),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17278, 158),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17446, 190),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17446, 190),
    )
)

internal val Res.string.Percentage_H_4: StringResource
  get() = CommonMainString0.Percentage_H_4

private fun init_Percentage_H_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_H_4", "Percentage_H_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18301, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17437, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17637, 182),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17637, 182),
    )
)

internal val Res.string.Percentage_H_5: StringResource
  get() = CommonMainString0.Percentage_H_5

private fun init_Percentage_H_5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_H_5", "Percentage_H_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18484, 174),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17620, 150),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17820, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17820, 162),
    )
)

internal val Res.string.Percentage_I_1: StringResource
  get() = CommonMainString0.Percentage_I_1

private fun init_Percentage_I_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_I_1", "Percentage_I_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18659, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17771, 186),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    17983, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    17983, 162),
    )
)

internal val Res.string.Percentage_I_2: StringResource
  get() = CommonMainString0.Percentage_I_2

private fun init_Percentage_I_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_I_2", "Percentage_I_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    18838, 254),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    17958, 210),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    18146, 186),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    18146, 186),
    )
)

internal val Res.string.Percentage_I_3: StringResource
  get() = CommonMainString0.Percentage_I_3

private fun init_Percentage_I_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_I_3", "Percentage_I_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19093, 254),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    18169, 206),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    18333, 194),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    18333, 194),
    )
)

internal val Res.string.Percentage_J_1: StringResource
  get() = CommonMainString0.Percentage_J_1

private fun init_Percentage_J_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_J_1", "Percentage_J_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19348, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    18376, 182),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    18528, 154),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    18528, 154),
    )
)

internal val Res.string.Percentage_J_2: StringResource
  get() = CommonMainString0.Percentage_J_2

private fun init_Percentage_J_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_J_2", "Percentage_J_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19527, 258),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    18559, 214),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    18683, 186),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    18683, 186),
    )
)

internal val Res.string.Percentage_J_3: StringResource
  get() = CommonMainString0.Percentage_J_3

private fun init_Percentage_J_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Percentage_J_3", "Percentage_J_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    19786, 254),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    18774, 202),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    18870, 194),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    18870, 194),
    )
)

internal val Res.string.Periodic_lastDigitApowN: StringResource
  get() = CommonMainString0.Periodic_lastDigitApowN

private fun init_Periodic_lastDigitApowN(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Periodic_lastDigitApowN", "Periodic_lastDigitApowN",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20041, 103),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    18977, 107),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    19065, 103),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    19065, 103),
    )
)

internal val Res.string.PistiFever_a: StringResource
  get() = CommonMainString0.PistiFever_a

private fun init_PistiFever_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:PistiFever_a", "PistiFever_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20145, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19085, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    19169, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    19169, 76),
    )
)

internal val Res.string.PistiFever_b: StringResource
  get() = CommonMainString0.PistiFever_b

private fun init_PistiFever_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:PistiFever_b", "PistiFever_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20210, 96),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19162, 108),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    19246, 264),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    19246, 264),
    )
)

internal val Res.string.PistiFever_c: StringResource
  get() = CommonMainString0.PistiFever_c

private fun init_PistiFever_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:PistiFever_c", "PistiFever_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20307, 196),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19271, 180),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    19511, 196),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    19511, 196),
    )
)

internal val Res.string.Pistifever: StringResource
  get() = CommonMainString0.Pistifever

private fun init_Pistifever(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Pistifever", "Pistifever",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20504, 362),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19452, 314),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    19708, 314),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    19708, 314),
    )
)

internal val Res.string.PiszkosFred: StringResource
  get() = CommonMainString0.PiszkosFred

private fun init_PiszkosFred(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:PiszkosFred", "PiszkosFred",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20023, 295),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20023, 295),
    )
)

internal val Res.string.PracticeSolvingEquationSystems: StringResource
  get() = CommonMainString0.PracticeSolvingEquationSystems

private fun init_PracticeSolvingEquationSystems(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:PracticeSolvingEquationSystems", "PracticeSolvingEquationSystems",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20867, 74),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19767, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20319, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20319, 78),
    )
)

internal val Res.string.PracticingEquations: StringResource
  get() = CommonMainString0.PracticingEquations

private fun init_PracticingEquations(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:PracticingEquations", "PracticingEquations",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    20942, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19850, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20398, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20398, 59),
    )
)

internal val Res.string.PractisingFractions: StringResource
  get() = CommonMainString0.PractisingFractions

private fun init_PractisingFractions(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:PractisingFractions", "PractisingFractions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21002, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19906, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20458, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20458, 55),
    )
)

internal val Res.string.PreviousTasks: StringResource
  get() = CommonMainString0.PreviousTasks

private fun init_PreviousTasks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:PreviousTasks", "PreviousTasks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21062, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    19962, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20514, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20514, 45),
    )
)

internal val Res.string.Proportionality1: StringResource
  get() = CommonMainString0.Proportionality1

private fun init_Proportionality1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Proportionality1", "Proportionality1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21104, 168),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20008, 188),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20560, 136),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20560, 136),
    )
)

internal val Res.string.Proportionality2: StringResource
  get() = CommonMainString0.Proportionality2

private fun init_Proportionality2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Proportionality2", "Proportionality2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21273, 156),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20197, 172),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20697, 140),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20697, 140),
    )
)

internal val Res.string.Proportionality3: StringResource
  get() = CommonMainString0.Proportionality3

private fun init_Proportionality3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Proportionality3", "Proportionality3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21430, 196),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20370, 256),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    20838, 172),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    20838, 172),
    )
)

internal val Res.string.Proportionality4: StringResource
  get() = CommonMainString0.Proportionality4

private fun init_Proportionality4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Proportionality4", "Proportionality4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21627, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20627, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21011, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21011, 128),
    )
)

internal val Res.string.Proportionality5: StringResource
  get() = CommonMainString0.Proportionality5

private fun init_Proportionality5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Proportionality5", "Proportionality5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21772, 132),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20752, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21140, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21140, 128),
    )
)

internal val Res.string.RangeOfNumbersToBeConverted: StringResource
  get() = CommonMainString0.RangeOfNumbersToBeConverted

private fun init_RangeOfNumbersToBeConverted(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:RangeOfNumbersToBeConverted", "RangeOfNumbersToBeConverted",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21905, 91),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20877, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21269, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21269, 79),
    )
)

internal val Res.string.Rblue: StringResource
  get() = CommonMainString0.Rblue

private fun init_Rblue(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rblue", "Rblue",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    21997, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    20957, 89),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21349, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21349, 65),
    )
)

internal val Res.string.ReadTheCoordinatesOfThePoint: StringResource
  get() = CommonMainString0.ReadTheCoordinatesOfThePoint

private fun init_ReadTheCoordinatesOfThePoint(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:ReadTheCoordinatesOfThePoint", "ReadTheCoordinatesOfThePoint",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22059, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21047, 80),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21415, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21415, 80),
    )
)

internal val Res.string.RecommendedModifiers: StringResource
  get() = CommonMainString0.RecommendedModifiers

private fun init_RecommendedModifiers(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:RecommendedModifiers", "RecommendedModifiers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22148, 116),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21128, 136),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21496, 128),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21496, 128),
    )
)

internal val Res.string.Rectangle: StringResource
  get() = CommonMainString0.Rectangle

private fun init_Rectangle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rectangle", "Rectangle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22323, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21327, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21687, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21687, 29),
    )
)

internal val Res.string.RectangleHelp: StringResource
  get() = CommonMainString0.RectangleHelp

private fun init_RectangleHelp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:RectangleHelp", "RectangleHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22265, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21265, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21625, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21625, 61),
    )
)

internal val Res.string.Red: StringResource
  get() = CommonMainString0.Red

private fun init_Red(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Red", "Red",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22353, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21361, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21717, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21717, 19),
    )
)

internal val Res.string.Rename: StringResource
  get() = CommonMainString0.Rename

private fun init_Rename(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rename", "Rename",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22369, 58),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21381, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21737, 70),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21737, 70),
    )
)

internal val Res.string.Response: StringResource
  get() = CommonMainString0.Response

private fun init_Response(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Response", "Response",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22428, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21476, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21808, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21808, 28),
    )
)

internal val Res.string.Rhombus: StringResource
  get() = CommonMainString0.Rhombus

private fun init_Rhombus(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rhombus", "Rhombus",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22509, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21565, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21893, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21893, 27),
    )
)

internal val Res.string.RhombusHelp: StringResource
  get() = CommonMainString0.RhombusHelp

private fun init_RhombusHelp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:RhombusHelp", "RhombusHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22457, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21509, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21837, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21837, 55),
    )
)

internal val Res.string.RightAngledTriangle: StringResource
  get() = CommonMainString0.RightAngledTriangle

private fun init_RightAngledTriangle(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:RightAngledTriangle", "RightAngledTriangle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22677, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21725, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22045, 63),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22045, 63),
    )
)

internal val Res.string.RightAngledTriangleHelp: StringResource
  get() = CommonMainString0.RightAngledTriangleHelp

private fun init_RightAngledTriangleHelp(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:RightAngledTriangleHelp", "RightAngledTriangleHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22537, 139),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21589, 135),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    21921, 123),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    21921, 123),
    )
)

internal val Res.string.Roman: StringResource
  get() = CommonMainString0.Roman

private fun init_Roman(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Roman", "Roman",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22787, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21831, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22151, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22151, 21),
    )
)

internal val Res.string.RomanToArabic: StringResource
  get() = CommonMainString0.RomanToArabic

private fun init_RomanToArabic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:RomanToArabic", "RomanToArabic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22737, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21785, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22109, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22109, 41),
    )
)

internal val Res.string.Rounds: StringResource
  get() = CommonMainString0.Rounds

private fun init_Rounds(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rounds", "Rounds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22813, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21857, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22173, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22173, 34),
    )
)

internal val Res.string.Rred: StringResource
  get() = CommonMainString0.Rred

private fun init_Rred(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Rred", "Rred",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22852, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21908, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22208, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22208, 64),
    )
)

internal val Res.string.Saturday: StringResource
  get() = CommonMainString0.Saturday

private fun init_Saturday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Saturday", "Saturday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22913, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    21997, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22273, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22273, 28),
    )
)

internal val Res.string.SavePicturesLocation: StringResource
  get() = CommonMainString0.SavePicturesLocation

private fun init_SavePicturesLocation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:SavePicturesLocation", "SavePicturesLocation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    22942, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22026, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22302, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22302, 72),
    )
)

internal val Res.string.SetPicSize: StringResource
  get() = CommonMainString0.SetPicSize

private fun init_SetPicSize(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:SetPicSize", "SetPicSize",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23003, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22095, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22375, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22375, 62),
    )
)

internal val Res.string.SetPicturesLocation: StringResource
  get() = CommonMainString0.SetPicturesLocation

private fun init_SetPicturesLocation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:SetPicturesLocation", "SetPicturesLocation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23054, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22158, 71),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22438, 79),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22438, 79),
    )
)

internal val Res.string.Solution: StringResource
  get() = CommonMainString0.Solution

private fun init_Solution(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Solution", "Solution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23102, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22230, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22518, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22518, 28),
    )
)

internal val Res.string.Square: StringResource
  get() = CommonMainString0.Square

private fun init_Square(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Square", "Square",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23182, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22314, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22606, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22606, 26),
    )
)

internal val Res.string.SquareHelp: StringResource
  get() = CommonMainString0.SquareHelp

private fun init_SquareHelp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:SquareHelp", "SquareHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23131, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22259, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22547, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22547, 58),
    )
)

internal val Res.string.Subtraction: StringResource
  get() = CommonMainString0.Subtraction

private fun init_Subtraction(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Subtraction", "Subtraction",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23209, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22341, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22633, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22633, 31),
    )
)

internal val Res.string.Sunday: StringResource
  get() = CommonMainString0.Sunday

private fun init_Sunday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Sunday", "Sunday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23245, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22369, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22665, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22665, 26),
    )
)

internal val Res.string.Szamtani_A: StringResource
  get() = CommonMainString0.Szamtani_A

private fun init_Szamtani_A(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Szamtani_A", "Szamtani_A",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23272, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22392, 174),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22692, 134),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22692, 134),
    )
)

internal val Res.string.Szamtani_B: StringResource
  get() = CommonMainString0.Szamtani_B

private fun init_Szamtani_B(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Szamtani_B", "Szamtani_B",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23443, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22567, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22827, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22827, 162),
    )
)

internal val Res.string.Szamtani_C: StringResource
  get() = CommonMainString0.Szamtani_C

private fun init_Szamtani_C(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Szamtani_C", "Szamtani_C",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23622, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22762, 178),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    22990, 130),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    22990, 130),
    )
)

internal val Res.string.Szamtani_D: StringResource
  get() = CommonMainString0.Szamtani_D

private fun init_Szamtani_D(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Szamtani_D", "Szamtani_D",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23793, 190),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    22941, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23121, 162),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23121, 162),
    )
)

internal val Res.string.Thursday: StringResource
  get() = CommonMainString0.Thursday

private fun init_Thursday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Thursday", "Thursday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    23984, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23136, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23284, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23284, 32),
    )
)

internal val Res.string.Tie: StringResource
  get() = CommonMainString0.Tie

private fun init_Tie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Tie", "Tie",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24017, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23165, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23317, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23317, 27),
    )
)

internal val Res.string.TimeBetweenTasks: StringResource
  get() = CommonMainString0.TimeBetweenTasks

private fun init_TimeBetweenTasks(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:TimeBetweenTasks", "TimeBetweenTasks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24049, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23185, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23345, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23345, 60),
    )
)

internal val Res.string.Timeofmeasurments: StringResource
  get() = CommonMainString0.Timeofmeasurments

private fun init_Timeofmeasurments(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Timeofmeasurments", "Timeofmeasurments",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24110, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23242, 61),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23406, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23406, 57),
    )
)

internal val Res.string.Totalnumberofcallsduringtheweek: StringResource
  get() = CommonMainString0.Totalnumberofcallsduringtheweek

private fun init_Totalnumberofcallsduringtheweek(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:Totalnumberofcallsduringtheweek", "Totalnumberofcallsduringtheweek",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24176, 123),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23304, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23464, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23464, 95),
    )
)

internal val Res.string.Trapezoid: StringResource
  get() = CommonMainString0.Trapezoid

private fun init_Trapezoid(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Trapezoid", "Trapezoid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24346, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23442, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23606, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23606, 29),
    )
)

internal val Res.string.TrapezoidHelp: StringResource
  get() = CommonMainString0.TrapezoidHelp

private fun init_TrapezoidHelp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:TrapezoidHelp", "TrapezoidHelp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24300, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23400, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23560, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23560, 45),
    )
)

internal val Res.string.Tuesday: StringResource
  get() = CommonMainString0.Tuesday

private fun init_Tuesday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Tuesday", "Tuesday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24372, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23472, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23636, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23636, 23),
    )
)

internal val Res.string.Variableminmax: StringResource
  get() = CommonMainString0.Variableminmax

private fun init_Variableminmax(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Variableminmax", "Variableminmax",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24400, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23500, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23660, 54),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23660, 54),
    )
)

internal val Res.string.VeryHard_diff: StringResource
  get() = CommonMainString0.VeryHard_diff

private fun init_VeryHard_diff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:VeryHard_diff", "VeryHard_diff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24447, 41),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23547, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23715, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23715, 53),
    )
)

internal val Res.string.Wednesday: StringResource
  get() = CommonMainString0.Wednesday

private fun init_Wednesday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:Wednesday", "Wednesday",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24489, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23613, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23769, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23769, 25),
    )
)

internal val Res.string.WrongSolution: StringResource
  get() = CommonMainString0.WrongSolution

private fun init_WrongSolution(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:WrongSolution", "WrongSolution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24719, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23779, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23923, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23923, 53),
    )
)

internal val Res.string.WrongSolutionEquationSystem: StringResource
  get() = CommonMainString0.WrongSolutionEquationSystem

private fun init_WrongSolutionEquationSystem(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:WrongSolutionEquationSystem", "WrongSolutionEquationSystem",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24519, 115),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23643, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23795, 87),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23795, 87),
    )
)

internal val Res.string.WrongSolutionWP: StringResource
  get() = CommonMainString0.WrongSolutionWP

private fun init_WrongSolutionWP(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:WrongSolutionWP", "WrongSolutionWP",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24635, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23739, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23883, 39),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23883, 39),
    )
)

internal val Res.string.YouClickThis: StringResource
  get() = CommonMainString0.YouClickThis

private fun init_YouClickThis(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:YouClickThis", "YouClickThis",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24805, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23829, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    23977, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    23977, 48),
    )
)

internal val Res.string._2_tagsz_m: StringResource
  get() = CommonMainString0._2_tagsz_m

private fun init__2_tagsz_m(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_2_tagsz_m", "_2_tagsz_m",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24854, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23874, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24026, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24026, 34),
    )
)

internal val Res.string._3_tagsz_m: StringResource
  get() = CommonMainString0._3_tagsz_m

private fun init__3_tagsz_m(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_3_tagsz_m", "_3_tagsz_m",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24885, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23909, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24061, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24061, 34),
    )
)

internal val Res.string._4_tagsz_m: StringResource
  get() = CommonMainString0._4_tagsz_m

private fun init__4_tagsz_m(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_4_tagsz_m", "_4_tagsz_m",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24916, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23944, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24096, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24096, 34),
    )
)

internal val Res.string._5_tagsz_m: StringResource
  get() = CommonMainString0._5_tagsz_m

private fun init__5_tagsz_m(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:_5_tagsz_m", "_5_tagsz_m",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24947, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    23979, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24131, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24131, 34),
    )
)

internal val Res.string.about: StringResource
  get() = CommonMainString0.about

private fun init_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about", "about",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    24978, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24014, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24166, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24166, 25),
    )
)

internal val Res.string.above30: StringResource
  get() = CommonMainString0.above30

private fun init_above30(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:above30", "above30",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25000, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24052, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24192, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24192, 51),
    )
)

internal val Res.string.agegroups: StringResource
  get() = CommonMainString0.agegroups

private fun init_agegroups(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:agegroups", "agegroups",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25044, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24096, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24244, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24244, 37),
    )
)

internal val Res.string.agota_question_1: StringResource
  get() = CommonMainString0.agota_question_1

private fun init_agota_question_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:agota_question_1", "agota_question_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24282, 204),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24282, 204),
    )
)

internal val Res.string.agota_question_2: StringResource
  get() = CommonMainString0.agota_question_2

private fun init_agota_question_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:agota_question_2", "agota_question_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24487, 192),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24487, 192),
    )
)

internal val Res.string.agota_question_3: StringResource
  get() = CommonMainString0.agota_question_3

private fun init_agota_question_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:agota_question_3", "agota_question_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24680, 200),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24680, 200),
    )
)

internal val Res.string.agota_question_4: StringResource
  get() = CommonMainString0.agota_question_4

private fun init_agota_question_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:agota_question_4", "agota_question_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    24881, 268),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    24881, 268),
    )
)

internal val Res.string.alapmuveletekMaradék: StringResource
  get() = CommonMainString0.alapmuveletekMaradék

private fun init_alapmuveletekMaradék(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:alapmuveletekMaradék", "alapmuveletekMaradék",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25082, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24130, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25150, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25150, 41),
    )
)

internal val Res.string.am7: StringResource
  get() = CommonMainString0.am7

private fun init_am7(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:am7", "am7",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25136, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24168, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25192, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25192, 31),
    )
)

internal val Res.string.angle_estimation: StringResource
  get() = CommonMainString0.angle_estimation

private fun init_angle_estimation(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:angle_estimation", "angle_estimation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25168, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24188, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25224, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25224, 44),
    )
)

internal val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25233, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24237, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25269, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25269, 28),
    )
)

internal val Res.string.apple: StringResource
  get() = CommonMainString0.apple

private fun init_apple(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:apple", "apple",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25262, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24266, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25298, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25298, 21),
    )
)

internal val Res.string.aquarium_fish: StringResource
  get() = CommonMainString0.aquarium_fish

private fun init_aquarium_fish(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:aquarium_fish", "aquarium_fish",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25320, 213),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25320, 213),
    )
)

internal val Res.string.area: StringResource
  get() = CommonMainString0.area

private fun init_area(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:area", "area",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25284, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24288, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25534, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25534, 24),
    )
)

internal val Res.string.arithmetical: StringResource
  get() = CommonMainString0.arithmetical

private fun init_arithmetical(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:arithmetical", "arithmetical",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25305, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24309, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25559, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25559, 32),
    )
)

internal val Res.string.atvaltasTask: StringResource
  get() = CommonMainString0.atvaltasTask

private fun init_atvaltasTask(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:atvaltasTask", "atvaltasTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25342, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24346, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25592, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25592, 48),
    )
)

internal val Res.string.autogyar: StringResource
  get() = CommonMainString0.autogyar

private fun init_autogyar(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:autogyar", "autogyar",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25872, 316),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24800, 228),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26106, 244),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26106, 244),
    )
)

internal val Res.string.autogyar_a: StringResource
  get() = CommonMainString0.autogyar_a

private fun init_autogyar_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:autogyar_a", "autogyar_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25403, 226),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24403, 190),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25641, 202),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25641, 202),
    )
)

internal val Res.string.autogyar_b: StringResource
  get() = CommonMainString0.autogyar_b

private fun init_autogyar_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:autogyar_b", "autogyar_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25630, 118),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24594, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25844, 146),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25844, 146),
    )
)

internal val Res.string.autogyar_c: StringResource
  get() = CommonMainString0.autogyar_c

private fun init_autogyar_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:autogyar_c", "autogyar_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    25749, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    24697, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    25991, 114),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    25991, 114),
    )
)

internal val Res.string.baseNumber: StringResource
  get() = CommonMainString0.baseNumber

private fun init_baseNumber(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:baseNumber", "baseNumber",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26189, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25029, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26351, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26351, 30),
    )
)

internal val Res.string.basket_nuts: StringResource
  get() = CommonMainString0.basket_nuts

private fun init_basket_nuts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:basket_nuts", "basket_nuts",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26382, 203),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26382, 203),
    )
)

internal val Res.string.beginner1: StringResource
  get() = CommonMainString0.beginner1

private fun init_beginner1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:beginner1", "beginner1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26220, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25060, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26586, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26586, 29),
    )
)

internal val Res.string.beginner2: StringResource
  get() = CommonMainString0.beginner2

private fun init_beginner2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:beginner2", "beginner2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26254, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25090, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26616, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26616, 29),
    )
)

internal val Res.string.bekuldoszam: StringResource
  get() = CommonMainString0.bekuldoszam

private fun init_bekuldoszam(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:bekuldoszam", "bekuldoszam",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26288, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25120, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26646, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26646, 43),
    )
)

internal val Res.string.between15_30: StringResource
  get() = CommonMainString0.between15_30

private fun init_between15_30(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:between15_30", "between15_30",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26336, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25164, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26690, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26690, 44),
    )
)

internal val Res.string.biologists: StringResource
  get() = CommonMainString0.biologists

private fun init_biologists(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:biologists", "biologists",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    26735, 482),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    26735, 482),
    )
)

internal val Res.string.bluewontheround: StringResource
  get() = CommonMainString0.bluewontheround

private fun init_bluewontheround(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:bluewontheround", "bluewontheround",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26385, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25205, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27218, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27218, 51),
    )
)

internal val Res.string.bodyTemperature: StringResource
  get() = CommonMainString0.bodyTemperature

private fun init_bodyTemperature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:bodyTemperature", "bodyTemperature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26441, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25273, 55),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27270, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27270, 55),
    )
)

internal val Res.string.bokszolo_question: StringResource
  get() = CommonMainString0.bokszolo_question

private fun init_bokszolo_question(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:bokszolo_question", "bokszolo_question",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27326, 197),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27326, 197),
    )
)

internal val Res.string.boys: StringResource
  get() = CommonMainString0.boys

private fun init_boys(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:boys", "boys",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26497, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25329, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27524, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27524, 28),
    )
)

internal val Res.string.car_parking: StringResource
  get() = CommonMainString0.car_parking

private fun init_car_parking(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:car_parking", "car_parking",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27553, 239),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27553, 239),
    )
)

internal val Res.string.chairs: StringResource
  get() = CommonMainString0.chairs

private fun init_chairs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:chairs", "chairs",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26518, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25362, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27793, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27793, 26),
    )
)

internal val Res.string.chocolate_distribution: StringResource
  get() = CommonMainString0.chocolate_distribution

private fun init_chocolate_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:chocolate_distribution", "chocolate_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    27820, 198),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    27820, 198),
    )
)

internal val Res.string.cinderella_beans: StringResource
  get() = CommonMainString0.cinderella_beans

private fun init_cinderella_beans(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:cinderella_beans", "cinderella_beans",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28019, 280),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28019, 280),
    )
)

internal val Res.string.circus_performers: StringResource
  get() = CommonMainString0.circus_performers

private fun init_circus_performers(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:circus_performers", "circus_performers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28300, 165),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28300, 165),
    )
)

internal val Res.string.classA: StringResource
  get() = CommonMainString0.classA

private fun init_classA(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:classA", "classA",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26545, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25385, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28466, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28466, 30),
    )
)

internal val Res.string.classB: StringResource
  get() = CommonMainString0.classB

private fun init_classB(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:classB", "classB",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26572, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25412, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28497, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28497, 30),
    )
)

internal val Res.string.close: StringResource
  get() = CommonMainString0.close

private fun init_close(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:close", "close",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26599, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25439, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28528, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28528, 21),
    )
)

internal val Res.string.combinatorics: StringResource
  get() = CommonMainString0.combinatorics

private fun init_combinatorics(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:combinatorics", "combinatorics",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26629, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25461, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28550, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28550, 41),
    )
)

internal val Res.string.conversionFromDecimal: StringResource
  get() = CommonMainString0.conversionFromDecimal

private fun init_conversionFromDecimal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:conversionFromDecimal", "conversionFromDecimal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26667, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25499, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28592, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28592, 77),
    )
)

internal val Res.string.conversionToDecimal: StringResource
  get() = CommonMainString0.conversionToDecimal

private fun init_conversionToDecimal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:conversionToDecimal", "conversionToDecimal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26737, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25577, 75),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28670, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28670, 75),
    )
)

internal val Res.string.correctAngle: StringResource
  get() = CommonMainString0.correctAngle

private fun init_correctAngle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:correctAngle", "correctAngle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26801, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25653, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28746, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28746, 52),
    )
)

internal val Res.string.costume: StringResource
  get() = CommonMainString0.costume

private fun init_costume(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:costume", "costume",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26846, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25710, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28799, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28799, 27),
    )
)

internal val Res.string.csokik: StringResource
  get() = CommonMainString0.csokik

private fun init_csokik(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:csokik", "csokik",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28827, 166),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28827, 166),
    )
)

internal val Res.string.cube_1: StringResource
  get() = CommonMainString0.cube_1

private fun init_cube_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cube_1", "cube_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26870, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25738, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    28994, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    28994, 110),
    )
)

internal val Res.string.cube_2: StringResource
  get() = CommonMainString0.cube_2

private fun init_cube_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cube_2", "cube_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    26993, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25861, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29105, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29105, 102),
    )
)

internal val Res.string.cubeoid_1: StringResource
  get() = CommonMainString0.cubeoid_1

private fun init_cubeoid_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_1", "cubeoid_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27116, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    25964, 145),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29208, 153),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29208, 153),
    )
)

internal val Res.string.cubeoid_2: StringResource
  get() = CommonMainString0.cubeoid_2

private fun init_cubeoid_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_2", "cubeoid_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27270, 145),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26110, 125),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29362, 145),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29362, 145),
    )
)

internal val Res.string.cubeoid_3: StringResource
  get() = CommonMainString0.cubeoid_3

private fun init_cubeoid_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_3", "cubeoid_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27416, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26236, 197),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29508, 165),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29508, 165),
    )
)

internal val Res.string.cubeoid_4: StringResource
  get() = CommonMainString0.cubeoid_4

private fun init_cubeoid_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_4", "cubeoid_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27610, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26434, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29674, 161),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29674, 161),
    )
)

internal val Res.string.cubeoid_5: StringResource
  get() = CommonMainString0.cubeoid_5

private fun init_cubeoid_5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_5", "cubeoid_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27800, 193),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26596, 197),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    29836, 169),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    29836, 169),
    )
)

internal val Res.string.cubeoid_6: StringResource
  get() = CommonMainString0.cubeoid_6

private fun init_cubeoid_6(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_6", "cubeoid_6",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    27994, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26794, 177),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30006, 169),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30006, 169),
    )
)

internal val Res.string.cubeoid_7: StringResource
  get() = CommonMainString0.cubeoid_7

private fun init_cubeoid_7(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_7", "cubeoid_7",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28184, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    26972, 197),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30176, 165),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30176, 165),
    )
)

internal val Res.string.cubeoid_8: StringResource
  get() = CommonMainString0.cubeoid_8

private fun init_cubeoid_8(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cubeoid_8", "cubeoid_8",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28374, 189),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27170, 161),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30342, 161),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30342, 161),
    )
)

internal val Res.string.customrange: StringResource
  get() = CommonMainString0.customrange

private fun init_customrange(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:customrange", "customrange",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28564, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27332, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30504, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30504, 47),
    )
)

internal val Res.string.day: StringResource
  get() = CommonMainString0.day

private fun init_day(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:day", "day",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28653, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27389, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30573, 15),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30573, 15),
    )
)

internal val Res.string.days: StringResource
  get() = CommonMainString0.days

private fun init_days(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:days", "days",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28632, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27368, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30552, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30552, 20),
    )
)

internal val Res.string.defineTime: StringResource
  get() = CommonMainString0.defineTime

private fun init_defineTime(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:defineTime", "defineTime",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28669, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27405, 94),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30589, 62),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30589, 62),
    )
)

internal val Res.string.denominator: StringResource
  get() = CommonMainString0.denominator

private fun init_denominator(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:denominator", "denominator",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28732, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27500, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30652, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30652, 31),
    )
)

internal val Res.string.description: StringResource
  get() = CommonMainString0.description

private fun init_description(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:description", "description",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28764, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27536, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30684, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30684, 31),
    )
)

internal val Res.string.difficulty: StringResource
  get() = CommonMainString0.difficulty

private fun init_difficulty(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:difficulty", "difficulty",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28864, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27644, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30788, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30788, 34),
    )
)

internal val Res.string.difficulty2: StringResource
  get() = CommonMainString0.difficulty2

private fun init_difficulty2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:difficulty2", "difficulty2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28800, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27572, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30716, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30716, 35),
    )
)

internal val Res.string.difficulty3: StringResource
  get() = CommonMainString0.difficulty3

private fun init_difficulty3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:difficulty3", "difficulty3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28832, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27608, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30752, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30752, 35),
    )
)

internal val Res.string.dinosaur_park: StringResource
  get() = CommonMainString0.dinosaur_park

private fun init_dinosaur_park(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dinosaur_park", "dinosaur_park",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    30823, 181),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    30823, 181),
    )
)

internal val Res.string.dog_treat_distribution: StringResource
  get() = CommonMainString0.dog_treat_distribution

private fun init_dog_treat_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dog_treat_distribution", "dog_treat_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31005, 290),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31005, 290),
    )
)

internal val Res.string.dolgozat: StringResource
  get() = CommonMainString0.dolgozat

private fun init_dolgozat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dolgozat", "dolgozat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    28895, 180),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27679, 136),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31296, 152),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31296, 152),
    )
)

internal val Res.string.done: StringResource
  get() = CommonMainString0.done

private fun init_done(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:done", "done",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29076, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27816, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31449, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31449, 20),
    )
)

internal val Res.string.drawAngle: StringResource
  get() = CommonMainString0.drawAngle

private fun init_drawAngle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drawAngle", "drawAngle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29283, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28003, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31596, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31596, 37),
    )
)

internal val Res.string.drawAngleDiff: StringResource
  get() = CommonMainString0.drawAngleDiff

private fun init_drawAngleDiff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drawAngleDiff", "drawAngleDiff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29097, 185),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    27837, 165),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31470, 125),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31470, 125),
    )
)

internal val Res.string.drawTask: StringResource
  get() = CommonMainString0.drawTask

private fun init_drawTask(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drawTask", "drawTask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29321, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28041, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31634, 60),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31634, 60),
    )
)

internal val Res.string.drone: StringResource
  get() = CommonMainString0.drone

private fun init_drone(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drone", "drone",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29746, 137),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28462, 133),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32075, 153),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32075, 153),
    )
)

internal val Res.string.drone_a: StringResource
  get() = CommonMainString0.drone_a

private fun init_drone_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drone_a", "drone_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29382, 111),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28102, 115),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31695, 103),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31695, 103),
    )
)

internal val Res.string.drone_b: StringResource
  get() = CommonMainString0.drone_b

private fun init_drone_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drone_b", "drone_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29494, 91),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28218, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31799, 107),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31799, 107),
    )
)

internal val Res.string.drone_c: StringResource
  get() = CommonMainString0.drone_c

private fun init_drone_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:drone_c", "drone_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29586, 159),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28318, 143),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    31907, 167),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    31907, 167),
    )
)

internal val Res.string.easy: StringResource
  get() = CommonMainString0.easy

private fun init_easy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:easy", "easy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29884, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28596, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32229, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32229, 24),
    )
)

internal val Res.string.ecologist: StringResource
  get() = CommonMainString0.ecologist

private fun init_ecologist(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ecologist", "ecologist",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32254, 381),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32254, 381),
    )
)

internal val Res.string.emptyrange: StringResource
  get() = CommonMainString0.emptyrange

private fun init_emptyrange(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:emptyrange", "emptyrange",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29905, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28617, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32636, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32636, 46),
    )
)

internal val Res.string.emptytermsRange: StringResource
  get() = CommonMainString0.emptytermsRange

private fun init_emptytermsRange(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:emptytermsRange", "emptytermsRange",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    29956, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28684, 87),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32683, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32683, 75),
    )
)

internal val Res.string.enter_your_teacher_s_email: StringResource
  get() = CommonMainString0.enter_your_teacher_s_email

private fun init_enter_your_teacher_s_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_teacher_s_email", "enter_your_teacher_s_email",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    30020, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28772, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32759, 82),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32759, 82),
    )
)

internal val Res.string.equationDescription: StringResource
  get() = CommonMainString0.equationDescription

private fun init_equationDescription(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:equationDescription", "equationDescription",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    30111, 727),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    28843, 411),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    32842, 711),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    32842, 711),
    )
)

internal val Res.string.equilateralTriangle_1: StringResource
  get() = CommonMainString0.equilateralTriangle_1

private fun init_equilateralTriangle_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:equilateralTriangle_1", "equilateralTriangle_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    30839, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29255, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    33554, 129),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    33554, 129),
    )
)

internal val Res.string.equilateralTriangle_2: StringResource
  get() = CommonMainString0.equilateralTriangle_2

private fun init_equilateralTriangle_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:equilateralTriangle_2", "equilateralTriangle_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    30993, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29409, 141),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    33684, 141),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    33684, 141),
    )
)

internal val Res.string.ermedobas: StringResource
  get() = CommonMainString0.ermedobas

private fun init_ermedobas(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobas", "ermedobas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31677, 329),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30061, 309),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34328, 365),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34328, 365),
    )
)

internal val Res.string.ermedobasBottom: StringResource
  get() = CommonMainString0.ermedobasBottom

private fun init_ermedobasBottom(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobasBottom", "ermedobasBottom",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31147, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29551, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    33826, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    33826, 59),
    )
)

internal val Res.string.ermedobasLeft: StringResource
  get() = CommonMainString0.ermedobasLeft

private fun init_ermedobasLeft(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobasLeft", "ermedobasLeft",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31207, 97),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29611, 89),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    33886, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    33886, 85),
    )
)

internal val Res.string.ermedobas_a: StringResource
  get() = CommonMainString0.ermedobas_a

private fun init_ermedobas_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobas_a", "ermedobas_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31305, 67),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29701, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    33972, 91),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    33972, 91),
    )
)

internal val Res.string.ermedobas_b: StringResource
  get() = CommonMainString0.ermedobas_b

private fun init_ermedobas_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobas_b", "ermedobas_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31373, 107),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29785, 91),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34064, 107),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34064, 107),
    )
)

internal val Res.string.ermedobas_c: StringResource
  get() = CommonMainString0.ermedobas_c

private fun init_ermedobas_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobas_c", "ermedobas_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31481, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29877, 87),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34172, 67),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34172, 67),
    )
)

internal val Res.string.ermedobas_d: StringResource
  get() = CommonMainString0.ermedobas_d

private fun init_ermedobas_d(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ermedobas_d", "ermedobas_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    31577, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    29965, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34240, 87),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34240, 87),
    )
)

internal val Res.string.exponentiation: StringResource
  get() = CommonMainString0.exponentiation

private fun init_exponentiation(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:exponentiation", "exponentiation",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32007, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30371, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34694, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34694, 42),
    )
)

internal val Res.string.findPrimeFactorsfor: StringResource
  get() = CommonMainString0.findPrimeFactorsfor

private fun init_findPrimeFactorsfor(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:findPrimeFactorsfor", "findPrimeFactorsfor",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32050, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30414, 95),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34737, 115),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34737, 115),
    )
)

internal val Res.string.find_teacher_by_email: StringResource
  get() = CommonMainString0.find_teacher_by_email

private fun init_find_teacher_by_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:find_teacher_by_email", "find_teacher_by_email",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32130, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30510, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34853, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34853, 57),
    )
)

internal val Res.string.flower_distribution: StringResource
  get() = CommonMainString0.flower_distribution

private fun init_flower_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:flower_distribution", "flower_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    34911, 231),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    34911, 231),
    )
)

internal val Res.string.fodrasz_hajvagas: StringResource
  get() = CommonMainString0.fodrasz_hajvagas

private fun init_fodrasz_hajvagas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fodrasz_hajvagas", "fodrasz_hajvagas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35143, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35143, 160),
    )
)

internal val Res.string.fractions1: StringResource
  get() = CommonMainString0.fractions1

private fun init_fractions1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fractions1", "fractions1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32196, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30568, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35304, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35304, 30),
    )
)

internal val Res.string.fractions2: StringResource
  get() = CommonMainString0.fractions2

private fun init_fractions2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fractions2", "fractions2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32235, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30603, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35335, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35335, 30),
    )
)

internal val Res.string.fractions3: StringResource
  get() = CommonMainString0.fractions3

private fun init_fractions3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fractions3", "fractions3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32274, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30638, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35366, 30),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35366, 30),
    )
)

internal val Res.string.from: StringResource
  get() = CommonMainString0.from

private fun init_from(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:from", "from",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32313, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30673, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35397, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35397, 20),
    )
)

internal val Res.string.fruitSurvey: StringResource
  get() = CommonMainString0.fruitSurvey

private fun init_fruitSurvey(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey", "fruitSurvey",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32982, 347),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31298, 295),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    36102, 343),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    36102, 343),
    )
)

internal val Res.string.fruitSurvey_a: StringResource
  get() = CommonMainString0.fruitSurvey_a

private fun init_fruitSurvey_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey_a", "fruitSurvey_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32330, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30694, 141),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35418, 157),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35418, 157),
    )
)

internal val Res.string.fruitSurvey_b: StringResource
  get() = CommonMainString0.fruitSurvey_b

private fun init_fruitSurvey_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey_b", "fruitSurvey_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32698, 141),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31054, 113),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35846, 137),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35846, 137),
    )
)

internal val Res.string.fruitSurvey_b1: StringResource
  get() = CommonMainString0.fruitSurvey_b1

private fun init_fruitSurvey_b1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey_b1", "fruitSurvey_b1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32436, 138),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30836, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35576, 130),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35576, 130),
    )
)

internal val Res.string.fruitSurvey_b2: StringResource
  get() = CommonMainString0.fruitSurvey_b2

private fun init_fruitSurvey_b2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey_b2", "fruitSurvey_b2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32575, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    30939, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35707, 138),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35707, 138),
    )
)

internal val Res.string.fruitSurvey_c: StringResource
  get() = CommonMainString0.fruitSurvey_c

private fun init_fruitSurvey_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruitSurvey_c", "fruitSurvey_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    32840, 141),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31168, 129),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    35984, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    35984, 117),
    )
)

internal val Res.string.fruit_basket: StringResource
  get() = CommonMainString0.fruit_basket

private fun init_fruit_basket(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fruit_basket", "fruit_basket",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    36446, 212),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    36446, 212),
    )
)

internal val Res.string.fruit_distribution: StringResource
  get() = CommonMainString0.fruit_distribution

private fun init_fruit_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fruit_distribution", "fruit_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    36659, 210),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    36659, 210),
    )
)

internal val Res.string.funSlope: StringResource
  get() = CommonMainString0.funSlope

private fun init_funSlope(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:funSlope", "funSlope",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33330, 72),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31594, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    36870, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    36870, 64),
    )
)

internal val Res.string.futar_csomagszallitas: StringResource
  get() = CommonMainString0.futar_csomagszallitas

private fun init_futar_csomagszallitas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:futar_csomagszallitas", "futar_csomagszallitas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    36935, 161),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    36935, 161),
    )
)

internal val Res.string.games: StringResource
  get() = CommonMainString0.games

private fun init_games(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:games", "games",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33403, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31655, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37097, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37097, 25),
    )
)

internal val Res.string.garden_flowers: StringResource
  get() = CommonMainString0.garden_flowers

private fun init_garden_flowers(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:garden_flowers", "garden_flowers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37123, 206),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37123, 206),
    )
)

internal val Res.string.gasPrice: StringResource
  get() = CommonMainString0.gasPrice

private fun init_gasPrice(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gasPrice", "gasPrice",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34025, 112),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32249, 124),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37910, 144),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37910, 144),
    )
)

internal val Res.string.gasPrice_a: StringResource
  get() = CommonMainString0.gasPrice_a

private fun init_gasPrice_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gasPrice_a", "gasPrice_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33425, 110),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31677, 106),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37330, 102),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37330, 102),
    )
)

internal val Res.string.gasPrice_b: StringResource
  get() = CommonMainString0.gasPrice_b

private fun init_gasPrice_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gasPrice_b", "gasPrice_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33536, 134),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31784, 122),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37433, 114),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37433, 114),
    )
)

internal val Res.string.gasPrice_c: StringResource
  get() = CommonMainString0.gasPrice_c

private fun init_gasPrice_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gasPrice_c", "gasPrice_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33671, 134),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    31907, 142),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37548, 142),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37548, 142),
    )
)

internal val Res.string.gasPrice_d: StringResource
  get() = CommonMainString0.gasPrice_d

private fun init_gasPrice_d(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gasPrice_d", "gasPrice_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    33806, 218),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32050, 198),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    37691, 218),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    37691, 218),
    )
)

internal val Res.string.geometrical: StringResource
  get() = CommonMainString0.geometrical

private fun init_geometrical(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:geometrical", "geometrical",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34138, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32374, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38055, 31),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38055, 31),
    )
)

internal val Res.string.geometry: StringResource
  get() = CommonMainString0.geometry

private fun init_geometry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:geometry", "geometry",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34174, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32406, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38087, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38087, 28),
    )
)

internal val Res.string.gep_gyartas: StringResource
  get() = CommonMainString0.gep_gyartas

private fun init_gep_gyartas(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:gep_gyartas", "gep_gyartas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38116, 171),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38116, 171),
    )
)

internal val Res.string.girls: StringResource
  get() = CommonMainString0.girls

private fun init_girls(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:girls", "girls",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34203, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32439, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38288, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38288, 33),
    )
)

internal val Res.string.guessAngle: StringResource
  get() = CommonMainString0.guessAngle

private fun init_guessAngle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guessAngle", "guessAngle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34423, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32679, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38512, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38512, 38),
    )
)

internal val Res.string.guessAngleDiff: StringResource
  get() = CommonMainString0.guessAngleDiff

private fun init_guessAngleDiff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guessAngleDiff", "guessAngleDiff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34229, 146),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32473, 134),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38322, 130),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38322, 130),
    )
)

internal val Res.string.guessAngleResponse: StringResource
  get() = CommonMainString0.guessAngleResponse

private fun init_guessAngleResponse(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:guessAngleResponse", "guessAngleResponse",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34376, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32608, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38453, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38453, 58),
    )
)

internal val Res.string.guesstask: StringResource
  get() = CommonMainString0.guesstask

private fun init_guesstask(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:guesstask", "guesstask",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34466, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32722, 89),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38551, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38551, 61),
    )
)

internal val Res.string.hallgatok: StringResource
  get() = CommonMainString0.hallgatok

private fun init_hallgatok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hallgatok", "hallgatok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38613, 205),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38613, 205),
    )
)

internal val Res.string.hard: StringResource
  get() = CommonMainString0.hard

private fun init_hard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hard", "hard",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34516, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32812, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38819, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38819, 20),
    )
)

internal val Res.string.hary_janos: StringResource
  get() = CommonMainString0.hary_janos

private fun init_hary_janos(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hary_janos", "hary_janos",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    38840, 318),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    38840, 318),
    )
)

internal val Res.string.height: StringResource
  get() = CommonMainString0.height

private fun init_height(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:height", "height",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34541, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32837, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39159, 34),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39159, 34),
    )
)

internal val Res.string.helyettesitesiErtek: StringResource
  get() = CommonMainString0.helyettesitesiErtek

private fun init_helyettesitesiErtek(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:helyettesitesiErtek", "helyettesitesiErtek",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34568, 103),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32868, 99),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39194, 99),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39194, 99),
    )
)

internal val Res.string.hideshow: StringResource
  get() = CommonMainString0.hideshow

private fun init_hideshow(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hideshow", "hideshow",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34672, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    32968, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39294, 72),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39294, 72),
    )
)

internal val Res.string.hours: StringResource
  get() = CommonMainString0.hours

private fun init_hours(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hours", "hours",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34713, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33029, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39367, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39367, 21),
    )
)

internal val Res.string.howmuch: StringResource
  get() = CommonMainString0.howmuch

private fun init_howmuch(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:howmuch", "howmuch",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34739, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33051, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39389, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39389, 23),
    )
)

internal val Res.string.identity: StringResource
  get() = CommonMainString0.identity

private fun init_identity(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:identity", "identity",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34783, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33083, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39413, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39413, 32),
    )
)

internal val Res.string.igen: StringResource
  get() = CommonMainString0.igen

private fun init_igen(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:igen", "igen",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34812, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33112, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39446, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39446, 20),
    )
)

internal val Res.string.instagram_friends: StringResource
  get() = CommonMainString0.instagram_friends

private fun init_instagram_friends(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:instagram_friends", "instagram_friends",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39467, 229),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39467, 229),
    )
)

internal val Res.string.integer: StringResource
  get() = CommonMainString0.integer

private fun init_integer(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:integer", "integer",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34829, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33129, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39697, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39697, 23),
    )
)

internal val Res.string.isoscelesTriangle_1: StringResource
  get() = CommonMainString0.isoscelesTriangle_1

private fun init_isoscelesTriangle_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:isoscelesTriangle_1", "isoscelesTriangle_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34861, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33153, 223),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39721, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39721, 27),
    )
)

internal val Res.string.isoscelesTriangle_2: StringResource
  get() = CommonMainString0.isoscelesTriangle_2

private fun init_isoscelesTriangle_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:isoscelesTriangle_2", "isoscelesTriangle_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34889, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33377, 191),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39749, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39749, 27),
    )
)

internal val Res.string.isoscelesTriangle_3: StringResource
  get() = CommonMainString0.isoscelesTriangle_3

private fun init_isoscelesTriangle_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:isoscelesTriangle_3", "isoscelesTriangle_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34917, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33569, 215),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39777, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39777, 27),
    )
)

internal val Res.string.isoscelesTriangle_4: StringResource
  get() = CommonMainString0.isoscelesTriangle_4

private fun init_isoscelesTriangle_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:isoscelesTriangle_4", "isoscelesTriangle_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34945, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33785, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39805, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39805, 27),
    )
)

internal val Res.string.jozsiba_question_1: StringResource
  get() = CommonMainString0.jozsiba_question_1

private fun init_jozsiba_question_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:jozsiba_question_1", "jozsiba_question_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    39833, 286),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    39833, 286),
    )
)

internal val Res.string.jozsiba_question_2: StringResource
  get() = CommonMainString0.jozsiba_question_2

private fun init_jozsiba_question_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:jozsiba_question_2", "jozsiba_question_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40120, 238),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40120, 238),
    )
)

internal val Res.string.justWrong: StringResource
  get() = CommonMainString0.justWrong

private fun init_justWrong(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:justWrong", "justWrong",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    34973, 37),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33813, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40359, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40359, 37),
    )
)

internal val Res.string.karcsi: StringResource
  get() = CommonMainString0.karcsi

private fun init_karcsi(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:karcsi", "karcsi",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35011, 302),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    33859, 306),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40397, 258),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40397, 258),
    )
)

internal val Res.string.kite_1: StringResource
  get() = CommonMainString0.kite_1

private fun init_kite_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:kite_1", "kite_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35314, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34166, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40656, 14),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40656, 14),
    )
)

internal val Res.string.kite_2: StringResource
  get() = CommonMainString0.kite_2

private fun init_kite_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:kite_2", "kite_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35329, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34181, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40671, 14),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40671, 14),
    )
)

internal val Res.string.konk_v_n_gysz_g: StringResource
  get() = CommonMainString0.konk_v_n_gysz_g

private fun init_konk_v_n_gysz_g(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:konk_v_n_gysz_g", "konk_v_n_gysz_g",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35344, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34196, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40686, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40686, 47),
    )
)

internal val Res.string.konkav_otszog: StringResource
  get() = CommonMainString0.konkav_otszog

private fun init_konkav_otszog(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:konkav_otszog", "konkav_otszog",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35392, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34244, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40734, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40734, 45),
    )
)

internal val Res.string.konyvkoto_kotes: StringResource
  get() = CommonMainString0.konyvkoto_kotes

private fun init_konyvkoto_kotes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:konyvkoto_kotes", "konyvkoto_kotes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40780, 151),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40780, 151),
    )
)

internal val Res.string.koppinstráFeladat: StringResource
  get() = CommonMainString0.koppinstráFeladat

private fun init_koppinstráFeladat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:koppinstráFeladat", "koppinstráFeladat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35438, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34290, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40932, 58),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40932, 58),
    )
)

internal val Res.string.kutyaeledel: StringResource
  get() = CommonMainString0.kutyaeledel

private fun init_kutyaeledel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:kutyaeledel", "kutyaeledel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    40991, 259),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    40991, 259),
    )
)

internal val Res.string.labor: StringResource
  get() = CommonMainString0.labor

private fun init_labor(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:labor", "labor",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41251, 213),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41251, 213),
    )
)

internal val Res.string.lastCorrects: StringResource
  get() = CommonMainString0.lastCorrects

private fun init_lastCorrects(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:lastCorrects", "lastCorrects",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35501, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34353, 100),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41465, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41465, 80),
    )
)

internal val Res.string.learningTime: StringResource
  get() = CommonMainString0.learningTime

private fun init_learningTime(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:learningTime", "learningTime",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35594, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34454, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41546, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41546, 64),
    )
)

internal val Res.string.legfeljebb_egy_konyv_nyaron: StringResource
  get() = CommonMainString0.legfeljebb_egy_konyv_nyaron

private fun init_legfeljebb_egy_konyv_nyaron(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:legfeljebb_egy_konyv_nyaron", "legfeljebb_egy_konyv_nyaron",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35659, 159),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34515, 143),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41611, 155),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41611, 155),
    )
)

internal val Res.string.length: StringResource
  get() = CommonMainString0.length

private fun init_length(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:length", "length",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35819, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34659, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41767, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41767, 22),
    )
)

internal val Res.string.level: StringResource
  get() = CommonMainString0.level

private fun init_level(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:level", "level",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35842, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34686, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41790, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41790, 29),
    )
)

internal val Res.string.library: StringResource
  get() = CommonMainString0.library

private fun init_library(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:library", "library",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    41820, 287),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    41820, 287),
    )
)

internal val Res.string.liquidMeasure: StringResource
  get() = CommonMainString0.liquidMeasure

private fun init_liquidMeasure(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:liquidMeasure", "liquidMeasure",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35872, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34716, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42108, 37),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42108, 37),
    )
)

internal val Res.string.logintxt: StringResource
  get() = CommonMainString0.logintxt

private fun init_logintxt(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logintxt", "logintxt",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35906, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34750, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42146, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42146, 56),
    )
)

internal val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35963, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34795, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42203, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42203, 26),
    )
)

internal val Res.string.macskaeledel: StringResource
  get() = CommonMainString0.macskaeledel

private fun init_macskaeledel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:macskaeledel", "macskaeledel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42230, 264),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42230, 264),
    )
)

internal val Res.string.mark: StringResource
  get() = CommonMainString0.mark

private fun init_mark(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mark", "mark",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36035, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34863, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42536, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42536, 28),
    )
)

internal val Res.string.markThePoint: StringResource
  get() = CommonMainString0.markThePoint

private fun init_markThePoint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:markThePoint", "markThePoint",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    35990, 44),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34822, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42495, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42495, 40),
    )
)

internal val Res.string.mass: StringResource
  get() = CommonMainString0.mass

private fun init_mass(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mass", "mass",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36056, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34884, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42565, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42565, 20),
    )
)

internal val Res.string.matekverseny: StringResource
  get() = CommonMainString0.matekverseny

private fun init_matekverseny(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matekverseny", "matekverseny",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36648, 528),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    35420, 448),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    43085, 508),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    43085, 508),
    )
)

internal val Res.string.matekverseny_a: StringResource
  get() = CommonMainString0.matekverseny_a

private fun init_matekverseny_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matekverseny_a", "matekverseny_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36077, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34905, 70),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42586, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42586, 78),
    )
)

internal val Res.string.matekverseny_b: StringResource
  get() = CommonMainString0.matekverseny_b

private fun init_matekverseny_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matekverseny_b", "matekverseny_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36148, 138),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    34976, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42665, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42665, 110),
    )
)

internal val Res.string.matekverseny_c: StringResource
  get() = CommonMainString0.matekverseny_c

private fun init_matekverseny_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:matekverseny_c", "matekverseny_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36287, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    35091, 146),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42776, 134),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42776, 134),
    )
)

internal val Res.string.matekversenybottomlabel: StringResource
  get() = CommonMainString0.matekversenybottomlabel

private fun init_matekversenybottomlabel(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matekversenybottomlabel", "matekversenybottomlabel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36458, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    35238, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42911, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42911, 59),
    )
)

internal val Res.string.matekversenylabelleft: StringResource
  get() = CommonMainString0.matekversenylabelleft

private fun init_matekversenylabelleft(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matekversenylabelleft", "matekversenylabelleft",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    36510, 137),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    35298, 121),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    42971, 113),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    42971, 113),
    )
)

internal val Res.string.matematikadolgozat: StringResource
  get() = CommonMainString0.matematikadolgozat

private fun init_matematikadolgozat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matematikadolgozat", "matematikadolgozat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    37913, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36481, 170),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44294, 194),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44294, 194),
    )
)

internal val Res.string.matematikadolgozat_a: StringResource
  get() = CommonMainString0.matematikadolgozat_a

private fun init_matematikadolgozat_a(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matematikadolgozat_a", "matematikadolgozat_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    37177, 236),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    35869, 156),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    43594, 172),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    43594, 172),
    )
)

internal val Res.string.matematikadolgozat_b: StringResource
  get() = CommonMainString0.matematikadolgozat_b

private fun init_matematikadolgozat_b(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matematikadolgozat_b", "matematikadolgozat_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    37414, 188),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36026, 168),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    43767, 200),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    43767, 200),
    )
)

internal val Res.string.matematikadolgozat_c: StringResource
  get() = CommonMainString0.matematikadolgozat_c

private fun init_matematikadolgozat_c(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matematikadolgozat_c", "matematikadolgozat_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    37603, 156),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36195, 140),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    43968, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    43968, 160),
    )
)

internal val Res.string.matematikadolgozat_d: StringResource
  get() = CommonMainString0.matematikadolgozat_d

private fun init_matematikadolgozat_d(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:matematikadolgozat_d", "matematikadolgozat_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    37760, 152),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36336, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44129, 164),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44129, 164),
    )
)

internal val Res.string.max: StringResource
  get() = CommonMainString0.max

private fun init_max(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:max", "max",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44489, 15),
    )
)

internal val Res.string.medium: StringResource
  get() = CommonMainString0.medium

private fun init_medium(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:medium", "medium",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38108, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36652, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44489, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44505, 26),
    )
)

internal val Res.string.megoldokulcs: StringResource
  get() = CommonMainString0.megoldokulcs

private fun init_megoldokulcs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:megoldokulcs", "megoldokulcs",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38131, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36679, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44516, 40),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44532, 40),
    )
)

internal val Res.string.menu_order_distribution: StringResource
  get() = CommonMainString0.menu_order_distribution

private fun init_menu_order_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:menu_order_distribution", "menu_order_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44557, 251),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44573, 251),
    )
)

internal val Res.string.menyasszonyiauto: StringResource
  get() = CommonMainString0.menyasszonyiauto

private fun init_menyasszonyiauto(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:menyasszonyiauto", "menyasszonyiauto",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    44809, 276),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    44825, 276),
    )
)

internal val Res.string.menyasszonyitorta: StringResource
  get() = CommonMainString0.menyasszonyitorta

private fun init_menyasszonyitorta(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:menyasszonyitorta", "menyasszonyitorta",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45086, 277),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45102, 277),
    )
)

internal val Res.string.min: StringResource
  get() = CommonMainString0.min

private fun init_min(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:min", "min",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45380, 15),
    )
)

internal val Res.string.mixedFractions: StringResource
  get() = CommonMainString0.mixedFractions

private fun init_mixedFractions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:mixedFractions", "mixedFractions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38164, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36712, 42),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45364, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45396, 42),
    )
)

internal val Res.string.month: StringResource
  get() = CommonMainString0.month

private fun init_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month", "month",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38227, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36755, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45407, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45439, 21),
    )
)

internal val Res.string.nagymama_himezes: StringResource
  get() = CommonMainString0.nagymama_himezes

private fun init_nagymama_himezes(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:nagymama_himezes", "nagymama_himezes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45429, 160),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45461, 160),
    )
)

internal val Res.string.nem_alatta: StringResource
  get() = CommonMainString0.nem_alatta

private fun init_nem_alatta(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nem_alatta", "nem_alatta",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38249, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36777, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45590, 38),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45622, 38),
    )
)

internal val Res.string.nem_folotte: StringResource
  get() = CommonMainString0.nem_folotte

private fun init_nem_folotte(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nem_folotte", "nem_folotte",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38300, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36816, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45629, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45661, 43),
    )
)

internal val Res.string.no: StringResource
  get() = CommonMainString0.no

private fun init_no(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no", "no",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38502, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37002, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45831, 14),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45863, 14),
    )
)

internal val Res.string.noOperator: StringResource
  get() = CommonMainString0.noOperator

private fun init_noOperator(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:noOperator", "noOperator",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38352, 82),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36856, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45673, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45705, 78),
    )
)

internal val Res.string.noType: StringResource
  get() = CommonMainString0.noType

private fun init_noType(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:noType", "noType",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38435, 66),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    36923, 78),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45752, 78),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45784, 78),
    )
)

internal val Res.string.numberOfDays: StringResource
  get() = CommonMainString0.numberOfDays

private fun init_numberOfDays(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:numberOfDays", "numberOfDays",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38521, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37017, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45846, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45878, 36),
    )
)

internal val Res.string.numberofcalls: StringResource
  get() = CommonMainString0.numberofcalls

private fun init_numberofcalls(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:numberofcalls", "numberofcalls",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38562, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37058, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45883, 45),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45915, 45),
    )
)

internal val Res.string.numberofmoons: StringResource
  get() = CommonMainString0.numberofmoons

private fun init_numberofmoons(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:numberofmoons", "numberofmoons",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38608, 45),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37104, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45929, 41),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    45961, 41),
    )
)

internal val Res.string.numberofstudents: StringResource
  get() = CommonMainString0.numberofstudents

private fun init_numberofstudents(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:numberofstudents", "numberofstudents",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38654, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37154, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    45971, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46003, 44),
    )
)

internal val Res.string.numerator: StringResource
  get() = CommonMainString0.numerator

private fun init_numerator(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:numerator", "numerator",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38707, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37203, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46016, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46048, 33),
    )
)

internal val Res.string.nyulak: StringResource
  get() = CommonMainString0.nyulak

private fun init_nyulak(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:nyulak", "nyulak",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46050, 182),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46082, 182),
    )
)

internal val Res.string.onWhichDayDidTheMostCustomersCall: StringResource
  get() = CommonMainString0.onWhichDayDidTheMostCustomersCall

private fun init_onWhichDayDidTheMostCustomersCall(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onWhichDayDidTheMostCustomersCall", "onWhichDayDidTheMostCustomersCall",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38737, 109),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37233, 109),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46233, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46265, 149),
    )
)

internal val Res.string.`open`: StringResource
  get() = CommonMainString0.`open`

private fun init_open(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:open", "open",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38847, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37343, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46383, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46415, 24),
    )
)

internal val Res.string.otosok: StringResource
  get() = CommonMainString0.otosok

private fun init_otosok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otosok", "otosok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39384, 286),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37800, 242),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46864, 230),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46896, 230),
    )
)

internal val Res.string.otosokBottom: StringResource
  get() = CommonMainString0.otosokBottom

private fun init_otosokBottom(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otosokBottom", "otosokBottom",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38872, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37364, 76),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46408, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46440, 68),
    )
)

internal val Res.string.otosok_a: StringResource
  get() = CommonMainString0.otosok_a

private fun init_otosok_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otosok_a", "otosok_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    38961, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37441, 128),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46477, 140),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46509, 140),
    )
)

internal val Res.string.otosok_b: StringResource
  get() = CommonMainString0.otosok_b

private fun init_otosok_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otosok_b", "otosok_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39106, 164),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37570, 144),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46618, 164),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46650, 164),
    )
)

internal val Res.string.otosok_c: StringResource
  get() = CommonMainString0.otosok_c

private fun init_otosok_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:otosok_c", "otosok_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39271, 112),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    37715, 84),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    46783, 80),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    46815, 80),
    )
)

internal val Res.string.paralellogram_1: StringResource
  get() = CommonMainString0.paralellogram_1

private fun init_paralellogram_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paralellogram_1", "paralellogram_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39671, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38043, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47095, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47127, 23),
    )
)

internal val Res.string.paralellogram_2: StringResource
  get() = CommonMainString0.paralellogram_2

private fun init_paralellogram_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paralellogram_2", "paralellogram_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39695, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38067, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47119, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47151, 23),
    )
)

internal val Res.string.paralellogram_3: StringResource
  get() = CommonMainString0.paralellogram_3

private fun init_paralellogram_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:paralellogram_3", "paralellogram_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39719, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38091, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47143, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47175, 23),
    )
)

internal val Res.string.parentheses1: StringResource
  get() = CommonMainString0.parentheses1

private fun init_parentheses1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:parentheses1", "parentheses1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39743, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38115, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47167, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47199, 36),
    )
)

internal val Res.string.parentheses2: StringResource
  get() = CommonMainString0.parentheses2

private fun init_parentheses2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:parentheses2", "parentheses2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39780, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38156, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47204, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47236, 36),
    )
)

internal val Res.string.peach: StringResource
  get() = CommonMainString0.peach

private fun init_peach(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:peach", "peach",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39817, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38197, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47241, 21),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47273, 21),
    )
)

internal val Res.string.pear: StringResource
  get() = CommonMainString0.pear

private fun init_pear(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pear", "pear",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39843, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38219, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47263, 20),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47295, 20),
    )
)

internal val Res.string.people: StringResource
  get() = CommonMainString0.people

private fun init_people(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:people", "people",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39864, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38240, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47284, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47316, 18),
    )
)

internal val Res.string.percentage: StringResource
  get() = CommonMainString0.percentage

private fun init_percentage(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:percentage", "percentage",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40012, 38),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38388, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47416, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47448, 50),
    )
)

internal val Res.string.percentageofallenquirers: StringResource
  get() = CommonMainString0.percentageofallenquirers

private fun init_percentageofallenquirers(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:percentageofallenquirers", "percentageofallenquirers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    39891, 120),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38267, 120),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47303, 112),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47335, 112),
    )
)

internal val Res.string.periodic: StringResource
  get() = CommonMainString0.periodic

private fun init_periodic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:periodic", "periodic",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40051, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38443, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47467, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47499, 32),
    )
)

internal val Res.string.pieces: StringResource
  get() = CommonMainString0.pieces

private fun init_pieces(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pieces", "pieces",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40084, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38472, 22),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47500, 22),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47532, 22),
    )
)

internal val Res.string.pirate_treasures: StringResource
  get() = CommonMainString0.pirate_treasures

private fun init_pirate_treasures(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:pirate_treasures", "pirate_treasures",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47523, 200),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47555, 200),
    )
)

internal val Res.string.planetindex: StringResource
  get() = CommonMainString0.planetindex

private fun init_planetindex(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:planetindex", "planetindex",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40107, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38495, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47724, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47756, 47),
    )
)

internal val Res.string.planets: StringResource
  get() = CommonMainString0.planets

private fun init_planets(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:planets", "planets",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40155, 199),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38559, 175),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    47772, 295),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    47804, 295),
    )
)

internal val Res.string.play: StringResource
  get() = CommonMainString0.play

private fun init_play(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:play", "play",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40355, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38735, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48068, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48100, 24),
    )
)

internal val Res.string.pm2: StringResource
  get() = CommonMainString0.pm2

private fun init_pm2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pm2", "pm2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40376, 35),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38756, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48093, 35),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48125, 35),
    )
)

internal val Res.string.potion_brewing: StringResource
  get() = CommonMainString0.potion_brewing

private fun init_potion_brewing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:potion_brewing", "potion_brewing",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48129, 198),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48161, 198),
    )
)

internal val Res.string.practiceRomanNumerals: StringResource
  get() = CommonMainString0.practiceRomanNumerals

private fun init_practiceRomanNumerals(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:practiceRomanNumerals", "practiceRomanNumerals",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40412, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38776, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48328, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48360, 65),
    )
)

internal val Res.string.price: StringResource
  get() = CommonMainString0.price

private fun init_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:price", "price",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40482, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38842, 33),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48394, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48426, 33),
    )
)

internal val Res.string.primefactors: StringResource
  get() = CommonMainString0.primefactors

private fun init_primefactors(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:primefactors", "primefactors",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40516, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38876, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48428, 68),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48460, 68),
    )
)

internal val Res.string.programozo_kodiras: StringResource
  get() = CommonMainString0.programozo_kodiras

private fun init_programozo_kodiras(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:programozo_kodiras", "programozo_kodiras",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48497, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48529, 158),
    )
)

internal val Res.string.proportionality: StringResource
  get() = CommonMainString0.proportionality

private fun init_proportionality(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:proportionality", "proportionality",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40569, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    38945, 63),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48656, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48688, 59),
    )
)

internal val Res.string.questions: StringResource
  get() = CommonMainString0.questions

private fun init_questions(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:questions", "questions",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40629, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39009, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48716, 33),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48748, 33),
    )
)

internal val Res.string.rabbit_distribution: StringResource
  get() = CommonMainString0.rabbit_distribution

private fun init_rabbit_distribution(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rabbit_distribution", "rabbit_distribution",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48750, 215),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48782, 215),
    )
)

internal val Res.string.rajtaVanePQ: StringResource
  get() = CommonMainString0.rajtaVanePQ

private fun init_rajtaVanePQ(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rajtaVanePQ", "rajtaVanePQ",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40655, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39039, 83),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    48966, 83),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    48998, 83),
    )
)

internal val Res.string.rajtavane: StringResource
  get() = CommonMainString0.rajtavane

private fun init_rajtavane(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rajtavane", "rajtavane",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40739, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39123, 81),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49050, 81),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49082, 81),
    )
)

internal val Res.string.readPoint: StringResource
  get() = CommonMainString0.readPoint

private fun init_readPoint(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:readPoint", "readPoint",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40825, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39205, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49132, 61),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49164, 61),
    )
)

internal val Res.string.readbooks: StringResource
  get() = CommonMainString0.readbooks

private fun init_readbooks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:readbooks", "readbooks",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40891, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39263, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49194, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49226, 53),
    )
)

internal val Res.string.rectangle_1: StringResource
  get() = CommonMainString0.rectangle_1

private fun init_rectangle_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_1", "rectangle_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40949, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39317, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49248, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49280, 19),
    )
)

internal val Res.string.rectangle_2: StringResource
  get() = CommonMainString0.rectangle_2

private fun init_rectangle_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_2", "rectangle_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40969, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39337, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49268, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49300, 19),
    )
)

internal val Res.string.rectangle_3: StringResource
  get() = CommonMainString0.rectangle_3

private fun init_rectangle_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_3", "rectangle_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    40989, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39357, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49288, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49320, 19),
    )
)

internal val Res.string.rectangle_4: StringResource
  get() = CommonMainString0.rectangle_4

private fun init_rectangle_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_4", "rectangle_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41009, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39377, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49308, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49340, 19),
    )
)

internal val Res.string.rectangle_5: StringResource
  get() = CommonMainString0.rectangle_5

private fun init_rectangle_5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_5", "rectangle_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41029, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39397, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49328, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49360, 19),
    )
)

internal val Res.string.rectangle_6: StringResource
  get() = CommonMainString0.rectangle_6

private fun init_rectangle_6(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rectangle_6", "rectangle_6",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41049, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39417, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49348, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49380, 19),
    )
)

internal val Res.string.redwontheround: StringResource
  get() = CommonMainString0.redwontheround

private fun init_redwontheround(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:redwontheround", "redwontheround",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41069, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39437, 62),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49368, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49400, 50),
    )
)

internal val Res.string.rhombus_1: StringResource
  get() = CommonMainString0.rhombus_1

private fun init_rhombus_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rhombus_1", "rhombus_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41120, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39500, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49419, 17),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49451, 17),
    )
)

internal val Res.string.rhombus_2: StringResource
  get() = CommonMainString0.rhombus_2

private fun init_rhombus_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rhombus_2", "rhombus_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41138, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39518, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49437, 17),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49469, 17),
    )
)

internal val Res.string.rhombus_3: StringResource
  get() = CommonMainString0.rhombus_3

private fun init_rhombus_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rhombus_3", "rhombus_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41156, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39536, 17),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49455, 17),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49487, 17),
    )
)

internal val Res.string.rightAngledTriangle_1: StringResource
  get() = CommonMainString0.rightAngledTriangle_1

private fun init_rightAngledTriangle_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rightAngledTriangle_1", "rightAngledTriangle_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41174, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39554, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49473, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49505, 29),
    )
)

internal val Res.string.rightAngledTriangle_2: StringResource
  get() = CommonMainString0.rightAngledTriangle_2

private fun init_rightAngledTriangle_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rightAngledTriangle_2", "rightAngledTriangle_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41204, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39584, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49503, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49535, 29),
    )
)

internal val Res.string.rightAngledTriangle_3: StringResource
  get() = CommonMainString0.rightAngledTriangle_3

private fun init_rightAngledTriangle_3(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rightAngledTriangle_3", "rightAngledTriangle_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41234, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39614, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49533, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49565, 29),
    )
)

internal val Res.string.rightAngledTriangle_4: StringResource
  get() = CommonMainString0.rightAngledTriangle_4

private fun init_rightAngledTriangle_4(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rightAngledTriangle_4", "rightAngledTriangle_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41264, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39644, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49563, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49595, 29),
    )
)

internal val Res.string.rightAngledTriangle_5: StringResource
  get() = CommonMainString0.rightAngledTriangle_5

private fun init_rightAngledTriangle_5(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:rightAngledTriangle_5", "rightAngledTriangle_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41294, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39674, 29),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49593, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49625, 29),
    )
)

internal val Res.string.robot_furat: StringResource
  get() = CommonMainString0.robot_furat

private fun init_robot_furat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:robot_furat", "robot_furat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49623, 187),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49655, 187),
    )
)

internal val Res.string.rows: StringResource
  get() = CommonMainString0.rows

private fun init_rows(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:rows", "rows",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41324, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39704, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49811, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49843, 32),
    )
)

internal val Res.string.seconds: StringResource
  get() = CommonMainString0.seconds

private fun init_seconds(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:seconds", "seconds",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41365, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39741, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49844, 23),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49876, 23),
    )
)

internal val Res.string.select_your_class: StringResource
  get() = CommonMainString0.select_your_class

private fun init_select_your_class(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_your_class", "select_your_class",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41389, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39761, 49),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49868, 65),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49900, 65),
    )
)

internal val Res.string.slope: StringResource
  get() = CommonMainString0.slope

private fun init_slope(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:slope", "slope",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41447, 25),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39811, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49934, 29),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49966, 29),
    )
)

internal val Res.string.square_1: StringResource
  get() = CommonMainString0.square_1

private fun init_square_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_1", "square_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41473, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39833, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49964, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    49996, 16),
    )
)

internal val Res.string.square_2: StringResource
  get() = CommonMainString0.square_2

private fun init_square_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_2", "square_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41490, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39850, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49981, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50013, 16),
    )
)

internal val Res.string.square_3: StringResource
  get() = CommonMainString0.square_3

private fun init_square_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_3", "square_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41507, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39867, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    49998, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50030, 16),
    )
)

internal val Res.string.square_4: StringResource
  get() = CommonMainString0.square_4

private fun init_square_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_4", "square_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41524, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39884, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50015, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50047, 16),
    )
)

internal val Res.string.square_5: StringResource
  get() = CommonMainString0.square_5

private fun init_square_5(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_5", "square_5",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41541, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39901, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50032, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50064, 16),
    )
)

internal val Res.string.square_6: StringResource
  get() = CommonMainString0.square_6

private fun init_square_6(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:square_6", "square_6",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41558, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39918, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50049, 16),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50081, 16),
    )
)

internal val Res.string.strokeWidth: StringResource
  get() = CommonMainString0.strokeWidth

private fun init_strokeWidth(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:strokeWidth", "strokeWidth",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41575, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39935, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50066, 43),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50098, 43),
    )
)

internal val Res.string.studentlogin: StringResource
  get() = CommonMainString0.studentlogin

private fun init_studentlogin(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:studentlogin", "studentlogin",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41619, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    39979, 48),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50110, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50142, 48),
    )
)

internal val Res.string.suit: StringResource
  get() = CommonMainString0.suit

private fun init_suit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:suit", "suit",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41672, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40028, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50159, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50191, 24),
    )
)

internal val Res.string.szamuraj_question_1: StringResource
  get() = CommonMainString0.szamuraj_question_1

private fun init_szamuraj_question_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:szamuraj_question_1", "szamuraj_question_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50184, 231),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50216, 231),
    )
)

internal val Res.string.szamuraj_question_2: StringResource
  get() = CommonMainString0.szamuraj_question_2

private fun init_szamuraj_question_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:szamuraj_question_2", "szamuraj_question_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50416, 95),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50448, 95),
    )
)

internal val Res.string.szazalek_round: StringResource
  get() = CommonMainString0.szazalek_round

private fun init_szazalek_round(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:szazalek_round", "szazalek_round",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50512, 126),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50544, 126),
    )
)

internal val Res.string.szogGameDiff: StringResource
  get() = CommonMainString0.szogGameDiff

private fun init_szogGameDiff(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:szogGameDiff", "szogGameDiff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41693, 60),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40049, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50639, 48),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50671, 48),
    )
)
