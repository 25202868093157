package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import matekit.matekit.utilityfunctions.CE
import matekit.matekit.utilityfunctions.randomNotZero
import matekit.ui.backButton
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.easy
import matekit_multiplatform.composeapp.generated.resources.medium
import matekit_multiplatform.composeapp.generated.resources.megoldokulcs
import org.jetbrains.compose.resources.stringResource
//import java.math.BigDecimal
import kotlin.math.absoluteValue
import kotlin.random.Random.Default.nextBoolean

class AlgebraiAzonossagokDolgozat(val TAG: String = "AAD", val navController: NavHostController) {

        @Composable
        fun UI()
        {

            /* var feladatok = remember {
                 mutableStateListOf<Task>()
             }*/

            var solutionsShown = rememberSaveable {
                mutableStateOf(false)
            }

            var feladat = rememberSaveable {
                mutableStateOf(exam() )
            }

            var feladatszoveg = rememberSaveable {
                mutableStateOf("")
            }
            var megoldokulcs = rememberSaveable{
                mutableStateOf("")
            }
                // A surface container using the 'background' color from the theme
                Surface(
                    modifier = Modifier.fillMaxSize(),
                    color = MaterialTheme.colorScheme.background
                ) {
                    backButton(navController)
                    Column(verticalArrangement = Arrangement.SpaceAround, horizontalAlignment = Alignment.CenterHorizontally) {
                        //todo balra zárni a szöveget
                        Column(
                            Modifier
                                .fillMaxHeight(0.9f)
                                .fillMaxWidth(), verticalArrangement = Arrangement.Center){
                            webviewkmm( LaTeX =if(solutionsShown.value) feladat.value[1]  else feladat.value[0], Modifier.fillMaxWidth().fillMaxHeight(.9f),mathEnvironmentwrap = false, transparent = true)
                        }

                        if (!solutionsShown.value) {
                            Button(onClick = { solutionsShown.value = !solutionsShown.value }) {
                                Text(stringResource(Res.string.megoldokulcs).dropLast(1))
                            }
                        } else {
                            Row() {
                                Button(onClick = {
                                    //val tasks = exam()
                                    /*  feladatok.forEachIndexed { index, task ->
                                          feladatok[index] = tasks[index]
                                      }*/solutionsShown.value=false
                                    feladat.value = exam()
                                }) {
                                    Text(stringResource(Res.string.easy))
                                }
                                Spacer(modifier = Modifier.width(6.dp))
                                Button(onClick = {
                                    feladat.value = exam(1)
                                    solutionsShown.value=false }) {
                                    Text(stringResource(Res.string.medium))
                                }
                            }
                        }
                    }

                }

            /*LaunchedEffect(key1 = Unit, block = {
                /*var tasks = exam()
                feladatok.forEachIndexed { index, task ->
                    feladatok[index] = tasks[index]
                }*/
                exam(feladatszoveg,megoldokulcs)

            })*/
        }



    fun toShow(feladatok: List<Task>): String {
        var szoveg = " \\(\\begin{align}"
         szoveg +="\\text{Bontsd fel a zárójelet!}\\\\"
        feladatok.forEachIndexed { index, task ->  szoveg += "${task.BO}=\\\\"
        if(index==7) szoveg+="\\\\\\text{Alakítsd szorzattá!}\\\\"
        }
        szoveg+=" \\end{align}\\)"
      //  Log.d("$TAG feladat: \n",szoveg)
        szoveg= szoveg.replace("-+","-").replace("+-","-")

        return szoveg
    }

    fun showSolution(feladatok: List<Task>): String {
        var szoveg = " \\(\\begin{align*}"
        szoveg+="${feladatok[0].BO}&=${feladatok[0].JO}"
        feladatok.forEachIndexed { index, task ->
            if(index!=0)   szoveg += "\\\\${task.BO}&=${task.JO}" }
        szoveg+=" \\end{align*}\\)"
        szoveg= szoveg.replace("-+","-").replace("+-","-")

     //   Log.d(TAG,szoveg)
        return szoveg
    }

    val tasklistA = listOf<() -> Task>(
        {//1. szint
            var a = 1
            var b = (-10..10).toList().filter { it != 0 }.random()
            var task = Task(
                BO = "(x+$b)^2",
                JO = "x^2+${2  * b}x+${b * b}",//ellenőrizve
            )
            return@listOf task
        },

        {//2. szint
            var a = (2..10).random()
            var b = (-10..10).toList().filter { it != 0 }.random()
            var task = Task(
                BO = "(${a}x+$b)^2",
                JO = "${a*a}x^2+${2 *a* b}x+${b * b}",//ellenőrizve
            )
            return@listOf task
        },
     /*   {//3. szint //todo itt is harmadik szint

            var a = BigDecimal((2..10).random())

            var b: BigDecimal = BigDecimal(
                "${if ((0..1).random() == 0) "" else "-"}0.${
                    (1..9).toList().filter { it != 0 }.random()
                }"
            )

            var task = Task(
                BO = "(${a}x+$b)^2",
                JO = "${a*a}x^2+${(b*a ).multiply(BigDecimal(2)).stripTrailingZeros()}x+${b * b}",//ellenőrizve
            )
            return@listOf task
        },*/
        {
            val a = (2..6).random()
            val c = (2..6).random()
            val d = (2..6).random()
            val b = (-10..10).randomNotZero()

            var task = Task(
                BO = "(${a}x^{$c}+${b}x^$d)^2",                                           // BO="(${a}x^{${c}}+${b})(${a}x^{${c}}-${b})",
                JO = "${a*a}x^{${2 * c}}+${2 * a * b}x^{${c + d}}+${b*b}x^{${2 * d}}",  //ellenőrizve                      //JO="a^2x^{${2*c}}-$b^2",
            )
            return@listOf task
        }

    )

    val tasklistB = listOf<() -> Task>(//(ax+b)(ax-b)=a^2*x^2 -b^2
        {//1. szint
            val a = 1
            val b = (1..15).random()

            var task = Task(
                BO = "(x+$b)(x-$b)",
                JO = "x^2-${b * b}"//ellenőrizve
            )
            return@listOf task
        },
        {//2. szint
            val a = (2..10).random()
            val b = (1..15).random()
            val tasknumbers = listOf<Int>(a * a, b * b)
            val type = (0..1).random()//a-t vagy b-t kérdezzük meg
            var task = Task(
                BO = "(${a}x+$b)(${a}x-$b)",
                JO = "${a*a}x^2-${b * b}"//ellenőrizve
            )
            return@listOf task

        },
        {
            val a = (2..10).random()
            val b = (2..10).random()
            val c = (2..10).random()
            val tasknumbers = listOf<Int>(a, c)

            var task = Task(
                BO = "(${a}x^{${c}}+${b})(${a}x^{${c}}-${b})",
                JO = "${a*a}x^{${2 * c}}-${b*b}"//ellenőrizve //(ax^c+b)(ax^c-b)
            )
            return@listOf task

        }

    )

    val tasklistC = listOf<() -> Task> {//1. szint
        val a = (-10..10).toList().filter { it != 0 }.random()
        val b = (-10..10).toList().filter { it != 0 && it.absoluteValue != a.absoluteValue }
            .random()


        var task = Task(
            BO = "(x+$a)(x+$b)",
            JO = "x^2+${a + b}x+${a * b}"//ellenőrizve
        )
        return@listOf task


    }

    val tasklistD = listOf<() -> Task>(
        {
            //1. szint
            val coprimes  =  generateCoprimes(2,15)

            val p = coprimes[0]
            val q = coprimes[1]
            val r:Int

            r=  smallprimes.subList(0,10).filter { it!=p&&it!=q }.random()
            //1. szint

            var task = Task(
                BO = "${r * p}x+${r * q}",
                JO = "$r(${p}x+$q)"//ellenőrizve
            )
            return@listOf task
        },
        {
            val a = listOf<Int>(2, 3, 5, 7, 11).random()
            val b = listOf<Int>(2, 3, 5, 7, 11).filter { it != a }.random()
            val n = (3..10).random()
            val k = (2 until n).random()
            val solution = listOf(n - k)

            var task = Task(
                BO = "${b}x^{${n}}+${a}x^{${k}}",
                JO = "x^{${k}}(${b}x^{${(n - k).CE()}}+$a)"//ellenőrizve
            )
            return@listOf task
        },

        )

    fun exam(difficulty: Int = 0):List<String>  {
        var feladatok = mutableListOf<Task>()
        if (difficulty == 0) {

            for (i in 0..1) {
                var task = tasklistA.subList(0,3).random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistB.random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistC.random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistD.subList(0,1).random()().reverse()
                feladatok.add(task)
            }

            for (i in 0..1) {
                var task = tasklistA.subList(0,3).random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistB.random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistC.random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistD.subList(0,1).random()()
                feladatok.add(task)
            }
        } else {
            for (i in 0..1) {
                var task = tasklistA.random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistB.random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistC.random()()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistD.random()().reverse()
                feladatok.add(task)
            }
// második nyolc feladat
            for (i in 0..1) {
                var task = tasklistA.random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistB.random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistC.random()().reverse()
                feladatok.add(task)
            }
            for (i in 0..1) {
                var task = tasklistD.random()()
                feladatok.add(task)
            }
        }
        return listOf<String>(toShow(feladatok ), showSolution(feladatok))


    }
    data class Task(
        var BO: String, //baloldal
        var JO: String,
    )  {

        fun reverse():Task {
            var csere = BO
            BO = JO
            JO = csere
            return Task(BO, JO)
        }

        fun randomReverse():Task {
            var reverse = nextBoolean()
            if (reverse) return reverse()
            else return this
        }

        fun printastask(): String {
            return "$BO="
        }

        fun printSolutions(): String {
            return "$BO=$JO"
        }
    }
}
val smallprimes = listOf<Int>(2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97)
