@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package matekit_multiplatform.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val add: DrawableResource by 
      lazy { init_add() }

  public val bar_chart: DrawableResource by 
      lazy { init_bar_chart() }

  public val check: DrawableResource by 
      lazy { init_check() }

  public val check_circle: DrawableResource by 
      lazy { init_check_circle() }

  public val checkmarkincircle: DrawableResource by 
      lazy { init_checkmarkincircle() }

  public val coloredborder: DrawableResource by 
      lazy { init_coloredborder() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val coordinatesystem: DrawableResource by 
      lazy { init_coordinatesystem() }

  public val expand_less: DrawableResource by 
      lazy { init_expand_less() }

  public val expand_more: DrawableResource by 
      lazy { init_expand_more() }

  public val focusedtextview: DrawableResource by 
      lazy { init_focusedtextview() }

  public val help: DrawableResource by 
      lazy { init_help() }

  public val ic_arrowrbt: DrawableResource by 
      lazy { init_ic_arrowrbt() }

  public val ic_backbt: DrawableResource by 
      lazy { init_ic_backbt() }

  public val ic_baseline_draw: DrawableResource by 
      lazy { init_ic_baseline_draw() }

  public val ic_color: DrawableResource by 
      lazy { init_ic_color() }

  public val ic_cube: DrawableResource by 
      lazy { init_ic_cube() }

  public val ic_cuboid: DrawableResource by 
      lazy { init_ic_cuboid() }

  public val ic_deltoid: DrawableResource by 
      lazy { init_ic_deltoid() }

  public val ic_download: DrawableResource by 
      lazy { init_ic_download() }

  public val ic_egyenl3: DrawableResource by 
      lazy { init_ic_egyenl3() }

  public val ic_egyenloderek: DrawableResource by 
      lazy { init_ic_egyenloderek() }

  public val ic_enterbt: DrawableResource by 
      lazy { init_ic_enterbt() }

  public val ic_gl7: DrawableResource by 
      lazy { init_ic_gl7() }

  public val ic_hatszog: DrawableResource by 
      lazy { init_ic_hatszog() }

  public val ic_korcikk: DrawableResource by 
      lazy { init_ic_korcikk() }

  public val ic_korszelet: DrawableResource by 
      lazy { init_ic_korszelet() }

  public val ic_launcher_background: DrawableResource by 
      lazy { init_ic_launcher_background() }

  public val ic_otszog: DrawableResource by 
      lazy { init_ic_otszog() }

  public val ic_paralelogramma: DrawableResource by 
      lazy { init_ic_paralelogramma() }

  public val ic_play_arrow: DrawableResource by 
      lazy { init_ic_play_arrow() }

  public val ic_play_circle: DrawableResource by 
      lazy { init_ic_play_circle() }

  public val ic_redo: DrawableResource by 
      lazy { init_ic_redo() }

  public val ic_refresh: DrawableResource by 
      lazy { init_ic_refresh() }

  public val ic_restart: DrawableResource by 
      lazy { init_ic_restart() }

  public val ic_results: DrawableResource by 
      lazy { init_ic_results() }

  public val ic_rombusz: DrawableResource by 
      lazy { init_ic_rombusz() }

  public val ic_simatrapez: DrawableResource by 
      lazy { init_ic_simatrapez() }

  public val ic_simatriangle: DrawableResource by 
      lazy { init_ic_simatriangle() }

  public val ic_size: DrawableResource by 
      lazy { init_ic_size() }

  public val ic_square: DrawableResource by 
      lazy { init_ic_square() }

  public val ic_szabalyos3: DrawableResource by 
      lazy { init_ic_szabalyos3() }

  public val ic_teglalap: DrawableResource by 
      lazy { init_ic_teglalap() }

  public val ic_trapez: DrawableResource by 
      lazy { init_ic_trapez() }

  public val ic_undo: DrawableResource by 
      lazy { init_ic_undo() }

  public val ic_visibility_black_24dp: DrawableResource by 
      lazy { init_ic_visibility_black_24dp() }

  public val ikon: DrawableResource by 
      lazy { init_ikon() }

  public val ikonpng: DrawableResource by 
      lazy { init_ikonpng() }

  public val indit: DrawableResource by 
      lazy { init_indit() }

  public val inputtextview: DrawableResource by 
      lazy { init_inputtextview() }

  public val keyboardbt: DrawableResource by 
      lazy { init_keyboardbt() }

  public val kovacsjanos: DrawableResource by 
      lazy { init_kovacsjanos() }

  public val oldsettings: DrawableResource by 
      lazy { init_oldsettings() }

  public val option1: DrawableResource by 
      lazy { init_option1() }

  public val option2: DrawableResource by 
      lazy { init_option2() }

  public val options_shape: DrawableResource by 
      lazy { init_options_shape() }

  public val remove: DrawableResource by 
      lazy { init_remove() }

  public val results3: DrawableResource by 
      lazy { init_results3() }

  public val settings: DrawableResource by 
      lazy { init_settings() }

  public val splash_image: DrawableResource by 
      lazy { init_splash_image() }

  public val sponge_svgrepo_com: DrawableResource by 
      lazy { init_sponge_svgrepo_com() }

  public val taskfieldshape: DrawableResource by 
      lazy { init_taskfieldshape() }

  public val unkp_logo_04_resized: DrawableResource by 
      lazy { init_unkp_logo_04_resized() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("add", CommonMainDrawable0.add)
  map.put("bar_chart", CommonMainDrawable0.bar_chart)
  map.put("check", CommonMainDrawable0.check)
  map.put("check_circle", CommonMainDrawable0.check_circle)
  map.put("checkmarkincircle", CommonMainDrawable0.checkmarkincircle)
  map.put("coloredborder", CommonMainDrawable0.coloredborder)
  map.put("compose_multiplatform", CommonMainDrawable0.compose_multiplatform)
  map.put("coordinatesystem", CommonMainDrawable0.coordinatesystem)
  map.put("expand_less", CommonMainDrawable0.expand_less)
  map.put("expand_more", CommonMainDrawable0.expand_more)
  map.put("focusedtextview", CommonMainDrawable0.focusedtextview)
  map.put("help", CommonMainDrawable0.help)
  map.put("ic_arrowrbt", CommonMainDrawable0.ic_arrowrbt)
  map.put("ic_backbt", CommonMainDrawable0.ic_backbt)
  map.put("ic_baseline_draw", CommonMainDrawable0.ic_baseline_draw)
  map.put("ic_color", CommonMainDrawable0.ic_color)
  map.put("ic_cube", CommonMainDrawable0.ic_cube)
  map.put("ic_cuboid", CommonMainDrawable0.ic_cuboid)
  map.put("ic_deltoid", CommonMainDrawable0.ic_deltoid)
  map.put("ic_download", CommonMainDrawable0.ic_download)
  map.put("ic_egyenl3", CommonMainDrawable0.ic_egyenl3)
  map.put("ic_egyenloderek", CommonMainDrawable0.ic_egyenloderek)
  map.put("ic_enterbt", CommonMainDrawable0.ic_enterbt)
  map.put("ic_gl7", CommonMainDrawable0.ic_gl7)
  map.put("ic_hatszog", CommonMainDrawable0.ic_hatszog)
  map.put("ic_korcikk", CommonMainDrawable0.ic_korcikk)
  map.put("ic_korszelet", CommonMainDrawable0.ic_korszelet)
  map.put("ic_launcher_background", CommonMainDrawable0.ic_launcher_background)
  map.put("ic_otszog", CommonMainDrawable0.ic_otszog)
  map.put("ic_paralelogramma", CommonMainDrawable0.ic_paralelogramma)
  map.put("ic_play_arrow", CommonMainDrawable0.ic_play_arrow)
  map.put("ic_play_circle", CommonMainDrawable0.ic_play_circle)
  map.put("ic_redo", CommonMainDrawable0.ic_redo)
  map.put("ic_refresh", CommonMainDrawable0.ic_refresh)
  map.put("ic_restart", CommonMainDrawable0.ic_restart)
  map.put("ic_results", CommonMainDrawable0.ic_results)
  map.put("ic_rombusz", CommonMainDrawable0.ic_rombusz)
  map.put("ic_simatrapez", CommonMainDrawable0.ic_simatrapez)
  map.put("ic_simatriangle", CommonMainDrawable0.ic_simatriangle)
  map.put("ic_size", CommonMainDrawable0.ic_size)
  map.put("ic_square", CommonMainDrawable0.ic_square)
  map.put("ic_szabalyos3", CommonMainDrawable0.ic_szabalyos3)
  map.put("ic_teglalap", CommonMainDrawable0.ic_teglalap)
  map.put("ic_trapez", CommonMainDrawable0.ic_trapez)
  map.put("ic_undo", CommonMainDrawable0.ic_undo)
  map.put("ic_visibility_black_24dp", CommonMainDrawable0.ic_visibility_black_24dp)
  map.put("ikon", CommonMainDrawable0.ikon)
  map.put("ikonpng", CommonMainDrawable0.ikonpng)
  map.put("indit", CommonMainDrawable0.indit)
  map.put("inputtextview", CommonMainDrawable0.inputtextview)
  map.put("keyboardbt", CommonMainDrawable0.keyboardbt)
  map.put("kovacsjanos", CommonMainDrawable0.kovacsjanos)
  map.put("oldsettings", CommonMainDrawable0.oldsettings)
  map.put("option1", CommonMainDrawable0.option1)
  map.put("option2", CommonMainDrawable0.option2)
  map.put("options_shape", CommonMainDrawable0.options_shape)
  map.put("remove", CommonMainDrawable0.remove)
  map.put("results3", CommonMainDrawable0.results3)
  map.put("settings", CommonMainDrawable0.settings)
  map.put("splash_image", CommonMainDrawable0.splash_image)
  map.put("sponge_svgrepo_com", CommonMainDrawable0.sponge_svgrepo_com)
  map.put("taskfieldshape", CommonMainDrawable0.taskfieldshape)
  map.put("unkp_logo_04_resized", CommonMainDrawable0.unkp_logo_04_resized)
}

internal val Res.drawable.add: DrawableResource
  get() = CommonMainDrawable0.add

private fun init_add(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/add.xml", -1, -1),
    )
)

internal val Res.drawable.bar_chart: DrawableResource
  get() = CommonMainDrawable0.bar_chart

private fun init_bar_chart(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:bar_chart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/bar_chart.xml", -1, -1),
    )
)

internal val Res.drawable.check: DrawableResource
  get() = CommonMainDrawable0.check

private fun init_check(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:check",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/check.xml", -1, -1),
    )
)

internal val Res.drawable.check_circle: DrawableResource
  get() = CommonMainDrawable0.check_circle

private fun init_check_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:check_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/check_circle.xml", -1, -1),
    )
)

internal val Res.drawable.checkmarkincircle: DrawableResource
  get() = CommonMainDrawable0.checkmarkincircle

private fun init_checkmarkincircle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:checkmarkincircle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/checkmarkincircle.xml", -1, -1),
    )
)

internal val Res.drawable.coloredborder: DrawableResource
  get() = CommonMainDrawable0.coloredborder

private fun init_coloredborder(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:coloredborder",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/coloredborder.xml", -1, -1),
    )
)

internal val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

internal val Res.drawable.coordinatesystem: DrawableResource
  get() = CommonMainDrawable0.coordinatesystem

private fun init_coordinatesystem(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:coordinatesystem",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/coordinatesystem.xml", -1, -1),
    )
)

internal val Res.drawable.expand_less: DrawableResource
  get() = CommonMainDrawable0.expand_less

private fun init_expand_less(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:expand_less",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/expand_less.xml", -1, -1),
    )
)

internal val Res.drawable.expand_more: DrawableResource
  get() = CommonMainDrawable0.expand_more

private fun init_expand_more(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:expand_more",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/expand_more.xml", -1, -1),
    )
)

internal val Res.drawable.focusedtextview: DrawableResource
  get() = CommonMainDrawable0.focusedtextview

private fun init_focusedtextview(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:focusedtextview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/focusedtextview.xml", -1, -1),
    )
)

internal val Res.drawable.help: DrawableResource
  get() = CommonMainDrawable0.help

private fun init_help(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:help",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/help.xml", -1, -1),
    )
)

internal val Res.drawable.ic_arrowrbt: DrawableResource
  get() = CommonMainDrawable0.ic_arrowrbt

private fun init_ic_arrowrbt(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_arrowrbt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_arrowrbt.xml", -1, -1),
    )
)

internal val Res.drawable.ic_backbt: DrawableResource
  get() = CommonMainDrawable0.ic_backbt

private fun init_ic_backbt(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_backbt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_backbt.xml", -1, -1),
    )
)

internal val Res.drawable.ic_baseline_draw: DrawableResource
  get() = CommonMainDrawable0.ic_baseline_draw

private fun init_ic_baseline_draw(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_baseline_draw",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_baseline_draw.xml", -1, -1),
    )
)

internal val Res.drawable.ic_color: DrawableResource
  get() = CommonMainDrawable0.ic_color

private fun init_ic_color(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_color",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_color.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cube: DrawableResource
  get() = CommonMainDrawable0.ic_cube

private fun init_ic_cube(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cube",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_cube.xml", -1, -1),
    )
)

internal val Res.drawable.ic_cuboid: DrawableResource
  get() = CommonMainDrawable0.ic_cuboid

private fun init_ic_cuboid(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_cuboid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_cuboid.xml", -1, -1),
    )
)

internal val Res.drawable.ic_deltoid: DrawableResource
  get() = CommonMainDrawable0.ic_deltoid

private fun init_ic_deltoid(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_deltoid",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_deltoid.xml", -1, -1),
    )
)

internal val Res.drawable.ic_download: DrawableResource
  get() = CommonMainDrawable0.ic_download

private fun init_ic_download(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_download",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_download.xml", -1, -1),
    )
)

internal val Res.drawable.ic_egyenl3: DrawableResource
  get() = CommonMainDrawable0.ic_egyenl3

private fun init_ic_egyenl3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_egyenl3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_egyenl3.xml", -1, -1),
    )
)

internal val Res.drawable.ic_egyenloderek: DrawableResource
  get() = CommonMainDrawable0.ic_egyenloderek

private fun init_ic_egyenloderek(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_egyenloderek",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_egyenloderek.xml", -1, -1),
    )
)

internal val Res.drawable.ic_enterbt: DrawableResource
  get() = CommonMainDrawable0.ic_enterbt

private fun init_ic_enterbt(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_enterbt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_enterbt.xml", -1, -1),
    )
)

internal val Res.drawable.ic_gl7: DrawableResource
  get() = CommonMainDrawable0.ic_gl7

private fun init_ic_gl7(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_gl7",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_gl7.xml", -1, -1),
    )
)

internal val Res.drawable.ic_hatszog: DrawableResource
  get() = CommonMainDrawable0.ic_hatszog

private fun init_ic_hatszog(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_hatszog",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_hatszog.xml", -1, -1),
    )
)

internal val Res.drawable.ic_korcikk: DrawableResource
  get() = CommonMainDrawable0.ic_korcikk

private fun init_ic_korcikk(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_korcikk",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_korcikk.xml", -1, -1),
    )
)

internal val Res.drawable.ic_korszelet: DrawableResource
  get() = CommonMainDrawable0.ic_korszelet

private fun init_ic_korszelet(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_korszelet",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_korszelet.xml", -1, -1),
    )
)

internal val Res.drawable.ic_launcher_background: DrawableResource
  get() = CommonMainDrawable0.ic_launcher_background

private fun init_ic_launcher_background(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_launcher_background",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_launcher_background.xml", -1, -1),
    )
)

internal val Res.drawable.ic_otszog: DrawableResource
  get() = CommonMainDrawable0.ic_otszog

private fun init_ic_otszog(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_otszog",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_otszog.xml", -1, -1),
    )
)

internal val Res.drawable.ic_paralelogramma: DrawableResource
  get() = CommonMainDrawable0.ic_paralelogramma

private fun init_ic_paralelogramma(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_paralelogramma",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_paralelogramma.xml", -1, -1),
    )
)

internal val Res.drawable.ic_play_arrow: DrawableResource
  get() = CommonMainDrawable0.ic_play_arrow

private fun init_ic_play_arrow(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_play_arrow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_play_arrow.xml", -1, -1),
    )
)

internal val Res.drawable.ic_play_circle: DrawableResource
  get() = CommonMainDrawable0.ic_play_circle

private fun init_ic_play_circle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_play_circle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_play_circle.xml", -1, -1),
    )
)

internal val Res.drawable.ic_redo: DrawableResource
  get() = CommonMainDrawable0.ic_redo

private fun init_ic_redo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_redo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_redo.xml", -1, -1),
    )
)

internal val Res.drawable.ic_refresh: DrawableResource
  get() = CommonMainDrawable0.ic_refresh

private fun init_ic_refresh(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_refresh",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_refresh.xml", -1, -1),
    )
)

internal val Res.drawable.ic_restart: DrawableResource
  get() = CommonMainDrawable0.ic_restart

private fun init_ic_restart(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_restart",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_restart.xml", -1, -1),
    )
)

internal val Res.drawable.ic_results: DrawableResource
  get() = CommonMainDrawable0.ic_results

private fun init_ic_results(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_results",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_results.xml", -1, -1),
    )
)

internal val Res.drawable.ic_rombusz: DrawableResource
  get() = CommonMainDrawable0.ic_rombusz

private fun init_ic_rombusz(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_rombusz",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_rombusz.xml", -1, -1),
    )
)

internal val Res.drawable.ic_simatrapez: DrawableResource
  get() = CommonMainDrawable0.ic_simatrapez

private fun init_ic_simatrapez(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_simatrapez",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_simatrapez.xml", -1, -1),
    )
)

internal val Res.drawable.ic_simatriangle: DrawableResource
  get() = CommonMainDrawable0.ic_simatriangle

private fun init_ic_simatriangle(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_simatriangle",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_simatriangle.xml", -1, -1),
    )
)

internal val Res.drawable.ic_size: DrawableResource
  get() = CommonMainDrawable0.ic_size

private fun init_ic_size(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_size.xml", -1, -1),
    )
)

internal val Res.drawable.ic_square: DrawableResource
  get() = CommonMainDrawable0.ic_square

private fun init_ic_square(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_square",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_square.xml", -1, -1),
    )
)

internal val Res.drawable.ic_szabalyos3: DrawableResource
  get() = CommonMainDrawable0.ic_szabalyos3

private fun init_ic_szabalyos3(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_szabalyos3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_szabalyos3.xml", -1, -1),
    )
)

internal val Res.drawable.ic_teglalap: DrawableResource
  get() = CommonMainDrawable0.ic_teglalap

private fun init_ic_teglalap(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_teglalap",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_teglalap.xml", -1, -1),
    )
)

internal val Res.drawable.ic_trapez: DrawableResource
  get() = CommonMainDrawable0.ic_trapez

private fun init_ic_trapez(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_trapez",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_trapez.xml", -1, -1),
    )
)

internal val Res.drawable.ic_undo: DrawableResource
  get() = CommonMainDrawable0.ic_undo

private fun init_ic_undo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_undo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_undo.xml", -1, -1),
    )
)

internal val Res.drawable.ic_visibility_black_24dp: DrawableResource
  get() = CommonMainDrawable0.ic_visibility_black_24dp

private fun init_ic_visibility_black_24dp(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:ic_visibility_black_24dp",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ic_visibility_black_24dp.xml", -1, -1),
    )
)

internal val Res.drawable.ikon: DrawableResource
  get() = CommonMainDrawable0.ikon

private fun init_ikon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ikon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ikon.ico", -1, -1),
    )
)

internal val Res.drawable.ikonpng: DrawableResource
  get() = CommonMainDrawable0.ikonpng

private fun init_ikonpng(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ikonpng",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/ikonpng.png", -1, -1),
    )
)

internal val Res.drawable.indit: DrawableResource
  get() = CommonMainDrawable0.indit

private fun init_indit(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:indit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/indit.jpg", -1, -1),
    )
)

internal val Res.drawable.inputtextview: DrawableResource
  get() = CommonMainDrawable0.inputtextview

private fun init_inputtextview(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:inputtextview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/inputtextview.xml", -1, -1),
    )
)

internal val Res.drawable.keyboardbt: DrawableResource
  get() = CommonMainDrawable0.keyboardbt

private fun init_keyboardbt(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:keyboardbt",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/keyboardbt.xml", -1, -1),
    )
)

internal val Res.drawable.kovacsjanos: DrawableResource
  get() = CommonMainDrawable0.kovacsjanos

private fun init_kovacsjanos(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:kovacsjanos",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/kovacsjanos.jpg", -1, -1),
    )
)

internal val Res.drawable.oldsettings: DrawableResource
  get() = CommonMainDrawable0.oldsettings

private fun init_oldsettings(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:oldsettings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/oldsettings.xml", -1, -1),
    )
)

internal val Res.drawable.option1: DrawableResource
  get() = CommonMainDrawable0.option1

private fun init_option1(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:option1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/option1.xml", -1, -1),
    )
)

internal val Res.drawable.option2: DrawableResource
  get() = CommonMainDrawable0.option2

private fun init_option2(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:option2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/option2.xml", -1, -1),
    )
)

internal val Res.drawable.options_shape: DrawableResource
  get() = CommonMainDrawable0.options_shape

private fun init_options_shape(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:options_shape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/options_shape.xml", -1, -1),
    )
)

internal val Res.drawable.remove: DrawableResource
  get() = CommonMainDrawable0.remove

private fun init_remove(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:remove",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/remove.xml", -1, -1),
    )
)

internal val Res.drawable.results3: DrawableResource
  get() = CommonMainDrawable0.results3

private fun init_results3(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:results3",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/results3.xml", -1, -1),
    )
)

internal val Res.drawable.settings: DrawableResource
  get() = CommonMainDrawable0.settings

private fun init_settings(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/settings.xml", -1, -1),
    )
)

internal val Res.drawable.splash_image: DrawableResource
  get() = CommonMainDrawable0.splash_image

private fun init_splash_image(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:splash_image",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/splash_image.xml", -1, -1),
    )
)

internal val Res.drawable.sponge_svgrepo_com: DrawableResource
  get() = CommonMainDrawable0.sponge_svgrepo_com

private fun init_sponge_svgrepo_com(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:sponge_svgrepo_com",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/sponge_svgrepo_com.xml", -1, -1),
    )
)

internal val Res.drawable.taskfieldshape: DrawableResource
  get() = CommonMainDrawable0.taskfieldshape

private fun init_taskfieldshape(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:taskfieldshape",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/taskfieldshape.xml", -1, -1),
    )
)

internal val Res.drawable.unkp_logo_04_resized: DrawableResource
  get() = CommonMainDrawable0.unkp_logo_04_resized

private fun init_unkp_logo_04_resized(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:unkp_logo_04_resized",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/drawable/unkp_logo_04_resized.xml", -1, -1),
    )
)
