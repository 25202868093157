package matekit.matekit.utilityfunctions

fun Int.factorial():Int{//         negatív számokat nem kezeltük, de mindig pozitív számok faktoriálisát fogjuk számolni
    var a =1
    for (i in 1..this){
        a *=i
    }
    return a
}
fun Int.choose(K:Int):Int{ //"n choose k" //n alatt a k
    return this.factorial()/(K.factorial()*(this-K).factorial())
}

fun IntRange.randomNotZero(): Int {
    var number = this.toList().filter { it != 0 }
        .random()//ezt még lehetett volna tovább bonyolítani azzal, hogy "és egyéb feltételek, paraméterként az _it" majd az it-et hivatkozni a függvény paraméterei közt, pl nem nulla és nem öt, vagy nem _a
    return number
}

fun kisGauss(n:Int):Int{
    var a =0
    for (i in 1..n){
        a+=i
    }
    return a
}