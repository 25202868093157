package matekit.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.background
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.Button
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.IntOffset
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.toSize
import kotlin.math.roundToInt

@Composable
fun FloatingKeyboard(
    focusedTextField: MutableState<TextFieldValue>?,
    keyboardToggle: MutableState<Boolean>,
    modifier: Modifier = Modifier,
    keyboardSize: Dp = 300.dp,

) {
    var offsetX by remember { mutableStateOf(0f) }
    var offsetY by remember { mutableStateOf(0f) }
    var parentSize by remember { mutableStateOf(androidx.compose.ui.geometry.Size.Zero) }

    Box(
        modifier = Modifier
            .fillMaxSize(),
        contentAlignment = Alignment.BottomStart
    ) {
        Button(
            onClick = {
                keyboardToggle.value = !keyboardToggle.value
            },
            modifier = Modifier
                .padding(16.dp) // Add padding to avoid the button being too close to the edge
        ) {
            Text("Toggle Keyboard")
        }
    }

    Box(
        modifier = modifier
            .fillMaxSize()
            .onSizeChanged { size ->
                parentSize = size.toSize()
            }
    ) {
        val surfaceSize = with(LocalDensity.current) { keyboardSize.toPx() }

        //todo választani egy másik animációt
        AnimatedVisibility(
            keyboardToggle.value,
           // enter = fadeIn(),
            //exit = fadeOut(),
            modifier = Modifier  .offset { IntOffset(offsetX.roundToInt(), offsetY.roundToInt()) }
                .size(keyboardSize)
                .pointerInput(Unit) {
                    detectDragGestures { change, dragAmount ->
                        change.consume()
                        offsetX = (offsetX + dragAmount.x).coerceIn(
                            0f,
                            parentSize.width - surfaceSize
                        )
                        offsetY = (offsetY + dragAmount.y).coerceIn(
                            0f,
                            parentSize.height - surfaceSize
                        )
                    }
                }  /* .pointerInput(Unit) {
                    detectTapGestures {
                        // Intercept tap events to prevent focus loss
                    }
                }*/
        ) {
            Surface(
                modifier = Modifier
                  ,
                shape = RoundedCornerShape(16.dp),
                color = Color.White,
            ) {
                Box(
                    modifier = Modifier
                        .fillMaxSize(.9f)
                        .background(Color.Blue)
                ) {
                    Column {
                       // if (mezőkattintvavan.value) Text("valami ki van jelölve és tudunk róla")
                        Text("selected text: ${focusedTextField?.value?.text}")
                        NumberKeyboard(focusedTextField)
                    }

                }
            }
        }
    }
}

@Composable
fun NumberKeyboard(focusedTextField: MutableState<TextFieldValue>?) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(8.dp)
    ) {
        val buttons = listOf(
            listOf("1", "2", "3"),
            listOf("4", "5", "6"),
            listOf("7", "8", "9"),
            listOf("-", "0", "Del"),
            listOf(",")
        )

        buttons.forEach { row ->
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceEvenly
            ) {
                row.forEach { label ->
                    Button(
                        onClick = { focusedTextField?.let { onButtonClick(label, it) } },
                        modifier = Modifier
                            .weight(1f)
                            .padding(4.dp)
                    ) {
                        Text(text = label)
                    }
                }
            }
        }
    }
}

fun onButtonClick(label: String, textFieldValue: MutableState<TextFieldValue>) {
    when (label) {
        "Del" -> {
            if (textFieldValue.value.text.isNotEmpty()) {
                textFieldValue.value = TextFieldValue(
                    textFieldValue.value.text.dropLast(1)
                )
            }
        }
        /*//hogyha a kurzor előtti karaktert akarjuk törölni
        * "Del" -> {
    val text = textFieldValue.value.text
    val cursorPosition = textFieldValue.value.selection.start

    if (text.isNotEmpty() && cursorPosition > 0) {
        val newText = text.removeRange(cursorPosition - 1, cursorPosition)
        textFieldValue.value = TextFieldValue(
            text = newText,
            selection = TextRange(cursorPosition - 1) // Move the cursor back by one position
        )
    }
}*/

        "-" -> {
            if (!textFieldValue.value.text.contains("-")) {
                textFieldValue.value = TextFieldValue("-" + textFieldValue.value.text)
            }
        }

        else -> {
            textFieldValue.value = TextFieldValue(textFieldValue.value.text + label)
        }
    }
}
