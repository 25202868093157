package matekit.ui

import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.ic_baseline_draw
import org.jetbrains.compose.resources.painterResource

@Composable
fun drawButton(switchDrawboard:()->Unit) {
    IconButton(onClick = {
            switchDrawboard()
    }, modifier = Modifier.size(60.dp)) {
        Icon(
            painter = painterResource( Res.drawable.ic_baseline_draw),
            contentDescription = null // decorative element
        )

    }

}

@Composable
fun drawButton(drawboard: MutableState<Boolean>) {
    IconButton(onClick = {
        drawboard.value = !drawboard.value
    }, modifier = Modifier.size(60.dp)) {
        Icon(
            painter = painterResource(Res.drawable.ic_baseline_draw),
            contentDescription = null // decorative element
        )

    }

}