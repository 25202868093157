package matekit.matekit

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color

@Composable
 fun getOnSurfaceColor(): Color {
    return Color(red = 28, green = 27, blue = 31)
}

@Composable
 fun getOnPrimaryColor(): Color {
    return Color.Black
}