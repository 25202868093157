package matekit.matekit.ui.theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.lightColorScheme
import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.Color
import matekit.ui.theme.Teal200
import matekit.ui.theme.newGreyPrimarylight

@Composable
fun desktopMateKIT( content: @Composable () -> Unit){

    MaterialTheme (
        colorScheme = lightColorScheme(
            primary = newGreyPrimarylight, //Purple500,
            onPrimary = Color.Black,
            secondary = newGreyPrimarylight, //oldGray,
            tertiary = Teal200,
            // secondaryContainer= Color(255, 230, 0, 255),
            surfaceVariant = Color(red = 255, green = 251, blue = 254),
            //onSurface =oldGray,
            /* Other default colors to override
            background = Color.White,
            surface = Color.White,
            onPrimary = Color.White,
            onSecondary = Color.Black,
            onBackground = Color.Black,
            onSurface = Color.Black,
            */

        ),
        content = content
    )
}