package matekit.matekit.ui.commonuielements

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.material3.Text
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp


//mvvm architektúrára átdolgozva, function overloading

@Composable
fun indexedInputField(
    text: String,
    index: Int,
    userinputs: List<String>,
    row: Boolean = true,
    értékelve: Boolean = false,
    correct: Boolean = false,
    label: String = "",
    keyboardtype:Int = 3,//számot gyakrabban kérünk, ezért legyen az a default
    editText: (String) -> Unit,
    boxSize: Int? = null
) {

    var keyboardtype = if(keyboardtype==1){
        KeyboardType.Text
    }else{
        KeyboardType.Number
    }


    if (row) {
        Row(
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(bottom = 4.dp)
        ) {
            Text(text = "$text: ", fontSize = 24.sp)
            OutlinedTextField(
                value = userinputs[index],
                onValueChange = { editText(it)},
                keyboardOptions = KeyboardOptions.Default.copy(
                    keyboardType = keyboardtype
                ))
        }
    } else {
        Column(//csak az oszloposat frissítettem, mert egyelőre csak arra van szükség, //todo átdolgozni a row verziót is
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.padding(bottom = 4.dp)
        ) {
         if(text!="")   Text(text = "$text", fontSize = 24.sp, textAlign = TextAlign.Center)

            OutlinedTextField(
                value = userinputs[index],
                onValueChange = { editText(it) },
                keyboardOptions = KeyboardOptions.Default.copy(
                    keyboardType = keyboardtype
                ),
                label = { Text(label.toString(),
                    color =
                    if(értékelve) { if (correct) Color.Green else Color.Red } else Color.Unspecified
                ) },
                colors = if (értékelve) {
                    OutlinedTextFieldDefaults.colors(
                      focusedBorderColor  = if (correct) Color.Green else Color.Red,
                    unfocusedBorderColor = if (correct) Color.Green else Color.Red
                    )
                } else {
                    OutlinedTextFieldDefaults.colors()
                },
                modifier=if(boxSize!=null) Modifier.width(boxSize.dp) else Modifier

            )

        }

    }

}