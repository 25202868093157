package matekit.matekit.games



import androidx.compose.animation.Animatable
import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.input.pointer.PointerEventType
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
//import matekit.matekit.OrientationChecker
import matekit.ui.basicfontsizeTextView
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.ClickAtThisAngle
import matekit_multiplatform.composeapp.generated.resources.GuessAngleSubmitAngle
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.correctAngle
import matekit_multiplatform.composeapp.generated.resources.drawAngle
import matekit_multiplatform.composeapp.generated.resources.drawAngleDiff
import matekit_multiplatform.composeapp.generated.resources.drawTask
import matekit_multiplatform.composeapp.generated.resources.guessAngle
import matekit_multiplatform.composeapp.generated.resources.guessAngleDiff
import matekit_multiplatform.composeapp.generated.resources.guessAngleResponse
import matekit_multiplatform.composeapp.generated.resources.guesstask
import matekit_multiplatform.composeapp.generated.resources.play
import matekit_multiplatform.composeapp.generated.resources.szogGameDiff

import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.PI
import kotlin.math.absoluteValue
import kotlin.math.acos
import kotlin.math.sqrt
import matekit.ui.ratios
import matekit_multiplatform.composeapp.generated.resources.JetBrainsMono_Regular
import org.jetbrains.compose.resources.Font

// import androidx.compose.ui.input.pointer.pointerInteropFilter /nem működik desktopon

class Szogbecsles(
    val navController: NavHostController
) {
    @Composable
    fun UI(
        ratios: ratios,
        orientation: MutableState<String>
    ) {
        var feladattípus by rememberSaveable { mutableStateOf(GUESS_ANGLE) }
        var guessStartAngle by rememberSaveable { mutableStateOf((0..180).random()) }
        var sweepAngle by rememberSaveable { mutableStateOf((guessStartAngle..(360 - guessStartAngle)).random()) }
        var feedbackcounter = rememberSaveable { mutableStateOf(0) }
        var feedback = rememberSaveable { mutableStateOf("") }

        Column(modifier = Modifier.fillMaxSize()) {
            FloatingActionButton(
                //     modifier = Modifier.align(Alignment.BottomStart),
                onClick = { navController.popBackStack() },
                containerColor = Color.Gray
            ) {
                Icon(
                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                    contentDescription = "Back"
                )
            }
            screen(
                feladattípus,
                { feladattípus = it },
                guessStartAngle,
                sweepAngle,
                { guessStartAngle = it },
                { sweepAngle = it },
                feedback,
                feedbackcounter,ratios,orientation
            )

        }
        val visible = rememberSaveable{ mutableStateOf(false)}

        LaunchedEffect(feedbackcounter.value){
            if(
                feedbackcounter.value>0
            ) {
                visible.value = true
                delay(3000)
                visible.value = false
            }
        }
        val fontSize = 36.sp
        val lineheight = 40.sp
        AnimatedVisibility(
            visible = visible.value,
            enter = fadeIn(),
            exit= fadeOut()
        ){
            Box(
                modifier= Modifier.fillMaxSize(),
                contentAlignment = Alignment.Center
            ){
                val fontFamily = FontFamily(
                    Font(Res.font.JetBrainsMono_Regular)
                )
                Surface(
                    Modifier.wrapContentWidth().wrapContentHeight(),
                    border = BorderStroke(6.dp, matekitcolor),
                    shape = Shapes().large,
                    color =Color.White
                ) {
                    Column(
                        Modifier.wrapContentWidth().wrapContentHeight().padding(16.dp),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Center
                    ) {
                        if(feedbackcounter.value>0) { Text(feedback.value, fontSize = fontSize , lineHeight = lineheight,fontFamily=fontFamily   ) }
                    }
                }

            }



        }
    }

    val GUESS_ANGLE = 0
    val DRAW_ANGLE = 1
    val GAME = 2


    suspend fun generateTask(
        feladattípus: Int,
        setGuessStart: (Int) -> Unit,
        setSweep: (Int) -> Unit,
        guessStartAngle: Int = 0,
         SetTaskText: ((String) -> Unit)?
    ) {
        when (feladattípus) {
            GUESS_ANGLE -> {
                val start = (0..180).random()
                setGuessStart(start)
                setSweep((start..(360 - start)).random())
            }

            DRAW_ANGLE -> {
                var generatedSweep = (5..355).random()
                setSweep(generatedSweep)
                if (SetTaskText != null) {
                    SetTaskText(getString(Res.string.drawTask, generatedSweep.toString()))
                }
            }
        }
    }

    @Composable
    fun screen(
        feladattípus: Int,
        setFeladattípus: (Int) -> Unit,
        guessStartAngle: Int,
        SweepAngle: Int,
        setGuessStart: (Int) -> Unit,
        setGuessSweep: (Int) -> Unit,
        feedBackText: MutableState<String>,
        numberofsolved: MutableState<Int>,
        ratios: ratios,
        orientation: MutableState<String>
    ) {


        var GameStartAngle by rememberSaveable { mutableStateOf(0.0) }
        var GameUserSweepAngle by rememberSaveable { mutableStateOf(0.0) }
        var gameAngle by rememberSaveable { mutableStateOf(0) }
        var gameScore by rememberSaveable { mutableStateOf(0) }
        var gameRunning by rememberSaveable { mutableStateOf(false) }
        var játékszorzó by rememberSaveable { mutableStateOf(1.0) }
        var gameAngleStep by rememberSaveable { mutableStateOf(0.1) }
        var myStrokeColor by remember { mutableStateOf(Color.Transparent) }

        var tasktext by remember { mutableStateOf("lorem ipsum") }

        LaunchedEffect(Unit){
            tasktext = getString(Res.string.guesstask)
        }
        val modifier = Modifier.fillMaxSize()
        val coroutineScope = rememberCoroutineScope()

       // val orientation =ratios.orientation //OrientationChecker().checkOrientation()
        Box() {
            Surface(
                modifier = modifier,
                color = MaterialTheme.colorScheme.background
            ) {
                var tippeltszög by rememberSaveable { mutableStateOf("") }
                if (orientation.value == "Portrait") Column(
                    modifier,
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.SpaceAround
                ) {

                    Column(verticalArrangement = Arrangement.Center) {
                        borderedbasicfontsizeTextView(tasktext)
                    }
                    canvasSurface(
                        feladattípus,
                        guessStartAngle,
                        SweepAngle,
                        GameStartAngle,
                        GameUserSweepAngle,
                        myStrokeColor,
                        0.5f
                    )
                    if (feladattípus != GAME) userInput(
                        tippeltszög,
                        { tippeltszög = it },
                        feladattípus,
                        SweepAngle,
                        { setGuessStart(it) },
                        { setGuessSweep(it) },
                        guessStartAngle,
                        { tasktext = it },
                        feedBackText,
                        numberofsolved
                    )
                    else gameuserinput(
                        gameAngle,
                        GameStartAngle = GameStartAngle,
                        GameUserSweepAngle = GameUserSweepAngle,
                        játékszorzó = játékszorzó,
                        gameScore = gameScore,
                        increaseGameScore = { gameScore += it },
                        setEndgame = { gameRunning = it },
                        increaseJátékszorzó = { játékszorzó *= it },
                        newGamestartAngle = { GameStartAngle = it },
                        newGameAngle = { gameAngle = it.toInt() },
                        returnTask = { tasktext = it },
                        resetUserSweep = { GameUserSweepAngle = it },
                        setStrokeColor = { myStrokeColor = it },
                        setFeedback = { feedBackText.value = it },
                        increaseFeedbackCounter = { numberofsolved.value++ })
                    Column(horizontalAlignment = Alignment.CenterHorizontally) {
                        Button(onClick = {
                            setFeladattípus(GUESS_ANGLE)
                            coroutineScope.launch {
                                tasktext = getString(Res.string.guesstask)
                                generateTask(
                                    GUESS_ANGLE,
                                    { setGuessStart(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    null
                                )
                            }
                            // Log.d(TAG,"$guessStartAngle, $SweepAngle")
                        }) {
                            Text(stringResource(Res.string.guessAngle))
                        }
                        Button(onClick = {
                            setFeladattípus(DRAW_ANGLE)
                            coroutineScope.launch {
                                generateTask(
                                    DRAW_ANGLE,
                                    { setGuessStart(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    { tasktext = it })
                            }
                        }) {
                            Text(stringResource(Res.string.drawAngle))
                        }
                        Button(
                            onClick = {
                                setFeladattípus(GAME)
                                gameScore = 0
                                GameStartAngle = 0.0
                                GameUserSweepAngle = 0.0
                                játékszorzó = 1.0
                                myStrokeColor = Color.Transparent
                                coroutineScope.launch {
                                    generateGameAngle(
                                        { gameAngle = it },
                                        { tasktext = it })
                                }
                                gameRunning = true


                            },
                            enabled = !gameRunning
                        )
                        {
                            var elapsedmilis by remember { mutableStateOf(0) }
                            Text(stringResource(Res.string.play))
                            LaunchedEffect(key1 = elapsedmilis, gameRunning) {
                                while (gameRunning) {
                                    delay(1)
                                    GameUserSweepAngle += gameAngleStep * játékszorzó
                                    elapsedmilis++
                                    //  Log.d(TAG, "$GameUserSweepAngle")
                                    //  Log.d(TAG, " startangle is $GameStartAngle")
                                }

                            }

                        }
                    }
                    /*   Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.End) {

                           IconButton(onClick = {

                           },modifier=Modifier.size(60.dp)){
                               /* Image(
                                     painterResource(Res.drawable.ic_results),
                                     contentDescription = "",
                                     contentScale = ContentScale.Inside,
                                     modifier = Modifier.fillMaxSize()
                                 )*/
                               Icon( painter = painterResource(id =Res.drawable.ic_results ),
                                   contentDescription = null // decorative element
                               )

                           }
                           Spacer(modifier = Modifier.fillMaxWidth(0.05f))
                       }*/
                    //logbutton(context, "szogbecsles")
                }
                if (orientation.value == "Landscape") Row(
                    modifier,
                    horizontalArrangement = Arrangement.SpaceAround,
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    /*   Column(verticalArrangement = Arrangement.Center){
                       }*/
                    canvasSurface(
                        feladattípus,
                        guessStartAngle,
                        SweepAngle,
                        GameStartAngle,
                        GameUserSweepAngle,
                        myStrokeColor,
                        1f
                    )

                    Column(
                        modifier,
                        verticalArrangement = Arrangement.SpaceAround,
                        horizontalAlignment = Alignment.CenterHorizontally
                    ) {
                        basicfontsizeTextView(tasktext)
                        if (feladattípus != GAME) userInput(
                            tippeltszög,
                            { tippeltszög = it },
                            feladattípus,
                            SweepAngle,
                            { setGuessStart(it) },
                            { setGuessSweep(it) },
                            guessStartAngle,
                            { tasktext = it },
                            feedBackText,
                            numberofsolved
                        )
                        else gameuserinput(
                            gameAngle,
                            GameStartAngle = GameStartAngle,
                            GameUserSweepAngle = GameUserSweepAngle,
                            játékszorzó = játékszorzó,
                            gameScore = gameScore,
                            increaseGameScore = { gameScore += it },
                            setEndgame = { gameRunning = it },
                            increaseJátékszorzó = { játékszorzó *= it },
                            newGamestartAngle = { GameStartAngle = it },
                            newGameAngle = { gameAngle = it.toInt() },
                            returnTask = { tasktext = it },
                            resetUserSweep = { GameUserSweepAngle = it },
                            setStrokeColor = { myStrokeColor = it },
                            setFeedback = { feedBackText.value = it },
                            increaseFeedbackCounter = { numberofsolved.value++ })

                        Button(onClick = {
                            setFeladattípus(GUESS_ANGLE)
                            coroutineScope.launch {
                                tasktext = getString(Res.string.guesstask)
                                generateTask(
                                    GUESS_ANGLE,
                                    { setGuessStart(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    null
                                )
                            }
                            // Log.d(TAG,"$guessStartAngle, $SweepAngle")
                        }) {
                            Text(stringResource(Res.string.guessAngle))
                        }

                        Button(onClick = {
                            setFeladattípus(DRAW_ANGLE)
                            coroutineScope.launch {
                                generateTask(
                                    DRAW_ANGLE,
                                    { setGuessStart(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    { tasktext = it })
                            }
                        }) {
                            Text(stringResource(Res.string.drawAngle))
                        }
                        Button(
                            onClick = {
                                setFeladattípus(GAME)
                                gameScore = 0
                                GameStartAngle = 0.0
                                GameUserSweepAngle = 0.0
                                játékszorzó = 1.0
                                myStrokeColor = Color.Transparent
                                coroutineScope.launch {
                                    generateGameAngle(
                                        { gameAngle = it },
                                        { tasktext = it })
                                }
                                gameRunning = true


                            },
                            enabled = !gameRunning
                        )
                        {
                            var elapsedmilis by remember { mutableStateOf(0) }
                            Text(stringResource(Res.string.play))
                            LaunchedEffect(key1 = elapsedmilis, gameRunning) {
                                while (gameRunning) {
                                    delay(1)
                                    GameUserSweepAngle += gameAngleStep * játékszorzó
                                    elapsedmilis++
                                    //  Log.d(TAG, "$GameUserSweepAngle")
                                    //  Log.d(TAG, " startangle is $GameStartAngle")
                                }

                            }

                        }
                        //logbutton(context,"szogbecsles")
                    }

                }


            }

        }
    }


    @Composable
    fun gameuserinput(
        gameAngle: Int,
        GameStartAngle: Double,
        GameUserSweepAngle: Double,
        játékszorzó: Double,
        gameScore: Int,
        increaseGameScore: (Int) -> Unit,
        setEndgame: (Boolean) -> Unit,
        increaseJátékszorzó: (Double) -> Unit,
        newGamestartAngle: (Double) -> Unit,
        newGameAngle: (Int) -> Unit,
        returnTask: (String) -> Unit,
        resetUserSweep: (Double) -> Unit,
        setStrokeColor: (Color) -> Unit,
        setFeedback: (String) -> Unit,
        increaseFeedbackCounter: () -> Int,
    ) {
        val coroutineScope = rememberCoroutineScope()

        Column(horizontalAlignment = Alignment.CenterHorizontally) {
            /*   gameAngle
               GameStartAngle
               GameUserSweepAngle
               gameScore
               játékszorzó*/
            Text("$gameScore", fontSize = 50.sp)
            Button(onClick = {
                coroutineScope.launch {

                    var difference = (gameAngle - GameUserSweepAngle).absoluteValue
                    //értékelje a pontosságot
                    if (difference > 50) {
                        //ha nem volt elég pontos, akkor legyen vége a játéknak, hívjon meg egy gameended függvényt
                        setStrokeColor(Color.Red)
                        endgame({ setEndgame(it) })
                    } else {
                        increaseGameScore(1)
                        //adjon pontot
                        // Log.d(TAG, "$GameStartAngle")
                        //  Log.d(TAG, "$GameUserSweepAngle")
                        newGamestartAngle((GameUserSweepAngle + GameStartAngle) % 360)
                        // induljon a mostani szögtől a következő szög
                        coroutineScope.launch {
                            generateGameAngle({ newGameAngle(it) }, { returnTask(it) })
                        }                    // generáljon egy szöget,
                        increaseJátékszorzó(1 + (difference) / 100)
                        // változzon meg a mozgás gyorsasága
                        resetUserSweep(0.0)
                        setStrokeColor(Color.Green)

                    }
                    var gameToast = if (difference<1) {
                        getString(Res.string.correctAngle)
                    } else {
                        getString(
                            Res.string.szogGameDiff,
                            difference.toInt(),//akkor legyen weben egész szám
                        )

                    }
                    //   var gameToast =""
                    setFeedback(gameToast.toString())
                    increaseFeedbackCounter()
                    /*  ShowToast.showToast(Res.style.mytoast,gameToast,context) *///todo ide jöhet a kmm toast
                    //  StyleableToast.makeText(this@Szogbecsles, gameToast,0).show()
                }
            }

            ) {
                Text(stringResource(Res.string.GuessAngleSubmitAngle, gameAngle))
            }

        }

    }

    fun endgame(setEndgame: (Boolean) -> Unit) {
        setEndgame(false)
    }

    suspend fun generateGameAngle(returnAngle: (Int) -> Unit, returnTask: (String) -> Unit) {
        var newSweep = (5..360).random()
        returnAngle(newSweep)
        returnTask(getString(Res.string.ClickAtThisAngle, newSweep))
    }


    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun userInput(
        tippeltszög: String,
        setString: (String) -> Unit,
        feladattípus: Int,
        guessSweepAngle: Int = 0,
        setGuessStart: (Int) -> Unit,
        setGuessSweep: (Int) -> Unit,
        guessStartAngle: Int,
         SetTaskText: ((String) -> Unit)?,
        feedBackText: MutableState<String>,
        numberofsolved: MutableState<Int>
    ) {
        val coroutineScope = rememberCoroutineScope()

        if (feladattípus == DRAW_ANGLE) {
            Button(onClick = {
                coroutineScope.launch {
                    Check(
                        feladattípus = feladattípus,
                        TaskSweepAngle = guessSweepAngle,
                        setGuessStart = { setGuessSweep(it) },
                        setGuessSweep = { setGuessSweep(it) },
                        SetTaskText = {
                            if (SetTaskText != null) {
                                SetTaskText(it)
                            }
                        },
                        feedBackText = feedBackText,
                        numberofsolved = numberofsolved
                    )

                }
                // Check(feladattípus = feladattípus)
            }) {
                Text(stringResource(Res.string.GuessAngleSubmitAngle, guessSweepAngle))
            }
        }

        if (feladattípus == GUESS_ANGLE) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Text(stringResource(Res.string.guessAngleResponse))
                Spacer(Modifier.width(4.dp))
                OutlinedTextField(
                    value = tippeltszög,
                    onValueChange = { setString(it) },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number,
                        imeAction = ImeAction.Done
                    ),
                    modifier = Modifier.width(80.dp).onPreviewKeyEvent { event ->
                        if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                            var guessedAngle = try {
                                tippeltszög.toInt()
                            } catch (e: Exception) {
                                return@onPreviewKeyEvent false
                            }
                            coroutineScope.launch {
                                Check(
                                    tippeltszög = guessedAngle,
                                    feladattípus,
                                    guessSweepAngle,
                                    { setGuessSweep(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    null,
                                    feedBackText,
                                    numberofsolved
                                )
                            }
                            setString("")
                            true // Indicate that the event was handled
                        } else {
                            false // Indicate that the event was not handled
                        }
                    },
                    keyboardActions = KeyboardActions(
                        onDone = {
                            var guessedAngle = try {
                                tippeltszög.toInt()
                            } catch (e: Exception) {
                                return@KeyboardActions
                            }
                            coroutineScope.launch {
                                Check(
                                    tippeltszög = guessedAngle,
                                    feladattípus,
                                    guessSweepAngle,
                                    { setGuessSweep(it) },
                                    { setGuessSweep(it) },
                                    guessStartAngle,
                                    null,
                                    feedBackText,
                                    numberofsolved
                                )
                            }
                            setString("")
                        }
                    )
                )
                Spacer(Modifier.width(4.dp))
                Button(onClick = {
                    var guessedAngle = try {
                        tippeltszög.toInt()
                    } catch (e: Exception) {
                        return@Button
                    }
                    coroutineScope.launch {
                        Check(
                            tippeltszög = guessedAngle,
                            feladattípus,
                            guessSweepAngle,
                            { setGuessSweep(it) },
                            { setGuessSweep(it) },
                            guessStartAngle,
                            null,
                            feedBackText,
                            numberofsolved
                        )
                    }
                    setString("")

                }) {
                    Text(stringResource(Res.string.Check))
                }
            }
        }
    }

    suspend fun Check(
        tippeltszög: Int = 0,
        feladattípus: Int,
        TaskSweepAngle: Int = 0,
        setGuessStart: (Int) -> Unit,
        setGuessSweep: (Int) -> Unit,
        guessStartAngle: Int = 0,
         SetTaskText: ((String) -> Unit)?,
        feedBackText: MutableState<String>,
        numberofsolved: MutableState<Int>
    ) {
        when (feladattípus) {
            GUESS_ANGLE -> {
                var difference = 0
                difference = (TaskSweepAngle - tippeltszög).absoluteValue
                var feedBack = if (difference == 0) {
                    getString(Res.string.correctAngle)
                } else {
                    getString(Res.string.guessAngleDiff, difference, TaskSweepAngle, tippeltszög)
                }

                feedBackText.value = feedBack
                numberofsolved.value++
                // ShowToast.showToast(Res.style.mytoast,Text,context)
                //   StyleableToast.makeText(this@Szogbecsles, Text,1,0).show()
                generateTask(
                    feladattípus,
                    { setGuessStart(it) },
                    { setGuessSweep(it) },
                    guessStartAngle,
                    null
                )
            }

            DRAW_ANGLE -> {
                var difference = 0
                difference = (TaskSweepAngle - evalEndangle.toInt()).absoluteValue
                var feedBack = if (difference == 0) {
                    getString(Res.string.correctAngle)
                } else {
                    getString(
                        Res.string.drawAngleDiff,
                        difference,
                        TaskSweepAngle,
                        evalEndangle.toInt()
                    )
                }

                // ShowToast.showToast(Res.style.mytoast,Text,context)
                feedBackText.value = feedBack
                numberofsolved.value++
                //    StyleableToast.makeText(this@Szogbecsles, Text,1,0).show()
                generateTask(
                    feladattípus,
                    { setGuessStart(it) },
                    { setGuessSweep(it) },
                    SetTaskText = {
                        if (SetTaskText != null) {
                            SetTaskText(it)
                        }
                    })
            }
        }
    }

    @Composable
    fun canvasSurface(
        feladattípus: Int,
        guessStartAngle: Int,
        guessSweepAngle: Int,
        GameStartAngle: Double = 0.0, gameSweepAngle: Double = 0.0,
        strokeColor: Color,
        maxheight: Float
    ) {
        val showStroke = remember { Animatable(Color.Transparent) }
        Surface(
            modifier = Modifier
                .fillMaxHeight(maxheight)
                .aspectRatio(1f),
            // .padding(top = 16.dp)
            shape = MaterialTheme.shapes.large,
            color = Color.Transparent, /*MaterialTheme.colors.onSurface.copy(0.05f)*/
            border = BorderStroke(
                6.dp,
                showStroke.value
            ),//todo ez változzon a transparent-zöld illetve transparent-piros tengelyen

        ) {
            LaunchedEffect(GameStartAngle) {
                showStroke.animateTo(strokeColor)
                delay(400)
                showStroke.animateTo(Color.Transparent)
            }
            myCanvas(feladattípus, guessStartAngle, guessSweepAngle, GameStartAngle, gameSweepAngle)
        }

    }

    var evalEndangle = 0f

    @OptIn(ExperimentalComposeUiApi::class)
    @Composable
    fun myCanvas(
        feladattípus: Int,
        guessStartAngle: Int,
        guessSweepAngle: Int,
        GameStartAngle: Double = 0.0,
        gameSweepAngle: Double = 0.0
    ) {
        var offsetX by rememberSaveable { mutableStateOf(0f) }
        var offsetY by rememberSaveable { mutableStateOf(0f) }
        val scope = rememberCoroutineScope()

        var Modifier = Modifier
            .fillMaxSize()
            .background(Color.Transparent/*MaterialTheme.colors.onSurface.copy(0.05f)*/)
            /*.pointerInput(Unit) {
                detectTapGestures(
                    onLongPress = {
                        println("tapped")
                        offsetY = it.y
                        offsetX = it.x
                    }
                )
            }*/
            .pointerInput(Unit) {
               /* detectTapGestures(
                    onPress = { offset ->
                        // Update offsets based on the pointer's position
                        offsetX = offset.x
                        offsetY = offset.y
                        // Wait for the gesture to end
                        tryAwaitRelease()
                    }
                )*/

                /*detectDragGestures { change, dragAmount ->
                    change.consume()
                    offsetX += dragAmount.x
                    offsetY += dragAmount.y
                }*/
            }
            .pointerInput(Unit) {
                awaitPointerEventScope {
                    while (true) {
                        val event = awaitPointerEvent()
                        if (event.type == PointerEventType.Press/*||event.type == PointerEventType.Move*/ ) {//todo kísérletezni érintőképernyőn
                            val position = event.changes.first().position
                            offsetX = position.x
                            offsetY = position.y
                        }
                    }
                }
            }
           /* .pointerInteropFilter {
                offsetX = it.x
                offsetY = it.y
                // println("(${it.x};${it.y})")
                true
            }*/
/* //ezzel még fél méternyi errort dob a kotlin/js
        Modifier = if (feladattípus == GAME) {
            Modifier.clickable(enabled = false) { //itt se legyen akkor kattintható. elég a gomb
                //Log.d(TAG,"kopp")
            }
        } else {
            Modifier.clickable(enabled = false) {}
        }*/
        var showangle by rememberSaveable { mutableStateOf(270f) }
        val animatedAngle by animateFloatAsState(targetValue = showangle.toFloat())
        var endangle by rememberSaveable { mutableStateOf(270f) }
        Canvas(modifier = Modifier) {

            val canvasWidth = size.width
            val canvasHeight = size.height
            var b1 = offsetX - canvasWidth / 2
            var b2 = canvasHeight / 2 - offsetY
            // println("b($b1,$b2)")

            var a1 = 0f
            var a2 = (canvasHeight / 2)
            val endangle =
                (acos((a1 * b1 + a2 * b2) / ((sqrt(a1 * a1 + a2 * a2) * sqrt(b1 * b1 + b2 * b2)))) * 180f / PI).toFloat()
            showangle = if (offsetX < canvasWidth / 2) {
                180f + (180f - endangle)
            } else {
                endangle
            }
            evalEndangle = showangle
            //  println("endangle is $endangle")
            //  println("showangle is $showangle")

            /* drawCircle(
                 color = Color.Yellow,
                 center = Offset(x = canvasWidth / 2, y = canvasHeight / 2),
                 radius = 6f
             )*/
            /* drawCircle(
                 color = Color.Blue,
                 center = Offset(x =/* canvasWidth / 2+*/offsetX, y = /*canvasHeight / 2+*/offsetY),
                 radius = 5f
             )*/
            /* drawLine(
                 color = Color.Cyan,
                 start= Offset(canvasWidth / 2,canvasHeight / 2 ),
                 end= Offset(offsetX, offsetY),
                 strokeWidth = 3f
             )*/
            /* drawLine(
                 color = Color.Red,
                 start=Offset(canvasWidth / 2,canvasHeight / 2 ),
                 end=Offset(a1,a2),
                 strokeWidth = 3f
             )*/

            // ez az arc a szögrajzolás
            when (feladattípus) {
                GUESS_ANGLE -> {
                    drawArc(
                        color = matekitcolor,//magenta volt korábban
                        startAngle = guessStartAngle.toFloat(),
                        sweepAngle = guessSweepAngle.toFloat(),
                        useCenter = true,
                        // alpha = 0.75f
                    )
                }

                DRAW_ANGLE -> {
                    drawArc(
                        color = matekitcolor,
                        startAngle = 270f,
                        sweepAngle = animatedAngle,
                        useCenter = true,
                        //  alpha = 0.75f
                    )
                }

                GAME -> {
                    drawArc(
                        color = matekitcolor,
                        startAngle = GameStartAngle.toFloat(),
                        sweepAngle = gameSweepAngle.toFloat(),// ezek  egyenlőek folyamatosan változó értékekkel
                        useCenter = true,
                        // alpha = 0.75f
                    )
                }
            }


        }
        LaunchedEffect(key1 = endangle) {
            //println("endangle is $endangle")
        }

    }


}