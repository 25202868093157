package matekit.ui

import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController


@Composable
fun testarea(navController: NavHostController) {

    backButton(navController)
webviewkmm("a^2+B^5=89")

    //val tesztpontok = listOf()
}