package matekit.matekit.taskgenerators

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Button
import androidx.compose.material3.Divider
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
//import matekit.matekit.OrientationChecker
import matekit.matekit.Rational
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.basicfontsizeTextView
import matekit.ui.koordinátarendszer
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.FunctionTask
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource


//import matekit.matekit.mybigfraction.bigfraction.BigFraction
//import matekit.matekit.mybigfraction.bigfraction.BigFraction
//import hm.binkley.kotlin.math.fixed.FixedBigRational

@Composable
fun functionReadingScreen(
    navController: NavHostController,
    focusedTextField: MutableState<MutableState<TextFieldValue>?>,
    tag: String = "FunctionReading",
    ratios: ratios,
    orientation: MutableState<String>
) {
    var vm = viewModel { FunctionReadingViewModel(tag) }
    val ui by vm.uiState.collectAsState()

    LaunchedEffect(Unit) {
        vm.generate()
    }

   var orientation =orientation.value//OrientationChecker().checkOrientation()
    val coroutineScope = rememberCoroutineScope()

    BoxWithConstraints {

        backButton(navController)



        var screenHeight = this.maxHeight

        if (orientation == "Landscape") {

            Row(modifier = Modifier. padding(start=60.dp), horizontalArrangement = Arrangement.Center) {
                content(MaterialTheme.colorScheme.onBackground,//todo localcurrent variables
                    MaterialTheme.colorScheme.background,
                    screenHeight,
                    orientation,
                    ui,
                    { coroutineScope.launch { vm.check() } },
                    { vm.enterNumerator(it) },
                    { vm.enterDenominator(it) },
                    { vm.enterConstant(it) },
                    tag,
                    navController
                )
            }
        } else {//akkor portrait
            Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
                content(MaterialTheme.colorScheme.onBackground,
                    MaterialTheme.colorScheme.background,
                    screenHeight,
                    orientation,
                    ui,
                    { coroutineScope.launch { vm.check() } },
                    { vm.enterNumerator(it) },
                    { vm.enterDenominator(it) },
                    { vm.enterConstant(it) },
                    tag,
                    navController
                )
            }

        }
    }
    responseToast(ui.isCorrect,ui.completed,ui.showsolution)
}

@Composable
fun content(
    mColor: Color,
    textcolor: Color,
    screenHeight: Dp,
    orientation: String,
    ui: FunctionReadingUIState,
    oncheck: () -> Unit,
    enterNumerator: (String) -> Unit ,
    enterDenominator: (String) -> Unit ,
    enterConstant: (String) -> Unit ,
    tag:String,
    navController:NavHostController
) {
    Column(horizontalAlignment = Alignment.CenterHorizontally) {
        Surface(
            border = BorderStroke(2.dp, matekitcolor),
            shape = Shapes().large,
            modifier = Modifier.wrapContentWidth()

                ,
            /*.wrapContentHeight()*/
        ) {
            basicfontsizeTextView(stringResource(Res.string.FunctionTask), Modifier.padding(5.dp))

        }
        koordinátarendszer(mColor, textcolor, ui.linepoints, onTap = {})
    }
    Spacer(modifier = Modifier.fillMaxWidth(0.1f))
    Column(
        verticalArrangement = Arrangement.SpaceEvenly,
        modifier = Modifier/*.height(500.dp)fillMaxHeight()*/
    ) {

        Spacer(modifier = Modifier.heightIn(5.dp, 10.dp))
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceAround
        ) {
            // var Xszámláló by remember { mutableStateOf( "") }
            // var Xnevező by remember { mutableStateOf( "") }
            //var konstans by remember { mutableStateOf( "+") }
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier/*.fillMaxHeight()*/
            ) {
                val számlálóFocusRequest = remember { FocusRequester() }
                val nevezőFocusRequest = remember { FocusRequester() }
                val constantFocusRequest = remember { FocusRequester() }
                Text("Y=", fontSize = 30.sp)
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    OutlinedTextField(
                        value = ui.Xszámláló,
                        textStyle = TextStyle(fontSize = 30.sp),
                        onValueChange = {enterNumerator(it) },
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Done
                        ),
                        modifier = Modifier
                            .width(80.dp)
                            .focusRequester(számlálóFocusRequest),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                nevezőFocusRequest.requestFocus()
                            }
                        )
                    )

                    Divider(
                        modifier = Modifier
                            .width(90.dp)
                            .padding(4.dp), thickness = 3.dp
                    )
                    OutlinedTextField(
                        value = ui.Xnevező,
                        textStyle = TextStyle(fontSize = 30.sp),
                        onValueChange = {enterDenominator(it) },
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Done
                        ),
                        modifier = Modifier
                            .width(80.dp)
                            .focusRequester(nevezőFocusRequest),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                constantFocusRequest.requestFocus()
                            }
                        )
                    )
                }
                Text("X", fontSize = 30.sp)
                /* var KONSTANS by remember {
                     mutableStateOf(
                     TextFieldValue(
                             text = "+",
                         selection = TextRange(1)
                         )
                     )
                 }*/
                OutlinedTextField(
                    value = ui.konstans, //KONSTANS,
                    textStyle = TextStyle(fontSize = 30.sp),
                    onValueChange =
                    {
                        enterConstant( if (it != "") {
                            it.replace("+-", "-")
                        } else {
                            "+"
                        })
                    }

                    /*    { KONSTANS =
                            TextFieldValue(
                                text=  if(it.text!=""){
                                    it.text.replace("+-","-")
                                } else{"+"},
                                selection = TextRange( if(it.text!=""){
                                    it.text.replace("+-","-").length
                                } else{"+".length})
                            )
                        }*/,
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number,
                        imeAction = ImeAction.Done,
                    ),
                    modifier = Modifier
                        .width(80.dp)
                        .focusRequester(constantFocusRequest),
                    keyboardActions = KeyboardActions(
                        onDone = {
//                            check(Xszámláló, Xnevező, konstans,ui[2])
//                            Xszámláló = ""
//                            Xnevező=""
//                            konstans="+"
                            számlálóFocusRequest.requestFocus()
                        }
                    )
                )
            }

            //textfield
            Button(onClick = {
                oncheck()
            }) {
                Text(stringResource(Res.string.Check))

            }
            LatexLogButton(navController,tag)

        }
        if (screenHeight > 150.dp && orientation == "Landscape") {
            // whiteboard(activity = this@FunctionReading, context =context , darkmode =darkmode )//todo
        }
    }


}


data class FunctionReadingUIState(
    var Xszámláló: String = "",
    var Xnevező: String = "",
    var konstans: String = "+",
    var solution: List<Float> = listOf(),
    var meredekség: Rational = Rational(1, 1),
    var linepoints: List<List<Float>> = mutableListOf<List<Float>>(),
    var completed: Int = 0,//majd csak nem old meg több milliárd feladatot
    var showsolution:String="",
    val isCorrect:Boolean=false
)

class FunctionReadingViewModel(tag:String) : ViewModel() {
    val tag = tag

    private val _uiState = MutableStateFlow(FunctionReadingUIState())
    val uiState: StateFlow<FunctionReadingUIState> = _uiState.asStateFlow() //todo itt folytatni


    fun enterNumerator(text: String) {
        _uiState.update { currentState ->
            currentState.copy(
                Xszámláló = text
            )
        }
    }
    fun enterDenominator(text: String) {
        _uiState.update { currentState ->
            currentState.copy(
                Xnevező = text
            )
        }
    }
    fun enterConstant(text: String) {
        _uiState.update { currentState ->
            currentState.copy(
                konstans = text
            )
        }
    }

    fun generate() {
        var Ax = 0f
        var Ay = (-6..6).random().toFloat()
        var By = (-6..5).random().toFloat()
        By = if (By < Ay) By else By + 1f
        var Bx = (-6..5).random().toFloat()
        Bx = if (Bx < 0) Bx else Bx + 1f

        var m: Float = (By - Ay) / Bx
        var drawnA = listOf(Bx + 40, By + 40 * m)
        var drawnB = listOf(Bx - 40, By - 40 * m)




        _uiState.update { currentState ->
            currentState.copy(
                solution = listOf(m, Ay),
                linepoints = listOf(drawnA, drawnB, listOf(m, Ay)),
                meredekség = Rational((By.toInt() - Ay.toInt()), Bx.toInt())
            )
        }
    }

    suspend fun check(
        //  Xszámláló: String, Xnevező: String, konstans: String, solution: List<Float>
    ) {

        var diáksz = try {
            uiState.value.Xszámláló.toFloat()
        } catch (e: Exception) {
            return
        }
        var nevező = try {
            uiState.value.Xnevező.toFloat()
        } catch (e: Exception) {
            1f
        }
        var diákM = diáksz / nevező
        var diákkonstans = try {
            uiState.value.konstans.toFloat()
        } catch (e: Exception) {
            0f
        }
        var helyesMO: Boolean
        var showSolutionIfUserWrong: String
        var showsolution = "${
            uiState.value.meredekség.toString().replace("-1/1*", "-").replace("/1", "")
            //format.format(solution[0])
        }*X" + if (uiState.value.solution[1] != 0f) {
            "+${uiState.value.solution[1].formatToDecimals()}"
        } else {
            ""
        }

        showsolution =showsolution. replace("++", "+").replace("+-", "-").replace("*", "·")
        showSolutionIfUserWrong =
            getString(Res.string.WrongSolution, showsolution).replace("++", "+").replace("+-", "-")

        var userinput =
            "${diáksz.formatToDecimals()}/${nevező.formatToDecimals()}*X" + if (diákkonstans != 0f) {
                "+${diákkonstans.formatToDecimals()}"
            } else {
                ""
            }
        userinput = userinput.replace("++", "+").replace("+-", "-").replace("/1*", "*")

        var displayedTask = getString(Res.string.FunctionTask)

        if (diákM == uiState.value.solution[0] && diákkonstans == uiState.value.solution[1]) {
            //  Log.d(TAG, "helyes megoldás ")
         //   println("helyes megoldás")
            helyesMO = true
        } else {
            // Log.d(TAG, "helytelen megoldás")
         //   println("hibás megoldás")
            helyesMO = false
        }
        var text = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong

        _uiState.update { currentState ->
            currentState.copy(
                completed = currentState.completed + 1,
                showsolution = showsolution,
                isCorrect = helyesMO,
            )
        }
        enterConstant( "+"       )
        enterNumerator( ""       )
        enterDenominator(   ""     )


        LaTexLogWriteDesktop(uiState.value.isCorrect, userinput,showSolutionIfUserWrong,tag,displayedTask, taskLaTexWrap = false)

        //  var color = if (helyesMO) Res.style.CorrectToast else Res.style.WrongToast
        // ShowToast.showToast(color, text, context, true)
        // SaveUserInput.simplelog(this@FunctionReading,helyesMO,"userinput","showSolutionIfUserWrong","FunctionReading","displayedTask")

        /* SaveUserInput.simplelog(//todo implement
             this@FunctionReading,
             helyesMO,
             userinput,
             showSolutionIfUserWrong,
             "FunctionReading",
             displayedTask,
             userDocRef, modulID =13
         )*/
        generate()
    }


}