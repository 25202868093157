package matekit.matekit.games.szamkiraly

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import matekit.ui.backButton
import matekit.ui.ratios

@Composable
fun SzamkiralyHostUI(
    navController: NavHostController,
    ratios: ratios,
    orientation: MutableState<String>
){

    var startgame = remember { mutableStateOf(false) }//todo ha egyszer működni fog a navigáció kmm könyvtár, akkor a settings screenből navigáljunk a játék screenre
    val gameparams = remember { mutableStateOf(MathKingGameSettings(
        randomTagszam =false,
        osszead = false,
        kivon = false,
        szorzas = false,
        osztas =false,
        tagszam = 0,
        tagSzamMin = 0,
        tagSzamMax = 0,
        korszam = 0,
        min = 0,
        max = 0,
        delay = 0,
        recommendedModifiers = true,
        feladatszam = 0
    )) }
    if(!startgame.value)  backButton(navController)

    Column(Modifier.fillMaxSize(), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
     //todo backbutton


     if(!startgame.value){
         MathKingSettingsScreen(startgame = startgame, navController = navController, gameparams = gameparams)
     }
     else{
         MathKingGameScreen(gameparams.value, onGameEnd = {navController.popBackStack()},ratios=ratios,orientation=orientation)
     }



 }




}