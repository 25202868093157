package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Switch
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onKeyEvent
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
//import matekit.matekit.OrientationChecker
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.generatorSettings
import matekit.ui.opsize
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.uifont
import matekit.ui.widthtype
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.DivisionRemainder
import matekit_multiplatform.composeapp.generated.resources.ExtendedMultiplicationTable
import matekit_multiplatform.composeapp.generated.resources.OperationChain
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.add
import matekit_multiplatform.composeapp.generated.resources.alapmuveletekMaradék
import matekit_multiplatform.composeapp.generated.resources.customrange
import matekit_multiplatform.composeapp.generated.resources.from
import matekit_multiplatform.composeapp.generated.resources.ic_baseline_draw
import matekit_multiplatform.composeapp.generated.resources.remove
import matekit_multiplatform.composeapp.generated.resources.tagszam
import matekit_multiplatform.composeapp.generated.resources.to
import matekit_multiplatform.composeapp.generated.resources.v_letlenszer_tagsz_m
import matekit_multiplatform.composeapp.generated.resources.wrongRemainder
//import matekitonline.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource



data class AlapmuveletekUIState(
    val randomTagszam: Boolean = false,
    val recommendedModifiers: Boolean = true,
    val evalType: String = "simple",
    val previousEvalType:String="simple",
    val addition: Boolean = false,
    val subtraction: Boolean = false,
    val multiplication: Boolean = false,
    val division: Boolean = false,
    val tagSzamMin: Int = 2,
    val tagSzamMax: Int = 5,
    val tagszam: Int = 2,
    val min: Int = 0,
    val max: Int = 20,
    val solutionMin: Int = 0,
    val solutionMax: Int = 10,
    val limitSolution: Boolean = false,
    val showToast: Boolean = true,
    val rangeMin: Int = 0,
    val rangeMax: Int = 20,
    val művelet: String = "false",
    val feladat: String = "",
    val operationsToChooseFrom: List<String> = listOf("+", "-", "*", "/"),
    val operatorFilter: List<Boolean> = listOf(true, true, false, false),
    val operationsInTask: List<String> = emptyList(),
    val szamok: List<Int> = emptyList(),
    val solution: Double = 0.0,
    val solutionRemainder: Int = 0,
    val name: String = "",
    //val fastInput: Boolean = false,
    val displayedTask: String = "",

    val correctAnswer: Boolean = false,
    val showUserInput: String = "",
    val showSolution: String = "",
    val numberofSolvedTasks: Int = 0,
    val settingsShown: Boolean = true,
)

class AlapmuveletekViewModel : ViewModel() { //todo viewmodel ellenőrzése
    private val _uiState = MutableStateFlow(AlapmuveletekUIState())
    val uiState: StateFlow<AlapmuveletekUIState> = _uiState

    fun updateMin(minValue: Int) {
        _uiState.update { it.copy(min = minValue) }
    }

    fun updateMax(maxValue: Int) {
        _uiState.update { it.copy(max = maxValue) }
    }

    fun updateTagszam(tagszamValue: Int) {
        _uiState.update { it.copy(tagszam = tagszamValue) }
    }

    fun updateTagSzamMin(value: Int) {
        _uiState.update { it.copy(tagSzamMin = value) }
    }

    fun updateTagSzamMax(value: Int) {
        _uiState.update { it.copy(tagSzamMax = value) }
    }

    fun updateRandomTagszam(value: Boolean) {
        _uiState.update { it.copy(randomTagszam = value) }
    }

    fun updateOperatorFilter(index: Int, value: Boolean) {
        _uiState.update { currentState ->
            val newOperatorFilter = currentState.operatorFilter.toMutableList()
            newOperatorFilter[index] = value
            currentState.copy(operatorFilter = newOperatorFilter)
        }
    }
    fun updateLimitSolution(value: Boolean) {
        _uiState.update { it.copy(limitSolution = value) }
    }
    fun updateSolutionMin(value: Int) {
        _uiState.update { it.copy(solutionMin = value) }
    }
    fun updateSolutionMax(value: Int) {
        _uiState.update { it.copy(solutionMax = value) }
    }



    fun toggleSettingsShown() {
        _uiState.update { it.copy(settingsShown = !it.settingsShown) }
    }

    fun setPresetExtendedMultiplicationTable() {
        _uiState.update {
            it.copy(
                operatorFilter = listOf(false, false, true, false),
                tagszam = 2,
                randomTagszam = false,
                min = 11,
                max = 20
            )
        }
        newTask()
    }

    fun setPresetOperationChain() {
        _uiState.update {
            it.copy(
                operatorFilter = listOf(true, true, false, false),
                randomTagszam = true,
                tagSzamMin = 5,
                tagSzamMax = 10,
                min = -50,
                max = 50
            )
        }
        newTask()
    }

    fun setCustomRange(minValue: Int, maxValue: Int) {
        _uiState.update {
            it.copy(min = minValue, max = maxValue)
        }
    }

    fun newNum():Int{
        var localmin = uiState.value.min
        var localmax = uiState.value.max
        if (uiState.value.tagszam == 2 && uiState.value.operationsInTask.contains("/")) {
            if (uiState.value.min < 0) {
                localmin = 2
                localmax = 20
            }
        }
        var newnum = (localmin..localmax).filter { it != 0 }.random()
        return newnum
    }

    fun newTask() {
        _uiState.update { currentState ->



            val availableOperations = currentState.operationsToChooseFrom
                .filterIndexed { index, _ -> currentState.operatorFilter[index] }

            if (currentState.min > currentState.max || availableOperations.isEmpty()) {
                // Handle empty range error
                return@update currentState
            }

            val szamok = mutableListOf<Int>()
            val operationsInTask = mutableListOf<String>()
            var feladat = ""

            val tagszam = if (currentState.randomTagszam) {
                (currentState.tagSzamMin..currentState.tagSzamMax).random()
            } else {
                currentState.tagszam
            }

            for (i in 0 until tagszam) {
                szamok.add(newNum())
            }


            for (i in 0 until tagszam - 1) {
                val newOperation = availableOperations.random()
                operationsInTask.add(newOperation)
            }

            feladat += szamok[0]
            operationsInTask.forEachIndexed { index, operator ->
                feladat += operator
                feladat += if (szamok[index + 1] < 0) "(${szamok[index + 1]})" else szamok[index + 1]
            }




            var solution:Double = evaluateExpression(feladat)
            val indexellenőrzéshelyett =0

            if(currentState.limitSolution){//todo azt az int castot érdemes lehet még ellenőrizni
                if(solution.toInt() in currentState.solutionMin..currentState.solutionMax){
                    //akkor jó
                }
                else{
                    //leellenőrizhetjük, hogy lehet-e ilyen feladatot készíteni, ha nem, akkor return

                    //ha igen, akkor készítünk egyet
                    while((solution.toInt() !in currentState.solutionMin..currentState.solutionMax)){

                        if( indexellenőrzéshelyett<200) {//az összes eset manuális ellenőrzése helyett próbáljunk meg 200-szor megfelelő feladatot generálni
                            szamok.clear()
                            for (i in 0 until tagszam) {
                                szamok.add(newNum())
                            }
                            feladat=""
                            feladat += szamok[0]
                            operationsInTask.forEachIndexed { index, operator ->
                                feladat += operator
                                feladat += if (szamok[index + 1] < 0) "(${szamok[index + 1]})" else szamok[index + 1]
                            }

                            solution = evaluateExpression(feladat)
                        }else{
                            return@update currentState
                        }
                    }

                }

            }



        if (operationsInTask.contains("/") && tagszam == 2) solution = (szamok[0] / szamok[1]).toDouble()
            val solutionRemainder = if (operationsInTask.contains("/") && tagszam == 2) {
                szamok[0] % szamok[1]
            } else {
                0
            }
            val recommendedInput = if (operationsInTask.contains("/")) {
                if (tagszam == 2) {
                    "simplemaradek"
                } else {
                    "accurate"
                }
            } else {
                "simple"
            }


            currentState.copy(
                szamok = szamok,
                operationsInTask = operationsInTask,
                feladat = feladat,
                solution = solution,
                solutionRemainder = solutionRemainder,
                displayedTask = feladat.replace("*", "⋅"),
                evalType = recommendedInput,
                //solved = false
            )
        }
    }

    suspend fun checkSolution(integer: String, maradék: String, evalType: String) {


        var integer =try {
            integer.toDouble()
        }catch (e:Exception){
            return@checkSolution
        }
        var maradék = maradék.toIntOrNull() ?: 0

        //println("solution = ${uiState.value.solution}")
       // println("checkSolution called with integer: $integer, maradék: $maradék, evalType: $evalType")
       lateinit var showSolution:String
        _uiState.update { currentState ->
            val maradek = if (evalType == "simplemaradek") maradék else 0
            val tipp: Double = when (evalType) {
                "accurate" -> integer // Implement accurate logic if needed
                "simple", "decimal", "simplemaradek" -> integer
                else -> 0.0
            }

            val solved = when {
                evalType != "simplemaradek" && tipp in (currentState.solution - 0.000001)..(currentState.solution + 0.000001) -> true
                evalType == "simplemaradek" && tipp == currentState.solution && maradek == currentState.solutionRemainder -> true
                else -> false
            }

            val showUserInput = buildString {
                append(tipp.formatToDecimals())
                if (evalType == "simplemaradek") {
                    append(" ${getString(Res.string.DivisionRemainder)}: $maradek")
                }
            }

             showSolution =when(evalType){
                "simplemaradek"-> getString(
                    Res.string.WrongSolution, currentState.solution.formatToDecimals()//todo cserélni egy új stringre. androidon is
                ) + " "+getString(
                    Res.string.wrongRemainder, currentState.solutionRemainder.toString()
                )
                else-> currentState.solution.formatToDecimals()
            }



            // Update the state with the result
            currentState.copy(
                correctAnswer = solved,
                showUserInput = showUserInput,
                showSolution = showSolution,
                numberofSolvedTasks = currentState.numberofSolvedTasks +1,
                previousEvalType = evalType
            )
        }
        val writewrongsolution = when(evalType){
            "simplemaradek"-> getString(
                Res.string.WrongSolution, uiState.value.solution.formatToDecimals()//todo cserélni egy új stringre. androidon is
            ) + " "+getString(
                Res.string.wrongRemainder, uiState.value.solutionRemainder.toString()
            )
            else-> getString(Res.string.WrongSolution, uiState.value.solution.formatToDecimals())
        }

        LaTexLogWriteDesktop(uiState.value.correctAnswer,uiState.value.showUserInput, writewrongsolution,"alapm",uiState.value.displayedTask)//ez jó, mert az aktuális értékre hivatkozunk


        newTask()
    }

    /*  private fun getString(resId: Int): String {
          // Implement a way to get string resources, possibly using a resource manager
          return ""
      }*/
}


@Composable
fun AlapmuveletekScreen( //ui jó
    navController: NavHostController,
    ratios: ratios,
    viewModel: AlapmuveletekViewModel = viewModel { AlapmuveletekViewModel() }
) {
    val uiState by viewModel.uiState.collectAsState()
    val coroutineScope = rememberCoroutineScope()
   // val orientation = OrientationChecker().checkOrientation()

    LaunchedEffect(Unit) {
        viewModel.newTask()
    }






        Box(Modifier.fillMaxSize()){
    Column(modifier = Modifier.fillMaxSize().padding(bottom=60.dp),
        horizontalAlignment = Alignment.CenterHorizontally,) {
        borderedbasicfontsizeTextView(tasktext = uiState.displayedTask)

        Column(horizontalAlignment = Alignment.CenterHorizontally) {
            var egesztipp by rememberSaveable { mutableStateOf("") }
            var maradek by rememberSaveable { mutableStateOf("") }

            Row(
                horizontalArrangement = Arrangement.Center,
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                IconButton(onClick = { viewModel.toggleSettingsShown() }) {
                    Icon(
                        painter = painterResource(Res.drawable.ic_baseline_draw),
                        contentDescription = null
                    )
                }

                Spacer(Modifier.width(16.dp))

                Row(verticalAlignment = Alignment.CenterVertically) {
                    val egeszfocusRequester = remember { FocusRequester() }
                    val maradekfocusRequester = remember { FocusRequester() }

                    OutlinedTextField(
                        value = egesztipp,
                        onValueChange = { egesztipp = it },
                        label = { Text(stringResource(Res.string.Solution)) },
                        modifier = Modifier
                            .width(120.dp)
                            .focusRequester(egeszfocusRequester)
                              .onPreviewKeyEvent { event ->
                                if ((event.key == Key.NumPadEnter||event.key==Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                                    coroutineScope.launch {
                                      //  println("enter pressed")

                                        // Handle the Enter key press
                                        if (uiState.evalType == "simplemaradek") {
                                            maradekfocusRequester.requestFocus()
                                        } else {
                                            viewModel.checkSolution(
                                                egesztipp,
                                                maradek,
                                                uiState.evalType
                                            )
                                            egesztipp = "" // Clear the text field
                                            maradek = ""   // Clear the remainder
                                        }
                                    }
                                    true // Indicate that the event was handled
                                } else {
                                    false // Indicate that the event was not handled
                                }}

                        ,
                        keyboardOptions = KeyboardOptions(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Done
                        ),
                        keyboardActions = KeyboardActions(
                            onDone = {
                                coroutineScope.launch {
                                    if (uiState.evalType == "simplemaradek") {
                                        maradekfocusRequester.requestFocus()
                                    } else {
                                        viewModel.checkSolution(
                                            egesztipp,
                                            maradek,
                                            uiState.evalType
                                        )
                                        egesztipp = ""//todo ez nem igazán mvvm
                                        maradek = ""
                                    }
                                }
                            }
                        )
                    )

                    if (uiState.evalType == "simplemaradek") {
                        Spacer(modifier = Modifier.width(6.dp))
                        OutlinedTextField(
                            value = maradek,
                            onValueChange = { maradek = it },
                            label = { Text(stringResource(Res.string.alapmuveletekMaradék)) },
                            modifier = Modifier
                                .width(120.dp)
                                .focusRequester(maradekfocusRequester)
                                .onPreviewKeyEvent { event ->
                                    if ((event.key == Key.NumPadEnter||event.key==Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                                        coroutineScope.launch {
                                            viewModel.checkSolution(
                                                egesztipp,
                                                maradek,
                                                uiState.evalType
                                            )
                                            egeszfocusRequester.requestFocus()
                                            egesztipp = ""
                                            maradek = ""
                                        }
                                        true // Indicate that the event was handled
                                    } else {
                                        false // Indicate that the event was not handled
                                    }
                                },
                            keyboardOptions = KeyboardOptions(
                                keyboardType = KeyboardType.Number,
                                imeAction = ImeAction.Done
                            ),
                            keyboardActions = KeyboardActions(
                                onDone = {
                                    coroutineScope.launch {
                                        viewModel.checkSolution(
                                            egesztipp,
                                            maradek,
                                            uiState.evalType
                                        )
                                        egeszfocusRequester.requestFocus()
                                        egesztipp = ""
                                        maradek = ""
                                    }
                                }
                            )
                        )
                    }
                }
                Spacer(Modifier.width(16.dp))
                Row( verticalAlignment = Alignment.CenterVertically){
                    Button(onClick = {
                        coroutineScope.launch {
                            viewModel.checkSolution(
                                egesztipp,
                                maradek,
                                uiState.evalType
                            )
                            egesztipp = ""
                            maradek = ""
                        }
                    }) {
                        Text(text = stringResource(Res.string.Check))
                    }
                    Spacer(Modifier.width(8.dp))
                    LatexLogButton(navController,"alapm") //todo legyen simple log
                }




            }

           /* if (orientation == "Portrait") {//enélkül is jó a webes verzió. visszagomb miatt spórolunk a hellyel
                Row(
                    modifier = Modifier
                        .wrapContentHeight()
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceAround
                ) {
                    IconButton(onClick = { viewModel.toggleSettingsShown() }) {
                        Icon(
                            painter = painterResource(Res.drawable.ic_baseline_draw),
                            contentDescription = null
                        )
                    }
                    LatexLogButton(navController, value = "Alapmuveletek")
                }
            }*/
        }

        // Settings Panel
        if (uiState.settingsShown) {
            Spacer(Modifier.height(16.dp))
          //  Column(modifier = Modifier.wrapContentHeight().widthIn(200.dp,600.dp)){
                generatorSettings(
                ) {
                    // Range Settings
                    settingListItem(alpha = alpha1, content = {
                        Text(
                            text = stringResource(Res.string.customrange),
                            fontSize = uifont,
                            modifier = Modifier.padding(8.dp)
                        )
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            OutlinedTextField(
                                value = uiState.min.toString(),
                                onValueChange = { value ->
                                    viewModel.updateMin(value.toIntOrNull() ?: uiState.min)
                                },
                                label = { Text(stringResource(Res.string.from)) },
                                modifier = Modifier.weight(0.5f).widthIn(max=100.dp)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            OutlinedTextField(
                                value = uiState.max.toString(),
                                onValueChange = { value ->
                                    viewModel.updateMax(value.toIntOrNull() ?: uiState.max)
                                },
                                label = { Text(stringResource(Res.string.to)) },
                                modifier = Modifier.weight(0.5f).widthIn(max=100.dp)
                            )
                        }
                    })

                    // Operator Selection
                    settingListItem(alpha = alpha2, content = {
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Checkbox(
                                checked = uiState.operatorFilter[0],
                                onCheckedChange = { viewModel.updateOperatorFilter(0, it) }
                            )
                            Text(
                                text = "+",
                                fontSize = opsize,
                                textAlign = TextAlign.Center
                            )
                            Checkbox(
                                checked = uiState.operatorFilter[1],
                                onCheckedChange = { viewModel.updateOperatorFilter(1, it) }
                            )
                            Text(
                                text = "-",
                                fontSize = opsize,
                                textAlign = TextAlign.Center
                            )
                            Checkbox(
                                checked = uiState.operatorFilter[2],
                                onCheckedChange = { viewModel.updateOperatorFilter(2, it) }
                            )
                            Text(
                                text = "×",
                                fontSize = opsize,
                                textAlign = TextAlign.Center
                            )
                            Checkbox(
                                checked = uiState.operatorFilter[3],
                                onCheckedChange = { viewModel.updateOperatorFilter(3, it) }
                            )
                            Text(
                                text = "/",
                                fontSize = opsize,
                                textAlign = TextAlign.Center
                            )
                        }
                    })

                   /* settingListItem(alpha=alpha1,content={
                           Text("Megoldáshalmaz korlátozása")
                            Switch(uiState.limitSolution, onCheckedChange = { viewModel.updateLimitSolution(it)})
                    }
                    )*/

                    if(uiState.limitSolution){
                    settingListItem(alpha=alpha1,content={
                        OutlinedTextField(
                            value = uiState.solutionMin.toString(),
                            onValueChange = { value ->
                                viewModel.updateSolutionMin(value.toIntOrNull() ?: uiState.min)
                            },
                            label = { Text(stringResource(Res.string.from)) },
                            modifier = Modifier.weight(0.5f).widthIn(max=100.dp)
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        OutlinedTextField(
                            value = uiState.solutionMax.toString(),
                            onValueChange = { value ->
                                viewModel.updateSolutionMax(value.toIntOrNull() ?: uiState.max)
                            },
                            label = { Text(stringResource(Res.string.to)) },
                            modifier = Modifier.weight(0.5f).widthIn(max=100.dp)
                        )

                    })
                    }



                    // Random Tagszam Switch
                    settingListItem(alpha = alpha1, content = {
                        Row(
                            verticalAlignment = Alignment.CenterVertically,
                            horizontalArrangement = Arrangement.Center,
                            modifier = Modifier.wrapContentWidth()
                                .weight(1f, fill = false)
                        ) {
                            Text(
                                text = stringResource(Res.string.v_letlenszer_tagsz_m),
                                fontSize = uifont,
                                // modifier = Modifier.weight(1f)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            Switch(
                                checked = uiState.randomTagszam,
                                onCheckedChange = { viewModel.updateRandomTagszam(it) }
                            )
                            Spacer(modifier = Modifier.width(8.dp))

                            if (!uiState.randomTagszam) {
                                // Fixed tagszam
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    OutlinedTextField(
                                        value = uiState.tagszam.toString(),
                                        onValueChange = { value ->
                                            viewModel.updateTagszam(
                                                value.toIntOrNull() ?: uiState.tagszam
                                            )
                                        },
                                        label = { Text(stringResource(Res.string.tagszam)) },
                                        modifier = Modifier.widthIn(max=90.dp)
                                    )
                                    IconButton(
                                        onClick = {
                                            val newTagszam = uiState.tagszam + 1
                                            viewModel.updateTagszam(newTagszam)
                                        },
                                        modifier = Modifier.size(30.dp)
                                    ) {
                                        Icon(
                                            painter = painterResource(Res.drawable.add),
                                            contentDescription = null
                                        )
                                    }
                                    IconButton(
                                        onClick = {
                                            val newTagszam = (uiState.tagszam - 1).coerceAtLeast(2)
                                            viewModel.updateTagszam(newTagszam)
                                        },
                                        modifier = Modifier.size(30.dp)
                                    ) {
                                        Icon(
                                            painter = painterResource(Res.drawable.remove),
                                            contentDescription = null
                                        )
                                    }
                                }
                            } else {
                                // Random tagszam range
                                Row(verticalAlignment = Alignment.CenterVertically) {
                                    OutlinedTextField(
                                        value = uiState.tagSzamMin.toString(),
                                        onValueChange = { value ->
                                            viewModel.updateTagSzamMin(
                                                value.toIntOrNull() ?: uiState.tagSzamMin
                                            )
                                        },
                                        label = { Text(stringResource(Res.string.from)) },
                                        modifier = Modifier.widthIn(max=60.dp)
                                    )
                                    Spacer(modifier = Modifier.width(8.dp))
                                    OutlinedTextField(
                                        value = uiState.tagSzamMax.toString(),
                                        onValueChange = { value ->
                                            viewModel.updateTagSzamMax(
                                                value.toIntOrNull() ?: uiState.tagSzamMax
                                            )
                                        },
                                        label = { Text(stringResource(Res.string.to)) },
                                        modifier = Modifier.widthIn(max=60.dp)
                                    )
                                }
                            }
                        }


                    })

                    // Preset Buttons
                    settingListItem(alpha = alpha2, content = {
                        Button(onClick = { viewModel.setPresetExtendedMultiplicationTable() }) {
                            Text(stringResource(Res.string.ExtendedMultiplicationTable))
                        }
                        Spacer(modifier = Modifier.width(8.dp))
                        Button(onClick = { viewModel.setPresetOperationChain() }) {
                            Text(
                                stringResource(Res.string.OperationChain),
                                textAlign = TextAlign.Center
                            )
                        }
                    })

                    // Custom Range Buttons
                    settingListItem(alpha = alpha1, content = {
                        Column(
                            verticalArrangement = Arrangement.Center,
                            modifier = Modifier.fillMaxWidth(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            Text(
                                stringResource(Res.string.customrange),
                                fontSize = uifont
                            )
                            Spacer(modifier = Modifier.height(8.dp))
                            val ranges = listOf(
                                1 to 20,
                                1 to 50,
                                1 to 100,
                                1 to 200,
                                -20 to 20,
                                -500 to 500,
                                -1000 to 1000,
                                -2000 to 2000,
                              //  -5000 to 5000 //telefonon nem fér ki
                            )
                            val chunksize = when(ratios.widthtype){
                                widthtype.small->3
                                widthtype.medium->4
                                widthtype.large->4
                            }

                            ranges.chunked(chunksize).forEach { rangeRow ->
                                Row {
                                    rangeRow.forEach { (min, max) ->
                                        Button(
                                            onClick = { viewModel.setCustomRange(min, max) },
                                            modifier = Modifier.padding(end = 8.dp)
                                        ) {
                                            Text("$min..$max")
                                        }
                                    }
                                }
                                Spacer(modifier = Modifier.height(8.dp))
                            }
                        }
                    })

                }
         //   }
            //SettingsPanel(viewModel)
        }
        else{

        }
    }

            responseToast(
                correct=    uiState.correctAnswer,
                numberofsolved = uiState.numberofSolvedTasks,
                solution = uiState.showSolution,
                completeResponse = uiState.showSolution,
                useCompleteFeedbackMessage = uiState.previousEvalType=="simplemaradek", //ha simplemaradek akkor az egész megjelenítendő szöveget átadjuk paraméterként, különben még hozzá kell tenni, hogy "a megoldás xy volt"

            )
           backButton(navController)
        }
}
/*
@Composable
fun SettingsPanel(viewModel: AlapmuveletekViewModel) {
    val uiState by viewModel.uiState.collectAsState()
    val alpha1 = 0.09f
    val alpha2 = 0.175f
    val uifont = 18.sp
    val opsize = 24.sp
    val buttonSpacer = Modifier.width(5.dp)

    Column(
        Modifier
            .fillMaxWidth()
            .verticalScroll(rememberScrollState()),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {

        // Additional settings can be added here...
    }
}

@Composable
fun SettingListItem( alpha: Float,scrollable :Boolean = false, modifier: Modifier = Modifier.padding(2.dp).fillMaxWidth(0.5f),content: @Composable () -> Unit) {
    Surface(
        shape = RoundedCornerShape(12.dp),
        modifier = modifier,//todo adaptív legyen a szélesség
        color = MaterialTheme.colorScheme.onSurface.copy(alpha)

    ) {

        val scrollState = rememberScrollState()
        var modifier = if (scrollable) Modifier. horizontalScroll(scrollState) else Modifier
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround,
            modifier = modifier.padding(top = 6.dp, start = 6.dp, end = 6.dp, bottom = 6.dp).wrapContentWidth()
        ) {
            content()
        }
    }
//SurfaceWBorder(content = {content()}, color = color)
}*/

