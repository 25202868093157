package matekit.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.fadeIn
import androidx.compose.animation.fadeOut
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontFamily
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.dokar.sonner.ToastType
import com.dokar.sonner.Toaster
import com.dokar.sonner.ToasterDefaults
import com.dokar.sonner.rememberToasterState
import kotlinx.coroutines.delay
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.JetBrainsMono_Regular
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.justWrong
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource

@Composable
fun responseToast(correct:Boolean, numberofsolved:Int, solution:Any?=null, nosolution:Boolean = false, useCompleteFeedbackMessage:Boolean=false, completeResponse:String="", neutralcolor:Boolean=false,jetbrainsfont:Boolean = false){//Any, mivel lehet string és lehet int is.

    val fontFamily =if(jetbrainsfont) FontFamily(Font(Res.font.JetBrainsMono_Regular, FontWeight.Normal)) else FontFamily.Serif

    val type = derivedStateOf {
        if(correct) {ToastType.Success}
        else{ToastType.Warning}
    }
    val color = derivedStateOf {

        if(correct) {
            Color.Green
        }else{
            Color.Red
        }
    }
    var border = derivedStateOf {

        if(correct) {
            Color.Green
        }else{
            Color.Red
        }
    }

    if(neutralcolor){border= mutableStateOf(matekitcolor)}

    val visible = rememberSaveable{ mutableStateOf(false)}

    LaunchedEffect(numberofsolved){
        if(
            numberofsolved>0
        ) {
            visible.value = true
            delay(3000)
            visible.value = false
        }
    }
    val fontSize = 36.sp
    val lineheight = 40.sp
    AnimatedVisibility(
        visible = visible.value,
        enter = fadeIn(),
        exit= fadeOut()
    ){
        Box(
            modifier= Modifier.fillMaxSize(),
            contentAlignment = Alignment.Center
        ){


            Surface(
                Modifier.width(400.dp).wrapContentHeight().heightIn(min=200.dp),
                border = BorderStroke(6.dp, border.value),
                shape = Shapes().large,
                color =Color.White //MaterialTheme.colorScheme.surface,
            ) {
                Column(
                    Modifier.fillMaxWidth().wrapContentHeight().padding(16.dp),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center
                ) {
                    if(numberofsolved>0) {
                        //println("numberofSolvedTasks: $numberofsolved")
                        if (correct) {
                            Text(stringResource(Res.string.CorrectSolution), fontSize = fontSize, lineHeight = lineheight,fontFamily=fontFamily)
                        } else {
                            if(useCompleteFeedbackMessage) {
                                Text(completeResponse.toString(), fontSize = fontSize , lineHeight = lineheight,fontFamily=fontFamily   )
                            }else{
                                if(nosolution) Text(stringResource(Res.string.justWrong), fontSize = fontSize, lineHeight = lineheight,fontFamily=fontFamily)
                                else solution?.let {  Text(stringResource(Res.string.WrongSolution, it), fontSize = fontSize, lineHeight = lineheight,fontFamily=fontFamily)}
                            }
                        }
                    }

                }
            }



        }

    }

//background = { Brush.linearGradient(0f to backgroundcolor.value)
  /*  Toaster(state=toaster, border={ BorderStroke(6.dp, color.value) }, shape = { RoundedCornerShape(50)})
    LaunchedEffect(numberofsolved) {
        if(numberofsolved>0) {
            println("numberofSolvedTasks: $numberofsolved")
            if (correct) {
                toaster.show(
                    getString(Res.string.CorrectSolution),
                    duration = ToasterDefaults.DurationLong,
                    type=type.value
                )
            } else {
                if(useCompleteFeedbackMessage) {
                toaster.show(completeResponse)
                }else{
                    if(nosolution)toaster.show(getString(Res.string.justWrong))
                    else solution?.let {  toaster.show(getString(Res.string.WrongSolution, it)) }
                }




            }
        }
    }*/
}