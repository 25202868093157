package matekit.matekit.taskgenerators
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlin.random.Random
import androidx.compose.material3.*
import androidx.navigation.NavHostController

data class IrasbeliUIState(
    val number1Range: IntRange =(1..10),
    val number2Range: IntRange=(1..10),
    val number1: Int,
    val number2: Int,
    val rowLength: Int,
    val operation: String ="+", // "+", "-", "*", "/"
    val height: Int =3, // 3 for addition and subtraction
    val partialResultList: List<String>,
    val displayList: List<String>,
    val unkownlineIndex :Int = 2,
    val divisionRemainder :Int = 0//ezzel egyelőre nem foglalkozunk
)



class IrasbeliViewModel : ViewModel() {

    private val _uiState = MutableStateFlow(
        IrasbeliUIState(
            number1 = 0,
            number2 = 0,
            rowLength = 0,
            operation = "+",
            partialResultList = listOf<String>(),
            displayList = listOf<String>()
        )
    )
    val uiState: StateFlow<IrasbeliUIState> = _uiState

    init {
        generateNewProblem()
    }

    fun generateNewProblem() {
        val number1 = (_uiState.value.number1Range.first .._uiState.value.number1Range.last ).random()
        val number2 = (_uiState.value.number2Range.first.. _uiState.value.number2Range.last ).random()
        val operation = _uiState.value.operation

        val solution = evalirasbeli(number1, number2, operation)


        val partialResults = calculatePartialResults(number1, number2, operation)
        val displayList = getDisplayList(number1, number2, operation, partialResults, solution)
        val unknownlineindex = 2//egyelőre a 2-es indexű sor

        displayList[unknownlineindex] = ""

        _uiState.update {
            it.copy(
                number1 = number1,
                number2 = number2,
                rowLength = maxOf(number1.toString().length, number2.toString().length),
                partialResultList = partialResults,
                displayList = displayList,
                unkownlineIndex = unknownlineindex
            )
        }
    }

    private fun evalirasbeli(number1: Int, number2: Int, operation: String): Int {
val feladat = "$number1 $operation $number2"
        return evaluateExpression(feladat).toInt()//ennek egész számnak kell lennie. ha tört szám, akkor még a maradékot kell majd visszaadni
    }

    private fun getDisplayList(number1: Int, number2: Int, operation: String, partialResults: List<String>, solution: Int): MutableList<String> {
        return when (operation) {
            "+" -> mutableListOf(number1.toString(), number2.toString(),solution.toString())
            "-" -> mutableListOf(number1.toString(), number2.toString(),solution.toString())
            "*" -> {
                mutableListOf("")
            }
            "/" -> {
                mutableListOf("")
            }
            else -> mutableListOf()//default case should not be reached
        }
    }

    fun setOperation(operation: String) {
        _uiState.update { it.copy(operation = operation) }
        generateNewProblem()
    }

    private fun calculatePartialResults(number1: Int, number2: Int, operation: String): List<String> {
        return when (operation) {
            "+" -> listOf("")
            "-" -> listOf("")
            "*" -> {
              listOf("")
            }
            "/" -> {
                listOf("")
            }
            else -> emptyList()//default case should not be reached
        }
    }
}

@Composable
fun IrasbeliScreen(viewModel: IrasbeliViewModel = IrasbeliViewModel(),navController: NavHostController,tag:String="IrasbeliMuveletek") {
    val uiState by viewModel.uiState.collectAsState()

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        // Operation Selection Buttons
        Row(
            modifier = Modifier.fillMaxWidth(),
            horizontalArrangement = Arrangement.SpaceEvenly
        ) {
            Button(onClick = { viewModel.setOperation("+") }) { Text("+") }
            Button(onClick = { viewModel.setOperation("-") }) { Text("-") }
            Button(onClick = { viewModel.setOperation("*") }) { Text("*") }
            //Button(onClick = { viewModel.setOperation("/") }) { Text("/") } //ez egy teljesen más feladat lesz
        }

       // Spacer(modifier = Modifier.height(16.dp))

        // Display the grid of OutlinedTextFields
        DisplayGrid(uiState.displayList, uiState.rowLength, uiState.operation)

      //  Spacer(modifier = Modifier.height(16.dp))

        // Button to generate a new problem
        Button(onClick = { viewModel.generateNewProblem() }) {
            Text("Generate New Problem")
        }
    }
}

@Composable
fun DisplayGrid(displayList: List<String>, rowLength: Int, operation: String,) {
  //  val len =  displayList.maxOf { it.length }
    Column {

        displayList.forEachIndexed { index, string ->

            for( i in rowLength downTo 0){
                OutlinedTextField(
                    value = displayList[index][i].toString(),
                    onValueChange = {},
                )
            }
        }
    }
}