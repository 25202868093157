package matekit.matekit.masodfokufv

import matekit.matekit.utilityfunctions.DrawableLine

data class MasodfokuUIState(
    var tasktype: masodfokutasktype = masodfokutasktype.A,
    var a: Float = 0f,
    var b: Float = 0f,
    var c: Float = 0f,
    var roots:List<Float> = listOf(),
    var x1: Float? = null,
    var x2: Float? = null,
    var n :Int=0,//adunk neki kezdő értéket, ezért ne felejtsük el módosítani, ahol szükséges
    var k:Int=0,
    val userInputs: List<String> = listOf("", "", "", "", "", "", "", ""),
    var numberofsolved: Int = 0,
    var showsolution: String = "",
    var questions: List<String> = listOf("", "", "", "", "", "", "", ""),
    var isCorrect: Boolean = false,
    var solution: List<String> = listOf(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ),//a halmazösszehasonlítások miatt nem stringlistával ellenőrzünk. ezt a logba kerül, meg amikor megmutatjuk a jó válaszokat
    var results: List<Boolean> = listOf(false, false, false, false, false, false, false, false), //helyes/helytelen
    var letnext: Boolean = false,
    var szélsőérték:Pair<Float,Float> = Pair(0f,0f),
    var lines :List<DrawableLine>?=null

)