package matekit.ui

import androidx.compose.foundation.layout.size
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.navigation.NavController
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.ic_results
import org.jetbrains.compose.resources.painterResource

@Composable
fun LatexLogButton(navController: NavController, value:String){
    IconButton(onClick = {

        navController.navigate("latexlog/$value")
        //todo navigáció

    },modifier= Modifier.size(60.dp)){
        Icon( painter = painterResource(Res.drawable.ic_results),
            contentDescription = null, // decorative element
            modifier = Modifier.size(50.dp)
        )
    }
}