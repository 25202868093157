package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
//import com.github.kiprobinson.bigfraction.BigFraction
import kotlinx.coroutines.launch
import matekit.matekit.Rational
import matekit.matekit.toLaTeX
import matekit.ui.alpha2
import matekit.ui.indexedInputField
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.funSlope
import matekit_multiplatform.composeapp.generated.resources.helyettesitesiErtek
import matekit_multiplatform.composeapp.generated.resources.rajtaVanePQ
import matekit_multiplatform.composeapp.generated.resources.zerushely
import org.jetbrains.compose.resources.getString
import matekit.matekit.ui.SegmentedButtonItem
import matekit.matekit.ui.SegmentedButtons
import matekit.matekit.ui.SegmentedButtonsDefaults
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.randomNotZero
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.responseToast
import matekit.ui.webviewkmm
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.igen
import matekit_multiplatform.composeapp.generated.resources.nem_alatta
import matekit_multiplatform.composeapp.generated.resources.nem_folotte
import matekit_multiplatform.composeapp.generated.resources.slope
import org.jetbrains.compose.resources.stringResource
import kotlin.math.absoluteValue

class FunctionAnalysis (
 val tag:String=   "Függvényelemzés",
    val navController: NavHostController,
) {
    val rajta = 0
    val alatta = 1
    val fölötte = 2


            @Composable
            fun UI(){
                val coroutineScope = rememberCoroutineScope()
                // A surface container using the 'background' color from the theme
                Surface(
                    modifier = Modifier.fillMaxSize(),
                    color = MaterialTheme.colorScheme.background
                ) {
                    backButton(navController)
                    val numberofsolved = rememberSaveable { mutableStateOf(0) }
                    val feedback = remember{ mutableStateOf("") }
                    val isCorrect = rememberSaveable { mutableStateOf(false) }


                    var userinputList = remember {
                        mutableStateListOf<String>(
                            "", "", "", ""
                        )
                    }
                    val filter = remember { mutableStateListOf(true, true, true, true) }

                    val whiteboard = rememberSaveable { mutableStateOf(false) }
                    var elemzendőfv = rememberSaveable { mutableStateOf(generate()) }
                    val tasktext =
                        remember {
                            derivedStateOf { elemzendőfv.value.toString() }
                        } //  Válaszolj a következő fügvénnyel kapcsolatos kérdésekre: <br>
                    val questions = listOf<Paramstr>(
                         Paramstr(Res.string.funSlope)
                        ,
                         Paramstr(Res.string.zerushely) ,

                            Paramstr(
                                Res.string.helyettesitesiErtek,
                               listOf( elemzendőfv.value.slope.denominator)
                            )
                        ,
                         Paramstr(Res.string.rajtaVanePQ, listOf(elemzendőfv.value.pont.toCoordinates()))

                    )
                   // responseToast(isCorrect.value, numberofsolved.value, completeResponse =feedback.value, useCompleteFeedbackMessage = true) //ez itt weben nem lesz jó
                    Column(
                        Modifier.padding(bottom=60.dp)//weben backbuttonnak
                    ) {

                       /* MathJaxView(
                            context = context,
                            LaTeX = tasktext.value,
                            darkmode = darkmode,
                            simplefontsize = basicfontsize.sp.pixelSize().toInt()
                        )*/
                        webviewkmm(
                            tasktext.value,extrapadding = true
                        )

                        Surface(
                            modifier = Modifier
                                .fillMaxWidth()
                                .wrapContentHeight()
                                .padding(8.dp),
                            color = MaterialTheme.colorScheme.onSurface.copy(.09f),
                            shape = RoundedCornerShape(12.dp)
                        ) {
                            var scroll = rememberScrollState()
                            Column(
                                Modifier
                                    .padding(8.dp)
                                    .fillMaxWidth()
                                    .verticalScroll(scroll),
                                horizontalAlignment = Alignment.CenterHorizontally,
                            ) {
                                for (i in 0..2) {
                                    indexedInputField(
                                        indexSymbol = questions[i].getparamstr(),
                                        index = i,
                                        userinput = userinputList,
                                        row = false,
                                        jetbrainsfont = false
                                    )
                                    Spacer(modifier = Modifier.height(6.dp))
                                }
                                Text(
                                    text = questions[3].getparamstr(),
                                    fontSize = 24.sp,
                                    textAlign = TextAlign.Center
                                )
                                var selectedindex by rememberSaveable { mutableStateOf(rajta) }
                                var mysegmentedbuttoncolors =
                                    SegmentedButtonsDefaults.colors().copy(

                                        outlineColor = matekitcolor,
                                        indicatorColor = MaterialTheme.colorScheme.onSurface.copy(
                                            alpha2
                                        )

                                    )



                                // a hivatalos experimental verziójában nem lehet könnyen beállítani, hogy ugyanakkorák legyenek a gombok. az "igen" gomb kisebb, mint a másik kettő, mert azok szövegei két sorban jelennek meg
                                /* SingleChoiceSegmentedButtonRow { //experimental official
                                     SegmentedButton(
                                         selected = selectedindex == rajta,
                                         onClick ={ selectedindex = rajta },
                                         shape = SegmentedButtonDefaults.itemShape(
                                             index = 0,
                                             count = 3
                                         ),
                                         modifier = Modifier.fillMaxHeight()
                                     ) {
                                         Text(getString(Res.string.igen))
                                     }
                                     SegmentedButton(
                                         selected = selectedindex == alatta,
                                         onClick ={ selectedindex = alatta },
                                         shape = SegmentedButtonDefaults.itemShape(
                                             index = 1,
                                             count = 3
                                         )
                                     ) {
                                     Text(getString(Res.string.nem_alatta))
                                     }
                                     SegmentedButton(
                                         selected = selectedindex == fölötte,
                                         onClick ={ selectedindex = fölötte },
                                         shape = SegmentedButtonDefaults.itemShape(
                                             index = 2,
                                             count = 3
                                         )
                                     ) {
                                         Text(getString(Res.string.nem_folotte))
                                     }


                                 }*/


                               // MateKITTheme { //ha esetleg kell valamilyen theme
                                    SegmentedButtons {
                                        SegmentedButtonItem(
                                            selected = selectedindex == rajta,
                                            onClick = { selectedindex = rajta },
                                            label = { Text(stringResource(Res.string.igen)) },
                                            colors = mysegmentedbuttoncolors
                                        )//rajta
                                        SegmentedButtonItem(
                                            selected = selectedindex == alatta,
                                            onClick = { selectedindex = alatta },
                                            label = { Text(stringResource(Res.string.nem_alatta)) },
                                            colors = mysegmentedbuttoncolors
                                        )//alatta
                                        SegmentedButtonItem(
                                            selected = selectedindex == fölötte,
                                            onClick = { selectedindex = fölötte },
                                            label = { Text(stringResource(Res.string.nem_folotte)) },
                                            colors = mysegmentedbuttoncolors
                                        )//fölötte
                                    }
                               // }

                                Row(
                                    horizontalArrangement = Arrangement.SpaceAround,
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(top = 4.dp),
                                    verticalAlignment = Alignment.CenterVertically
                                ) {


                                    // drawButton(drawboard = whiteboard) //ha rajzolunk //todo drawbutton legyen a commonui elementek között
                                    Button(onClick = {
                                        userinputList[3] = selectedindex.toString()

                                        coroutineScope.launch {
                                            check(
                                                userinputList, listOf(
                                                    elemzendőfv.value.slopetoString(),
                                                    "${elemzendőfv.value.b}",
                                                    "${
                                                        elemzendőfv.value.slope.numerator + elemzendőfv.value.b
                                                        
                                                    }",
                                                ), filter, elemzendőfv.value
                                                ,numberofsolved,feedback,isCorrect
                                            ) {
                                                for (i in 0..2) {
                                                    userinputList[i] = ""
                                                }
                                                elemzendőfv.value = generate()
                                            }
                                        }


                                    }) {
                                        Text(text = stringResource(Res.string.Check))
                                    }
                                    LatexLogButton(navController = navController, value = tag)
                                }
                            }

                        }
                    }
                    responseToast(isCorrect.value, numberofsolved.value, completeResponse =feedback.value, useCompleteFeedbackMessage = true)//weben ide kell helyezni
                }

            }


    suspend fun check(
        userinput: List<String>,
        solution: List<String>,
        filter: List<Boolean>,
        elemzendőfv: Elemzendőfv,
        numberofsolved :MutableState<Int>,
        feedback :MutableState<String>,
        isCorrect: MutableState<Boolean>,
        function: () -> Unit,

    ) {

  if(userinput.subList(0,3).all { it.isEmpty() }) return //ha valamelyik nem üres, akkor már engedi

        var slope = elemzendőfv.slope
        var q = elemzendőfv.slope * Rational(elemzendőfv.pont.first,1) + Rational(elemzendőfv.b,1) //függvény értéke p helyen
        var rajtavane_megoldókulcs_int = if (q == Rational(elemzendőfv.pont.second,1)) {
            rajta
        } else if (elemzendőfv.pont.second < (q.numerator/q.denominator)) {
            alatta
        } else {
            fölötte
        } //függvény értéke p helyen hogyan viszonyul a megkérdezett pont Y koordinátájához

        var rajtavolte_megoldókulcs = when (rajtavane_megoldókulcs_int) {
            0 -> getString(Res.string.igen)
            1 -> getString(Res.string.nem_alatta)
            2 -> getString(Res.string.nem_folotte)
            else -> {
                ""
            }
        }

        var rajtavolte_response = when (userinput[3].toInt()) {
            0 -> getString(Res.string.igen)
            1 -> getString(Res.string.nem_alatta)
            2 -> getString(Res.string.nem_folotte)
            else -> {
                ""
            }
        }

        var pointquestion = getString(Res.string.rajtaVanePQ, elemzendőfv.pont.toCoordinates())


        var solution = solution.toMutableList()
        solution.add(rajtavolte_megoldókulcs.toString())
        var showSolutionIfUserWrong = ""
        var helyesMO: Boolean
        if (userinput.filterIndexed { index, s -> filter[index] } == solution.filterIndexed { index, s -> filter[index] }) {
            helyesMO = true
        } else {
            helyesMO = false
            showSolutionIfUserWrong = getString(
                Res.string.Solution,
            ) + " " + solutionwas(solution, filter, elemzendőfv, rajtavolte_megoldókulcs)

        }
        var text = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
   //     var color = if (helyesMO) Res.style.CorrectToast else Res.style.WrongToast
       // ShowToast.showToast(color, text, context, true)
        LaTexLogWriteDesktop(
          helyesMO=  helyesMO,
            userinput= solutionwas(userinput, filter, elemzendőfv, rajtavolte_response),
            showSolutionIfUserWrong = showSolutionIfUserWrong,
           activityType = tag,
          displayedTask =   "$$$elemzendőfv$$"+"<br>$pointquestion",
            taskLaTexWrap = false
            //,userDocRef = userDocRef, modulID =14
        )
        feedback.value = text
        numberofsolved.value += 1
        isCorrect.value = helyesMO
        function()
    }

    suspend fun solutionwas(
        solution: List<String>,
        filter: List<Boolean>,
        elemzendőfv: Elemzendőfv,
        rajtavolte: String
    ): String {
        var formatted = ""
        if (filter[0]) formatted += getString(Res.string.slope) + "=${solution[0]}; "
        if (filter[1]) formatted += "y(0)=${solution[1]}; "
        if (filter[2]) formatted += "y(${elemzendőfv.slope.denominator})=${solution[2]}; "
        if (filter[3]) formatted += rajtavolte

        //if (filter[3]) formatted +="
        return formatted
    }

    fun generate(): Elemzendőfv {
        var n = (-8..8).randomNotZero()
        var m = (-8..8).toList().filter { it != 0 && it.absoluteValue != n.absoluteValue }
            .random() //ne legyen se nulla, se ugyanaz a kettő, de még ellentétes előjellel se
        var b = (-8..8).randomNotZero()
        var slope = Rational(n, m)
        var p = 24 * (5..10).random()
        var Δ = listOf<Int>(-1, 0, 1).random()
        var q = (((n * 1.0 / m) * p + b) + Δ).toInt()
        var pont = Pair(p, q) //q a generált pont y koordinátája
        return Elemzendőfv(slope, b, pont)
    }
}




data class Elemzendőfv(
    var slope: Rational,
    var b: Int,
    var pont: Pair<Int, Int>
)  {
    override fun toString(): String {
        return "y=${slope.toLaTeX()}x+$b".replace("+-", "-").replace("++", "+")
    }

    fun slopetoString(): String {
        var string = slope.toString()

        if (slope.denominator == 1) {
            string = slope.numerator.toString()
        }
        return string
    }

}


fun String.LaTeXWrap(): String {
    return "\\[$this\\]"
}

fun String.inlineLaTeXWrap(): String {
    return "\\(${this}\\)"
}

fun Pair<Int, Int>.toCoordinates(): String {
    return "(${this.first};${this.second})"
}