package matekit.matekit.taskgenerators

import androidx.compose.animation.animateContentSize
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.gestures.detectTapGestures
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberUpdatedState
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
//import matekit.matekit.OrientationChecker
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.fromAndroidCanvasCoordinate
import matekit.matekit.utilityfunctions.toAndroidCanvasCoordinate
import matekit.ui.SurfaceWBorder
import matekit.ui.backButton
import matekit.ui.basicfontsizeTextView
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.ReadTheCoordinatesOfThePoint
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.koppinstráFeladat
import matekit_multiplatform.composeapp.generated.resources.markThePoint
import matekit_multiplatform.composeapp.generated.resources.readPoint
import org.jetbrains.compose.resources.stringResource

const val LEOLVASÁS = 1
const val KOPPINTSRÁ = 2


class CoordinateSystemIntroduction(val tag: String = "CoordinateSystemIntroduction", val navController: NavHostController, ratios:ratios) {
    val controller = navController
    val ratios = mutableStateOf(ratios)
    val mytag = tag
    @Composable
    fun coordinateSystemIntroduction(
     //   navController: NavHostController = controller,
      //  tag: String = mytag,
        orientation: MutableState<String>
    ) {


      //  val orientation = ratios.value.orientation//OrientationChecker().checkOrientation()
        var usertipp = remember { mutableStateOf(listOf<Float>()) }
        var taskType = rememberSaveable { mutableStateOf(KOPPINTSRÁ) }
        var numberoftasks = rememberSaveable { mutableStateOf(0) }

        var lastsolution = rememberSaveable { mutableStateOf("") }
        var correctsolution = rememberSaveable { mutableStateOf(false) }

        val size = rememberSaveable{mutableStateOf(6)}

        var taskOffset = rememberSaveable {
            mutableStateOf(
                listOf<Float>(
                    0f, 0f
                )
            )
        }



        LaunchedEffect(Unit) {
            generate(taskOffset,size.value)
        }

        val leolvasás = stringResource(Res.string.readPoint)
        val koppintás = mutableStateOf(
            stringResource(
                Res.string.koppinstráFeladat,
                "(${taskOffset.value[0].toInt()};${taskOffset.value[1].toInt()})"
            )
        )

        val tasktext by derivedStateOf {
            if (taskType.value == LEOLVASÁS) {
                leolvasás
            } else {//akkor KOPPINTSRÁ
                koppintás.value
            }
        }
        // A surface container using the 'background' color from the theme
        Surface(
            modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
        ) {

            backButton(navController = navController)



            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.SpaceAround,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                SurfaceWBorder(content = {
                    basicfontsizeTextView(
                        tasktext, modifier = Modifier.padding(5.dp)
                    )
                }, matekitcolor)/*  Column(
                  modifier = Modifier/*.verticalScroll(rememberScrollState())*//*.fillMaxSize()*/,
                  verticalArrangement = Arrangement.SpaceAround,
                  horizontalAlignment = Alignment.CenterHorizontally
              ) {*/
                if (orientation.value == "Portrait") {
                    //  Column( Modifier.fillMaxHeight(0.8f)/*.verticalScroll(rememberScrollState())*/) {
                    leolvasásBevitel(
                        taskType,
                        taskOffset,
                        usertipp,
                        tasktext,
                        lastsolution,
                        correctsolution,
                        numberoftasks
                    )

                    koordinátarendszer(
                        mClor = MaterialTheme.colorScheme.onBackground,
                        textcolor = MaterialTheme.colorScheme.onPrimary,
                        linePoints = listOf(),
                        onTap = {
                            if (taskType.value == KOPPINTSRÁ) {
                                usertipp.value = it
                                check(
                                    taskType.value,
                                    usertipp,
                                    taskOffset,
                                    tasktext,
                                    lastsolution,
                                    correctsolution,
                                    numberoftasks
                                )
                            }
                        },
                        szélesség = size.value,
                        dotCoordinates = if (LEOLVASÁS == taskType.value) {
                            taskOffset.value
                        } else {
                            listOf<Float>()
                        }
                    )
                    // }
                    beállításokLog(taskType, taskOffset, size)
                } else {
                    Row(
                        modifier = Modifier.fillMaxWidth()/*.wrapContentHeight()*/,
                        horizontalArrangement = Arrangement.SpaceAround
                    ) {
                        Column(
                            modifier = Modifier.fillMaxWidth(0.5f).fillMaxHeight(),
                            horizontalAlignment = Alignment.CenterHorizontally
                        ) {
                            koordinátarendszer(
                                mClor = MaterialTheme.colorScheme.onBackground,
                                textcolor = MaterialTheme.colorScheme.onPrimary,
                                linePoints = listOf(),
                                onTap = {
                                    if (taskType.value == KOPPINTSRÁ) {
                                        usertipp.value = it
                                        check(
                                            taskType.value,
                                            usertipp,
                                            taskOffset,
                                            tasktext,
                                            lastsolution,
                                            correctsolution,
                                            numberoftasks
                                        )
                                    }
                                },
                                szélesség = size.value,
                                dotCoordinates = if (LEOLVASÁS == taskType.value) {
                                    taskOffset.value
                                } else {
                                    listOf<Float>()
                                }

                            )
                            FloatingActionButton(
                                modifier = Modifier.align(Alignment.Start),
                                onClick = { navController.popBackStack() },
                                containerColor = Color.Gray
                            ) {
                                Icon(
                                    imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                                    contentDescription = "Back"
                                )
                            }


                        }
                        Column(
                            modifier = Modifier.widthIn(200.dp, 400.dp).fillMaxWidth()
                                .fillMaxHeight(),
                            verticalArrangement = Arrangement.SpaceAround
                        ) {
                            leolvasásBevitel(
                                taskType,
                                taskOffset,
                                usertipp,
                                tasktext,
                                lastsolution,
                                correctsolution,
                                numberoftasks
                            )
                            beállításokLog(taskType, taskOffset, size)
                            adjustSize(size)

                        }
                    }
                }
                // }

            }
        }

        responseToast(
            correctsolution.value,
            numberofsolved = numberoftasks.value,
            solution = lastsolution.value,
            nosolution = true
        )
    }


   @Composable
    fun adjustSize(value: MutableState<Int>){
       Column(horizontalAlignment = Alignment.CenterHorizontally){
           Text("Koordinátarendszer mérete: ${value.value}")//todo stringresource
           Slider(
               value = value.value.toFloat(), onValueChange = {
                   value.value = it.toInt()
               },
               valueRange = 6f..20f, // Define the range of the slider
               steps = (20-6)/2-1 // Define the number of steps (10 steps for even numbers from 0 to 20)

           )
       }
    }


    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun leolvasásBevitel(
        taskType: MutableState<Int>,
        taskOffset: MutableState<List<Float>>,
        usertipp: MutableState<List<Float>>,
        tasktext: String,
        lastsolution: MutableState<String>,
        correctsolution: MutableState<Boolean>,
        numberoftasks: MutableState<Int>
    ) {
        if (taskType.value == LEOLVASÁS) { //leolvasás beviteli mezők
            var userinputX by rememberSaveable { mutableStateOf("") }
            var userinputY by rememberSaveable { mutableStateOf("") }
            val focusRequesterX = remember { FocusRequester() }
            val focusRequesterY = remember { FocusRequester() }

            Row(
                modifier = Modifier.widthIn(100.dp, 300.dp).fillMaxWidth()
                    .wrapContentHeight(),//Modifier.wrapContentHeight().navigationBarsWithImePadding()
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                OutlinedTextField(value = userinputX,
                    onValueChange = { userinputX = it },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number, imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(onDone = {
                        if (userinputY == "") focusRequesterY.requestFocus()
                        else {
                            try {
                                usertipp.value = listOf(
                                    userinputX.toFloat(), userinputY.toFloat()
                                )
                                check(
                                    taskType.value,
                                    usertipp,
                                    taskOffset,
                                    tasktext,
                                    lastsolution,
                                    correctsolution,
                                    numberoftasks
                                )
                                userinputX = ""
                                userinputY = ""
                            } catch (e: Exception) {
                                return@KeyboardActions
                            }

                        }
                    }),
                    modifier = Modifier.width(80.dp).focusRequester(focusRequesterX),
                    placeholder = { Text("X") })
                OutlinedTextField(value = userinputY,
                    onValueChange = { userinputY = it },
                    keyboardOptions = KeyboardOptions(
                        keyboardType = KeyboardType.Number, imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(onDone = {
                        if (userinputX == "") focusRequesterX.requestFocus()
                        else {
                            try {
                                usertipp.value = listOf(
                                    userinputX.toFloat(), userinputY.toFloat()
                                )
                                check(
                                    taskType.value,
                                    usertipp,
                                    taskOffset,
                                    tasktext,
                                    lastsolution,
                                    correctsolution,
                                    numberoftasks
                                )
                                userinputX = ""
                                userinputY = ""
                                focusRequesterX.requestFocus()
                            } catch (e: Exception) {
                                return@KeyboardActions
                            }

                        }
                    }),
                    modifier = Modifier.width(80.dp).focusRequester(focusRequesterY),
                    placeholder = { Text("Y") })
                Button(onClick = {
                    try {
                        usertipp.value = listOf(
                            userinputX.toFloat(), userinputY.toFloat()
                        )
                        check(
                            taskType.value,
                            usertipp,
                            taskOffset,
                            tasktext,
                            lastsolution,
                            correctsolution,
                            numberoftasks
                        )
                        userinputX = ""
                        userinputY = ""
                        focusRequesterX.requestFocus()
                    } catch (e: Exception) {
                        return@Button
                    }
                }) { Text(stringResource(Res.string.Check)) }
            }

        }
    }

    @Composable
    fun beállításokLog(taskType: MutableState<Int>, taskOffset: MutableState<List<Float>>, size: MutableState<Int>) {

        // Box(modifier=Modifier.fillMaxWidth(), contentAlignment = Alignment.Center){
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            modifier = Modifier.wrapContentHeight()
        ) {//beállítások, log
            Button(onClick = {
                taskType.value = KOPPINTSRÁ
                generate(offset = taskOffset,size.value)
            }) {
                Text(stringResource(Res.string.markThePoint))
            }
            Button(onClick = {
                taskType.value = LEOLVASÁS
                generate(offset = taskOffset,size.value)
            }) {
                Text(stringResource(Res.string.ReadTheCoordinatesOfThePoint))
            }
            //logbutton(context = context, value = TAG)
        }

        // }

    }

    private fun check(
        tasktype: Int,
        userinput: MutableState<List<Float>>,
        taskOffset: MutableState<List<Float>>,
        displayedTask: String,
        lastsolution: MutableState<String>,
        correctsolution: MutableState<Boolean>,
        numberoftasks: MutableState<Int>,
    ) {
        var helyesMO: Boolean
        var showSolutionIfUserWrong: String
        var saveuserinput: String
        var showsolution: String
      //  println("koppintás koordinátái: ${userinput.toString()}")

        var tűrésrangeX = (taskOffset.value[0] - 0.33..taskOffset.value[0] + 0.33)
        var tűrésrangeY = (taskOffset.value[1] - 0.33..taskOffset.value[1] + 0.33)
        var saveuserX = try {
            userinput.value[0].formatToDecimals(2)
        } catch (e: Exception) {
            return
        }
        var saveuserY = try {
            userinput.value[1].formatToDecimals(2)
        } catch (e: Exception) {
            return
        }
        saveuserinput = "($saveuserX;$saveuserY)"
        if (userinput.value[0] in tűrésrangeX && userinput.value[1] in tűrésrangeY) {//jó a megoldás
            correctsolution.value = true
            showSolutionIfUserWrong = ""
        } else {//rossz a megoldás
            correctsolution.value = false/* showSolutionIfUserWrong =
             if (tasktype == KOPPINTSRÁ) getString(R.string.justWrong) else {
                 getString(
                     R.string.WrongSolution,
                     "(${format.format(taskOffset.value[0])};${format.format(taskOffset.value[1])})"
                 )
             }*/
        }
        //}

       // println("helyesmo:${correctsolution.value}")
        /* var text =
         if (helyesMO) getString(R.string.CorrectSolution) else getString(R.string.justWrong)
     var color = if (helyesMO) R.style.CorrectToast else R.style.WrongToast
     ShowToast.showToast(color, text, context ,true)*/ //todo toast

        /* SaveUserInput.simplelog(
             this@CoordinateSystemIntroduction,
             helyesMO,
             saveuserinput,
             showSolutionIfUserWrong,
             TAG,
             displayedTask,
             userDocRef, modulID =12
         )*/
        numberoftasks.value++
        generate(taskOffset)
    }

    fun generate(offset: MutableState<List<Float>>, size: Int = 6) {//size páros egész szám
        val n =size/2
        val range = -n..n
        offset.value = listOf<Float>(range.random().toFloat(), range.random().toFloat())
    }


    @Composable
    fun koordinátarendszer(
        mClor: Color,
        textcolor: Color,
        linePoints: List<List<Float>>,
        szélesség: Int ,//alapbeállítás 6
        onTap: (List<Float>) -> Unit,
        dotCoordinates: List<Float> = listOf()
    ) {
        val modifier = Modifier.aspectRatio(1f).fillMaxHeight().animateContentSize()
        val density = LocalDensity.current
        val textMeasurer = rememberTextMeasurer()

        var cw by remember { mutableStateOf(100f) }
        val unit by derivedStateOf { cw / szélesség }
     //   println("unit=$unit")


        val currentUnit = rememberUpdatedState(unit)
        val currentwidth = rememberUpdatedState(szélesség)

        Canvas(modifier = modifier.padding(12.dp).clipToBounds()/* .pointerInteropFilter {
            var myoffset = listOf<Float>(it.x,it.y)
            var coordinates = myoffset.toAndroidCanvasCoordinate(unit, szélesség)
            onTap(coordinates)
            // println("(${it.x};${it.y})")
            true
        }*/.pointerInput(Unit) {
            detectTapGestures(onTap = { offset ->
                var myoffset = listOf<Float>(offset.x, offset.y)
                var coordinates = myoffset.fromAndroidCanvasCoordinate(currentUnit.value, currentwidth.value)
                onTap(coordinates)
                // todo adja vissza az érintés pontját
                //   println("TAP!")

            })
        }

        ) {
            cw = size.width
            val ch = size.height //unused
            // val unit = cw / szélesség
            drawLine(//x tengely
                start = Offset(x = 0f, y = szélesség / 2.toFloat() * unit),
                end = Offset(x = szélesség.toFloat() * unit, y = szélesség / 2.toFloat() * unit),
                color = mClor.copy(1f)
            )
            drawLine(//y tengely
                start = Offset(x = szélesség / 2.toFloat() * unit, y = 0f),
                end = Offset(x = szélesség / 2.toFloat() * unit, y = szélesség.toFloat() * unit),
                color = mClor.copy(1f)
            )
            var arrowstarts = listOf<List<Float>>(
                listOf(-0.125f, szélesség / 2 - 0.25f), //x tengely
                listOf(0.125f, szélesség / 2 - 0.25f),//x tengely
                listOf(szélesség / 2 - 0.25f, 0.125f),//y tengely
                listOf(szélesség / 2 - 0.25f, -0.125f),//y tengely
                /* szorzással felírva (nem működik)
                listOf(-0.01f*szélesség, 0.48f*szélesség), //x tengely
                listOf(0.01f*szélesség, 0.48f*szélesség),//x tengely
                listOf(0.48f*szélesség, 0.01f*szélesség),//y tengely
                listOf(0.48f*szélesség, -0.01f*szélesség),//y tengely*/
                /*
                korábbi koordináták nem paraméteres felírással:
                    listOf(-0.125f, 5.75f), //x tengely
                listOf(0.125f, 5.75f),//x tengely
                listOf(5.75f, 0.125f),//y tengely
                listOf(5.75f, -0.125f),//y tengely
                */

//            listOf(0.25f, -5.5f),
//            listOf(-0.25f, -5.5f),
//            listOf(-5.5f, -0.25f),
//            listOf(-5.5f, 0.25f)
            )
            var arrowends = listOf<List<Float>>(
                listOf(0f, szélesség / 2.toFloat()),
                listOf(0f, szélesség / 2.toFloat()),
                listOf(szélesség / 2.toFloat(), 0f),
                listOf(szélesség / 2.toFloat(), 0f),
                /* korábbi koordiníták, nem paraméteresen felírva
                 listOf(0f, 6f),
                 listOf(0f, 6f),
                 listOf(6f, 0f),
                 listOf(6f, 0f),
                 */
//            listOf(0f, -6f),
//            listOf(0f, -6f),
//            listOf(-6f, -0f),
//            listOf(-6f, -0f)
            )
            arrowstarts.forEachIndexed { index, list ->
                var start = list.toAndroidCanvasCoordinate(unit, szélesség)
                var end = arrowends[index].toAndroidCanvasCoordinate(unit, szélesség)
                drawLine(
                    start = Offset(x = start[0], y = start[1]),
                    end = Offset(x = end[0], y = end[1]),
                    color = mClor.copy(1f)
                )
            }

            for (i in 0..szélesség) {
                drawLine(
                    start = Offset(x = i * unit, y = 0f),
                    end = Offset(x = i * unit, y = szélesség * unit),
                    color = mClor.copy(0.8f)
                )
                drawLine(
                    start = Offset(x = 0f, y = i * unit),
                    end = Offset(x = szélesség * unit, y = i * unit),
                    color = mClor.copy(0.8f)
                )

                var koordináta =
                    listOf((i - szélesség / 2).toFloat(), 0f).toAndroidCanvasCoordinate(
                        unit,
                        szélesség
                    )
                drawContext.canvas.nativeCanvas.apply {
                    drawText(  //todo debuggolni
                        textMeasurer = textMeasurer, text = buildAnnotatedString {
                            withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                append("${i - szélesség / 2}")
                            }
                        }, topLeft = Offset(
                            koordináta[0],
                            koordináta[1],
                        )
                    )
                }
                drawContext.canvas.nativeCanvas.apply {
                    if (i != szélesség / 2) {
                        drawText(
                            textMeasurer = textMeasurer, text = buildAnnotatedString {
                                withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                    append("${(-szélesség / 2 + i) * -1}")
                                }
                            }, topLeft = Offset(
                                koordináta[1] + 10,
                                koordináta[0],
                            )
                        )
                    }
                }
            }
            var topy = listOf(0.35f, szélesség / 2 - 0.35f).toAndroidCanvasCoordinate(
                unit, szélesség
            ) //(0.35f,5.65f)
            var rightx = listOf(szélesség / 2 - 0.5f, 0.25f).toAndroidCanvasCoordinate(
                unit, szélesség
            ) //(5.5f,0.25f)
            drawContext.canvas.nativeCanvas.apply {
                drawText(
                    textMeasurer = textMeasurer, text = buildAnnotatedString {
                        withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                            append("x")
                        }
                    }, topLeft = Offset(
                        rightx[0],
                        rightx[1],
                    )
                )
                drawText(
                    textMeasurer = textMeasurer, text = buildAnnotatedString {
                        withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                            append("y")
                        }
                    }, topLeft = Offset(
                        topy[0],
                        topy[1],
                    )
                )
            }
            try {
                var ALinepoint = linePoints[0].toAndroidCanvasCoordinate(unit)
                var BLinepoint = linePoints[1].toAndroidCanvasCoordinate(unit)

                drawLine(
                    start = Offset(x = ALinepoint[0], ALinepoint[1]),
                    end = Offset(x = BLinepoint[0], BLinepoint[1]),
                    color = Color.Red,
                    strokeWidth = 3f
                )
            } catch (e: Exception) {
            }

            try {
                val dotDrawCoordinates = dotCoordinates.toAndroidCanvasCoordinate(unit, szélesség)
                // val x = dotDrawCoordinates[0]
                //  val y = dotDrawCoordinates[1]
                // var offset = (Offset(x=dotDrawCoordinates[0],y=dotDrawCoordinates[1]))
                // drawCircle(center = offset,color= matekitcolor, radius = 2f )
                // drawCircle(center =Offset(0f,0f),color= matekitcolor, radius = 25f)

                drawCircle(
                    center = Offset(dotDrawCoordinates[0], dotDrawCoordinates[1]),
                    color = matekitcolor,
                    radius = 20f
                )
            } catch (e: Exception) {
            }/*var b = listOf(5.5f,0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )
        b = listOf(5.5f,-0.5f).toAndroidCanvasCoordinate()
        drawLine(
            start = Offset(x = b[0]*unit, y = b[1]*unit ),
            end = Offset(x = 12f*unit, y = 6f*unit),
            color = Color.Blue
        )*/
        }
    }


    /*

    @Composable
    fun CoordinateSystemScreen(
        viewModel: CoordinateSystemViewModel = viewModel{ CoordinateSystemViewModel() }
    ) {
        val uiState by viewModel.uiState.collectAsState()

        Column(
            modifier = Modifier.fillMaxSize().padding(16.dp),
            verticalArrangement = Arrangement.SpaceAround,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            // Task Display
            Text(
                text = uiState.taskText,
                style = MaterialTheme.typography.titleLarge,
                modifier = Modifier.padding(vertical = 16.dp)
            )

            // User Input Section
            if (uiState.taskType == LEOLVASÁS) {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceAround
                ) {
                    OutlinedTextField(
                        value = uiState.userInputX,
                        onValueChange = { viewModel.updateUserInputX(it) },
                        label = { Text("X") },
                        keyboardOptions = KeyboardOptions.Default.copy(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Next
                        ),
                        modifier = Modifier.width(80.dp)
                    )
                    OutlinedTextField(
                        value = uiState.userInputY,
                        onValueChange = { viewModel.updateUserInputY(it) },
                        label = { Text("Y") },
                        keyboardOptions = KeyboardOptions.Default.copy(
                            keyboardType = KeyboardType.Number,
                            imeAction = ImeAction.Done
                        ),
                        modifier = Modifier.width(80.dp)
                    )
                    Button(onClick = { viewModel.checkSolution() }) {
                        Text("Check")
                    }
                }
            }

            // Coordinate System Display
            koordinátarendszer(
                mClor = MaterialTheme.colorScheme.onBackground,
                textcolor = MaterialTheme.colorScheme.onPrimary,
                linePoints = uiState.linePoints,
                onTap = {listofcoordinates ->
                    viewModel.updateUserInputX(listofcoordinates[0].toString())
                    viewModel.updateUserInputY(listofcoordinates[1].toString())
                    viewModel.checkSolution()
                }
            )

            // Feedback Message
            if (uiState.feedbackMessage.isNotEmpty()) {
                Text(
                    text = uiState.feedbackMessage,
                    color = if (uiState.isCorrect) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.error,
                    modifier = Modifier.padding(vertical = 16.dp)
                )
            }

            // Task Type Buttons
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceAround
            ) {
                Button(onClick = { viewModel.setTaskType(KOPPINTSRÁ) }) {
                    Text("Mark the Point")
                }
                Button(onClick = { viewModel.setTaskType(LEOLVASÁS) }) {
                    Text("Read the Coordinates")
                }
            }
        }

    }



            data class CoordinateSystemUiState(
                val taskText: String = "",
                val usertipp:List<Float> = listOf(0f,0f),
                val feedbackMessage: String = "",
                val isCorrect: Boolean = false,
                val tasktextres: StringResource = Res.string.readPoint,
                val taskType: Int = KOPPINTSRÁ,
                val taskOffset: List<Float> = listOf(0f, 0f)
            )

            class CoordinateSystemViewModel : ViewModel() {

                private val _uiState = MutableStateFlow(CoordinateSystemUiState())
                val uiState: StateFlow<CoordinateSystemUiState> = _uiState

                init {
                    generateNewTask()
                }

                fun generateNewTask() {
                    val newOffset = listOf((-3..3).random().toFloat(), (-3..3).random().toFloat())
                    val taskText = if (_uiState.value.taskType == LEOLVASÁS) {
                        "Read the coordinates of the point."
                    } else {
                        "Tap on the point at (${newOffset[0].toInt()};${newOffset[1].toInt()})"
                    }
                    _uiState.update { it.copy(taskOffset = newOffset, taskText = taskText) }
                }

                fun checkSolution() {
                    val userInputX = _uiState.value.userInputX.toFloatOrNull()
                    val userInputY = _uiState.value.userInputY.toFloatOrNull()
                    val taskOffset = _uiState.value.taskOffset

                    if (userInputX == null || userInputY == null) {
                        _uiState.update { it.copy(feedbackMessage = "Please enter valid numbers.") }
                        return
                    }

                    val toleranceX = taskOffset[0] - 0.33..taskOffset[0] + 0.33
                    val toleranceY = taskOffset[1] - 0.33..taskOffset[1] + 0.33

                    val isCorrect = userInputX in toleranceX && userInputY in toleranceY
                    val feedbackMessage = if (isCorrect) {
                        "Correct Solution!"
                    } else {
                        "Wrong Solution: The correct coordinates were (${taskOffset[0]};${taskOffset[1]})."
                    }

                    _uiState.update {
                        it.copy(
                            feedbackMessage = feedbackMessage,
                            isCorrect = isCorrect,
                            userInputX = "",
                            userInputY = ""
                        )
                    }

                    generateNewTask()
                }

                fun updateUserInputX(input: String) {
                    _uiState.update { it.copy(userInputX = input) }
                }

                fun updateUserInputY(input: String) {
                    _uiState.update { it.copy(userInputY = input) }
                }

                fun setTaskType(type: Int) {
                    _uiState.update { it.copy(taskType = type) }
                    generateNewTask()
                }
            }
    */
}