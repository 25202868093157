package matekit.matekit.utilityfunctions

fun List<Float>.toAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): List<Float> { //lapközéppontos koordinátarendszerből átrakja bal felső középpontosba, és még megszorozza a koordinátát a unit együtthatóval.
    var újX = (this[0] + szélesség/2) * unit
    var újY = (szélesség/2 - this[1]) * unit
    return listOf(újX, újY)
}
fun List<Float>.fromAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): List<Float> {
   // println("fromAndroidCanvasCoordinate unit : $unit")
   // println("szélesség : $szélesség")
    var újX = this[0]  / unit-szélesség/2
    var újY = szélesség/2 - this[1] / unit

   // println("újX : $újX")
    //println("újY : $újY")
    return listOf(újX, újY)
}

fun Pair<Float,Float>.toAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): Pair<Float,Float> {
    var újX = (this.first + szélesség/2) * unit
    var újY = (szélesség/2 - this.second) * unit
    return Pair(újX, újY)
}
fun Pair<Float,Float>.fromAndroidCanvasCoordinate(unit: Float,szélesség:Int = 12): Pair<Float,Float>{
    var újX = this.first  / unit-szélesség/2
    var újY = szélesség/2 - this.second / unit
    return Pair(újX, újY)
}