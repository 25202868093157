package matekit.matekit.games

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.animation.animateColorAsState
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.ArrowBack
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.FloatingActionButton
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import matekit.ui.generatorSettings
import matekit.ui.settingListItem
//import java.lang.Exception

import androidx.compose.material3.Icon
import androidx.compose.material3.OutlinedTextFieldDefaults
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.text.font.FontFamily
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel

import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
//import matekit.matekit.OrientationChecker //weben nem működött, de lehet még meg lehet javítani
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.ratios
import matekit.ui.settingtext
import matekit.ui.theme.matekitcolor
import matekit.ui.uifont
import matekit.ui.widthtype
import matekit_multiplatform.composeapp.generated.resources.JetBrainsMono_Regular
import matekit_multiplatform.composeapp.generated.resources.NewTask
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.checkmarkincircle
import matekit_multiplatform.composeapp.generated.resources.easy
import matekit_multiplatform.composeapp.generated.resources.hard
import matekit_multiplatform.composeapp.generated.resources.medium
import matekit_multiplatform.composeapp.generated.resources.unknown_numbers
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

class MagicSquare ( val navController: NavHostController,val TAG:String = "bűvösnégyzet") {

        @Composable
        fun UI(ratios: ratios, orientation: MutableState<String>) {

                Surface(
                    modifier = Modifier.fillMaxSize(),
                    color = MaterialTheme.colorScheme.background
                ) {
                                    // val density2: Int = LocalConfiguration.current.densityDpi
                    val density: Float =15f// LocalDensity.current.density

                   // val orientation =ratios.orientation//OrientationChecker().checkOrientation()
                    var ismeretlenekszáma = rememberSaveable { mutableStateOf(2f) }
                    var difficulty = rememberSaveable { mutableStateOf(1) }


                   var viewModel = viewModel { squareViewModel(ismeretlenekszáma.value.toInt(), difficulty.value) }
                    val uiState by viewModel.uiState.collectAsState()


                    if (orientation.value == "Portrait") Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.SpaceBetween
                    ) {
                        Settings(ismeretlenekszáma, difficulty)
                        háromszorhármasUI(uiState = uiState, density = density, viewModel = viewModel,ratios=ratios)
                        Checkbutton({ viewModel.check() })
                        //    Newbutton(logic, ismeretlenekszáma.value.toInt(), difficulty.value)
                        AnimatedVisibility(visible = uiState.complete) {
                            Button(onClick = {
                                viewModel.new(ismeretlenekszáma.value.toInt(), difficulty.value)

                            }) {
                                Text(stringResource(Res.string.NewTask))
                            }
                        }
                        FloatingActionButton(
                            // modifier = Modifier.align(Alignment.BottomStart),
                            onClick = { navController.popBackStack() },
                            containerColor = Color.Gray
                        ) {
                            Icon(
                                imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                                contentDescription = "Back"
                            )
                        }

                    }
                    else {
                        Row(
                            modifier = Modifier.fillMaxSize(),
                            horizontalArrangement = Arrangement.SpaceAround,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Spacer(modifier = Modifier.width(16.dp))

                            //  Column(modifier=Modifier.fillMaxSize(0.5f), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally) {
                            háromszorhármasUI(uiState =uiState , .9f, density,viewModel,ratios)

                            //}
                            Spacer(modifier = Modifier.width(16.dp))
                            Column(
                                modifier = Modifier.fillMaxSize(),
                                horizontalAlignment = Alignment.CenterHorizontally,
                                verticalArrangement = Arrangement.SpaceAround
                            ) {
                                FloatingActionButton(
                                    // modifier = Modifier.align(Alignment.BottomStart),
                                    onClick = { navController.popBackStack() },
                                    containerColor = Color.Gray
                                ) {
                                    Icon(
                                        imageVector = Icons.AutoMirrored.Filled.ArrowBack,
                                        contentDescription = "Back"
                                    )
                                }
                                Settings(ismeretlenekszáma, difficulty)
                                Checkbutton({ viewModel.check() })
                                // Newbutton(logic, ismeretlenekszáma.value.toInt(), difficulty.value)
                                AnimatedVisibility(visible = uiState.complete) {
                                    Button(onClick = {
                                        viewModel.new(ismeretlenekszáma.value.toInt(), difficulty.value)

                                    }) {
                                        Text(stringResource(Res.string.NewTask))
                                    }
                                }
                            }
                            Spacer(modifier = Modifier.width(16.dp))

                        }
                    }

                }

        }


    @Composable
    fun Settings(ismeretlenekszáma: MutableState<Float>, difficulty: MutableState<Int>) {
        generatorSettings {
            Column() {
                settingListItem(
                    {
                        Row() {
                            Column() {
                                settingtext(stringResource( Res.string.unknown_numbers) + " ${ismeretlenekszáma.value.toInt()}")
                                Slider(
                                    value = ismeretlenekszáma.value,
                                    onValueChange = { ismeretlenekszáma.value = it },
                                    valueRange = 1f..4f,/*, steps = 1*/
                                    steps = 2
                                )
                            }

                        }
                    },
                    alpha1
                )
                settingListItem(content = {
                    Row() {

                        Button(onClick = { difficulty.value = 1 }) {
                            Text(stringResource(Res.string.easy), fontSize = uifont)
                        }
                        Spacer(modifier = Modifier.width(4.dp))
                        Button(onClick = { difficulty.value = 2 }) {
                            Text(stringResource(Res.string.medium), fontSize = uifont)
                        }
                        Spacer(modifier = Modifier.width(4.dp))

                        Button(onClick = { difficulty.value = 3 }) {
                            Text(stringResource(Res.string.hard), fontSize = uifont)
                        }
                    }

                }, alpha = alpha2)


            }
        }

    }

    @Composable
    fun Checkbutton(check:()->Unit) {
        IconButton(onClick = { check() }, modifier = Modifier.padding(20.dp)) {
            Icon(
                painterResource( Res.drawable.checkmarkincircle),
                contentDescription = "checkmark in circle",
                modifier = Modifier.size(60.dp)
            )
        }
    }
/* //ezt a gombot nem használjuk
    @Composable
    fun Newbutton(logic: squareViewModel, ismeretlenekszáma: Int, difficulty: Int) {
        AnimatedVisibility(visible = logic.complete.value) {
            Button(onClick = {
                logic.new(ismeretlenekszáma.toInt(), difficulty)

            }) {
                Text(stringResource(Res.string.NewTask))
            }
        }
    }*/


    @Composable
    fun háromszorhármasUI(uiState: squareUiState, fill: Float = 0.5f, density: Float, viewModel: squareViewModel,ratios:ratios) {
        Column(
            Modifier
                .fillMaxHeight(fill)
                .aspectRatio(1f),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            for (i in 0..2) {
                Row(Modifier.weight(1f, true)) {
                    for (j in 0..2) {

                        var index = 3 * i + j

                        var label = (uiState.fields[index].label)


                        //remember{ mutableStateOf(logic.fields[index].number.toString())}
                        var filled = uiState.fields[index].filledatstart
                        var enabled = uiState.fields[index].enabled

                        //  var readonly = remember { derivedStateOf {  filled.value&&!enabled.value } }

                        //   var userinput = remember { mutableStateOf("") }
                        var text = if (filled.value) {
                            uiState.fields[index].number
                        } else {
                            uiState.fields[index].uservalue
                        }
                        //remember{ mutableStateOf(logic.fields[index].number.toString())}
                        Field(
                            borderColor = uiState.fields[index].color.value,
                            text = text,
                            label = label,
                            readonly = !enabled,
                            Modifier
                                .weight(1f, true)
                                .fillMaxHeight(),
                            density,
                            ratios,
                            enterText = {viewModel.enterText(it,index)}
                        )
                    }
                }

            }
        }


    }

    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun Field(
        borderColor: Color,
        text: MutableState<String>,
        label: MutableState<String>,
        readonly: Boolean,
        modifier: Modifier = Modifier,
        density: Float,
        ratios: ratios,
        enterText:(String)->Unit
    ) {
        val animateFocusedColor by animateColorAsState(targetValue = matekitcolor)
        val animateunFocusedColor by animateColorAsState(targetValue = borderColor)
        var fontsize by remember { mutableStateOf(400f) }
        when(ratios.widthtype){
            widthtype.small->fontsize=100f
            widthtype.medium ->fontsize=240f
            widthtype.large->fontsize=400f
        }
        var size by remember { mutableStateOf(0f) }
        val jetbrainsmonofamily =  FontFamily(
            Font(Res.font.JetBrainsMono_Regular)
        )
       /* Log.d("bűvösnégyzet", "fontsize: $fontsize")
        Log.d("bűvösnégyzet", "size: $size")
        Log.d("bűvösnégyzet", "density: $density")*/

        Box(contentAlignment = Alignment.Center, modifier = modifier) {
            OutlinedTextField(

                modifier = modifier/*.onSizeChanged { // ez a függvény nem működik windowson
                    size = it.height * 1f
                    fontsize = it.height * 1f / density
                }*/,//,fillMaxWidth(1f/(n.toFloat()))
                value = text.value,
                label = { Text(text = label.value, fontSize = (fontsize / 6).sp, fontFamily = jetbrainsmonofamily) },
                keyboardOptions = KeyboardOptions(
                    keyboardType = KeyboardType.Number,
                    imeAction = ImeAction.Done
                ),
                textStyle = LocalTextStyle.current.copy(
                    textAlign = TextAlign.Center,
                    fontSize = (fontsize / 3).sp,
                    lineHeight = (fontsize * 1).sp
                ),
                readOnly = readonly,
                enabled = !readonly,
                onValueChange = {
                    enterText(it)
                },
                colors = OutlinedTextFieldDefaults.colors(
                    focusedBorderColor = animateFocusedColor,
                    unfocusedBorderColor = animateunFocusedColor,
                    disabledBorderColor = animateunFocusedColor
                ),

                )

        }


    }




}

data class Field(
    var index: Int,
    var number: MutableState<String>,
    var filledatstart: MutableState<Boolean>,//ha filled, akkor disabled, ha nem filled, akkor enabled, tehát bele kell írni a számot
    var enabled: Boolean, //mivel nem az egyes paraméterek változnak meg, hanem a field objektumból generálunk 9 újat, ezért ezeknek nem kell mutable state-nek lenniük többé.
    var color: MutableState<Color>,
    var label: MutableState<String>,
    var uservalue: MutableState<String>,

    )

data class squareUiState(
    var complete :Boolean = false,
    var fields :MutableList<Field> = mutableListOf()
)

class squareViewModel(val ismeretlenekszáma: Int, val difficulty: Int): ViewModel()  {

    private val _uiState = MutableStateFlow(squareUiState())
    val uiState: StateFlow<squareUiState> = _uiState.asStateFlow()


    init {
        new(ismeretlenekszáma, difficulty)
    }

    fun check() {
        for (i in 0..8) {
            _uiState.value.  fields[i].enabled = false
            var userin = ""
            var helyesMegoldás = true
            if (!_uiState.value. fields[i].filledatstart.value) { //ha az adott mezőbe nincs írva valami eredetileg, akkor ott várjuk a diák válaszát
                try {
                    userin =_uiState.value.  fields[i].uservalue.value
                } catch (e: Exception) {
                    // return
                }

            } else {
                userin = _uiState.value. fields[i].number.value//az egyszerűség kedvéért, ha eleve ki van töltve, úgy teszünk, mintha az lett volna beleírva
            }

            /*   Log.d(
               "bűvösnégyzet",
               "Az $i. mező értéke:${fields[i].number}\n beírt érték:  ${fields[i].uservalue.value}"
           )*/
            if (_uiState.value. fields[i].number.value != userin) {
                helyesMegoldás = false
                _uiState.value.  fields[i].color.value = Color.Red
                _uiState.value.   fields[i].label.value =_uiState.value.  fields[i].number.value.toString()
            } else {
                _uiState.value.     fields[i].color.value = Color.Green
                _uiState.value.    fields[i].label.value = "✓"
            }
            // fields[i].enabled.value=false//ez csak azért, hogy ne legyen kijelölve egyik se
        }
      //  _uiState.value.  complete = true
        _uiState.update {
            it.copy(complete = true)
        }

    }

        fun enterText(text:String,index:Int){
        _uiState.update {
            it.copy(fields = it.fields.apply {
                this[index].uservalue.value = text
            })
        }
    }


    fun new(ismeretlenekszáma: Int, difficulty: Int) {

        var kRange = {
            when (difficulty) {
                1 -> {
                    (4..12)
                }

                2 -> {
                    (12..100)
                }

                3 -> {
                    (101..300)
                }

                else -> {
                    (4..12)
                }
            }
        }


        var k = kRange().random()
        var x = (1..(k - 2)).random()
        var y = (1..(k - x)).random()
        var kilenc = (0..8).toList()

        //var ismeretlenek = kilenc.shuffled().takeLast(ismeretlenekszáma)

        var numbers = mutableListOf(
            derivedStateOf { k + x }, derivedStateOf { k - x - y }, derivedStateOf { k + y },
            derivedStateOf { k - x + y }, derivedStateOf { k }, derivedStateOf { k + x - y },
            derivedStateOf { k - y }, derivedStateOf { k + x + y }, derivedStateOf { k - x },
        )
       var newfields = mutableStateListOf<Field>()
        var ismeretlenek = kilenc.shuffled().takeLast(ismeretlenekszáma)
        for (i in 0..8) {
            newfields.add(
                Field(
                    i,
                    mutableStateOf(numbers[i].value.toString()),
                    mutableStateOf(i !in ismeretlenek),
                    i in ismeretlenek,
                    mutableStateOf(Color.Gray),
                    mutableStateOf(""),
                    mutableStateOf("")
                )
            )
        }

        _uiState.update {
            it.copy(fields = newfields,
                complete = false)
        }
    }
}