package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.RangeSlider
import androidx.compose.material3.SegmentedButton
import androidx.compose.material3.SegmentedButtonDefaults
import androidx.compose.material3.SingleChoiceSegmentedButtonRow
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.TextFieldValue
import androidx.compose.ui.text.toUpperCase
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.generatorSettings
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.settingtext
import matekit_multiplatform.composeapp.generated.resources.ArabicToRoman
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.NInterval
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.RomanToArabic
import matekit_multiplatform.composeapp.generated.resources.Solution
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun RomaiSzamScreen(
    navController: NavHostController,
    focusedTextField: MutableState<MutableState<TextFieldValue>?>,
    tag:String="RomaiSzam",
    vm: RomaiSzamViewModel = viewModel {//viewModel
        RomaiSzamViewModel(tag)
    }
) {

    // val lifecycleOwner = LocalLifecycleOwner.current

    // CompositionLocalProvider(LocalLifecycleOwner provides lifecycleOwner) {//todo ez azért van itt mert nem fut telefonon
    // Your composable content here


    LaunchedEffect(Unit) {
        vm.generate()
    }

    val ui by vm.uiState.collectAsState()
val coroutineScope = rememberCoroutineScope()
    backButton(navController)
    Column(
        modifier = Modifier.fillMaxSize().padding(bottom=60.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        //verticalArrangement = Arrangement.Center
    ) {

        ui.displayed.let {
            borderedbasicfontsizeTextView(
                it,
                //     widthModifier = Modifier.widthIn(max = 200.dp)
            )
        }


        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            OutlinedTextField(
                value = ui.userInput, // Bind this to your state
                onValueChange = { vm.userEnterText(it) },
                label = { Text(text = stringResource(Res.string.Solution)) },
                modifier = Modifier
                    .padding(horizontal = 6.dp).onPreviewKeyEvent { event ->
                        if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                            coroutineScope.launch { vm.check()}
                            true // Indicate that the event was handled
                        } else {
                            false // Indicate that the event was not handled
                        }
                    },
            )
            Button(
                onClick = {

                   coroutineScope.launch { vm.check()}

                    //show result()
                },
                modifier = Modifier
                    .padding(horizontal = 6.dp)

            ) {
                Text(text = stringResource(Res.string.Check))
            }

            LatexLogButton(navController,tag)

        }
        generatorSettings(modifier=Modifier) {
            settingListItem(
                {
                    Column() {
                        settingtext("${stringResource(Res.string.NInterval)} ${ui.min}..${ui.max}")
                        RangeSlider(
                            value = (ui.min.toFloat()..ui.max.toFloat()),
                            onValueChange = {
                                vm.onSliderValueChange(
                                    it.start.toInt(),
                                    it.endInclusive.toInt()
                                )
                            },
                            valueRange = 10f..4000f,
                            steps = (4000 - 10) / 5 - 1
                        )
                    }
                },
                alpha1
            )

            settingListItem(content = {
                val options = listOf(
                    stringResource(Res.string.ArabicToRoman),
                    stringResource(Res.string.RomanToArabic),
                )
                SingleChoiceSegmentedButtonRow {
                    options.forEachIndexed { index, s ->
                        SegmentedButton(
                            shape = SegmentedButtonDefaults.itemShape(
                                index = index,
                                count = options.size
                            ),
                            onClick = { vm.onTypechange(index) },
                            selected = index ==ui.selectedIndex
                        ) { Text(s) }
                    }
                }
            }, alpha2)


        }

    }
    responseToast(ui.correctAnswer, ui.solvedtasks,ui.showsolution)

}


data class RomaiSzamUIState(

    var TOArabic: Boolean = false,
    var selectedIndex:Int = 0,
    var min: Int = 5,
    var max: Int = 100,
    var number: Int = 0,
    var displayed: String = "",
    var userInput: String = "",
    var solvedtasks:Int =0,
    var correctAnswer :Boolean = false, //csak onnantól számít az értéke, hogy egy feladat értékelve lett
    var solution:String="",
    var showsolution :String=""
)


class RomaiSzamViewModel(tag: String) : ViewModel() {
val tag = tag
    private val _uiState = MutableStateFlow(RomaiSzamUIState())

    // private val _uiState = MutableStateFlow(RomaiSzamUIState())
    val uiState: StateFlow<RomaiSzamUIState> = _uiState.asStateFlow()


    fun generate() {

        _uiState.update { currentState ->
            var number = (currentState.min..currentState.max).random()


            currentState.copy(
                number = number,
                displayed = if (currentState.TOArabic) {//arab számba váltunk rómaiból
                    getRomanNumber(number)//ezért rómaiba konvertáljuk a számot
                } else {
                    number.toString()
                },
                solution = if(currentState.TOArabic) number.toString() else getRomanNumber(number)

            )
        }

    }

    suspend fun check() {
        var guess = ""
      //  var helyesMO: Boolean =false
        var displayedTask = ""
        var showSolutionIfUserWrong: String =""

        if(uiState.value.userInput.isEmpty()) return

       // val answer = uiState.value.userInput.uppercase()

        if (_uiState.value.TOArabic) {
            displayedTask = getRomanNumber(_uiState.value.number)
            try {
                guess = _uiState.value.userInput.uppercase()
            } catch (e: Exception) {
                return
            }
            if (guess == _uiState.value.number.toString()) {
            //    println("helyes")
             uiState.value.   correctAnswer = true
            } else {
             //   println("helytelen")
                uiState.value.  correctAnswer = false
            }
        } else {
            displayedTask = _uiState.value.number.toString()
            try {
                guess = _uiState.value.userInput.uppercase()
            } catch (e: Exception) {
                return
            }
            uiState.value. correctAnswer= if (guess == uiState.value.solution) {
           //     println("helyes")

                true
            } else {
           //     println("helytelen")
                false
            }
        }

        LaTexLogWriteDesktop( uiState.value. correctAnswer,guess,showSolutionIfUserWrong,tag,displayedTask, taskLaTexWrap = false)


        _uiState.update { currentState ->
            currentState.copy(
                userInput = "",
                showsolution = currentState.solution
            )
        }

        uiState.value.solvedtasks++

        /*  showSolutionIfUserWrong=getString(R.string.WrongSolution, solution)
          var text=if(helyesMO) getString(R.string.CorrectSolution) else showSolutionIfUserWrong
          var color=if(helyesMO) R.style.CorrectToast else R.style.WrongToast
          /*  Snackbar.make(binding.taskRoman, text, Snackbar.LENGTH_SHORT).setAnchorView(binding.romanCheck).setBackgroundTint(Color.Red.toArgb()
                )
                .show()*/

          ShowToast.showToast(color, text, context,true, )
          SaveUserInput.LaTexLogWrite(context,helyesMO,guess,showSolutionIfUserWrong,TAG,displayedTask,userDocRef = userDocRef, modulID =6)*/


        generate()

    }
    fun onTypechange(index: Int) {
        _uiState.update { currentState ->
            currentState.copy(
                TOArabic = index==1,
                selectedIndex = index
            )

        }
        generate()
    }


    fun onSliderValueChange(
        min: Int,
        max: Int,
    ) {
        _uiState.update { currentState ->

            currentState.copy(
                min = min,
                max = max
            )
        }
    }

    fun userEnterText(text: String) {
        _uiState.update { currentState ->
            currentState.copy(
                userInput = text
            )
        }
    }


    fun getRomanNumber(number: Int): String {//todo ellenőrizni, de egy darabig biztos jó
        var string = "I".repeat(number).replace("IIIII", "V")
            .replace("IIII", "IV")
            .replace("VV", "X")
            .replace("VIV", "IX")
            .replace("XXXXX", "L")
            .replace("XXXX", "XL")
            .replace("LL", "C")
            .replace("LXL", "XC")
            .replace("CCCCC", "D")
            .replace("CCCC", "CD")
            .replace("DD", "M")
            .replace("DCD", "CM")
        return string
    }


}