@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package matekit_multiplatform.composeapp.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString1 {
  public val t_pusv_laszt_s: StringResource by 
      lazy { init_t_pusv_laszt_s() }

  public val table: StringResource by 
      lazy { init_table() }

  public val tagszam: StringResource by 
      lazy { init_tagszam() }

  public val takarito_takaritas: StringResource by 
      lazy { init_takarito_takaritas() }

  public val tanar_dolgozatjavitas: StringResource by 
      lazy { init_tanar_dolgozatjavitas() }

  public val tanchaz_question: StringResource by 
      lazy { init_tanchaz_question() }

  public val task: StringResource by 
      lazy { init_task() }

  public val taskgenerators: StringResource by 
      lazy { init_taskgenerators() }

  public val temperature: StringResource by 
      lazy { init_temperature() }

  public val theRound: StringResource by 
      lazy { init_theRound() }

  public val these_are_the_students_of_classX_select_your_account: StringResource by 
      lazy { init_these_are_the_students_of_classX_select_your_account() }

  public val time: StringResource by 
      lazy { init_time() }

  public val timeSinceTakeoff: StringResource by 
      lazy { init_timeSinceTakeoff() }

  public val title_activity_algebrai_azonossagok: StringResource by 
      lazy { init_title_activity_algebrai_azonossagok() }

  public val title_activity_algebrai_azonossagok_dolgozat: StringResource by 
      lazy { init_title_activity_algebrai_azonossagok_dolgozat() }

  public val title_activity_convert_number_base: StringResource by 
      lazy { init_title_activity_convert_number_base() }

  public val title_activity_function_analysis: StringResource by 
      lazy { init_title_activity_function_analysis() }

  public val title_activity_geometria_kockas_lapon: StringResource by 
      lazy { init_title_activity_geometria_kockas_lapon() }

  public val title_activity_irasbeli_muveletek: StringResource by 
      lazy { init_title_activity_irasbeli_muveletek() }

  public val title_activity_irasbeli_muveletek_simple: StringResource by 
      lazy { init_title_activity_irasbeli_muveletek_simple() }

  public val title_activity_kombinatorika: StringResource by 
      lazy { init_title_activity_kombinatorika() }

  public val title_activity_la_te_xlog: StringResource by 
      lazy { init_title_activity_la_te_xlog() }

  public val title_activity_la_tex_test: StringResource by 
      lazy { init_title_activity_la_tex_test() }

  public val title_activity_magic_square: StringResource by 
      lazy { init_title_activity_magic_square() }

  public val title_activity_masodfokufv: StringResource by 
      lazy { init_title_activity_masodfokufv() }

  public val title_activity_number_pyramid: StringResource by 
      lazy { init_title_activity_number_pyramid() }

  public val title_activity_pythagorean_theorem: StringResource by 
      lazy { init_title_activity_pythagorean_theorem() }

  public val title_activity_settings: StringResource by 
      lazy { init_title_activity_settings() }

  public val title_activity_settings_compose: StringResource by 
      lazy { init_title_activity_settings_compose() }

  public val title_activity_sorozatok: StringResource by 
      lazy { init_title_activity_sorozatok() }

  public val title_activity_statisztika: StringResource by 
      lazy { init_title_activity_statisztika() }

  public val title_activity_statisztika_ii: StringResource by 
      lazy { init_title_activity_statisztika_ii() }

  public val title_activity_szamolas_negativ_szamokkal: StringResource by 
      lazy { init_title_activity_szamolas_negativ_szamokkal() }

  public val title_activity_szoveges_feladatok: StringResource by 
      lazy { init_title_activity_szoveges_feladatok() }

  public val title_activity_tizhatvany: StringResource by 
      lazy { init_title_activity_tizhatvany() }

  public val title_perimeter: StringResource by 
      lazy { init_title_perimeter() }

  public val to: StringResource by 
      lazy { init_to() }

  public val trapezoid_1: StringResource by 
      lazy { init_trapezoid_1() }

  public val trapezoid_2: StringResource by 
      lazy { init_trapezoid_2() }

  public val trapezoid_3: StringResource by 
      lazy { init_trapezoid_3() }

  public val triangle: StringResource by 
      lazy { init_triangle() }

  public val triangle_1: StringResource by 
      lazy { init_triangle_1() }

  public val triangle_2: StringResource by 
      lazy { init_triangle_2() }

  public val triangle_3: StringResource by 
      lazy { init_triangle_3() }

  public val triangle_4: StringResource by 
      lazy { init_triangle_4() }

  public val twoMathTests: StringResource by 
      lazy { init_twoMathTests() }

  public val twoMathTests_a: StringResource by 
      lazy { init_twoMathTests_a() }

  public val twoMathTests_b: StringResource by 
      lazy { init_twoMathTests_b() }

  public val twoMathTests_c: StringResource by 
      lazy { init_twoMathTests_c() }

  public val type: StringResource by 
      lazy { init_type() }

  public val under15: StringResource by 
      lazy { init_under15() }

  public val unknown_numbers: StringResource by 
      lazy { init_unknown_numbers() }

  public val unkp: StringResource by 
      lazy { init_unkp() }

  public val v_letlenszer_tagsz_m: StringResource by 
      lazy { init_v_letlenszer_tagsz_m() }

  public val varroda: StringResource by 
      lazy { init_varroda() }

  public val varroda_a: StringResource by 
      lazy { init_varroda_a() }

  public val varroda_b: StringResource by 
      lazy { init_varroda_b() }

  public val varroda_c: StringResource by 
      lazy { init_varroda_c() }

  public val varroda_d: StringResource by 
      lazy { init_varroda_d() }

  public val varroda_e: StringResource by 
      lazy { init_varroda_e() }

  public val veryHard: StringResource by 
      lazy { init_veryHard() }

  public val viraghagymak: StringResource by 
      lazy { init_viraghagymak() }

  public val viragkoto_csokorkeszites: StringResource by 
      lazy { init_viragkoto_csokorkeszites() }

  public val volume: StringResource by 
      lazy { init_volume() }

  public val vonatSebessége: StringResource by 
      lazy { init_vonatSebessége() }

  public val vonatSebessége_a: StringResource by 
      lazy { init_vonatSebessége_a() }

  public val vonatSebessége_b: StringResource by 
      lazy { init_vonatSebessége_b() }

  public val vonatSebessége_c: StringResource by 
      lazy { init_vonatSebessége_c() }

  public val wasIdentitiy: StringResource by 
      lazy { init_wasIdentitiy() }

  public val waterLevel: StringResource by 
      lazy { init_waterLevel() }

  public val whiteboard: StringResource by 
      lazy { init_whiteboard() }

  public val won: StringResource by 
      lazy { init_won() }

  public val wrongRemainder: StringResource by 
      lazy { init_wrongRemainder() }

  public val yes: StringResource by 
      lazy { init_yes() }

  public val zerodivision: StringResource by 
      lazy { init_zerodivision() }

  public val zerushely: StringResource by 
      lazy { init_zerushely() }
}

@InternalResourceApi
internal fun _collectCommonMainString1Resources(map: MutableMap<String, StringResource>) {
  map.put("t_pusv_laszt_s", CommonMainString1.t_pusv_laszt_s)
  map.put("table", CommonMainString1.table)
  map.put("tagszam", CommonMainString1.tagszam)
  map.put("takarito_takaritas", CommonMainString1.takarito_takaritas)
  map.put("tanar_dolgozatjavitas", CommonMainString1.tanar_dolgozatjavitas)
  map.put("tanchaz_question", CommonMainString1.tanchaz_question)
  map.put("task", CommonMainString1.task)
  map.put("taskgenerators", CommonMainString1.taskgenerators)
  map.put("temperature", CommonMainString1.temperature)
  map.put("theRound", CommonMainString1.theRound)
  map.put("these_are_the_students_of_classX_select_your_account",
      CommonMainString1.these_are_the_students_of_classX_select_your_account)
  map.put("time", CommonMainString1.time)
  map.put("timeSinceTakeoff", CommonMainString1.timeSinceTakeoff)
  map.put("title_activity_algebrai_azonossagok",
      CommonMainString1.title_activity_algebrai_azonossagok)
  map.put("title_activity_algebrai_azonossagok_dolgozat",
      CommonMainString1.title_activity_algebrai_azonossagok_dolgozat)
  map.put("title_activity_convert_number_base",
      CommonMainString1.title_activity_convert_number_base)
  map.put("title_activity_function_analysis", CommonMainString1.title_activity_function_analysis)
  map.put("title_activity_geometria_kockas_lapon",
      CommonMainString1.title_activity_geometria_kockas_lapon)
  map.put("title_activity_irasbeli_muveletek", CommonMainString1.title_activity_irasbeli_muveletek)
  map.put("title_activity_irasbeli_muveletek_simple",
      CommonMainString1.title_activity_irasbeli_muveletek_simple)
  map.put("title_activity_kombinatorika", CommonMainString1.title_activity_kombinatorika)
  map.put("title_activity_la_te_xlog", CommonMainString1.title_activity_la_te_xlog)
  map.put("title_activity_la_tex_test", CommonMainString1.title_activity_la_tex_test)
  map.put("title_activity_magic_square", CommonMainString1.title_activity_magic_square)
  map.put("title_activity_masodfokufv", CommonMainString1.title_activity_masodfokufv)
  map.put("title_activity_number_pyramid", CommonMainString1.title_activity_number_pyramid)
  map.put("title_activity_pythagorean_theorem",
      CommonMainString1.title_activity_pythagorean_theorem)
  map.put("title_activity_settings", CommonMainString1.title_activity_settings)
  map.put("title_activity_settings_compose", CommonMainString1.title_activity_settings_compose)
  map.put("title_activity_sorozatok", CommonMainString1.title_activity_sorozatok)
  map.put("title_activity_statisztika", CommonMainString1.title_activity_statisztika)
  map.put("title_activity_statisztika_ii", CommonMainString1.title_activity_statisztika_ii)
  map.put("title_activity_szamolas_negativ_szamokkal",
      CommonMainString1.title_activity_szamolas_negativ_szamokkal)
  map.put("title_activity_szoveges_feladatok", CommonMainString1.title_activity_szoveges_feladatok)
  map.put("title_activity_tizhatvany", CommonMainString1.title_activity_tizhatvany)
  map.put("title_perimeter", CommonMainString1.title_perimeter)
  map.put("to", CommonMainString1.to)
  map.put("trapezoid_1", CommonMainString1.trapezoid_1)
  map.put("trapezoid_2", CommonMainString1.trapezoid_2)
  map.put("trapezoid_3", CommonMainString1.trapezoid_3)
  map.put("triangle", CommonMainString1.triangle)
  map.put("triangle_1", CommonMainString1.triangle_1)
  map.put("triangle_2", CommonMainString1.triangle_2)
  map.put("triangle_3", CommonMainString1.triangle_3)
  map.put("triangle_4", CommonMainString1.triangle_4)
  map.put("twoMathTests", CommonMainString1.twoMathTests)
  map.put("twoMathTests_a", CommonMainString1.twoMathTests_a)
  map.put("twoMathTests_b", CommonMainString1.twoMathTests_b)
  map.put("twoMathTests_c", CommonMainString1.twoMathTests_c)
  map.put("type", CommonMainString1.type)
  map.put("under15", CommonMainString1.under15)
  map.put("unknown_numbers", CommonMainString1.unknown_numbers)
  map.put("unkp", CommonMainString1.unkp)
  map.put("v_letlenszer_tagsz_m", CommonMainString1.v_letlenszer_tagsz_m)
  map.put("varroda", CommonMainString1.varroda)
  map.put("varroda_a", CommonMainString1.varroda_a)
  map.put("varroda_b", CommonMainString1.varroda_b)
  map.put("varroda_c", CommonMainString1.varroda_c)
  map.put("varroda_d", CommonMainString1.varroda_d)
  map.put("varroda_e", CommonMainString1.varroda_e)
  map.put("veryHard", CommonMainString1.veryHard)
  map.put("viraghagymak", CommonMainString1.viraghagymak)
  map.put("viragkoto_csokorkeszites", CommonMainString1.viragkoto_csokorkeszites)
  map.put("volume", CommonMainString1.volume)
  map.put("vonatSebessége", CommonMainString1.vonatSebessége)
  map.put("vonatSebessége_a", CommonMainString1.vonatSebessége_a)
  map.put("vonatSebessége_b", CommonMainString1.vonatSebessége_b)
  map.put("vonatSebessége_c", CommonMainString1.vonatSebessége_c)
  map.put("wasIdentitiy", CommonMainString1.wasIdentitiy)
  map.put("waterLevel", CommonMainString1.waterLevel)
  map.put("whiteboard", CommonMainString1.whiteboard)
  map.put("won", CommonMainString1.won)
  map.put("wrongRemainder", CommonMainString1.wrongRemainder)
  map.put("yes", CommonMainString1.yes)
  map.put("zerodivision", CommonMainString1.zerodivision)
  map.put("zerushely", CommonMainString1.zerushely)
}

internal val Res.string.t_pusv_laszt_s: StringResource
  get() = CommonMainString1.t_pusv_laszt_s

private fun init_t_pusv_laszt_s(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:t_pusv_laszt_s", "t_pusv_laszt_s",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41754, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40118, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50688, 46),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50720, 46),
    )
)

internal val Res.string.table: StringResource
  get() = CommonMainString1.table

private fun init_table(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:table", "table",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41801, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40165, 21),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50735, 25),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50767, 25),
    )
)

internal val Res.string.tagszam: StringResource
  get() = CommonMainString1.tagszam

private fun init_tagszam(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tagszam", "tagszam",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41823, 23),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40187, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50761, 27),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50793, 27),
    )
)

internal val Res.string.takarito_takaritas: StringResource
  get() = CommonMainString1.takarito_takaritas

private fun init_takarito_takaritas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:takarito_takaritas", "takarito_takaritas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50789, 158),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50821, 158),
    )
)

internal val Res.string.tanar_dolgozatjavitas: StringResource
  get() = CommonMainString1.tanar_dolgozatjavitas

private fun init_tanar_dolgozatjavitas(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tanar_dolgozatjavitas", "tanar_dolgozatjavitas",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    50948, 161),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    50980, 161),
    )
)

internal val Res.string.tanchaz_question: StringResource
  get() = CommonMainString1.tanchaz_question

private fun init_tanchaz_question(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tanchaz_question", "tanchaz_question",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51110, 396),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51142, 396),
    )
)

internal val Res.string.task: StringResource
  get() = CommonMainString1.task

private fun init_task(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:task", "task",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41898, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40270, 20),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51558, 24),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51590, 24),
    )
)

internal val Res.string.taskgenerators: StringResource
  get() = CommonMainString1.taskgenerators

private fun init_taskgenerators(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:taskgenerators", "taskgenerators",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41847, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40215, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51507, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51539, 50),
    )
)

internal val Res.string.temperature: StringResource
  get() = CommonMainString1.temperature

private fun init_temperature(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:temperature", "temperature",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41923, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40291, 43),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51583, 47),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51615, 47),
    )
)

internal val Res.string.theRound: StringResource
  get() = CommonMainString1.theRound

private fun init_theRound(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:theRound", "theRound",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41967, 16),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40335, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51631, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51663, 28),
    )
)

internal val Res.string.these_are_the_students_of_classX_select_your_account: StringResource
  get() = CommonMainString1.these_are_the_students_of_classX_select_your_account

private fun init_these_are_the_students_of_classX_select_your_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:these_are_the_students_of_classX_select_your_account",
    "these_are_the_students_of_classX_select_your_account",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    41984, 136),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40364, 132),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51660, 156),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51692, 156),
    )
)

internal val Res.string.time: StringResource
  get() = CommonMainString1.time

private fun init_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:time", "time",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40562, 12),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51894, 12),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51926, 12),
    )
)

internal val Res.string.timeSinceTakeoff: StringResource
  get() = CommonMainString1.timeSinceTakeoff

private fun init_timeSinceTakeoff(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:timeSinceTakeoff", "timeSinceTakeoff",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42121, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40497, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51817, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51849, 76),
    )
)

internal val Res.string.title_activity_algebrai_azonossagok: StringResource
  get() = CommonMainString1.title_activity_algebrai_azonossagok

private fun init_title_activity_algebrai_azonossagok(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_algebrai_azonossagok", "title_activity_algebrai_azonossagok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42279, 79),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40664, 71),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52000, 71),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52032, 71),
    )
)

internal val Res.string.title_activity_algebrai_azonossagok_dolgozat: StringResource
  get() = CommonMainString1.title_activity_algebrai_azonossagok_dolgozat

private fun init_title_activity_algebrai_azonossagok_dolgozat(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_algebrai_azonossagok_dolgozat",
    "title_activity_algebrai_azonossagok_dolgozat",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42186, 92),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40575, 88),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    51907, 92),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    51939, 92),
    )
)

internal val Res.string.title_activity_convert_number_base: StringResource
  get() = CommonMainString1.title_activity_convert_number_base

private fun init_title_activity_convert_number_base(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_convert_number_base", "title_activity_convert_number_base",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42359, 86),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40736, 90),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52072, 90),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52104, 90),
    )
)

internal val Res.string.title_activity_function_analysis: StringResource
  get() = CommonMainString1.title_activity_function_analysis

private fun init_title_activity_function_analysis(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_function_analysis", "title_activity_function_analysis",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42446, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40827, 64),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52163, 64),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52195, 64),
    )
)

internal val Res.string.title_activity_geometria_kockas_lapon: StringResource
  get() = CommonMainString1.title_activity_geometria_kockas_lapon

private fun init_title_activity_geometria_kockas_lapon(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_geometria_kockas_lapon", "title_activity_geometria_kockas_lapon",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42511, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    40892, 121),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52228, 97),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52260, 97),
    )
)

internal val Res.string.title_activity_irasbeli_muveletek: StringResource
  get() = CommonMainString1.title_activity_irasbeli_muveletek

private fun init_title_activity_irasbeli_muveletek(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_irasbeli_muveletek", "title_activity_irasbeli_muveletek",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52423, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52455, 69),
    )
)

internal val Res.string.title_activity_irasbeli_muveletek_simple: StringResource
  get() = CommonMainString1.title_activity_irasbeli_muveletek_simple

private fun init_title_activity_irasbeli_muveletek_simple(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_irasbeli_muveletek_simple", "title_activity_irasbeli_muveletek_simple",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52326, 96),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52358, 96),
    )
)

internal val Res.string.title_activity_kombinatorika: StringResource
  get() = CommonMainString1.title_activity_kombinatorika

private fun init_title_activity_kombinatorika(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_kombinatorika", "title_activity_kombinatorika",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42581, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41014, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52493, 56),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52525, 56),
    )
)

internal val Res.string.title_activity_la_te_xlog: StringResource
  get() = CommonMainString1.title_activity_la_te_xlog

private fun init_title_activity_la_te_xlog(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_la_te_xlog", "title_activity_la_te_xlog",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41067, 45),
    )
)

internal val Res.string.title_activity_la_tex_test: StringResource
  get() = CommonMainString1.title_activity_la_tex_test

private fun init_title_activity_la_tex_test(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_la_tex_test", "title_activity_la_tex_test",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41113, 46),
    )
)

internal val Res.string.title_activity_magic_square: StringResource
  get() = CommonMainString1.title_activity_magic_square

private fun init_title_activity_magic_square(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_magic_square", "title_activity_magic_square",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42634, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41160, 59),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52550, 59),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52582, 59),
    )
)

internal val Res.string.title_activity_masodfokufv: StringResource
  get() = CommonMainString1.title_activity_masodfokufv

private fun init_title_activity_masodfokufv(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_masodfokufv", "title_activity_masodfokufv",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52610, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52642, 66),
    )
)

internal val Res.string.title_activity_number_pyramid: StringResource
  get() = CommonMainString1.title_activity_number_pyramid

private fun init_title_activity_number_pyramid(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_number_pyramid", "title_activity_number_pyramid",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42694, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41220, 65),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52677, 53),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52709, 53),
    )
)

internal val Res.string.title_activity_pythagorean_theorem: StringResource
  get() = CommonMainString1.title_activity_pythagorean_theorem

private fun init_title_activity_pythagorean_theorem(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_pythagorean_theorem", "title_activity_pythagorean_theorem",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52731, 66),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52763, 66),
    )
)

internal val Res.string.title_activity_settings: StringResource
  get() = CommonMainString1.title_activity_settings

private fun init_title_activity_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_settings", "title_activity_settings",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42752, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41350, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52798, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52830, 51),
    )
)

internal val Res.string.title_activity_settings_compose: StringResource
  get() = CommonMainString1.title_activity_settings_compose

private fun init_title_activity_settings_compose(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_settings_compose", "title_activity_settings_compose",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41286, 63),
    )
)

internal val Res.string.title_activity_sorozatok: StringResource
  get() = CommonMainString1.title_activity_sorozatok

private fun init_title_activity_sorozatok(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_sorozatok", "title_activity_sorozatok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42804, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41402, 40),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52850, 44),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52882, 44),
    )
)

internal val Res.string.title_activity_statisztika: StringResource
  get() = CommonMainString1.title_activity_statisztika

private fun init_title_activity_statisztika(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_statisztika", "title_activity_statisztika",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42899, 46),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41501, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52953, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52985, 50),
    )
)

internal val Res.string.title_activity_statisztika_ii: StringResource
  get() = CommonMainString1.title_activity_statisztika_ii

private fun init_title_activity_statisztika_ii(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_statisztika_ii", "title_activity_statisztika_ii",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42845, 53),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41443, 57),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    52895, 57),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    52927, 57),
    )
)

internal val Res.string.title_activity_szamolas_negativ_szamokkal: StringResource
  get() = CommonMainString1.title_activity_szamolas_negativ_szamokkal

private fun init_title_activity_szamolas_negativ_szamokkal(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_szamolas_negativ_szamokkal", "title_activity_szamolas_negativ_szamokkal",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53004, 89),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53036, 89),
    )
)

internal val Res.string.title_activity_szoveges_feladatok: StringResource
  get() = CommonMainString1.title_activity_szoveges_feladatok

private fun init_title_activity_szoveges_feladatok(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_szoveges_feladatok", "title_activity_szoveges_feladatok",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53094, 69),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53126, 69),
    )
)

internal val Res.string.title_activity_tizhatvany: StringResource
  get() = CommonMainString1.title_activity_tizhatvany

private fun init_title_activity_tizhatvany(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:title_activity_tizhatvany", "title_activity_tizhatvany",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53164, 77),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53196, 77),
    )
)

internal val Res.string.title_perimeter: StringResource
  get() = CommonMainString1.title_perimeter

private fun init_title_perimeter(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:title_perimeter", "title_perimeter",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53242, 75),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53274, 75),
    )
)

internal val Res.string.to: StringResource
  get() = CommonMainString1.to

private fun init_to(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:to", "to",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42946, 14),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41556, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53318, 14),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53350, 14),
    )
)

internal val Res.string.trapezoid_1: StringResource
  get() = CommonMainString1.trapezoid_1

private fun init_trapezoid_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trapezoid_1", "trapezoid_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42961, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41575, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53333, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53365, 19),
    )
)

internal val Res.string.trapezoid_2: StringResource
  get() = CommonMainString1.trapezoid_2

private fun init_trapezoid_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trapezoid_2", "trapezoid_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    42981, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41595, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53353, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53385, 19),
    )
)

internal val Res.string.trapezoid_3: StringResource
  get() = CommonMainString1.trapezoid_3

private fun init_trapezoid_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:trapezoid_3", "trapezoid_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43001, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41615, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53373, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53405, 19),
    )
)

internal val Res.string.triangle: StringResource
  get() = CommonMainString1.triangle

private fun init_triangle(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:triangle", "triangle",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43097, 28),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41711, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53469, 32),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53501, 32),
    )
)

internal val Res.string.triangle_1: StringResource
  get() = CommonMainString1.triangle_1

private fun init_triangle_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:triangle_1", "triangle_1",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43021, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41635, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53393, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53425, 18),
    )
)

internal val Res.string.triangle_2: StringResource
  get() = CommonMainString1.triangle_2

private fun init_triangle_2(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:triangle_2", "triangle_2",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43040, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41654, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53412, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53444, 18),
    )
)

internal val Res.string.triangle_3: StringResource
  get() = CommonMainString1.triangle_3

private fun init_triangle_3(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:triangle_3", "triangle_3",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43059, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41673, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53431, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53463, 18),
    )
)

internal val Res.string.triangle_4: StringResource
  get() = CommonMainString1.triangle_4

private fun init_triangle_4(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:triangle_4", "triangle_4",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43078, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41692, 18),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53450, 18),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53482, 18),
    )
)

internal val Res.string.twoMathTests: StringResource
  get() = CommonMainString1.twoMathTests

private fun init_twoMathTests(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:twoMathTests", "twoMathTests",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43567, 140),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42137, 116),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53935, 124),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53967, 124),
    )
)

internal val Res.string.twoMathTests_a: StringResource
  get() = CommonMainString1.twoMathTests_a

private fun init_twoMathTests_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:twoMathTests_a", "twoMathTests_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43126, 126),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41744, 114),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53502, 122),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53534, 122),
    )
)

internal val Res.string.twoMathTests_b: StringResource
  get() = CommonMainString1.twoMathTests_b

private fun init_twoMathTests_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:twoMathTests_b", "twoMathTests_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43253, 118),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41859, 102),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53625, 110),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53657, 110),
    )
)

internal val Res.string.twoMathTests_c: StringResource
  get() = CommonMainString1.twoMathTests_c

private fun init_twoMathTests_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:twoMathTests_c", "twoMathTests_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43372, 194),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    41962, 174),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    53736, 198),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    53768, 198),
    )
)

internal val Res.string.type: StringResource
  get() = CommonMainString1.type

private fun init_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:type", "type",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43708, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42254, 24),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54060, 28),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54092, 28),
    )
)

internal val Res.string.under15: StringResource
  get() = CommonMainString1.under15

private fun init_under15(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:under15", "under15",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43733, 31),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42279, 39),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54089, 55),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54121, 55),
    )
)

internal val Res.string.unknown_numbers: StringResource
  get() = CommonMainString1.unknown_numbers

private fun init_unknown_numbers(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unknown_numbers", "unknown_numbers",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43765, 47),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42319, 51),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54145, 51),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54177, 51),
    )
)

internal val Res.string.unkp: StringResource
  get() = CommonMainString1.unkp

private fun init_unkp(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unkp", "unkp",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    43813, 380),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42371, 260),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54197, 304),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54229, 304),
    )
)

internal val Res.string.v_letlenszer_tagsz_m: StringResource
  get() = CommonMainString1.v_letlenszer_tagsz_m

private fun init_v_letlenszer_tagsz_m(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:v_letlenszer_tagsz_m", "v_letlenszer_tagsz_m",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44194, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42632, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54502, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54534, 52),
    )
)

internal val Res.string.varroda: StringResource
  get() = CommonMainString1.varroda

private fun init_varroda(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda", "varroda",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44913, 295),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43247, 267),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    55277, 319),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    55309, 319),
    )
)

internal val Res.string.varroda_a: StringResource
  get() = CommonMainString1.varroda_a

private fun init_varroda_a(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda_a", "varroda_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44247, 77),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42689, 69),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54555, 117),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54587, 117),
    )
)

internal val Res.string.varroda_b: StringResource
  get() = CommonMainString1.varroda_b

private fun init_varroda_b(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda_b", "varroda_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44325, 109),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42759, 97),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54673, 109),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54705, 109),
    )
)

internal val Res.string.varroda_c: StringResource
  get() = CommonMainString1.varroda_c

private fun init_varroda_c(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda_c", "varroda_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44435, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42857, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54783, 141),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54815, 141),
    )
)

internal val Res.string.varroda_d: StringResource
  get() = CommonMainString1.varroda_d

private fun init_varroda_d(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda_d", "varroda_d",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44541, 185),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    42943, 149),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    54925, 173),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    54957, 173),
    )
)

internal val Res.string.varroda_e: StringResource
  get() = CommonMainString1.varroda_e

private fun init_varroda_e(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:varroda_e", "varroda_e",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    44727, 185),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43093, 153),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    55099, 177),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    55131, 177),
    )
)

internal val Res.string.veryHard: StringResource
  get() = CommonMainString1.veryHard

private fun init_veryHard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:veryHard", "veryHard",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45209, 36),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43515, 32),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    55597, 36),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    55629, 36),
    )
)

internal val Res.string.viraghagymak: StringResource
  get() = CommonMainString1.viraghagymak

private fun init_viraghagymak(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:viraghagymak", "viraghagymak",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    55634, 196),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    55666, 196),
    )
)

internal val Res.string.viragkoto_csokorkeszites: StringResource
  get() = CommonMainString1.viragkoto_csokorkeszites

private fun init_viragkoto_csokorkeszites(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:viragkoto_csokorkeszites", "viragkoto_csokorkeszites",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    55831, 168),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    55863, 168),
    )
)

internal val Res.string.volume: StringResource
  get() = CommonMainString1.volume

private fun init_volume(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:volume", "volume",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45246, 30),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43548, 26),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56000, 26),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56032, 26),
    )
)

internal val Res.string.vonatSebessége: StringResource
  get() = CommonMainString1.vonatSebessége

private fun init_vonatSebessége(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:vonatSebessége", "vonatSebessége",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45655, 163),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43925, 135),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56385, 159),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56417, 159),
    )
)

internal val Res.string.vonatSebessége_a: StringResource
  get() = CommonMainString1.vonatSebessége_a

private fun init_vonatSebessége_a(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:vonatSebessége_a", "vonatSebessége_a",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45277, 101),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43575, 85),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56027, 93),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56059, 93),
    )
)

internal val Res.string.vonatSebessége_b: StringResource
  get() = CommonMainString1.vonatSebessége_b

private fun init_vonatSebessége_b(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:vonatSebessége_b", "vonatSebessége_b",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45379, 117),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43661, 105),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56121, 113),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56153, 113),
    )
)

internal val Res.string.vonatSebessége_c: StringResource
  get() = CommonMainString1.vonatSebessége_c

private fun init_vonatSebessége_c(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:vonatSebessége_c", "vonatSebessége_c",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45497, 157),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    43767, 157),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56235, 149),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56267, 149),
    )
)

internal val Res.string.wasIdentitiy: StringResource
  get() = CommonMainString1.wasIdentitiy

private fun init_wasIdentitiy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:wasIdentitiy", "wasIdentitiy",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45819, 52),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44061, 56),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56545, 52),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56577, 52),
    )
)

internal val Res.string.waterLevel: StringResource
  get() = CommonMainString1.waterLevel

private fun init_waterLevel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:waterLevel", "waterLevel",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45872, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44118, 34),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56598, 42),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56630, 42),
    )
)

internal val Res.string.whiteboard: StringResource
  get() = CommonMainString1.whiteboard

private fun init_whiteboard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whiteboard", "whiteboard",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    45907, 146),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44153, 146),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56641, 138),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56673, 138),
    )
)

internal val Res.string.won: StringResource
  get() = CommonMainString1.won

private fun init_won(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:won", "won",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    46054, 27),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44300, 19),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56780, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56812, 19),
    )
)

internal val Res.string.wrongRemainder: StringResource
  get() = CommonMainString1.wrongRemainder

private fun init_wrongRemainder(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:wrongRemainder", "wrongRemainder",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    46082, 54),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44320, 50),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56800, 50),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56832, 50),
    )
)

internal val Res.string.yes: StringResource
  get() = CommonMainString1.yes

private fun init_yes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:yes", "yes",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    46137, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44371, 15),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56851, 19),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56883, 19),
    )
)

internal val Res.string.zerodivision: StringResource
  get() = CommonMainString1.zerodivision

private fun init_zerodivision(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zerodivision", "zerodivision",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    46153, 68),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44387, 72),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56871, 76),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56903, 76),
    )
)

internal val Res.string.zerushely: StringResource
  get() = CommonMainString1.zerushely

private fun init_zerushely(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zerushely", "zerushely",
    setOf(
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("de"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-de/strings.commonMain.cvr",
    46222, 89),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("es"),
    ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-es/strings.commonMain.cvr",
    44460, 73),
     
    org.jetbrains.compose.resources.ResourceItem(setOf(org.jetbrains.compose.resources.LanguageQualifier("hu"),
    org.jetbrains.compose.resources.RegionQualifier("HU"), ),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values-hu-rHU/strings.commonMain.cvr",
    56948, 85),
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/matekit_multiplatform.composeapp.generated.resources/values/strings.commonMain.cvr",
    56980, 85),
    )
)
