package matekit.matekit.utilityfunctions

import androidx.compose.ui.util.fastForEachIndexed
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Response
import org.jetbrains.compose.resources.getString
/*import java.time.LocalDateTime
import java.time.format.DateTimeFormatter
import java.io.File
import java.io.FileWriter
import java.text.DateFormat
import java.text.SimpleDateFormat
import java.util.Date
import java.util.Locale*/
import kotlinx.browser.localStorage
import kotlinx.serialization.json.Json
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json.Default.encodeToString
import matekit.matekit.CANCELLATIONX
import matekit.matekit.checkmark
import matekit_multiplatform.composeapp.generated.resources.Solution


suspend fun LaTexLogWriteDesktop(//desktop a neve de valójában ez a webes verzió
    helyesMO: Boolean,
    userinput: String,
    showSolutionIfUserWrong: String,
    activityType: String,
    displayedTask: String,
    answerLaTeXWrap: Boolean = false,
    taskLaTexWrap: Boolean = true,
    userName: String = "user1",
    userIndex: Int = 1
) {
    val name = "${userName}_$userIndex"
    val logKey = "${name}_$activityType"

    val logColor = if (helyesMO) "green" else "red"
    val currentDate = js("new Date().toLocaleString()") as String

    val fileContents1 =
        " \n ${
            if (answerLaTeXWrap) {
                "$$ \\text{"
            } else ""
        } ${getString(Res.string.Response)} ${if (answerLaTeXWrap) "}" else ""} ${
            (userinput).replace(
                "✓",
                ""
            )
        }  " +//"Helyes" Helyett rakunk egy pipát. Egy cseles diák beírhatna néhány pipát, de nem engedjük neki
                " ${
                    if (helyesMO) {
                        if (answerLaTeXWrap) {
                            "\\text{✓}$$"
                        } else {
                            "✓"
                        }

                        // context.getString(R.string.CorrectSolution)
                    } else {
                        " ${if (answerLaTeXWrap) "$$" else " <br>"}  " + showSolutionIfUserWrong

                    }
                } ${if (answerLaTeXWrap) "" else "<br>"}  $currentDate ${if (answerLaTeXWrap) "" else "<br>"} \n" /* ha a diák válasza egy latex formula, amit matematikai környezetben akarunk megjeleníteni, akkor a megoldás is az (jelenleg) viszont akkor nem kell dupla sortörés*/

    val fileContents2 =
        if (helyesMO) "<font color=green>${if (taskLaTexWrap) "$$" else ""}${displayedTask}${if (taskLaTexWrap) "$$" else "<br>"}</font> \n" else "<font color=red>${if (taskLaTexWrap) "$$" else "<br>"}${displayedTask}${if (taskLaTexWrap) "$$" else "<br>"}</font> \n"


    val logData = fileContents2 + fileContents1
    // Retrieve existing logs
    val existingLogsJson = localStorage.getItem(logKey)
    val existingLogs: MutableList<String> = if (existingLogsJson != null) {
        try {
            Json.decodeFromString(existingLogsJson)
        } catch (e: Exception) {
            mutableListOf()
        }
    } else {
        mutableListOf()
    }

    // Append new log data
    existingLogs.add(logData)

    // Serialize and store updated logs
    val updatedLogsJson = Json.encodeToString(existingLogs)
    localStorage.setItem(logKey, updatedLogsJson)
}

suspend fun LogWriteAltalanosKerdeslistas(//3\n van eredetileg egy feladatban ezért ezt megtartjuk
    tasktext: String,
    questiontexts: List<String>,//ez a három lehetne egy objektum listája
    megoldókulcs: List<String>,//már string formátumban, tehát előre átváltva intből, double-ből, vagy bigfractionből
    answerscorrect: List<Boolean>,
    userinputs: List<String>,
    szovegBejegyzesVegere: String,//általánositás esetén ez még további extra szöveges információ, akár a feladatbejegyzés végére
    teljesenJóMegoldás: Boolean, //előre kiértékeljük, hogy teljesen jó-e a megoldás, ha igen, azt külön jelezzük
    TAG: String,
    userName: String = "user1",
    userIndex: Int = 1,
    moduleID:Int
) {
    val name = "${userName}_$userIndex"
    val logKey = "${name}_$TAG"

    val currentDate = js("new Date().toLocaleString()") as String
    var firetasklog1 =""
    var firetasklog2 =""


    var fontcolor = if (teljesenJóMegoldás) "green" else "red"
    var fileContents1 = "<font color=$fontcolor>${tasktext}</font><br><br>"




        questiontexts.fastForEachIndexed { i, questiontext ->
            var fileContents =
                "$questiontext<br>${getString(Res.string.Response)} ${userinputs[i]}${
                    if (answerscorrect[i]) {
                        checkmark
                    } else {
                        CANCELLATIONX
                    }
                }<br> ${
                    if (answerscorrect[i] == false) {
                        "${getString(Res.string.Solution)}: ${megoldókulcs[i]}<br><br>"
                    } else {
                        ""
                    }
                }"
            fileContents1+=fileContents
        }
        fileContents1+=szovegBejegyzesVegere





    val logData =  fileContents1
    // Retrieve existing logs
    val existingLogsJson = localStorage.getItem(logKey)
    val existingLogs: MutableList<String> = if (existingLogsJson != null) {
        try {
            Json.decodeFromString(existingLogsJson)
        } catch (e: Exception) {
            mutableListOf()
        }
    } else {
        mutableListOf()
    }

    // Append new log data
    existingLogs.add(logData)

    // Serialize and store updated logs
    val updatedLogsJson = Json.encodeToString(existingLogs)
    localStorage.setItem(logKey, updatedLogsJson)

}






/*
suspend fun LaTexLogWriteDesktop(//ez működik
    helyesMO: Boolean,
    userinput: String,
    showSolutionIfUserWrong: String,
    activityType: String,
    displayedTask: String,
    answerLaTeXWrap: Boolean = false,
    taskLaTexWrap: Boolean = true,
    userName: String = "user1",
    userIndex: Int = 1,
    logDirectory: String = "logs"
) {
    val name = "${userName}_$userIndex"
    val filename = "$logDirectory/${name}_$activityType.txt"

    // Ensure the log directory exists
    println("directories")
   // println(File(logDirectory).mkdirs())


    val logColor = if (helyesMO) "green" else "red"
  //  val currentDate = LocalDateTime.now().format(DateTimeFormatter.ofPattern("HH:mm:ss dd/MM/yyyy"))

    var fontsize = 20 //todo reszponzív
    var textcolor = "black" //todo follow system theme


    val fileContents1 =
        " \n ${
            if (answerLaTeXWrap) {
                "$$ \\text{"
            } else ""
        } ${getString(Res.string.Response)} ${if (answerLaTeXWrap) "}" else ""} ${
            (userinput).replace(
                "✓",
                ""
            )
        }  " +//"Helyes" Helyett rakunk egy pipát. Egy cseles diák beírhatna néhány pipát, de nem engedjük neki
                " ${
                    if (helyesMO) {
                        if (answerLaTeXWrap) {
                            "\\text{✓}$$"
                        } else {
                            "✓"
                        }

                        // context.getString(R.string.CorrectSolution)
                    } else {
                        " ${if (answerLaTeXWrap) "$$" else " <br>"}  " + showSolutionIfUserWrong

                    }
                } ${if (answerLaTeXWrap) "" else "<br>"}  currentDate  \n" /* ha a diák válasza egy latex formula, amit matematikai környezetben akarunk megjeleníteni, akkor a megoldás is az (jelenleg) viszont akkor nem kell dupla sortörés*/

    val fileContents2 =
        if (helyesMO) "<font color=green>${if (taskLaTexWrap) "$$" else ""}${displayedTask}${if (taskLaTexWrap) "$$" else "<br>"}</font> \n" else "<font color=red>${if (taskLaTexWrap) "$$" else "<br>"}${displayedTask}${if (taskLaTexWrap) "$$" else "<br>"}</font> \n"


    // Write to file
    //File(filename).appendText(fileContents1)
   // File(filename).appendText(fileContents2)
}*/

suspend fun simplelog(
    helyesMO: Boolean,
    userinput: String,
    showSolutionIfUserWrong: String,
    activityType: String,
    displayedTask: String,
    //userDocRef:UserDocRef,
    // modulID:Int,
    userName: String = "user1",
    userIndex: Int = 1,
    logDirectory: String = "logs"
) {
    var firetasklog1 = ""
    var firetasklog2 = ""

    val name = "${userName}_$userIndex"
    val filename = "$logDirectory/${name}_$activityType.txt"

    var logColor = if (helyesMO) "green" else "red"

   // val currentDate = LocalDateTime.now().format(DateTimeFormatter.ofPattern("HH:mm:ss dd/MM/yyyy"))

    //   currentDate = idő

    var fileContents1 =
        " \n ${getString(Res.string.Response)} ${(userinput)}  " +
                " ${
                    if (helyesMO) {
                        "✓"   // context.getString(R.string.CorrectSolution)
                    } else {
                        "  <br> $showSolutionIfUserWrong"
                    }
                } <br> currentDate  \n"
    //firetasklog1=fileContents //todo database
    //  it.write("\n".toByteArray())


    var fileContents2 =
        if (helyesMO) "<font color=green>$displayedTask</font> \n" else "<font color=red>$displayedTask</font> \n"

    //File(filename).appendText(fileContents1)
   // File(filename).appendText(fileContents2)

    // firetasklog2=fileContents
    //  it.write("\n".toByteArray())


    // uploadtofirebase(userDocRef,helyesMO,(firetasklog2+"<br>"+firetasklog1),modulID)

}


/*
fun LaTexLogWrite(
    helyesMO: Boolean,
    userinput: String,
    showSolutionIfUserWrong: String,
    activityType: String,
    displayedTask: String,
    answerLaTeXWrap: Boolean = false,
    taskLaTexWrap: Boolean = true,
    userName: String="user1", // Pass the user name as a parameter
    //moduleID: Int
) {
    val fileName = "$userName $activityType.txt" // You can add .txt extension or any extension you prefer

    val sdf = SimpleDateFormat("HH:mm:ss dd/M/yyyy", java.util.Locale.getDefault())
    val currentDate = "Time: " + sdf.format(Date())

    val answerWrapStart = if (answerLaTeXWrap) "$$ \\text{" else ""
    val answerWrapEnd = if (answerLaTeXWrap) "}" else ""
    val correctIndicator = if (helyesMO) {
        if (answerLaTeXWrap) "\\text{✓}$$" else "✓"
    } else {
        if (answerLaTeXWrap) "$$" else " <br>" + showSolutionIfUserWrong
    }

    val fileContents1 = "\n $answerWrapStart Response $answerWrapEnd ${userinput.replace("✓", "")}  $correctIndicator ${if (answerLaTeXWrap) "" else "<br>"}  $currentDate  \n"

    val logColor = if (helyesMO) "green" else "red"
    val taskWrapStart = if (taskLaTexWrap) "$$" else "<br>"
    val taskWrapEnd = if (taskLaTexWrap) "$$" else "<br>"

    val fileContents2 = "<font color=$logColor>$taskWrapStart$displayedTask$taskWrapEnd</font> \n"

    // Write to file
    try {
        val logFile = File(fileName)
        val writer = FileWriter(logFile, true) // 'true' to append to the file
        writer.write(fileContents1)
        writer.write(fileContents2)
        writer.close()
    } catch (e: Exception) {
        e.printStackTrace()
    }
}*/

