package matekit.matekit.taskgenerators


import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Settings
import androidx.compose.material3.Button
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.elrendezes
import matekit.ui.generatorSettings
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Coefficientminmax
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Variableminmax
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.easy
import matekit_multiplatform.composeapp.generated.resources.hard
import matekit_multiplatform.composeapp.generated.resources.medium
import matekit_multiplatform.composeapp.generated.resources.veryHard
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.random.Random


@Composable
fun EquationSystemScreen(
    navController: NavHostController,
    ratios:ratios,
    tag:String = "EquationSystem",
    viewModel: EquationSystemViewModel = viewModel { EquationSystemViewModel(tag) }
) {
    val uiState by viewModel.uiState.collectAsState()
    backButton(navController)
   // responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.lastsolution)// a webes toast  nem jó itt
    val coroutineScope = rememberCoroutineScope()
    Column(
        modifier = Modifier.fillMaxSize()
    ) {
        // Equation Text

        borderedbasicfontsizeTextView(
            tasktext =uiState.equationText
        )

        // User Input Fields and Check Button
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally){
            Row(
                modifier = Modifier.padding(horizontal = 16.dp).align(Alignment.CenterHorizontally)
            ) {
                OutlinedTextField(
                    value = uiState.userInputA,
                    onValueChange = { viewModel.updateUserInputA(it) },
                    label = { Text("a =") },
                    keyboardOptions = KeyboardOptions.Default.copy(
                        keyboardType = KeyboardType.Number, imeAction = ImeAction.Next
                    ),
                    modifier = Modifier.padding(end = 8.dp).widthIn(max = 150.dp)
                )

                OutlinedTextField(
                    value = uiState.userInputB,
                    onValueChange = { viewModel.updateUserInputB(it) },
                    label = { Text("b =") },
                    keyboardOptions = KeyboardOptions.Default.copy(
                        keyboardType = KeyboardType.Number, imeAction = ImeAction.Done
                    ),
                    modifier = Modifier.padding(end = 8.dp).widthIn(max = 150.dp)
                )

                if (ratios.elrendezes == elrendezes.Horizontal) {
                    Button(
                        onClick = { coroutineScope.launch { viewModel.checkSolution() } },
                        modifier = Modifier.align(Alignment.CenterVertically)
                    ) {
                        Text(stringResource(Res.string.Check))
                    }
                    LatexLogButton(navController, tag)
                }
            }
            if (ratios.elrendezes == elrendezes.Vertical) {
                Row(horizontalArrangement = Arrangement.Center,verticalAlignment = Alignment.CenterVertically,modifier=Modifier.fillMaxWidth()){
                    Button(
                        onClick = { coroutineScope.launch { viewModel.checkSolution() } },
                    ) {
                        Text(stringResource(Res.string.Check))
                    }
                    LatexLogButton(navController, tag)
                }
            }
        }

        // Feedback Message
        if (uiState.feedbackMessage.isNotEmpty()) {//todo ehelyett egy toast
            Text(
                text = uiState.feedbackMessage,
                color = if (uiState.isCorrect) MaterialTheme.colorScheme.primary else MaterialTheme.colorScheme.error,
                modifier = Modifier.padding(16.dp).align(Alignment.CenterHorizontally)
            )
        }

        // Settings and Difficulty Buttons

        SettingsContent(uiState = uiState,
            onMinUnknownChange = { viewModel.updateMinUnknown(it) },
            onMaxUnknownChange = { viewModel.updateMaxUnknown(it) },
            onMinCoefficientChange = { viewModel.updateMinCoefficient(it) },
            onMaxCoefficientChange = { viewModel.updateMaxCoefficient(it) },
            onSetDifficulty = { minU, maxU, minC, maxC ->
                viewModel.setDifficulty(minU, maxU, minC, maxC)
            })

    }
    responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.lastsolution)// a legutolsó doboz kerül felülre
}


@Composable
fun SettingsVisibilityButton(
    settingsVisible: Boolean, onToggleVisibility: () -> Unit
) {
    IconButton(onClick = onToggleVisibility) {
        Icon(
            imageVector = Icons.Default.Settings,
            contentDescription = if (settingsVisible) "Hide Settings" else "Show Settings"
        )
    }
}

@Composable
fun SettingsContent(//todo UI kiegészítése
    uiState: EquationSystemState,
    onMinUnknownChange: (String) -> Unit,
    onMaxUnknownChange: (String) -> Unit,
    onMinCoefficientChange: (String) -> Unit,
    onMaxCoefficientChange: (String) -> Unit,
    onSetDifficulty: (String, String, String, String) -> Unit
) {
    Column(modifier = Modifier.padding(16.dp).fillMaxWidth(),horizontalAlignment = Alignment.CenterHorizontally) {

        generatorSettings {

            settingListItem(
                content = {
                   // Text(text = "Difficulty Levels:", style = MaterialTheme.typography.titleMedium)
                    Column(modifier=Modifier.wrapContentWidth(), horizontalAlignment = Alignment.CenterHorizontally) {
                        DifficultyButtons(onSetDifficulty = onSetDifficulty)
                    }
                }, alpha = alpha1
            )


            settingListItem(

                content = {
                 //   Text(text = "Settings:", style = MaterialTheme.typography.titleMedium)
                    Column {
                        // Unknowns Range
                        RangeInputFields(
                            label = stringResource(Res.string.Variableminmax),
                            minValue = uiState.minUnknown,
                            maxValue = uiState.maxUnknown,
                            onMinValueChange = onMinUnknownChange,
                            onMaxValueChange = onMaxUnknownChange
                        )

                        Spacer(modifier = Modifier.height(8.dp))

                        // Coefficients Range
                        RangeInputFields(
                            label = stringResource(Res.string.Coefficientminmax),
                            minValue = uiState.minCoefficient,
                            maxValue = uiState.maxCoefficient,
                            onMinValueChange = onMinCoefficientChange,
                            onMaxValueChange = onMaxCoefficientChange
                        )
                    }
                }, alpha = alpha2

            )


        }
    }
}

@Composable
fun DifficultyButtons(
    onSetDifficulty: (String, String, String, String) -> Unit
) {
    Row(
        modifier = Modifier.wrapContentWidth(), horizontalArrangement = Arrangement.SpaceAround
    ) {
        Button(onClick = { onSetDifficulty("1", "12", "1", "12") }) {
            Text(stringResource(Res.string.easy))
        }
        Button(onClick = { onSetDifficulty("1", "20", "1", "20") }) {
            Text(stringResource(Res.string.medium))
        }
    }

    Spacer(modifier = Modifier.height(8.dp))

    Row(
        modifier = Modifier.wrapContentWidth(), horizontalArrangement = Arrangement.SpaceAround
    ) {
        Button(onClick = { onSetDifficulty("-20", "20", "-20", "20") }) {
            Text(stringResource(Res.string.hard))
        }
        Button(onClick = { onSetDifficulty("-40", "40", "-40", "40") }) {
            Text(stringResource(Res.string.veryHard))
        }
    }
}

@Composable
fun RangeInputFields(
    label: String,
    minValue: String,
    maxValue: String,
    onMinValueChange: (String) -> Unit,
    onMaxValueChange: (String) -> Unit
) {
    Column {
        Text(text = label, style = MaterialTheme.typography.bodyMedium)
        Row {
            OutlinedTextField(value = minValue.toString(),
                onValueChange = { value ->

                    onMinValueChange(value)
                },
                label = { Text("Min") },
                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                modifier = Modifier.weight(1f).padding(end = 8.dp)
            )
            OutlinedTextField(value = maxValue.toString(),
                onValueChange = { value ->
                    val intValue = value.toIntOrNull() ?: maxValue
                    onMaxValueChange(value)
                },
                label = { Text("Max") },
                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                modifier = Modifier.weight(1f)
            )
        }
    }
}


class EquationSystemViewModel(tag:String) : ViewModel() {
val tag =tag
    private val _uiState = MutableStateFlow(EquationSystemState())
    val uiState: StateFlow<EquationSystemState> = _uiState

    init {
        generateTask()
    }

    fun generateTask() {//todo generálás ellenőrzése
       // resetState()
        val minUnknown = _uiState.value.minUnknown.toIntOrNull() ?: 1
        val maxUnknown = _uiState.value.maxUnknown.toIntOrNull() ?: 1
        val minCoefficient = _uiState.value.minCoefficient.toIntOrNull() ?: 1
        val maxCoefficient = _uiState.value.maxCoefficient.toIntOrNull() ?: 1

        val a = Random.nextInt(minUnknown, maxUnknown + 1)
        val b = Random.nextInt(minUnknown, maxUnknown + 1)

        val e1 = generateCoefficient(minCoefficient, maxCoefficient)
        val e2 = generateCoefficient(minCoefficient, maxCoefficient)
        val e3 = generateCoefficient(minCoefficient, maxCoefficient)
        val e4 = generateCoefficient(minCoefficient, maxCoefficient)


        var da = 0
        var de = 0
        if(e1%e3==0) da=e1/e3
        if(e3%e1==0) da=e3/e1
        if(e2%e4==0) de=e2/e4
        if(e4%e2==0) de=e4/e2
        if(da==de) {
            generateTask()
            return
        }


        val I = e1 * a + e2 * b
        val II = e3 * a + e4 * b

        val equationText = "${e1}a + ${e2}b = $I\n${e3}a + ${e4}b = $II"

        _uiState.update { currentState ->
            currentState.copy(
                equationText = equationText.replace("+-", "-") .replace(" + -", " - "),
                a = a,
                b = b,
                e1 = e1,
                e2 = e2,
                e3 = e3,
                e4 = e4,
                I = I,
                II = II,
                userInputA = "",
                userInputB = "",
                feedbackMessage = "",
               // isCorrect = false
            )
        }
    }

    private fun generateCoefficient(min: Int, max: Int): Int {
        val coefficient = Random.nextInt(min, max + 1)
        return if (coefficient == 0) generateCoefficient(min, max) else coefficient
    }

    private fun resetState() {
        _uiState.update { currentState ->
            currentState.copy(
                a = 0,
                b = 0,
                e1 = 0,
                e2 = 0,
                e3 = 0,
                e4 = 0,
                I = 0,
                II = 0,
                userInputA = "",
                userInputB = "",
                feedbackMessage = "",
               // isCorrect = false
            )
        }
    }

    fun updateUserInputA(input: String) {
        _uiState.update { it.copy(userInputA = input) }
    }

    fun updateUserInputB(input: String) {
        _uiState.update { it.copy(userInputB = input) }
    }

    suspend fun checkSolution() {
        val state = _uiState.value
        val tippA = state.userInputA.toIntOrNull()
        val tippB = state.userInputB.toIntOrNull()

        if (tippA == null || tippB == null) {
            // Invalid input
            //_uiState.update { it.copy(feedbackMessage = "Please enter valid integers.") } //todo kiírhatjuk ezt magyarul
            return
        }

        val isCorrect = tippA == state.a && tippB == state.b
        val feedbackMessage = if (isCorrect) {
            "Correct Solution!"
        } else {
            "Wrong Solution: a = ${state.a}, b = ${state.b}"
        }
        //println(feedbackMessage)
        _uiState.update {
            it.copy(
                lastsolution = "a=${state.a}, b=${state.b}", isCorrect = isCorrect, numberofsolved = it.numberofsolved+1
            )
        }

        var answertosave = "a=${tippA}, b=${tippB}"
        var wrongsolution = getString(Res.string.WrongSolution, uiState.value.lastsolution)
        LaTexLogWriteDesktop(isCorrect,answertosave,wrongsolution,tag,uiState.value.equationText.replace("\n","<br>"), taskLaTexWrap = false)//\n helyett <br> sortörést használunk a latex miat

        // Generate new task regardless of correctness to match original functionality
        generateTask()
    }

    fun toggleSettingsVisibility() {
        _uiState.update { it.copy(settingsVisible = !it.settingsVisible) }
    }

    fun setDifficulty(
        minUnknown: String, maxUnknown: String, minCoefficient: String, maxCoefficient: String
    ) {
        _uiState.update {
            it.copy(
                minUnknown = minUnknown.toString(),
                maxUnknown = maxUnknown.toString(),
                minCoefficient = minCoefficient.toString(),
                maxCoefficient = maxCoefficient.toString()
            )
        }
        generateTask()
    }

    fun updateMinUnknown(value: String) {
        _uiState.update { it.copy(minUnknown = value) }
    }

    fun updateMaxUnknown(value: String) {
        _uiState.update { it.copy(maxUnknown = value) }
    }

    fun updateMinCoefficient(value: String) {
        _uiState.update { it.copy(minCoefficient = value) }
    }

    fun updateMaxCoefficient(value: String) {
        _uiState.update { it.copy(maxCoefficient = value) }
    }
}

data class EquationSystemState(
    val equationText: String = "",
    val userInputA: String = "",
    val userInputB: String = "",
    val minUnknown: String = "1",
    val maxUnknown: String = "12",
    val minCoefficient: String = "1",
    val maxCoefficient: String = "12",
    val settingsVisible: Boolean = true,
    val a: Int = 0,
    val b: Int = 0,
    val e1: Int = 0,
    val e2: Int = 0,
    val e3: Int = 0,
    val e4: Int = 0,
    val I: Int = 0,
    val II: Int = 0,
    val numberofsolved:Int =0,
    val lastsolution:String="",
    val feedbackMessage: String = "",
    val isCorrect: Boolean = false
)