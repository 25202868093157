package matekit.ui

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.clickable
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.LocalTextStyle
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.ui.theme.matekitcolor
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.expand_less
import matekit_multiplatform.composeapp.generated.resources.expand_more
import matekit_multiplatform.composeapp.generated.resources.ic_results
import matekit_multiplatform.composeapp.generated.resources.settings
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


var alpha1 = .09f
var alpha2 = .175f
var uifont = 18.sp
var opsize = 24.sp
var basicfontsize  by mutableStateOf(50f)






@Composable
 fun borderedbasicfontsizeTextView(
    tasktext: String,/*modifier:Modifier=Modifier,*/
    collapsable: Boolean = false,
    widthModifier:Modifier=Modifier.padding(6.dp).fillMaxWidth(),
) {//az összehajtás nem rossz ötlet, de a gombot máshova kell rakni, a statisztikához meg úgyis kevés
    val collapsed = remember { mutableStateOf(false) }
    val maxlines = remember {
        derivedStateOf {
            if (collapsed.value) {
                1
            } else {
                Int.MAX_VALUE
            }
        }
    }
    val topPadding  = remember {
        derivedStateOf {
            if (collapsable) {
                0.dp
            } else {
                5.dp
            }
        }
    }

    SurfaceWBorder(content = {
        Column(
            modifier = widthModifier,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            if (collapsable) {
                //  Row(modifier = Modifier.fillMaxWidth(), horizontalArrangement = Arrangement.End) {
                IconButton(onClick = {
                    collapsed.value = !collapsed.value
                }, modifier = Modifier.size(42.dp)) {
                    Icon(
                        painter = if (collapsed.value) painterResource(Res.drawable.expand_more) else painterResource(
                            Res.drawable.expand_less
                        ),
                        contentDescription = null // decorative element
                    )
                }
                // }

            }
            basicfontsizeTextView(
                tasktext,
                widthModifier.padding(bottom=5.dp, start = 5.dp,end=5.dp,top=topPadding.value),// Modifier.padding(bottom=5.dp, start = 5.dp,end=5.dp,top=topPadding.value),
                maxlines = maxlines.value,
            )
        }
    }, borderColor = matekitcolor,
        modifier=widthModifier
        )

}

@Composable
fun basicfontsizeTextView(
    tasktext: String,
    modifier: Modifier = Modifier,
    maxlines: Int = Int.MAX_VALUE
) {
    /*androidx.compose.material.Surface(
        border = BorderStroke(4.dp, matekitcolor),
        shape = Shapes.large,
        modifier = Modifier.padding(6.dp).wrapContentSize()
        //fillMaxHeight(0.2f).fillMaxWidth(0.9f)
    ) {*/
    Text(
        text = tasktext,
        fontSize = basicfontsize.sp,//erre a sorra nem volt szükség az android verzióban
        lineHeight = (basicfontsize+8).sp,
        textAlign = TextAlign.Center,
        modifier = modifier,
        maxLines = maxlines
    )
    // }
}

@Composable
fun SurfaceWBorder(content: @Composable () -> Unit, borderColor:Color=Color.Transparent, modifier:Modifier=Modifier.padding(6.dp)
    .fillMaxWidth(), color:Color=MaterialTheme.colorScheme.surface){
    Surface(
        border = BorderStroke(2.dp, borderColor),
        shape = Shapes().large,
        modifier = modifier

        /*.wrapContentHeight()*/,
        color = color
    ) {
        content()
    }
}



@Composable
fun settingtext(text: String) {
    Text(
        text, modifier = Modifier.padding(8.dp),
        fontSize = uifont
    )
}



@Composable
fun paramField(
    value:String,
    enterText: (String) -> Unit,
    label:  String,
    width: Int = 80
) {
    androidx.compose.material3.OutlinedTextField(
        value = value,
        onValueChange = {
            enterText(it)
        },
        label = { Text(label /*textAlign = TextAlign.Center*/) },
        modifier = Modifier.width(width.dp),//.padding(20.dp),
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Number,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(
            onDone = {

            }
        ),
        textStyle = LocalTextStyle.current.copy(textAlign = TextAlign.Center)
    )
}


@Composable
fun settingListItem(content: @Composable () -> Unit, alpha: Float, scrollable :Boolean = false, modifier: Modifier = Modifier.fillMaxWidth()) {
    Surface(
        shape = RoundedCornerShape(12.dp),
        modifier = modifier
            // .padding(8.dp)
            ,
        color = MaterialTheme.colorScheme.onSurface.copy(alpha)

    ) {

        val scrollState = rememberScrollState()
        var modifier = if (scrollable) Modifier. horizontalScroll(scrollState) else Modifier
        Row(
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.SpaceAround,
            modifier = modifier.padding(top = 6.dp, start = 6.dp, end = 6.dp, bottom = 6.dp).wrapContentWidth()
        ) {
            content()
        }
    }
//SurfaceWBorder(content = {content()}, color = color)
}

@Composable
fun generatorSettings( modifier: Modifier = Modifier.widthIn(200.dp, 600.dp),scroll:Boolean=true,content: @Composable () -> Unit,) {
    var alpha1 =.09f
    var alpha2 = .175f
    var uifont= 18.sp
    var collapsed by rememberSaveable {
        mutableStateOf(false)
    }
    var columnmodifier = modifier
   // MateKITTheme {//todo hátha nincs rá szükség
if(scroll )  columnmodifier = modifier.verticalScroll(rememberScrollState()) else  columnmodifier = modifier
        Box(modifier = modifier.padding(8.dp), contentAlignment = Alignment.TopCenter) {
            Column(modifier = modifier) {
                Spacer(modifier.height(16.dp))
                Surface(
                    border = BorderStroke(2.dp, Color.Gray),
                    shape = RoundedCornerShape(10.dp),
                    modifier = modifier
                        .padding(0.dp)
                        .wrapContentWidth(),
                    color =  MaterialTheme.colorScheme.background//Color.Transparent //MaterialTheme.colorScheme.surface
                ) {//itt vajon miért nincs közvetlenül még egy oszlop? válasz: valószínűleg egy korábbi bug miatt kimaradt
                    AnimatedVisibility(visible =!collapsed ) {
                        Column(columnmodifier
                            .padding(0.dp)){
                            content()
                        }
                    }

                }
            }
            Icon(
                painter = painterResource(Res.drawable.settings),
                null,
                modifier = Modifier
                    .size(35.dp)
                    .clickable {
                        collapsed = !collapsed
                    },
                tint = Color.Gray,
            )

        }
        // CompositionLocalProvider(LocalContentColor provides MaterialTheme.colors.surface) {
        //SurfaceWBorder(content = { content() }, borderColor = Color.Gray, modifier = Modifier.fillMaxHeight(0.7f),color=MaterialTheme.colors.onSurface)
        //}
   // }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun universalCheckField(userInput: String, check:(String)->Unit, enterText:(String) -> Unit,keyboardType:KeyboardType = KeyboardType.Decimal,label:StringResource =Res.string.Solution ){
    OutlinedTextField(
        value = userInput,
        onValueChange = {
            enterText(it) },
        label = { Text(stringResource(label)) },
        modifier = Modifier.width(160.dp) .onPreviewKeyEvent { event ->
            if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
            check(userInput)
                true // Indicate that the event was handled
            } else {
                false // Indicate that the event was not handled
            }
        },//.padding(20.dp),
        keyboardOptions = KeyboardOptions(keyboardType = keyboardType, imeAction = ImeAction.Done),
        keyboardActions = KeyboardActions(
          /*  onDone = {//todo mvvm adaptation
                var userinput = try {
                    userInput.value.toFloat().toString()
                }catch (e:Exception){
                    userInput.value
                    //return@KeyboardActions //így most ha nem tudunk belőle floatot csinálni, akkor visszaadjuk string ként
                }
                if(userinput != "" &&userinput != "." &&userinput != "-"&&userinput != ",") {
                    check(userinput)
                }else return@KeyboardActions
                userInput.value=""
            }*/
        )
    )
}

@Composable
fun LatexLogButton(navController: NavHostController, value: String) {
    IconButton(
        onClick = {
            navController.navigate("latexlog/$value")
        },
        modifier = Modifier.size(60.dp)
    ) {
        Icon(
            painter = painterResource(Res.drawable.ic_results),
            contentDescription = null, // decorative element
            modifier = Modifier.size(50.dp)
        )
    }
}

@Composable
fun simpleLogButton(navController: NavHostController, value: String) {
    IconButton(
        onClick = {
            navController.navigate("simplelog/$value")
        },
        modifier = Modifier.size(60.dp)
    ) {
        Icon(
            painter = painterResource(Res.drawable.ic_results),
            contentDescription = null, // decorative element
            modifier = Modifier.size(50.dp)
        )
    }
}