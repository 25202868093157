package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.Paramstr
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.getparamstr
import matekit.matekit.utilityfunctions.getstring
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Mertani_A
import matekit_multiplatform.composeapp.generated.resources.Mertani_B
import matekit_multiplatform.composeapp.generated.resources.Mertani_C
import matekit_multiplatform.composeapp.generated.resources.Periodic_lastDigitApowN
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Szamtani_A
import matekit_multiplatform.composeapp.generated.resources.Szamtani_B
import matekit_multiplatform.composeapp.generated.resources.Szamtani_C
import matekit_multiplatform.composeapp.generated.resources.Szamtani_D
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.arithmetical
import matekit_multiplatform.composeapp.generated.resources.geometrical
import matekit_multiplatform.composeapp.generated.resources.periodic
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.pow


@Composable
fun SorozatokScreen(
    navController: NavHostController,
    tag:String ="Sorozatok",
    viewModel: SorozatokViewModel = viewModel{ SorozatokViewModel(tag)}
) {
    val uiState by viewModel.uiState.collectAsState()

    val coroutineScope = rememberCoroutineScope()

    backButton(navController)

        Column(  modifier = Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally) {
            // Display the task text using string resources


            // Task type selection checkboxes
            Column(modifier=Modifier.wrapContentSize(),horizontalAlignment = Alignment.CenterHorizontally){
                borderedbasicfontsizeTextView(
                    uiState.taskText.getparamstr(),
                )

                Spacer(modifier = Modifier.height(10.dp))
                TaskTypeSelection(
                    taskTypes = uiState.taskTypes,
                    onTaskTypeToggle = { index -> viewModel.toggleTaskType(index) }
                )
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    universalCheckField(
                        uiState.userInput,
                        check = { coroutineScope.launch { viewModel.checkSolution()  }},
                        enterText = { viewModel.updateUserInput(it) })
                    // Check button
                    Spacer(modifier = Modifier.width(10.dp))
                    Button(
                        onClick = {coroutineScope.launch { viewModel.checkSolution()  } }
                    ) {
                        Text(stringResource(Res.string.Check))
                    }

                    LatexLogButton(navController,tag)

                }
            }

        }
    responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution)


}

@Composable
fun TaskTypeSelection(
    taskTypes: List<Boolean>,
    onTaskTypeToggle: (Int) -> Unit
) {
    settingListItem( content= {
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = taskTypes[0],
                onCheckedChange = { onTaskTypeToggle(0) }
            )
            Text(text = stringResource( Res.string.arithmetical))
        }
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = taskTypes[1],
                onCheckedChange = { onTaskTypeToggle(1) }
            )
            Text(text = stringResource(Res.string.geometrical))
        }
        Row(verticalAlignment = Alignment.CenterVertically) {
            Checkbox(
                checked = taskTypes[2],
                onCheckedChange = { onTaskTypeToggle(2) }
            )
            Text(text = stringResource(Res.string.periodic))
        }
    }, alpha= alpha1,scrollable= true, modifier=Modifier.wrapContentSize())
}


data class SorozatokUiState(
    val taskText: Paramstr = Paramstr(Res.string.Szamtani_A, listOf(0, 0, 0)),
    val userInput: String = "",
    val taskTypes: List<Boolean> = listOf(true, true, true),
    //val feedbackMessage: String = "",
    val isCorrect: Boolean = false,
    val numberofsolved:Int=0,
    val showsolution:String="",
)

class SorozatokViewModel(tag:String) : ViewModel() {
val tag = tag
    private val _uiState = MutableStateFlow(SorozatokUiState())
    val uiState: StateFlow<SorozatokUiState> = _uiState

    lateinit var currentTask: Task


    private val arithmeticSequenceTasks = listOf<(ArithmeticSequence) -> Task>(
        { sequence ->
            val n = (5..10).random()
            val taskText = Paramstr(Res.string.Szamtani_A, listOf(sequence.a1, sequence.d, n))
            val solution = sequenceElement(sequence, n)
            Task(taskText, solution.toDouble())
        },
        { sequence ->
            val n = (5..10).random()
            val taskText = Paramstr(Res.string.Szamtani_B, listOf(sequence.a1, sequence.d, n))
            val solution = sumOfArithmeticSequence(sequence, n)
            Task(taskText, solution)
        },
        { sequence ->
            val n = (6..16).random()
            val i = (1..5).random()
            val taskText = Paramstr(
                Res.string.Szamtani_C,
                listOf(n - i, sequenceElement(sequence, n - i), n + i, sequenceElement(sequence, n + i), n)
            )
            val solution = sequenceElement(sequence, n)
            Task(taskText, solution.toDouble())
        },
        { sequence ->
            val n = (5..10).random()
            val taskText = Paramstr(Res.string.Szamtani_D, listOf(sequence.a1, n, sumOfArithmeticSequence(sequence, n).toInt()))
            val solution = sequence.d
            Task(taskText, solution.toDouble())
        }
        // Add more tasks as needed
    )

    private val geometricSequenceTasks = listOf<(GeometricSequence) -> Task>(
        { sequence ->
            val n = (5..10).random()
            val taskText = Paramstr(Res.string.Mertani_A, listOf( sequence.a1, sequence.q,n,))
            val solution = sequenceElement(sequence, n)
            Task(taskText, solution.toDouble())
        },
        { sequence ->
            val n = (3..6).random()
            val taskText = Paramstr(Res.string.Mertani_B, listOf( sequence.a1, sequence.q,n,))
            val solution = sumOfGeometricSequence(sequence, n)
            Task(taskText, solution)
        },
        { sequence ->
            val n = (6..10).random()
            val i = (1..5).random()
            val taskText = Paramstr(
                Res.string.Mertani_C,
                listOf(n - i, sequenceElement(sequence, n - i), n + i, sequenceElement(sequence, n + i), n)
            )
            val solution = sequenceElement(sequence, n)
            Task(taskText, solution.toDouble())
        }
        // Add more tasks as needed
    )
    private val periodicSequenceTasks = listOf<() -> Task>(
        {
            val a = (2..20).random()
            val n = (100..300).random()
            val taskText = Paramstr(Res.string.Periodic_lastDigitApowN, listOf(a, n))
            val solution = lastDigitOfPower(a, n)
            Task(taskText, solution.toDouble())
        }
    )



    private fun newTask(types: List<Boolean>): Task {
        val typeIndex = listOf(0, 1, 2).filterIndexed { index, int -> types[index]==true }.random()
        return when (typeIndex) {
            0 -> arithmeticSequenceTasks.random()(generateArithmeticSequence())
            1 -> geometricSequenceTasks.random()(generateGeometricSequence())
            2 -> periodicSequenceTasks.random()()
            else -> throw IllegalStateException("Invalid task type")
        }
    }
   init {
      generateNewTask()
    }

    fun generateNewTask() {
        val taskTypes = _uiState.value.taskTypes
        currentTask = newTask(taskTypes)
        _uiState.update { it.copy(taskText = currentTask.text ) }
    }

    fun updateUserInput(input: String) {
        _uiState.update { it.copy(userInput = input) }
    }

    fun toggleTaskType(index: Int) {
        val updatedTaskTypes = _uiState.value.taskTypes.toMutableList()
        updatedTaskTypes[index] = !updatedTaskTypes[index]
        _uiState.update { it.copy(taskTypes = updatedTaskTypes) }
    }

    suspend fun checkSolution() {
        val userInput = try {
            _uiState.value.userInput.toDouble()
        }catch (
            e:Exception
        ){
            return@checkSolution
        }
        val solution = currentTask.solution
        val formattedSolution = solution.formatToDecimals(2).replace(",", ".").toDouble()

        val isCorrect = userInput == formattedSolution
        val feedbackMessage = if (isCorrect) {
           getString( Res.string.CorrectSolution)
        } else {
          getString  (Res.string.WrongSolution,currentTask.solution.formatToDecimals())
        }
        //println(feedbackMessage.toString())


        LaTexLogWriteDesktop(isCorrect, userInput.formatToDecimals(4), feedbackMessage,tag, currentTask.text.getstring(), taskLaTexWrap = false)

        _uiState.update {
            it.copy(
                //feedbackMessage = feedbackMessage,
                isCorrect = isCorrect,
                userInput = "",
                numberofsolved = it.numberofsolved+1,
                showsolution = solution.formatToDecimals()
            )
        }

        // Log the result and generate a new task
        generateNewTask()
    }



    val increasing = 1
    val decreasing = 0
    private fun generateArithmeticSequence(): ArithmeticSequence {
        val menet = listOf(
            increasing,
            decreasing
        ).random()//nem a leggyorsabb, de később is azonnal olvasható kódot eredményez
        var a1: Int
        var d: Int
        if (menet == increasing) {
            a1 = (1..20).random()
            d = (1..10).random()
        } else {
            a1 = (100..2000).random()
            d = (-10..-1).random()
        }
        return ArithmeticSequence(a1, d)
    }

    private fun generateGeometricSequence(): GeometricSequence {
        val menet = listOf(increasing).random()//kivesszük a csökkenőt
        var a1: Int
        var q: Int
        if (menet == increasing) {
            a1 = (1..10).random()
            q = (2..6).random()
        } else {
            a1 = (100..2000).random()
            q = (-10..-1).random()
        }
        return GeometricSequence(a1, q)
    }

    private fun sequenceElement(sequence: ArithmeticSequence, n: Int): Int {
        return sequence.a1 + (n - 1) * sequence.d
    }

    private fun sequenceElement(sequence: GeometricSequence, n: Int): Int {
        return (sequence.a1 * (sequence.q.toDouble().pow (n - 1).toDouble())).toInt()//todo ellenőrzni, hogy jól rendeztem-e át javaról kotlin szintaxisra
    }

    private fun sumOfArithmeticSequence(sequence: ArithmeticSequence, n: Int): Double {
        return ((2 * sequence.a1 + (n - 1) * sequence.d) * n) / 2.0
    }

    private fun sumOfGeometricSequence(sequence: GeometricSequence, n: Int): Double {
        val a1 = sequence.a1
        val q = sequence.q.toDouble()

        return a1 * ((sequence.q.toDouble().pow( n.toDouble()- 1.0))  / (q - 1.0))//todo ellenőrzni, hogy jól rendeztem-e át javaról kotlin szintaxisra
    }

    private fun lastDigitOfPower(a: Int, n: Int): Int {
        var lastDigit = a % 10
        for (i in 1 until n) {
            lastDigit = (lastDigit * a) % 10
        }
        return lastDigit
    }












    data class ArithmeticSequence(val a1: Int, val d: Int)
    data class GeometricSequence(val a1: Int, val q: Int)
    data class Task(val text: Paramstr, val solution: Double)
}
