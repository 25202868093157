package matekit.matekit.games.szamkiraly






import androidx.lifecycle.ViewModel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.update






import androidx.compose.foundation.layout.*
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.*
import androidx.compose.runtime.*
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import matekit.matekit.taskgenerators.content
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.generatorSettings
import matekit.ui.settingListItem
import matekit_multiplatform.composeapp.generated.resources.AmountOfTerms
import matekit_multiplatform.composeapp.generated.resources.DefineARange
import matekit_multiplatform.composeapp.generated.resources.DefineRounds
import matekit_multiplatform.composeapp.generated.resources.Operations
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.defineTime
import matekit_multiplatform.composeapp.generated.resources.tagszam
import matekit_multiplatform.composeapp.generated.resources.v_letlenszer_tagsz_m
import org.jetbrains.compose.resources.stringResource

@Composable
fun MathKingSettingsScreen(
   // onStartGame: (MathKingGameSettings) -> Unit,
    gameparams:MutableState<MathKingGameSettings>,
    navController:NavHostController,
    viewModel: MathKingSettingsViewModel = viewModel {MathKingSettingsViewModel(gameparams)},
    startgame:MutableState<Boolean>,

) {
    val uiState by viewModel.uiState.collectAsState()
    val textfieldwidth = 160.dp
    val modifier = Modifier.fillMaxWidth()

    Column(modifier=Modifier.wrapContentSize(), verticalArrangement = Arrangement.Center, horizontalAlignment = Alignment.CenterHorizontally){
        generatorSettings {

            settingListItem(
                content = {
                    Text(stringResource(Res.string.v_letlenszer_tagsz_m))
                    Spacer(modifier = Modifier.width(8.dp))
                    Switch(
                        checked = uiState.randomTagszam,
                        onCheckedChange = viewModel::onRandomTagszamChanged
                    )
                }, alpha1,modifier=modifier
            )
            settingListItem(
                content = {
                    if (uiState.showTerms) {
                        OutlinedTextField(
                            value = uiState.tagszam,
                            onValueChange = viewModel::onTagszamChanged,
                            label = { Text(stringResource(Res.string.tagszam)) },
                            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                            modifier = Modifier.width(textfieldwidth)
                        )
                    } else {
                        Row {
                            OutlinedTextField(
                                value = uiState.tagSzamMin,
                                onValueChange = viewModel::onTagSzamMinChanged,
                                label = { Text("Min") },
                                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                modifier = Modifier.width(textfieldwidth)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            OutlinedTextField(
                                value = uiState.tagSzamMax,
                                onValueChange = viewModel::onTagSzamMaxChanged,
                                label = { Text("Max") },
                                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                modifier = Modifier.width(textfieldwidth)
                            )
                        }
                    }
                },
                alpha2,modifier=modifier
            )
            settingListItem(
                content = {

                    // Operations checkboxes
                    Column(horizontalAlignment = Alignment.CenterHorizontally,verticalArrangement = Arrangement.Center){
                        Text(stringResource(Res.string.Operations))
                        Row(verticalAlignment = Alignment.CenterVertically) {
                            Checkbox(
                                checked = uiState.osszead,
                                onCheckedChange = { viewModel.onOperationChanged("sum", it) }
                            )
                            Text("+")
                            Spacer(modifier = Modifier.width(8.dp))
                            Checkbox(
                                checked = uiState.kivon,
                                onCheckedChange = { viewModel.onOperationChanged("sub", it) }
                            )
                            Text("-")
                            Spacer(modifier = Modifier.width(8.dp))
                            Checkbox(
                                checked = uiState.szorzas,
                                onCheckedChange = { viewModel.onOperationChanged("mul", it) }
                            )
                            Text("×")
                            Spacer(modifier = Modifier.width(8.dp))
                            Checkbox(
                                checked = uiState.osztas,
                                onCheckedChange = { viewModel.onOperationChanged("div", it) }
                            )
                            Text("/")
                        }
                    }

                },
                alpha1,modifier=modifier
            )
            settingListItem(
                content = {

                    // Range input

                    Column(horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.Center) {
                        Text(stringResource(Res.string.DefineARange))
                        Row {
                            OutlinedTextField(
                                value = uiState.min,
                                onValueChange = viewModel::onMinChanged,
                                label = { Text("Min") },
                                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                modifier = Modifier.width(textfieldwidth)
                            )
                            Spacer(modifier = Modifier.width(8.dp))
                            OutlinedTextField(
                                value = uiState.max,
                                onValueChange = viewModel::onMaxChanged,
                                label = { Text("Max") },
                                keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                                modifier = Modifier.width(textfieldwidth)
                            )
                        }
                    }
                },
                alpha2,modifier=modifier
            )
            settingListItem(
                content = {
                    OutlinedTextField(
                        value = uiState.delay,
                        onValueChange = viewModel::onDelayChanged,
                        label = { Text(stringResource(Res.string.defineTime)) },
                        keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                        modifier = Modifier.width(textfieldwidth)
                    )
                }, alpha1,modifier=modifier
            )
            settingListItem(

                content = {
                    // Number of rounds
                    OutlinedTextField(
                        value = uiState.korszam,
                        onValueChange = viewModel::onKorszamChanged,
                        label = { Text(stringResource(Res.string.DefineRounds)) },
                        keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Number),
                        modifier = Modifier.width(textfieldwidth)
                    )

                }, alpha2,modifier=modifier
            )
            // Recommended Modifiers Switch
            /* Row(verticalAlignment = Alignment.CenterVertically) { //ez legyen inkább egy segmented button közepes és nehéz nehézséggel
                 Text("Recommended Modifiers")
                 Spacer(modifier = Modifier.width(8.dp))
                 Switch(
                     checked = uiState.recommendedModifiers,
                     onCheckedChange = viewModel::onRecommendedModifiersChanged
                 )
             }*/

        }
        if (uiState.errorMessage.isNotEmpty()) {
            Text(
                text = uiState.errorMessage,
                color = MaterialTheme.colorScheme.error,
                modifier = Modifier.padding(bottom = 8.dp)
            )
        }
        Button(
            onClick = { viewModel.validateAndStartGame({

                //navController.navigate("szamkiralygame") //todo kb így lesz a navigáció ha tudunk kmm-ben objektumot átadni paraméterként
                startgame.value=true //
            }) }, //add the settings parameters to the navigation
            modifier = Modifier.size(240.dp),
        ) {
            Text("START", fontSize = 44.sp,color= Color.White)
        }
    }

}





class MathKingSettingsViewModel(val gameparams: MutableState<MathKingGameSettings>) : ViewModel() {
    private val _uiState = MutableStateFlow(MathKingSettingsUiState())
    val uiState: StateFlow<MathKingSettingsUiState> = _uiState

    fun onRandomTagszamChanged(checked: Boolean) {
        _uiState.update {
            it.copy(
                randomTagszam = checked,
                showTerms = !checked,
                showTermsMinMax = checked
            )
        }
    }

    fun onOperationChanged(operation: String, checked: Boolean) {
        when (operation) {
            "sum" -> _uiState.update { it.copy(osszead = checked) }
            "sub" -> _uiState.update { it.copy(kivon = checked) }
            "mul" -> _uiState.update { it.copy(szorzas = checked) }
            "div" -> _uiState.update { it.copy(osztas = checked) }
        }
    }

    fun onTagszamChanged(value: String) {
        _uiState.update { it.copy(tagszam = value) }
    }

    fun onTagSzamMinChanged(value: String) {
        _uiState.update { it.copy(tagSzamMin = value) }
    }

    fun onTagSzamMaxChanged(value: String) {
        _uiState.update { it.copy(tagSzamMax = value) }
    }

    fun onKorszamChanged(value: String) {
        _uiState.update { it.copy(korszam = value) }
    }

    fun onMinChanged(value: String) {
        _uiState.update { it.copy(min = value) }
    }

    fun onMaxChanged(value: String) {
        _uiState.update { it.copy(max = value) }
    }

    fun onDelayChanged(value: String) {
        _uiState.update { it.copy(delay = value) }
    }

    fun onRecommendedModifiersChanged(checked: Boolean) {
        _uiState.update { it.copy(recommendedModifiers = checked) }
    }

    fun validateAndStartGame(onStartGame: (MathKingGameSettings) -> Unit) {
        val uiState = _uiState.value
        // Validation
        var errorMessage = ""

        if (!uiState.osszead && !uiState.osztas && !uiState.kivon && !uiState.szorzas) {
            errorMessage = "Choose at least one operation"
        }

        val randomTagszam = uiState.randomTagszam
        val tagszam = uiState.tagszam.toIntOrNull()?.takeIf { it > 0 }
        val tagSzamMin = uiState.tagSzamMin.toIntOrNull()?.takeIf { it > 0 }
        val tagSzamMax = uiState.tagSzamMax.toIntOrNull()?.takeIf { it >= tagSzamMin ?: 0 }
        val korszam = uiState.korszam.toIntOrNull()?.takeIf { it > 0 }
        val min = uiState.min.toIntOrNull()
        val max = uiState.max.toIntOrNull()?.takeIf { it >= min ?: 0 }
        val delay = uiState.delay.toIntOrNull()?.takeIf { it >= 0 }

        if (randomTagszam) {
            if (tagSzamMin == null || tagSzamMax == null) {
                errorMessage = "Define the amount of terms (min and max)"
            }
        } else {
            if (tagszam == null) {
                errorMessage = "Define the amount of terms"
            }
        }

        if (korszam == null) {
            errorMessage = "Define the number of rounds"
        }

        if (min == null || max == null) {
            errorMessage = "Define the number range (min and max)"
        }

        if (delay == null) {
            errorMessage = "Define the time between tasks"
        }

        if (errorMessage.isNotEmpty()) {
            _uiState.update { it.copy(errorMessage = errorMessage) }
        } else {
            val gameSettings = MathKingGameSettings(
                randomTagszam = randomTagszam,
                osszead = uiState.osszead,
                kivon = uiState.kivon,
                szorzas = uiState.szorzas,
                osztas = uiState.osztas,
                tagszam = tagszam ?: 2,
                tagSzamMin = tagSzamMin ?: 2,
                tagSzamMax = tagSzamMax ?: 5,
                korszam = korszam ?: 5,
                min = min ?: 1,
                max = max ?: 20,
                delay = delay ?: 3,
                recommendedModifiers = uiState.recommendedModifiers
            )

            gameparams.value = gameSettings
            onStartGame(gameSettings)
        }
    }
}






// MathKingSettingsUiState.kt
data class MathKingSettingsUiState(
    val randomTagszam: Boolean = false,
    val osszead: Boolean = true,
    val kivon: Boolean = true,
    val szorzas: Boolean = false,
    val osztas: Boolean = false,
    val tagszam: String = "2",
    val tagSzamMin: String = "2",
    val tagSzamMax: String = "5",
    val korszam: String = "5",
    val min: String = "1",
    val max: String = "20",
    val delay: String = "3",
    val recommendedModifiers: Boolean = true,
    val errorMessage: String = "",
    val showTerms: Boolean = true,
    val showTermsMinMax: Boolean = false
)