package matekit.matekit

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp

import androidx.navigation.NavHostController
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.launch
import matekit.ui.backButton
import matekit.ui.basicfontsize
import matekit_multiplatform.composeapp.generated.resources.FontSize
import matekit_multiplatform.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.stringResource
import org.w3c.dom.HTMLElement

@Composable
fun SettingsMenu(
    navController: NavHostController
) {

    Surface(
        modifier = Modifier.fillMaxSize(),
        color = MaterialTheme.colorScheme.background
    ) {
        backButton(navController, onback = {
            basicfontsize = localStorage.getItem("basicfontsize")?.toFloat() ?: 32f
            updateMathJaxFontSize(basicfontsize)
        })
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceAround
        ) {

            var basicfontsizeoption = remember{ mutableStateOf(basicfontsize)}

            Settingoption(slidervalue = basicfontsizeoption, valueRange = 1f..100f, text =
                "${
                    stringResource(
                        Res.string.FontSize
                    )
                }: ${basicfontsizeoption.value.toInt()}"
            , scaleText = true, "basicfontsize", type = "int")
            /* Settingoption(slidervalue = imageSize, valueRange = 1f..100f, text = {
                 "${getString(R.string.GeometryBlindMapPictureSize)}: ${imageSize.value.toInt()}%"
             }, key = "multiplier", editor = editor, háttértár = háttértár, type = "int")*/


        }
    }
}

@Composable
fun Settingoption(
    slidervalue: MutableState<Float>,
    valueRange: ClosedFloatingPointRange<Float>,
    text:  String,
    scaleText: Boolean = false,
    key: String,
    type: String = "float",
) {//általános beállításopció függvény
    val scope = rememberCoroutineScope()

    Surface(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .padding(8.dp),
        color = MaterialTheme.colorScheme.onSurface.copy(.09f),
        shape = RoundedCornerShape(12.dp)
    ) {
        Column(
            Modifier
                .padding(8.dp)
                .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {

            if (scaleText) Text(
                text = text,
                fontSize = slidervalue.value.sp
            ) else Text(text = text)
            Slider(value = slidervalue.value, onValueChange = {
                slidervalue.value = it

                if (type == "float") {

                    localStorage.setItem(key, slidervalue.value.toInt().toString())
//weben mindenből string lesz local stroageban

                }
                if (type == "int") {
                    scope.launch {
                        localStorage.setItem(key, slidervalue.value.toInt().toString())

                    }

                }

            }, valueRange = valueRange)
        }
    }
}


fun updateMathJaxFontSize(fontSize: Float) {
    // Get the element by ID and cast it to HTMLElement
    val density =1// window.devicePixelRatio
    //println("density = $density")
    val mathContent = document.getElementById("mathContent") as? HTMLElement
    // Safely access the style property and set the font size
    val calculatedfont = fontSize*density
   // println("calculatedfont = $calculatedfont")
    mathContent?.style?.fontSize = "${calculatedfont}px"
}