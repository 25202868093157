package matekit.matekit.taskgenerators

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Canvas
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Shapes
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clipToBounds
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Outline
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.Path
import androidx.compose.ui.graphics.drawOutline
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.graphics.nativeCanvas
import androidx.compose.ui.input.key.Key
import androidx.compose.ui.input.key.KeyEventType
import androidx.compose.ui.input.key.key
import androidx.compose.ui.input.key.onPreviewKeyEvent
import androidx.compose.ui.input.key.type
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.ParagraphStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.drawText
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
//import matekit.matekit.OrientationChecker
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.matekit.utilityfunctions.fromAndroidCanvasCoordinate
import matekit.matekit.utilityfunctions.perimeter
import matekit.matekit.utilityfunctions.removetrailingzeros
import matekit.matekit.utilityfunctions.toAndroidCanvasCoordinate
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.basicfontsizeTextView
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.ratios
import matekit.ui.responseToast
import matekit.ui.theme.black
import matekit.ui.theme.matekitcolor
import matekit.ui.theme.white
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.GeometryAreaSaveTask
import matekit_multiplatform.composeapp.generated.resources.GeometryAreaTask
import matekit_multiplatform.composeapp.generated.resources.GeometryPerimeterSaveTask
import matekit_multiplatform.composeapp.generated.resources.GeometryPerimeterTask
import matekit_multiplatform.composeapp.generated.resources.Kite
import matekit_multiplatform.composeapp.generated.resources.Parallelogram
import matekit_multiplatform.composeapp.generated.resources.Rectangle
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.Rhombus
import matekit_multiplatform.composeapp.generated.resources.Solution
import matekit_multiplatform.composeapp.generated.resources.Square
import matekit_multiplatform.composeapp.generated.resources.Trapezoid
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.coordinatesystem
import matekit_multiplatform.composeapp.generated.resources.konk_v_n_gysz_g
import matekit_multiplatform.composeapp.generated.resources.konkav_otszog
import matekit_multiplatform.composeapp.generated.resources.noType
import matekit_multiplatform.composeapp.generated.resources.triangle
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import kotlin.math.absoluteValue


@Composable
fun GeometriaKockasLaponUI(navController: NavHostController, tag:String = "GeometriaKockasLapon", tasktype:String,viewModel: GeometriaViewModel = viewModel{ GeometriaViewModel(tag,tasktype = tasktype)},ratios: ratios,orientation: MutableState<String>) {



 //   val orientation =orientation// OrientationChecker().checkOrientation()
    val coroutineScope = rememberCoroutineScope()


            // A surface container using the 'background' color from the theme
            Surface(
                modifier = Modifier.fillMaxSize(),
                color = MaterialTheme.colorScheme.background
            ) {

                val uiState by viewModel.uiState.collectAsState()
                backButton(navController)

                var mColor = MaterialTheme.colorScheme.onBackground
                // var currentSíkidomNév by remember{ mutableStateOf("")}
                Column(
                    modifier = Modifier
                        // .verticalScroll(rememberScrollState())
                        .fillMaxHeight(),
                    verticalArrangement = Arrangement.SpaceAround,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    var showCoordinates = remember { mutableStateOf(false) }
                    // Spacer(modifier = Modifier.fillMaxWidth(0.1f))
                    // var points by remember{mutableStateOf(currentsíkidom.points)}
                    // Column(verticalArrangement = Arrangement.SpaceAround, modifier = Modifier.fillMaxSize()) {
                    if (orientation.value == "Landscape") {
                        Row(
                            horizontalArrangement = Arrangement.SpaceAround,
                            verticalAlignment = Alignment.CenterVertically,
                            modifier = Modifier.fillMaxWidth()
                        ) {
                            Column{
                                Surface(
                                    border = BorderStroke(2.dp, matekitcolor),
                                    shape = Shapes().large,
                                    modifier = Modifier
                                        .padding(6.dp)
                                        .wrapContentWidth(),
                                    /*.wrapContentHeight()*/

                                ) {
                                    basicfontsizeTextView(
                                        stringResource(Res.string.GeometryPerimeterTask),
                                        Modifier.padding(5.dp)
                                    )
                                }
                                if (tasktype == "perimeter") Text(
                                    "A kerekítés végett egyszázados eltérést elfogadunk a valós megoldástól.",
                                    textAlign = TextAlign.Center,
                                    modifier = Modifier.padding(bottom = 6.dp)
                                )
                            }
                            // Spacer(modifier = Modifier.height(1.dp))
                            showCoordinatesBT(showCoordinates)


                            solutionField( uiState.userinput,entertext = {viewModel.entertext(it)},check= {

                                coroutineScope.launch {
                                    viewModel.check(tasktype)
                                }
                                }

                            )

                            Button(onClick = {
                                coroutineScope.launch {
                                    viewModel.check(tasktype)
                                }
                            }){
                                Text(stringResource(Res.string.Check))
                            }

                            LatexLogButton(navController,tag)


                            //logbuttonsButton(context, TAG) //todo logbutton

                        }
                    } else {

                        borderedbasicfontsizeTextView(stringResource(Res.string.GeometryAreaTask))
                        if(tasktype=="perimeter")     Text("A kerekítés végett egyszázados eltérést elfogadunk a valós megoldástól.", textAlign = TextAlign.Center, modifier = Modifier.padding(bottom = 6.dp))

                        Row(horizontalArrangement = Arrangement.SpaceBetween, verticalAlignment = Alignment.CenterVertically) {
                            Spacer(modifier = Modifier.height(1.dp))
                            showCoordinatesBT(showCoordinates)
                            solutionField( uiState.userinput,entertext = {viewModel.entertext(it)},check= {
                                coroutineScope.launch {
                                    viewModel.check(tasktype)
                                }
                            }

                            )
                            Spacer(modifier = Modifier.width(4.dp))
                            Button(onClick = {
                                coroutineScope.launch {
                                    viewModel.check(tasktype)
                                }
                            }){
                                Text(stringResource(Res.string.Check))
                            }

                            LatexLogButton(navController,tag)

                            // logbuttonsButton(context, TAG)
                        }
                    }

                    Row(modifier = Modifier.fillMaxHeight()) {
                        Box(modifier = Modifier.fillMaxSize()) {
                            Column(modifier = Modifier.fillMaxHeight(0.9f)) {
                                kockáslap(
                                    mColor = mColor,
                                    path =uiState. currentSíkidom.points,
                                    MaterialTheme.colorScheme.onPrimary,
                                    showCoordinates.value
                                )
                                Spacer(modifier = Modifier.height(200.dp))
                            }
                           /* whiteboard( //todo legyen itt egy rajztábla
                                activity = this@GeometriaKockasLapon,
                                context = context,
                                darkmode = darkmode
                            )*/

                        }

                    }

                    /* Button(modifier = Modifier.size(150.dp),onClick = { currentsíkidom = generateTask() }) {
                         Text("generate a task for testing purpose")
                     }*/
                    // }

                }
                responseToast(uiState.correctAnswer,uiState.numberofsolved, completeResponse =  uiState.showsolution, useCompleteFeedbackMessage = true)


            }







}



data class GeometriaUiState(
  var currentSíkidom: Síkidom = Síkidom( listOf(),0f,0.0,Res.string.noType),
    var showSettings: Boolean = false,
    var userinput:String  ="",
    var correctAnswer:Boolean=false,
  var showsolution:String="",
    var numberofsolved:Int=0
)

class GeometriaViewModel(tag:String,val tasktype: String) : ViewModel() {
    val tag = tag
    private val _uiState = MutableStateFlow(GeometriaUiState())
    val uiState: StateFlow<GeometriaUiState> = _uiState.asStateFlow()

    var síkidomStringResources = listOf(
        Res.string.Square,
        Res.string.Rectangle,
       // Res.string.Rhombus, //ilyet nem generálunk (egyelőre)
        Res.string.Parallelogram,
        Res.string.Kite,
        Res.string.Trapezoid,
        Res.string.triangle,
        Res.string.konk_v_n_gysz_g,
        Res.string.konkav_otszog
    )


    init {
        if(tasktype=="perimeter"){
            síkidomStringResources = listOf(
                Res.string.Square,
                Res.string.Rectangle,
                // Res.string.Rhombus, //ilyet nem generálunk (egyelőre)
                Res.string.Parallelogram,
                Res.string.Kite,
                Res.string.Trapezoid,
                Res.string.triangle,
            )
        }

        generateTask()
    }

fun entertext(value:String){
    _uiState.update {
        it.copy(
            userinput = value
        )
    }
}
    fun generateTask() {//ez itt a logikai réteg
        var síkIdomStringResource = síkidomStringResources.random()
        var pointsandarea = generatePathForSíkidom(síkIdomStringResource)
        var points = pointsandarea.points.map {
            Pair(
                it.first.toDouble(),
                it.second.toDouble()
            )
        }//float párosok listájából double párosok listája

        var síkidom =
            Síkidom(name = síkIdomStringResource, points = pointsandarea.points, area = pointsandarea.area, perimeter = perimeter(points))
       // Log.d("GeometriaKockásLapon", pointsandarea.points.toString())
       // Log.d("GeometriaKockásLapon", pointsandarea.area.toString())


        _uiState.update {
            it.copy(currentSíkidom = síkidom)
        }
    }

    suspend fun check(tasktype: String) {
        if(uiState.value.userinput.isEmpty()) return@check
        var helyesMO: Boolean
        var displayedTask = uiState.value.  currentSíkidom?.let {
            getString(
                Res.string.GeometryAreaSaveTask,
                it.pointsToString(),
               getString( uiState.value.  currentSíkidom!!.name   )     ).replace(".0", "").replace("[[", "(").replace("]]", ")").replace("[", "(")
                .replace("]", ")")
        }?:""

        if(tasktype=="perimeter"){
            displayedTask = uiState.value.  currentSíkidom?.let {
                getString(
                    Res.string.GeometryPerimeterSaveTask,
                    it.pointsToString(),
                    getString( uiState.value.  currentSíkidom!!.name   )     ).replace(".0", "").replace("[[", "(").replace("]]", ")").replace("[", "(")
                    .replace("]", ")")
            }?:""
        }
        var showSolutionIfUserWrong =
            "" //nem adhatunk be null showsolutiont, mert akkor a lognál megáll a program jó megoldás esetén is


        val solution = if (tasktype == "area") {
            uiState.value.  currentSíkidom.area.toDouble()
        } else {
            uiState.value.  currentSíkidom.perimeter
        }


        if ( uiState.value.  currentSíkidom.area  == uiState.value.userinput.toFloatOrNull()) {
            helyesMO = true
            //println("helyes")
        } else {
           // println("hibás")
            helyesMO = false

            var param = solution.formatToDecimals(6)
            if (tasktype == "perimeter") param = "~$param"

            showSolutionIfUserWrong = getString(Res.string.WrongSolution,param)
        }

        if (tasktype == "perimeter") {
            if (uiState.value.userinput.toDouble() in solution - 0.01..solution + 0.01) {
                helyesMO = true
                showSolutionIfUserWrong = ""
            }
        }


        LaTexLogWriteDesktop(helyesMO, uiState.value.userinput, showSolutionIfUserWrong, tag, displayedTask, taskLaTexWrap = false)

        _uiState.update {
            it.copy(
                userinput = "",
                correctAnswer = helyesMO,
                numberofsolved = it.numberofsolved+1,
                showsolution = showSolutionIfUserWrong
            )
        }

       // var color = if (helyesMO) R.style.CorrectToast else R.style.WrongToast
      //  ShowToast.showToast(color, text, context, true)
      /*  SaveUserInput.simplelog( //todo toast és log
            this@GeometriaKockasLapon,
            helyesMO,
            userinput.toString().replace(".0", ""),
            showSolutionIfUserWrong,
            TAG,
            displayedTask,
            userDocRef, modulID =11
        )*/
        // Log.d(TAG,"b")

        generateTask()
    }



    fun clockwiseRotate(rotations: Int, pont: Pair<Float, Float>): Pair<Float, Float> {
        var újpont = pont
        if (rotations != 0) {
            var rotations = rotations
            rotations--
            var középpontosba = újpont.fromAndroidCanvasCoordinate(
                1f,
                12
            ) // átírjuk, hogy a középpont a lap közepén legyen
            újpont = Pair(középpontosba.second, középpontosba.first * -1) //megforgatjuk
            újpont = újpont.toAndroidCanvasCoordinate(1f, 12)//visszarakjuk bal felső középpontba
            clockwiseRotate(rotations, újpont)
        } else {
            return újpont
        }
        return újpont
    }

    fun clockwiseRotateList(
        rotations: Int,
        pontok: List<Pair<Float, Float>>
    ): List<Pair<Float, Float>> {
        var újlista = mutableListOf<Pair<Float, Float>>()
        pontok.forEachIndexed { index, pair ->
            var újpont = clockwiseRotate(rotations, pair)
            újlista.add(újpont)
        }
        return újlista

    }

    fun generatePathForSíkidom(síkIdomStringResource: StringResource): PointsAndArea { //ez a függvény generálja a véletlenszerűen választott síkidomhoz annak pontjait, és kiszámolja a területét is
        lateinit var pointsandarea: PointsAndArea
        var k = (0..8).random()

        when (síkIdomStringResource) {
            (Res.string.Square) -> {
                var x = (0..10).random().toFloat()
                var y = (0..10).random().toFloat()
                var Amax = listOf<Float>(12 - x, 12 - y).minOrNull()
                var a = (2..Amax!!.toInt()).random().toFloat() //a az oldalhossz
                var points = listOf<Pair<Float, Float>>(
                    Pair(x, y),
                    Pair(x + a, y),
                    Pair(x + a, y + a),
                    Pair(x, y + a)
                )
                points = clockwiseRotateList(k, points)
                val area = a * a
                pointsandarea = PointsAndArea(points, area)

            }

            (Res.string.Rectangle) -> {
                var x = (0..10).random().toFloat()
                var y = (0..10).random().toFloat()
                var a = (2..(12 - x).toInt()).random().toFloat()
                var b = (2..(12 - y).toInt()).random().toFloat()
                var points = listOf<Pair<Float, Float>>(
                    Pair(x, y),
                    Pair(x + a, y),
                    Pair(x + a, y + b),
                    Pair(x, y + b)
                )
                val area = a * b
                points = clockwiseRotateList(k, points)

                pointsandarea = PointsAndArea(points, area)
            }

            (Res.string.Rhombus) -> {
//ezt lehet passzolni kellene, nehezen lesz pont rombusz, ha random generáljuk, hogy mennyivel van eltolva egymáshoz képest a kettő alap
            }

            (Res.string.Parallelogram) -> {
                var x = (0..10).random().toFloat()
                var y = (2..12).random().toFloat()
                var a = (1..(12 - x - 1).toInt()).random().toFloat()
                var e = (1..(12 - a - x).toInt()).random().toFloat()  // e mint eltolás
                var m = (1..y.toInt()).random().toFloat()
                var points = listOf<Pair<Float, Float>>(
                    Pair(x, y),
                    Pair(x + a, y),
                    Pair(x + a + e, y - m),
                    Pair(x + e, y - m)
                )
                val area = a * m
                points = clockwiseRotateList(k, points)

                pointsandarea = PointsAndArea(points, area)
             //   Log.d(TAG, "a=$a, m=$m")
            }

            (Res.string.Kite) -> {
                /*kp*/
                var x = (1..11).random().toFloat()
                var y = (1..11).random().toFloat()
                var e = if (x == 6f) {
                    (1..5).random().toFloat()
                } else if (x < 6) {
                    (1..x.toInt()).random().toFloat()
                } else {
                    (1..(12 - x).toInt()).random().toFloat()
                }
                var Ffel = (1..y.toInt()).random().toFloat()
                var Fle = (1..(12 - y).toInt()).random().toFloat()
                var points = listOf<Pair<Float, Float>>(
                    Pair(x, y - Ffel),
                    Pair(x + e, y),
                    Pair(x, y + Fle),
                    Pair(x - e, y)
                )
                val area = e * (Ffel + Fle)
                points = clockwiseRotateList(k, points)

                pointsandarea = PointsAndArea(points, area)

            }

            (Res.string.Trapezoid) -> {
                var Yc = (0..8).random().toFloat()
                var Ya = ((Yc + 2).toInt()..12).random().toFloat()
                var Xa1 = (0..2).random().toFloat()
                var Xa2 = ((Xa1 + 2).toInt()..12).random().toFloat()
                var Xc1 = (0..8).random().toFloat()
                var Xc2 = ((Xc1 + 2).toInt()..12).random().toFloat()
                var points = listOf<Pair<Float, Float>>(
                    Pair(Xa1, Ya),
                    Pair(Xa2, Ya),
                    Pair(Xc2, Yc),
                    Pair(Xc1, Yc)
                )
                var a = Xa2 - Xa1
                var c = Xc2 - Xc1
                var m = Ya - Yc
                val area: Float = (a + c) * m / 2f
                points = clockwiseRotateList(k, points)

                pointsandarea = PointsAndArea(points, area)


            }

            (Res.string.triangle) -> {
                val triangle = triangle()
                var points = listOf<Pair<Float, Float>>(
                    Pair(triangle[0], triangle[3]),
                    Pair(triangle[1], triangle[4]),
                    Pair(triangle[2], triangle[5]),
                )
                points = clockwiseRotateList(k, points)

                pointsandarea = PointsAndArea(points, triangle.last())
            }

            (Res.string.konk_v_n_gysz_g) -> { //az összes többi pontjait úgy generálom, mintha a bal felső sarokban lenne a koordinátarendszer origója. itt meg középen van

                var centerx =
                    (2..10).random() //egy belső négyzet pontjai közül választunk, hogy még tudjunk menni jobbra, balra, fel meg le
                var centery = (2..10).random()
                var I_Y =
                    (0..centery - 1).random() //mentünk valamennyit lefele a koordinátarendszerben. 0-től annyi-1-ig mehetünk
                var II_X =
                    (centerx + 1..12).random() // legalább egyet lépjen jobbra, de mehet a széléig is
                var II_Y = (centery + 1..12).random()
                var III_Y = (centery + 1..12).random()
                var III_X = (0..centerx - 1).random()

                var center = Pair(centerx.toFloat(), centery.toFloat())
                var I = Pair(centerx.toFloat(), I_Y.toFloat())
                var II = Pair(II_X.toFloat(), II_Y.toFloat())
                var III = Pair(III_X.toFloat(), III_Y.toFloat())

                var points = listOf(I, II, center, III)
                points = clockwiseRotateList(k, points)
                var area = trianglearea(listOf(I, II, III)) - trianglearea(listOf(center, II, III))
                pointsandarea = PointsAndArea(points, area)
            }
            //todo rakjunk-e esetleg bele kört is?, vagy inkább körcikket?
           (Res.string.konkav_otszog) -> {
                var centerx =
                    (2..10).random() //egy belső négyzet pontjai közül választunk, hogy még tudjunk menni jobbra, balra, fel meg le
                var centery = (2..10).random()

                //az első pont balra fent
                var I_Y =
                    (0..centery - 1).random() //mentünk valamennyit lefele a koordinátarendszerben. 0-től annyi-1-ig mehetünk
                var I_X = (0..centerx - 1).random() //X tengelyen 0tól majdnem a centerig mehetünk

                //második pont jobbra fent
                var II_X =
                    (centerx + 1..12).random() // legalább egyet lépjen jobbra, de mehet a széléig is
                var II_Y = (0..centery - 1).random()

                //harmadik pont jobbra lent
                var III_Y = (centery + 1..12).random()
                var III_X = (centerx + 1..12).random()

                //negyedik pont balra le
                var IV_Y = (centery + 1..12).random()
                var IV_X = (0..centerx - 1).random()

                var center = Pair(centerx.toFloat(), centery.toFloat())
                var I = Pair(I_X.toFloat(), I_Y.toFloat())
                var II = Pair(II_X.toFloat(), II_Y.toFloat())
                var III = Pair(III_X.toFloat(), III_Y.toFloat())
                var IV = Pair(IV_X.toFloat(), IV_Y.toFloat())

                var points = listOf(I, II, center, III, IV)
                points = clockwiseRotateList(k, points)
                var area = trianglearea(listOf(I, II, center)) + trianglearea(listOf(center, III, IV)) +trianglearea(listOf(I,center,IV))
                pointsandarea = PointsAndArea(points, area)
            }

        }



        return pointsandarea
    }

    fun trianglearea(coordinates: List<Pair<Float, Float>>): Float {
        var X1 = coordinates[0].first
        var X2 = coordinates[1].first
        var X3 = coordinates[2].first
        var Y1 = coordinates[0].second
        var Y2 = coordinates[1].second
        var Y3 = coordinates[2].second

        val area = 0.5f * (X1 * (Y2 - Y3) + X2 * (Y3 - Y1) + X3 * (Y1 - Y2))
        /* if(area==0f){
             exitProcess(0)
         }*/

        return area


    }
    fun triangle(): List<Float> {
        var X1 = (0..12).random().toFloat()
        var X2 = (0..12).random().toFloat()
        var X3 = (0..12).random().toFloat()
        var Y1 = (0..12).random().toFloat()
        var Y2 = (0..12).random().toFloat()
        var Y3 = (0..12).random().toFloat()
        val area = 0.5f * (X1 * (Y2 - Y3) + X2 * (Y3 - Y1) + X3 * (Y1 - Y2))
        return if (area == 0f) {
            triangle()
            //listOf()
        } else {
            listOf(X1, X2, X3, Y1, Y2, Y3, area.absoluteValue)
        }
    }




}


data class PointsAndArea(var points: List<Pair<Float, Float>>, var area: Float)
class Síkidom(
    var points: List<Pair<Float, Float>>,
    var area: Float,
    var perimeter: Double,
    var name: StringResource
) {
    fun pointsToString(): String {

        var coordinates = ""
        for (point in points) {
            var convertedpoint = point.fromAndroidCanvasCoordinate(
                1f,
                12
            )//visszaváltjuk középpontos koordinátarendszerbe

            coordinates += "(${convertedpoint.first};${convertedpoint.second}), "
        }
        coordinates = coordinates.dropLast(2)
        return coordinates
    }

}
@Composable
fun kockáslap(
    mColor: Color,
    path: List<Pair<Float, Float>>,
    textcolor: Color,
    showcoordinates: Boolean
) {
    val textMeasurer = rememberTextMeasurer()

    var rotation by remember { mutableStateOf(0f) }
    val modifier = Modifier
        .fillMaxHeight()
        .aspectRatio(1f)
    val density = LocalDensity.current
    val textPaint = remember(density) {
        Paint().apply {
            color = if (textcolor == white) {
                white
            } else black
            //  textAlign = Paint.Align.CENTER //todo update it to kmm
            // textSize = density.run { 14.sp.toPx() }
        }
    }

    Box() {

        if (showcoordinates) {
            Canvas(
                modifier = modifier
                    .padding(12.dp)
                    .clipToBounds()
            ) {
                val cw = size.width
                val ch = size.height
                val unit = cw / 12
                val szélesség = 12
                drawLine(//x tengely
                    start = Offset(x = 0f, y = szélesség / 2.toFloat() * unit),
                    end = Offset(
                        x = szélesség.toFloat() * unit,
                        y = szélesség / 2.toFloat() * unit
                    ),
                    color = mColor.copy(1f)
                )
                drawLine(//y tengely
                    start = Offset(x = szélesség / 2.toFloat() * unit, y = 0f),
                    end = Offset(
                        x = szélesség / 2.toFloat() * unit,
                        y = szélesség.toFloat() * unit
                    ),
                    color = mColor.copy(1f)
                )
                var arrowstarts = listOf<List<Float>>(
                    listOf(-0.125f, szélesség / 2 - 0.25f), //x tengely
                    listOf(0.125f, szélesség / 2 - 0.25f),//x tengely
                    listOf(szélesség / 2 - 0.25f, 0.125f),//y tengely
                    listOf(szélesség / 2 - 0.25f, -0.125f),//y tengely
                )
                var arrowends = listOf<List<Float>>(
                    listOf(0f, szélesség / 2.toFloat()),
                    listOf(0f, szélesség / 2.toFloat()),
                    listOf(szélesség / 2.toFloat(), 0f),
                    listOf(szélesség / 2.toFloat(), 0f),
                )
                arrowstarts.forEachIndexed { index, list ->
                    var start = list.toAndroidCanvasCoordinate(unit, szélesség)
                    var end = arrowends[index].toAndroidCanvasCoordinate(unit, szélesség)
                    drawLine(
                        start = Offset(x = start[0], y = start[1]),
                        end = Offset(x = end[0], y = end[1]),
                        color = mColor.copy(1f)
                    )
                }

                for (i in 0..szélesség) {
                    drawLine(
                        start = Offset(x = i * unit, y = 0f),
                        end = Offset(x = i * unit, y = szélesség * unit),
                        color = mColor.copy(0.8f)
                    )
                    drawLine(
                        start = Offset(x = 0f, y = i * unit),
                        end = Offset(x = szélesség * unit, y = i * unit),
                        color = mColor.copy(0.8f)
                    )

                    var koordináta =
                        listOf((i - szélesség / 2).toFloat(), 0f).toAndroidCanvasCoordinate(
                            unit,
                            szélesség
                        )
                    drawContext.canvas.nativeCanvas.apply {
                        drawText(  //todo debuggolni
                            textMeasurer=textMeasurer,
                      text=   buildAnnotatedString {
                          withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                              append( "${i - szélesség / 2}")
                          }
                      },
                         topLeft = Offset   (  koordináta[0] ,
                            koordináta[1] ,
                        )
                        )
                    }
                    drawContext.canvas.nativeCanvas.apply {

                        if (i != szélesség / 2) {
                            drawText(
                                textMeasurer=textMeasurer,
                                text=   buildAnnotatedString {
                                    withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                        append( "${(-szélesség / 2 + i) * -1}")
                                    }
                                },
                                topLeft = Offset   (    koordináta[1] + 10,
                                    koordináta[0],
                                )
                            )
                        }
                    }
                }
                var topy = listOf(0.35f, szélesség / 2 - 0.35f).toAndroidCanvasCoordinate(
                    unit,
                    szélesség
                ) //(0.35f,5.65f)
                var rightx = listOf(szélesség / 2 - 0.5f, 0.25f).toAndroidCanvasCoordinate(
                    unit,
                    szélesség
                ) //(5.5f,0.25f)
                drawContext.canvas.nativeCanvas.apply {



                    drawText(
                        textMeasurer=textMeasurer,
                        text=   buildAnnotatedString {
                            withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                append( "x")
                            }
                        },
                        topLeft = Offset   (     rightx[0],
                            rightx[1],
                        )
                    )
                    drawText(
                        textMeasurer=textMeasurer,
                        text=   buildAnnotatedString {
                            withStyle(ParagraphStyle(textAlign = TextAlign.Center)) {
                                append( "y")
                            }
                        },
                        topLeft = Offset   (     topy[0],
                            topy[1],
                        )
                    )
                }


            }
        }

        Canvas(
            modifier = modifier
                //  .rotate(rotation) saját függvénnyel forgatjuk a síkidomot, hogy követni tudjuk a pontok koordinátáinak értékeit
                .padding(12.dp)
                .clipToBounds()
        )
        {
            val cw = size.width
            val ch = size.height
            val unit = cw / 12
            var pathToDraw = Path().apply {
                moveTo(path[0].first * unit, path[0].second * unit)
                for (i in 1 until path.size) {
                    lineTo(path[i].first * unit, path[i].second * unit)
                }
                close()
            }
            var testPath =
                listOf<Pair<Float, Float>>(Pair(0f, 0f), Pair(4f, 5f), Pair(9f, 2f), Pair(4f, 2f))
            var testPathtoDraw =
                Path().apply {
                    moveTo(testPath[0].first * unit, testPath[0].second * unit)
                    for (i in 1 until testPath.size) {
                        lineTo(testPath[i].first * unit, testPath[i].second * unit)
                    }
                    close()
                }

            /* drawLine( //nem kell már a debuggoló zöld vonal
                 start = Offset(x = 10f, y = 10f),
                 end = Offset(x = 10f * unit, y = 100f),
                 color = Color.Green
             )*/
            drawIntoCanvas { canvas ->

                canvas.drawOutline(
                    outline = Outline.Generic(pathToDraw),
                    paint = Paint().apply {
                        color = matekitcolor
                    }
                )
                var drawtest = false
                if (drawtest) {
                    canvas.drawOutline(
                        outline = Outline.Generic(testPathtoDraw),
                        paint = Paint().apply {
                            color = Color.Green
                        }
                    )
                }


            }
            var szélesség = 12
            for (i in 0..12) {
                drawLine(
                    start = Offset(x = i * unit, y = 0f),
                    end = Offset(x = i * unit, y = 12 * unit),
                    color = mColor.copy(0.8f)
                )
                drawLine(
                    start = Offset(x = 0f, y = i * unit),
                    end = Offset(x = 12 * unit, y = i * unit),
                    color = mColor.copy(0.8f)
                )
            } //benégyzetrácsozás


        }
    }


    LaunchedEffect(key1 = path, block = {
        var k = (0..8).random()
        rotation = 90f * k
        //Log.d("GeometriaKockásLapon", rotation.toString())
    })

}







@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun solutionField(value:String, entertext:(String)->Unit,modifier: Modifier = Modifier, check:()->Unit) {

    OutlinedTextField(
         modifier =Modifier.widthIn(max = 150.dp).onPreviewKeyEvent { event ->
             if ((event.key == Key.NumPadEnter||event.key== Key.Enter)&&event.type == KeyEventType.KeyDown ) {
                 check()
                 true // Indicate that the event was handled
             } else {
                 false // Indicate that the event was not handled
             }
         },
        value = value,
        onValueChange = { entertext(it)},
        label = { Text(stringResource(Res.string.Solution)) },
        keyboardOptions = KeyboardOptions(
            keyboardType = KeyboardType.Number,
            imeAction = ImeAction.Done
        ),
        keyboardActions = KeyboardActions(
            onDone = {
                    check()
            }
        )
    )
}

@Composable
fun showCoordinatesBT(showCoordinates: MutableState<Boolean>) {
    IconButton(
        onClick = { showCoordinates.value = !showCoordinates.value },
        modifier = Modifier
            .size(60.dp)
            .padding(6.dp)
    ) {
        Icon(
            painter = painterResource( Res.drawable.coordinatesystem),
            contentDescription = null,
            modifier = Modifier.fillMaxSize()// decorative element
        )
    }

}
