package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.material3.Button
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.GenitiveCase
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.Percentage_A_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_A_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_A_3
import matekit_multiplatform.composeapp.generated.resources.Percentage_A_4
import matekit_multiplatform.composeapp.generated.resources.Percentage_B_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_B_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_B_3
import matekit_multiplatform.composeapp.generated.resources.Percentage_C_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_D_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_E_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_F_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_G_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_G_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_G_3
import matekit_multiplatform.composeapp.generated.resources.Percentage_G_4
import matekit_multiplatform.composeapp.generated.resources.Percentage_G_5
import matekit_multiplatform.composeapp.generated.resources.Percentage_H_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_H_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_H_3
import matekit_multiplatform.composeapp.generated.resources.Percentage_H_4
import matekit_multiplatform.composeapp.generated.resources.Percentage_H_5
import matekit_multiplatform.composeapp.generated.resources.Percentage_I_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_I_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_I_3
import matekit_multiplatform.composeapp.generated.resources.Percentage_J_1
import matekit_multiplatform.composeapp.generated.resources.Percentage_J_2
import matekit_multiplatform.composeapp.generated.resources.Percentage_J_3
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.szazalek_round

import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.abs

@Composable
fun SzazalekScreen(navController: NavController,tag:String="Szazalek",viewModel: SzazalekViewModel = viewModel{
    SzazalekViewModel(tag)
}) {
    val uiState by viewModel.uiState.collectAsState()
    val coroutineScope = rememberCoroutineScope()
    backButton(navController)

    LaunchedEffect(Unit) {
        viewModel.generateNewTask()
    }
    val task = uiState.currentTask
    val taskNumbers = uiState.taskNumbers
    val taskTextIndex = uiState.taskTextIndex


    if (task != null) {
        val taskTextResId = task.taskTextResIds[taskTextIndex]
        val taskTextArgs = getTaskTextArgs(taskTextResId, taskNumbers)
        val taskText = stringResource(taskTextResId, *taskTextArgs.toTypedArray())

        Column(
            modifier = Modifier.fillMaxSize(),
          //  verticalArrangement = Arrangement.SpaceAround
        ) {
            Column(Modifier.wrapContentSize()) {
                borderedbasicfontsizeTextView(tasktext = taskText+"\n"+ stringResource(Res.string.szazalek_round))
                Spacer(modifier = Modifier.height(10.dp))
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    universalCheckField(userInput = uiState.userInput, check = {
                      coroutineScope.launch {  viewModel.checkSolution()}

                    },
                        enterText = {
                            viewModel.entertext(it)
                        }
                        )
                    Spacer(modifier = Modifier.width(10.dp))
                    Button(
                        onClick = {
                            coroutineScope.launch {  viewModel.checkSolution()}
                        }
                    ) {
                        Text(stringResource(Res.string.Check))
                    }

                    LatexLogButton(navController,tag)
                }
            }
        }

        responseToast(uiState.correctAnswer,uiState.numberofsolved,uiState.showsolution)

    }
}


data class Task(
    val taskTextResIds: List<StringResource>,
    val numberIntervals: List<Pair<List<Int>, Boolean>>,
    val solution: (List<Int>) -> Double
)

data class SzazalekUIState(
    val currentTask: Task? = null,
    val taskNumbers: List<Int> = emptyList(),
    val taskTextIndex: Int = 0,
    val solution: Double = 0.0,
    val showsolution:String="",
    val numberofsolved:Int=0,
    val correctAnswer:Boolean=false,
    val userInput: String = ""
)
fun generateTaskNumbers(task: Task): MutableList<Int> {
    val numbers = mutableListOf<Int>()
    task.numberIntervals.forEach { (intervalList, generateAdditional) ->
        val newNum = intervalList.random()
        numbers.add(newNum)
        if (generateAdditional) {
            val secondaryNumber = (1 until newNum).random()
            numbers.add(secondaryNumber)
        }
    }
    return numbers
}

class SzazalekViewModel(tag:String) : ViewModel() {
    val tag = tag
   private val _uiState = MutableStateFlow(SzazalekUIState())
    val uiState: StateFlow<SzazalekUIState> = _uiState.asStateFlow()

        private val tasks = listOf(
            // Task Type A
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_A_1,
                    Res.string.Percentage_A_2,
                    Res.string.Percentage_A_3,
                    Res.string.Percentage_A_4
                ),
                numberIntervals = listOf(
                    Pair((2..50).toList(), true)
                ),
                solution = { numbers ->
                    (numbers[1] / numbers[0].toDouble()) * 100.0
                }
            ),
            // Task Type B
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_B_1,
                    Res.string.Percentage_B_2,
                    Res.string.Percentage_B_3
                ),
                numberIntervals = listOf(
                    Pair((1..50).toList(), false),
                    Pair((1..50).toList(), false)
                ),
                solution = { numbers ->
                    numbers[0] * numbers[1] / 100.0
                }
            ),
            // Task Type C
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_C_1
                ),
                numberIntervals = listOf(
                    Pair((2..150).toList(), false),
                    Pair(listOf(1, 2, 4, 5, 8, 10, 20, 25, 40, 50, 80, 150, 200, 250), false)
                ),
                solution = { numbers ->
                    numbers[0] * 100.0 / numbers[1]
                }
            ),
            // Task Type D
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_D_1
                ),
                numberIntervals = listOf(
                    Pair((1..10).toList(), false),
                    Pair((5..10).toList(), true)
                ),
                solution = { numbers ->
                    (numbers[0] * numbers[1]) / numbers[2].toDouble() - numbers[0]
                }
            ),
            // Task Type E
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_E_1
                ),
                numberIntervals = listOf(
                    Pair(listOf(10, 20, 30, 40), false),
                    Pair(listOf(10, 20, 30, 40), false)
                ),
                solution = { numbers ->
                    (100.0 + numbers[0]) * (100 - numbers[1]) / 100.0
                }
            ),
            // Task Type F
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_F_1
                ),
                numberIntervals = listOf(
                    Pair((5..80).toList(), false),
                    Pair((500..3200 step 100).toList(), false)
                ),
                solution = { numbers ->
                    numbers[1] / (2.0 * (1 - (numbers[0] / 100.0)))
                }
            ),
            // Task Type G
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_G_1,
                    Res.string.Percentage_G_2,
                    Res.string.Percentage_G_3,
                    Res.string.Percentage_G_4,
                    Res.string.Percentage_G_5
                ),
                numberIntervals = listOf(
                    Pair((500..8000 step 10).toList(), false),
                    Pair((5..200 step 5).toList(), false)
                ),
                solution = { numbers ->
                    numbers[0] * (1 + numbers[1] / 100.0)
                }
            ),
            // Task Type H
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_H_1,
                    Res.string.Percentage_H_2,
                    Res.string.Percentage_H_3,
                    Res.string.Percentage_H_4,
                    Res.string.Percentage_H_5
                ),
                numberIntervals = listOf(
                    Pair((500..8000 step 10).toList(), false),
                    Pair((5..80 step 5).toList(), false)
                ),
                solution = { numbers ->
                    numbers[0] * (1 - numbers[1] / 100.0)
                }
            ),
            // Task Type I
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_I_1,
                    Res.string.Percentage_I_2,
                    Res.string.Percentage_I_3
                ),
                numberIntervals = listOf(
                    Pair((500..2000 step 100).toList(), true)
                ),
                solution = { numbers ->
                    (numbers[0].toDouble() / numbers[1] * 100.0) - 100.0
                }
            ),
            // Task Type J
            Task(
                taskTextResIds = listOf(
                    Res.string.Percentage_J_1,
                    Res.string.Percentage_J_2,
                    Res.string.Percentage_J_3
                ),
                numberIntervals = listOf(
                    Pair((500..2000 step 100).toList(), true)
                ),
                solution = { numbers ->
                    (1 - numbers[1].toDouble() / numbers[0]) * 100.0
                }
            )
        )

    fun entertext (enteredText:String){
        _uiState.update {
            it.copy(
               userInput = enteredText
            )
        }
    }

        fun generateNewTask() {
            val task = tasks.random()
            val taskNumbers = generateTaskNumbers(task)
            val taskTextIndex = (task.taskTextResIds.indices).random()
            val solution = task.solution(taskNumbers)

            _uiState.update {
                it.copy(
                    currentTask = task,
                    taskNumbers = taskNumbers,
                    taskTextIndex = taskTextIndex,
                    solution = solution
                )
            }
        }

    init {
        generateNewTask()
    }
        suspend fun checkSolution() {

            val input = try{ uiState.value.userInput.replace(",", ".").toDouble() } catch (e:Exception) {
                return@checkSolution
            }
            //println("input:$input")//todo ellenőrizni, hogy a tizedes jegyeket jól kezeli-e
            val isCorrect = input != null && abs(input - _uiState.value.solution) < 0.0001//ez lehet nem árt a számábrázolás miatt

            val feedback = if (isCorrect) {
                "Correct Solution"
            } else {
                getString(Res.string.WrongSolution,uiState.value.solution.formatToDecimals(2))
            }
            //println(feedback)

            entertext("")
            _uiState.update {
                it.copy(
                    correctAnswer = isCorrect,
                    numberofsolved = it.numberofsolved + 1,
                    showsolution = _uiState.value.solution.formatToDecimals(2)
                )
            }

            if(uiState.value.currentTask!=null){
                val tasktext = getString(uiState.value.currentTask!!.taskTextResIds[uiState.value.taskTextIndex], *uiState.value.taskNumbers.toTypedArray())

                LaTexLogWriteDesktop(isCorrect,input.formatToDecimals(),feedback,tag,tasktext, taskLaTexWrap = false)
            }
            generateNewTask()
        }
    }

fun getTaskTextArgs(taskTextResId: StringResource, numbers: List<Int>): List<Any> {
    return when (taskTextResId) {//todo manuálisan ellenőrizni, hogy jól vannak-e párban
        // Task Type A
        Res.string.Percentage_A_1 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_A_2 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_A_3 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_A_4 -> listOf(GenitiveCase(numbers[0]), numbers[1])

        // Task Type B
        Res.string.Percentage_B_1 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_B_2 -> listOf(GenitiveCase(numbers[0]), numbers[1])
        Res.string.Percentage_B_3 -> listOf(numbers[0], numbers[1])

        // Task Type C
        Res.string.Percentage_C_1 -> listOf(numbers[0], numbers[1])

        // Task Type D
        Res.string.Percentage_D_1 -> listOf(numbers[0], numbers[1], numbers[2])

        // Task Type E
        Res.string.Percentage_E_1 -> listOf(numbers[0], numbers[1])

        // Task Type F
        Res.string.Percentage_F_1 -> listOf(numbers[0], numbers[1])

        // Task Type G
        Res.string.Percentage_G_1 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_G_2 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_G_3 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_G_4 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_G_5 -> listOf(numbers[0], numbers[1])

        // Task Type H
        Res.string.Percentage_H_1 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_H_2 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_H_3 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_H_4 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_H_5 -> listOf(numbers[0], numbers[1])

        // Task Type I
        Res.string.Percentage_I_1 -> listOf(numbers[1], numbers[0])
        Res.string.Percentage_I_2 -> listOf(numbers[1], numbers[0])
        Res.string.Percentage_I_3 -> listOf(numbers[1], numbers[0])

        // Task Type J
        Res.string.Percentage_J_1 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_J_2 -> listOf(numbers[0], numbers[1])
        Res.string.Percentage_J_3 -> listOf(numbers[0], numbers[1])

        else -> emptyList()

    }
}