package matekit.matekit.taskgenerators

import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.Checkbox
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.SegmentedButton
import androidx.compose.material3.SegmentedButtonDefaults
import androidx.compose.material3.SingleChoiceSegmentedButtonRow
import androidx.compose.material3.Slider
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch
import matekit.matekit.ui.SegmentedButtonsDefaults
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.ui.LatexLogButton
import matekit.ui.alpha1
import matekit.ui.alpha2
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.generatorSettings
import matekit.ui.responseToast
import matekit.ui.settingListItem
import matekit.ui.theme.matekitcolor
import matekit.ui.uifont
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.NumberSystemTaskLog
import matekit_multiplatform.composeapp.generated.resources.NumberSystemsTask
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import matekit_multiplatform.composeapp.generated.resources.baseNumber
import matekit_multiplatform.composeapp.generated.resources.conversionFromDecimal
import matekit_multiplatform.composeapp.generated.resources.conversionToDecimal
import matekit_multiplatform.composeapp.generated.resources.max
import matekit_multiplatform.composeapp.generated.resources.min
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
//import java.lang.Exception

class ConvertNumberBase (
 val   navController: NavHostController,
    val TAG: String = "Szamrendszer"
)
{

        @OptIn(ExperimentalMaterial3Api::class)
        @Composable
        fun UI(){
            val coroutineScope = rememberCoroutineScope()

            var number = rememberSaveable { mutableStateOf(0) }
            var baseFrom = rememberSaveable { mutableStateOf(10) }
            var baseTo = rememberSaveable { mutableStateOf(2) }
            var userinput = rememberSaveable { mutableStateOf("") }
            var launched = rememberSaveable { mutableStateOf(false) }
            var min = rememberSaveable { mutableStateOf(1) }
            var max = rememberSaveable { mutableStateOf(20) }
            var displayNumber = rememberSaveable { mutableStateOf("") }//ez a fenti átváltandó szám
            var whiteboard = rememberSaveable { mutableStateOf(false) }
            var numberofsolved= rememberSaveable { mutableStateOf(0) }
            var isCorrect = rememberSaveable { mutableStateOf(false) }
            var feedback = rememberSaveable { mutableStateOf("") }
            //  var baseFilter = rememberSaveable{ mutableStateOf(mutableListOf(true,false,false,false,false,false,false,false,false,false,false,false,false,false,false))}///////rememberSaveable { mutableStateListOf<Boolean>(true,false,false,false,false,false,false,false,false,false,false,false,false,false,false)}
            //vajon akkor is működne, ha egyszerűen mutableListOf lenne? bár ez garantálja, hogy elforgatás esetén is változatlan marad a lista értéke
            // var baseFilter = mutableListOf<Boolean>(true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false)//rememberSaveable { mutableStateListOf<Boolean>(true,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false)}
            var baseFilter = remember {
                mutableStateListOf<Boolean>(
                    true,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                )
            }
            var to10base = rememberSaveable { mutableStateOf(true) }
                Surface(
                    modifier = Modifier.fillMaxSize(),
                    color = MaterialTheme.colorScheme.background
                ) {
                    responseToast(isCorrect.value,numberofsolved.value, useCompleteFeedbackMessage = true, completeResponse = feedback.value)
                    backButton(navController)
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.SpaceBetween
                    ) {
                        Column(horizontalAlignment = Alignment.CenterHorizontally) {


                            OutlinedTextField(
                                value = displayNumber.value,
                                onValueChange = {},
                                label = { Text(stringResource(Res.string.baseNumber) + ": " + baseFrom.value.toString()) },
                                enabled = false
                            )
                            borderedbasicfontsizeTextView(tasktext = stringResource(Res.string.NumberSystemsTask))
                            Row(verticalAlignment = Alignment.CenterVertically) {
                                Button(
                                    onClick = {
                                        if (userinput.value != "") coroutineScope.launch {

                                            evaluateResponse(
                                                solution =86.toString(),/* Integer.toString(
                                                    number.value,
                                                    baseTo.value//ez itt a radix
                                                ).uppercase()*/ //todo számrendszerek közötti váltás kotlinban
                                                userInput = userinput.value,
                                                displayedNumber = displayNumber.value,
                                                baseFrom = baseFrom.value,
                                                baseTo = baseTo.value,
                                                numberofsolved,
                                                isCorrect,
                                                feedback
                                            )
                                            userinput.value = ""
                                            generate(
                                                min,
                                                max,
                                                baseFrom,
                                                number,
                                                displayNumber,
                                                baseFilter,
                                                to10base,
                                                baseTo,
                                            )
                                        }
                                    }
                                ){
                                    Text(stringResource(Res.string.Check))
                                }

                                Spacer(Modifier.width(8.dp))
                               // drawButton(drawboard = whiteboard) //todo rajzolós gomb
                                OutlinedTextField(value = userinput.value,
                                    onValueChange = {
                                        userinput.value = it
                                    },
                                    label = { Text(stringResource(Res.string.baseNumber) + ": " + baseTo.value.toString()) },
                                    enabled = true,
                                    keyboardOptions = KeyboardOptions(
                                        keyboardType = KeyboardType.Ascii,
                                        imeAction = ImeAction.Done,
                                        capitalization = KeyboardCapitalization.Characters
                                    ),
                                    keyboardActions = KeyboardActions(
                                        onDone = {
                                            if (userinput.value != "") coroutineScope.launch{

                                                    evaluateResponse(
                                                        solution =86.toString(), /*Integer.toString(
                                                            number.value,
                                                            baseTo.value//ez itt a radix
                                                        ).uppercase(),*/
                                                        userInput = userinput.value,
                                                        displayedNumber = displayNumber.value,
                                                        baseFrom = baseFrom.value,
                                                        baseTo = baseTo.value,
                                                        numberofsolved,
                                                        isCorrect,
                                                        feedback
                                                    )
                                                    userinput.value = ""
                                                generate(
                                                        min,
                                                        max,
                                                        baseFrom,
                                                        number,
                                                        displayNumber,
                                                        baseFilter,
                                                        to10base,
                                                        baseTo,
                                                    )
                                            }


                                        }
                                    )

                                )
                                LatexLogButton(navController = navController, value=TAG)
                            }

                            AnimatedVisibility(visible = !whiteboard.value) {
                                generatorSettings {
                                    // todo ide azok a listák, amik a lehetséges min és max számok intervallumát, a kezdő és végső számrendszer alapszámát tartalmazzák,
                                    //lehetőleg egy-egy listában
                                    //lehet, hogy csak egy gomb kellene, hogy tizesből valamelyikbe, vagy valamelyikből tízesbe. Melinda szerint nem érdemes általános iskolásnak olyat adni, hogy nem tízesből nem tízesbe kelljen átváltania
                                    //ebből a scopeből közvetlenül kezelhető a min és max változó, azokat elére az új szám generáló fv
                                    //kell még egy változó arra, hogy melyikből melyikbe váltunk
                                    //kell egy lista a választható alapszámoknak is
                                    //igazából mindig lesz egy tízes számrendszer beli számunk, meg egy nem tízes. egy bool dönti el, hogy melyiket hol jeleníti meg a program, és mit várunk a diáktól. ez kb egy darab if elágazás.

                                    settingListItem(content = {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text(text = stringResource(Res.string.min), fontSize = uifont)
                                            OutlinedTextField(
                                                value = min.value.toString(),
                                                onValueChange = {
                                                    if (it.toString() != "0") {
                                                        try {
                                                            var new = it.toInt()
                                                            if (new in 1..4000) {
                                                                min.value = new
                                                            }
                                                        } catch (e: Exception) {
                                                        }
                                                    }
                                                },
                                                modifier = Modifier.widthIn(80.dp, 100.dp),

                                                )
                                            Slider(
                                                value = min.value.toFloat(), onValueChange = {
                                                    var new = it.toInt()
                                                    min.value = new
                                                },
                                                valueRange = 1f..4000f

                                            )
                                        }


                                    }, alpha = alpha1)
                                    settingListItem(content = {
                                        Row(verticalAlignment = Alignment.CenterVertically) {
                                            Text(text = stringResource(Res.string.max), fontSize = uifont)
                                            OutlinedTextField(
                                                value = max.value.toString(),
                                                onValueChange = {
                                                    if (it.toString() != "0") {
                                                        try {
                                                            var new = it.toInt()
                                                            if (new in 1..4000) {
                                                                max.value = new
                                                            }
                                                        } catch (e: Exception) {
                                                        }
                                                    }
                                                },
                                                modifier = Modifier.widthIn(80.dp, 100.dp),

                                                )
                                            Slider(
                                                value = max.value.toFloat(),
                                                onValueChange = {
                                                    var new = it.toInt()
                                                    max.value = new
                                                },
                                                valueRange = 1f..4000f,
                                                // a steps azt adná meg, hogy hány osztópontot veszünk fel az intervallumon belül
                                            )
                                        }


                                    }, alpha = alpha2)
                                    settingListItem(content = {
                                        val scrollState = rememberScrollState()
                                        Row(modifier = Modifier.horizontalScroll(scrollState)) {
                                            for (i in 2..9) {
                                                if (i != 10) BoolTxT(
                                                    txt = "$i",
                                                    bool = baseFilter[i - 2],
                                                    onchange = { baseFilter[i - 2] = it },
                                                    textsize = uifont)
                                            }//levonunk kettőt, mert a kettes szám az első, azaz nullával indexelt elem
                                            //ezzel ki is vettük a tízes számrendszert
                                            for (i in 10..16) {
                                                if (i != 10) BoolTxT(
                                                    txt = "$i",
                                                    bool = baseFilter[i - 3],
                                                    onchange = { baseFilter[i - 3] = it },
                                                    textsize = uifont)
                                            }


                                        }


                                    }, alpha = alpha1)
                                    settingListItem(
                                        content = {

                                            /*  Switch(
                                                  checked = to10base.value,
                                                  onCheckedChange = {//todo lecserélni segmented gombra, ha majd megjelenik jetpack compose-ra is
                                                      to10base.value = it
                                                  },
                                                  modifier = Modifier.weight(0.2f)
                                              )*/

                                            var mysegmentedbuttoncolors =
                                                SegmentedButtonsDefaults.colors().copy(

                                                    outlineColor = matekitcolor,
                                                    indicatorColor = MaterialTheme.colorScheme.onSurface.copy(
                                                        .3f
                                                    )

                                                )
                                            /* SegmentedButtons {
                                                 SegmentedButtonItem(
                                                     selected = !to10base.value,
                                                     onClick = { to10base.value = false },
                                                     label = {
                                                         Text(
                                                             getString(Res.string.conversionFromDecimal),
                                                             modifier = Modifier.weight(0.4f)
                                                         )
                                                     },
                                                     colors = mysegmentedbuttoncolors
                                                 )
                                                 SegmentedButtonItem(
                                                     selected = to10base.value,
                                                     onClick = { to10base.value = true }, label = {
                                                         Text(
                                                             getString(Res.string.conversionToDecimal),
                                                             modifier = Modifier.weight(0.4f)
                                                         )
                                                     },
                                                     colors = mysegmentedbuttoncolors
                                                 )
                                             }*/

                                            SingleChoiceSegmentedButtonRow {
                                                SegmentedButton(
                                                    selected = !to10base.value,
                                                    onClick =  { to10base.value = false },
                                                    shape = SegmentedButtonDefaults.itemShape(
                                                        index = 0,
                                                        count = 2
                                                    )
                                                ) {
                                                    Text(
                                                        stringResource(Res.string.conversionFromDecimal),
                                                        modifier = Modifier.weight(0.4f)
                                                    )
                                                }

                                                SegmentedButton(
                                                    selected = to10base.value,
                                                    onClick =  { to10base.value = true },
                                                    shape = SegmentedButtonDefaults.itemShape(
                                                        index = 1,
                                                        count = 2
                                                    )
                                                ) {
                                                    Text(
                                                        stringResource(Res.string.conversionToDecimal),
                                                        modifier = Modifier.weight(0.4f)
                                                    )
                                                }
                                            }


                                        }, alpha2
                                    ) // tízes számrendszerből váltani, tízes számrendszerbe váltani

                                }
                            }
                           /* AnimatedVisibility(visible = whiteboard.value) { //todo rajztábla
                                matekit.matekit.whiteboard(
                                    activity = this@ConvertNumberBase,
                                    context = context,
                                    darkmode = darkmode
                                )
                            }*/

                            Row() {
// todo ide a naplózás, és a rajztábla gombja
                            }

                        }
                    }


                }
                LaunchedEffect(key1 = Unit, block = {
                    if (!launched.value) {
                        generate(
                            min,
                            max,
                            baseFrom,
                            number,
                            displayNumber,
                            baseFilter,
                            to10base,
                            baseTo,
                        )
                        /* val taskdata: Pair<Int, String>
                         taskdata = generatenumberInGivenBase(min.value, max.value, baseFrom.value)
                         number.value = taskdata.first
                         displayNumber.value = taskdata.second*/
                        launched.value = true
                    }
                })

        }


    fun generate(
        min: MutableState<Int>,
        max: MutableState<Int>,
        baseFrom: MutableState<Int>,
        number: MutableState<Int>,
        displayNumber: MutableState<String>,
        basefilter: SnapshotStateList<Boolean>,
        to10base: MutableState<Boolean>,
        baseTo: MutableState<Int>
    ) {
        var numberBases = (2..9) + (11..16).toList()
        //var próbalista = listOf<Boolean>(true,true)
        var radix = 0
        var alsokorlat = 0

        if (to10base.value) {//ha tízes számrendszerbe váltunk, akkor a kezdő számrendszert kell kiválasztani úgy, hogy ne legyen tízes
            baseFrom.value = numberBases.filterIndexed { index, i -> basefilter[index] }.random()
            baseTo.value = 10
            radix = baseFrom.value
            alsokorlat = radix


        } else {//különben a tízes számrendszerből váltunk át, ilyenkor az érkezési számrendszert kell kiválasztani. nem lehet tízes itt sem
            baseFrom.value = 10
            baseTo.value = numberBases.filterIndexed { index, i -> basefilter[index] }.random()
            radix = baseTo.value
            alsokorlat = radix
        }


        val taskdata: Pair<Int, String>

        taskdata = generatenumberInGivenBase(maxOf(min.value, alsokorlat), max.value, radix)
        number.value = taskdata.first
        displayNumber.value =
            if (to10base.value) taskdata.second else taskdata.first.toString()//ha tízes számrendszerből váltunk, akkor ez a szám, amit kiválasztottunk az intervallumból, különben meg az átváltott

    }

    fun generatenumberInGivenBase(min: Int, max: Int, base: Int): Pair<Int, String> {
        var num = try {
            (min..max).random()
        } catch (e: Exception) {
            86
        }
        var numstring =86.toString() //Integer.toString(num, base).uppercase()
        return Pair(num, numstring)
    }

    suspend fun evaluateResponse(
        solution: String,
        userInput: String,
        displayedNumber: String,
        baseFrom: Int,
        baseTo: Int,
        numberofsolved: MutableState<Int>,
        isCorrect: MutableState<Boolean>,
        feedback: MutableState<String>
    ) { //TODO írni egy általánosított ellenőrző függvényt, hogy ne kelljen minden alkalommal deklarálni a toasthoz tartozó változókat
        var showSolutionIfUserWrong = ""
        var helyesMO: Boolean
        if (userInput == solution) {
            helyesMO = true
        } else {
            helyesMO = false
            showSolutionIfUserWrong = getString(
                Res.string.WrongSolution,
                solution.toString()
            )
        }
        var text = if (helyesMO) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong
        feedback.value= text
        isCorrect.value=helyesMO
        numberofsolved.value++
      //  var color = if (helyesMO) Res.style.CorrectToast else Res.style.WrongToast
     //   ShowToast.showToast(color, text, context, true)
       LaTexLogWriteDesktop(
            helyesMO,
            userInput.toString(),
            showSolutionIfUserWrong,
            TAG,
            getString(Res.string.NumberSystemTaskLog, displayedNumber, baseFrom, baseTo),
           taskLaTexWrap = false
           /// userDocRef, modulID =17
        )

//todo új feladat kérése

    }
    @OptIn(ExperimentalMaterial3Api::class)
    @Composable
    fun BoolTxT(txt: String, bool: Boolean, textsize: TextUnit, onchange: (Boolean) -> Unit) {
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(text = txt, fontSize = textsize)
            Checkbox(checked = bool, onCheckedChange = {
                onchange(it)
            })
        }
    }


}