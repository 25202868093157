package matekit.matekit.taskgenerators

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavHostController
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch
import matekit.matekit.utilityfunctions.InstrumentalCase
import matekit.matekit.utilityfunctions.LaTexLogWriteDesktop
import matekit.matekit.utilityfunctions.accusativeCase
import matekit.matekit.utilityfunctions.kisGauss
import matekit.matekit.utilityfunctions.szor_szer
import matekit.matekit.utilityfunctions.szorosa
import matekit.ui.LatexLogButton
import matekit.ui.backButton
import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.responseToast
import matekit.ui.universalCheckField
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.CorrectSolution
import matekit_multiplatform.composeapp.generated.resources.Res
import matekit_multiplatform.composeapp.generated.resources.WrongSolution
import org.jetbrains.compose.resources.getString
import org.jetbrains.compose.resources.stringResource
import kotlin.math.absoluteValue

@Composable
fun SzovegesUI(
    navController:NavHostController,
    tag:String ="SzovegesFeladat"
) {

    val vm = viewModel { SzovegesViewModel(tag) }
    val uiState by vm.uiState.collectAsState()
    val coroutineScope = rememberCoroutineScope()

    Surface(
        modifier = Modifier.fillMaxSize(), color = MaterialTheme.colorScheme.background
    ) {
        LaunchedEffect(Unit) {
            vm.generateNewTask()
        }
        //fun SzovegesUI(){
        Column(modifier = Modifier.fillMaxSize()) {
            borderedbasicfontsizeTextView(tasktext = uiState.feladat)

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Center
            ) {



                universalCheckField(
                    uiState.userInput,
                    enterText = { vm.entertext(it) },
                    check = {
                        coroutineScope.launch {
                        vm.check() }
                    }
                )
                Spacer(Modifier.width(8.dp))
                Button(
                    onClick = {
                        coroutineScope.launch {
                        vm.check()}
                    },
                ) {
                    Text(stringResource( Res.string.Check))
                }
                LatexLogButton(navController = navController, value = tag)

            }
        }
        responseToast(uiState.isCorrect,uiState.numberofsolved,uiState.showsolution)
        backButton(navController)}
}

data class SzovegesUIState(
    val feladat: String = "",
    val solution: String = "",
    val showsolution: String = "",
    val numberofsolved: Int = 0,
    val isCorrect: Boolean = false,
    val userInput: String = ""
)


class SzovegesViewModel(tag:String) : ViewModel() {
val tag = tag
    private val _uiState = MutableStateFlow(SzovegesUIState())
    val uiState: StateFlow<SzovegesUIState> = _uiState.asStateFlow()


    val feladatlista = listOf<() -> Szovegesfeladat>(

        {//A típus ellenőrizve

            val n = (2..5).random()

            val x = (20..240).random()


            val szöveg =

                "$n szomszédos szám összege ${n * x + kisGauss(n - 1)}. Melyik a legkisebb szám?"//ellenőrizve



            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = x.toString()

            )

        },


        {//B típus


            //ellenőrizve

            val x = (18..240 step 6).toList()

                .random() //nők száma, körték száma, kecskék száma, bankjegyek száma

            val n = (1..5).random()


            val optionlist = listOf<Pair<String, Int>>(

                Pair("számának harmada", x / 3),

                Pair("számának fele", x / 2),

                Pair("számának kétszerese", x * 2),

                Pair("számának háromszorosa", x * 3),

                Pair("számánál ${InstrumentalCase(n)} több", x + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                Pair("számának harmadánál ${InstrumentalCase(n)} több", x / 3 + n),

                Pair("számának harmadánál ${InstrumentalCase(n)} kevesebb", x / 3 - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", x * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", x * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", x * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", x * 3 - n)

            )


            val option = optionlist.random()


            val szövegváltozatok = listOf(

                "Egy nagy teremben férfiak és nők vannak. A férfiak száma a nők ${option.first}. A teremben összesen ${x + option.second} fő tartózkodik. Hány nő van közöttük?",

                "A kamrában két féle gyümölcsöt tárolunk, almát és körtét. Az almák száma a körték ${option.first}. A kamrában összesen ${x + option.second} gyümölcs van. Ezek közül hány darab körte?",//todo mindegyik legyen meg fordítva is

                "Egy legelőn birkák és kecskék legelnek. A birkák száma a kecskék ${option.first}. A legelőn összesen ${x + option.second} állat legel. Ezek közül hány darab kecske?",

                "Egy kincsesládában érmék és bankjegyek vannak. Az érmék száma a bankjegyek ${option.first}. A ládában együttvéve ${x + option.second} darab érme és bankjegy van. Ezek közül hány darab bankjegy?",

                "Egy akváriumban halak és teknősök élnek. A halak száma a teknősök ${option.first}. Az akváriumban összesen ${x + option.second} állat él. Hány teknős van az akváriumban?",

                "Egy stúdióban festményeket és szobrokat állítanak ki. A festmények száma a szobrok ${option.first}. A stúdióban összesen ${x + option.second} műalkotás van. Hány szobor van kiállítva?",

                "Egy raktárban dobozokat és zsákokat tárolnak. A dobozok száma a zsákok ${option.first}. A raktárban összesen ${x + option.second} tárolóegység van. Hány zsák van a raktárban?",

                "Egy parkban tölgyfák és fenyőfák vannak. A tölgyfák száma a fenyőfák ${option.first}. A parkban összesen ${x + option.second} fa van. Hány fenyőfa található a parkban?",

                "Egy könyvtárban regények és verseskötetek találhatók. A regények száma a verseskötetek ${option.first}. A könyvtárban összesen ${x + option.second} könyv van. Hány verseskötet található a könyvtárban?",


                )


            val szövegváltozat = szövegváltozatok.random()

            return@listOf Szovegesfeladat(

                taskText = szövegváltozat,

                solution = x.toString()

            )


        },

        {//C, //ellenőrizve egyszer

            val x = (18..240 step 6).toList().random()//húst rendelők/angolok/disznók

            val n = (1..5).random()


            val optionlist = listOf<Pair<String, Int>>(

                Pair("számának harmada", x / 3),

                Pair("számának fele", x / 2),

                Pair("számának kétszerese", x * 2),

                Pair("számának háromszorosa", x * 3),

                Pair("számánál ${InstrumentalCase(n)} több", x + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                Pair("számának harmadánál ${InstrumentalCase(n)} több", x / 3 + n),

                Pair("számának harmadánál ${InstrumentalCase(n)} kevesebb", x / 3 - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", x * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", x * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", x * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", x * 3 - n),

                Pair("számának felénél ${InstrumentalCase(n)} több", x / 2 + n),

                Pair("számának felénél ${InstrumentalCase(n)} kevesebb", x / 2 - n)

            )

            val option = optionlist.random()


            val húséssaláta = x + option.second //ez egy nagyon következetes változó név

            val tészta = húséssaláta

            val szövegváltozatok = listOf(

                "Egy menzán három féle menüt lehet rendelni, húst, salátát vagy tésztát. A salátát rendelők száma a húst rendelők ${option.first}. Tésztát annyian kérnek, mint húst és salátát összesen. A menzán összesen ${húséssaláta + tészta} menüt rendeltek. Hányan akartak közülük húst enni?",

                "Egy repülőn csak angol, német és francia utasok utaznak. A németek száma az angolok ${option.first}. Francia utas annyi ül a repülőgépen, mint ahány angol és német összesen. A repülőgép utasainak száma ${húséssaláta + tészta} fő. Hány angol utas ül a repülőgépen?",

                "Egy farmon disznókat, marhákat és lovakat tartanak. A marhák száma a disznók ${option.first}. Annyi ló van a farmon, mint ahány disznó és marha összesen. A farmon összesen ${húséssaláta + tészta} állatot tartanak. Ezek közül hány darab disznó?",

                "A nagymamám szakácskönyvet, befőttes üvegeket és asztalterítőket gyűjt. A befőttes üvegek száma a szakácskönyvek ${option.first}. Annyi asztalterítője van a nagymamámnak, mint ahány szakácskönyve és befőttes üvege összesen. A nagymamámnak mindebből összesen ${húséssaláta + tészta} darab van. Ezek közül hány darab szakácskönyv?",

                "Egy mai szegény legény világgá indul. Hol tömegközlekedéssel, hol autóstoppal, hol gyalog megy. Az autóstoppal megtett kilométerek száma a tömegközlekedéssel megtettek ${option.first}. Gyalog pontosan annyit ment, mint gépjárművel. Ha összesen ${húséssaláta + tészta} kilométert tett, akkor ebből mennyit ment tömegközlekedéssel?",

                "Egy turista havi túrái során kék vagy piros jelzésű turistaúton illetve egyéb utakon járt. A piros jelzésen megtett kilométerek száma a kéken megtettek ${option.first}. Egyéb utakon annyit ment, mint a piros és kék jelzésen együtt. Összesen ${húséssaláta + tészta} kilométert tett meg. Hány kilométert tett meg a kék jelzésen?",

                "Egy lakodalomba zserbót, minyont és málnás sütiszeleteket rendelnek. A minyonok száma a zserbók ${option.first}. Málnás sütiből annyit kérnek, mint minyonból és zserbóból együtt. A három fajtából összesen ${húséssaláta + tészta} darabot rendeltek. Hány zserbót rendeltek?",

                "Egy polcon egy piros, egy barna és egy fekete bőrkötésű könyv található. A barna kötésű oldalainak száma a piros kötésű oldalainak ${option.first}. A fekete kötésűnek annyi oldala van, mint a pirosnak és a barnának együtt. A három könyvnek összesen ${húséssaláta + tészta} oldala van. Hány oldalas a piros könyv?"

                //Dagobert bácsis feladat nem sért szerzői jogokat? //todo


            )


            val szöveg = szövegváltozatok.random()



            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = x.toString()

            )

        },


        {//D típus //ellenőrizve

            val x = (18..240 step 6).toList().random()

            val n = (1..5).random()//TODO kell n1 és n2?


            val optionlist1 = listOf<Pair<String, Int>>(

                Pair("számának harmada", x / 3),

                Pair("számának fele", x / 2),

                Pair("számának kétszerese", x * 2),

                Pair("számának háromszorosa", x * 3),

                Pair("számánál ${InstrumentalCase(n)} több", x + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                Pair("számának harmadánál ${InstrumentalCase(n)} több", x / 3 + n),

                Pair("számának harmadánál ${InstrumentalCase(n)} kevesebb", x / 3 - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", x * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", x * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", x * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", x * 3 - n),

                Pair("számának felénél ${InstrumentalCase(n)} több", x / 2 + n),

                Pair("számának felénél ${InstrumentalCase(n)} kevesebb", x / 2 - n)

            )


            val option1 = optionlist1.random()

            val hús = x

            val saláta = option1.second


            val optionlist2 = listOf<Pair<String, Int>>(

                Pair("számánál ${InstrumentalCase(n)} több", saláta + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", saláta - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", saláta * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", saláta * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", saláta * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", saláta * 3 - n),

                )

            val option2 = optionlist2.random()

            val tészta = option2.second

            val szöveglist = listOf(

                "Egy menzán három féle menüt lehet rendelni, húst, salátát vagy tésztát. A salátát rendelők száma a húst rendelők ${option1.first}. A tésztát rendelők száma a salátát kérők ${option2.first}. A menzán összesen ${hús + saláta + tészta} menüt rendeltek. Hányan akartak közülük húst enni?",

                "Egy repülőn csak amerikai, német és francia utasok utaznak. A németek száma az amerikaiak ${option1.first}. A francia utasok száma a németek ${option2.first}. A repülőgép utasainak száma ${hús + saláta + tészta} fő. Hány amerikai utas ül a repülőgépen?",

                "Egy farmon disznókat, marhákat és birkákat tartanak. A marhák száma a disznók ${option1.first}. A birkák száma a marhák ${option2.first}. A farmon összesen ${hús + saláta + tészta} állatot tartanak. Ezek közül hány darab disznó?",

                "A nagymamám pletykalapokat, befőttes üvegeket és asztalterítőket gyűjt. A befőttes üvegek száma a pletykalapok ${option1.first}. Az asztalterítők száma a befőttes üvegek ${option2.first}. A nagymamámnak mindebből összesen ${hús + saláta + tészta} darab van. Hány pletykalapot tart a nagymamám?",

                "Egy mai szegény legény világgá indul. Hol busszal, hol autóstoppal, hol gyalog megy. Az autóstoppal megtett kilométerek száma a busszal megtettek ${option1.first}. A gyalog megtett kilométerek száma az autóstoppal megtettek ${option2.first}. Ha összesen ${hús + saláta + tészta} kilométert tett meg, akkor ebből mennyit ment busszal?",

                "Egy turista havi túrái során kék, piros vagy zöld jelzésű turistautakon járt. A piros jelzésen megtett kilométerek száma a kéken megtettek ${option1.first}. A zöld jelzésen megtett kilométerek száma a sárgán megtettek ${option2.first}. Összesen ${hús + saláta + tészta} kilométert tett meg. Hány kilométert tett meg a kék jelzésen?",

                "Egy rendezvényre sajtos, krumplis és tepertős pogácsát rendelnek. A krumplis pogácsák száma a sajtosak ${option1.first}. A tepertősek száma pedig a krumplisak ${option2.first}. A három fajtából összesen ${hús + saláta + tészta} darabot rendeltek. Hány sajtos pogácsát rendeltek?",

                "Egy antikváriusnál piros, barna és fekete bőrkötésű könyvek találhatók. A barna kötésűek száma a pirosak ${option1.first}. A fekete kötésűek száma pedig a barnák ${option2.first}. Az antikváriusnál összesen ${hús + saláta + tészta} ilyen könyv van. Hány piros kötésű könyvet tart?"
            )


            val szöveg = szöveglist.random()

            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = hús.toString()

            )


        },

        {//E típus // ellenőrizve

            val x = (18..240 step 6).toList().random()

            val n = (1..5).random()//TODO kell n1 és n2?


            val optionlist1 = listOf<Pair<String, Int>>(

                Pair("számának harmada", x / 3),

                Pair("számának fele", x / 2),

                Pair("számának kétszerese", x * 2),

                Pair("számának háromszorosa", x * 3),

                Pair("számánál ${InstrumentalCase(n)} több", x + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                Pair("számának harmadánál ${InstrumentalCase(n)} több", x / 3 + n),

                Pair("számának harmadánál ${InstrumentalCase(n)} kevesebb", x / 3 - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", x * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", x * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", x * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", x * 3 - n),

                Pair("számának felénél ${InstrumentalCase(n)} több", x / 2 + n),

                Pair("számának felénél ${InstrumentalCase(n)} kevesebb", x / 2 - n)

            )


            val option1 = optionlist1.random()

            val hús = x

            val saláta = option1.second


            val optionlist2 = listOf<Pair<String, Int>>(

                Pair("számánál ${InstrumentalCase(n)} több", saláta + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", saláta - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", saláta * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", saláta * 2 - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", saláta * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", saláta * 3 - n),

                )

            val option2 = optionlist2.random()

            val tészta = option2.second


            val relation = if (tészta > hús) {

                "több"

            } else {

                "kevesebb"

            }


            val szöveglist = listOf(

                "Egy menzán három féle menüt lehet rendelni, húst, salátát vagy tésztát. A salátát rendelők száma a húst rendelők ${option1.first}. A tésztát rendelők száma a salátát kérők ${option2.first}. A menzán összesen ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } ${relation}en kértek tésztát, mint húst. Hányan rendeltek húst?",


                "Egy repülőn csak amerikai, német és francia utasok utaznak. A németek száma az amerikaiak ${option1.first}. A francia utasok száma a németek ${option2.first}. A repülőn összesen ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation francia utas van, mint amerikai. Hány amerikai utas ül a repülőgépen?",

                "Egy farmon disznókat, marhákat és birkákat tartanak. A marhák száma a disznók ${option1.first}. A birkák száma a marhák ${option2.first}. A farmon összesen összesen ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation birka él, mint ahány disznó. Hány disznót tartanak a farmon?",

                "A nagymamám pletykalapokat, befőttes üvegeket és asztalterítőket gyűjt. A befőttes üvegek száma a pletykalapok ${option1.first}. Az asztalterítők száma a befőttes üvegek ${option2.first}. Asztalterítőből összesen ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation van, mint pletykalapból. Hány pletykalapot tart a nagymamám?",

                "Egy mai szegény legény világgá indul. Hol busszal, hol autóstoppal, hol gyalog megy. Az autóstoppal megtett kilométerek száma a busszal megtettek ${option1.first}. A gyalog megtett kilométerek száma az autóstoppal megtettek ${option2.first}. Ha gyalog összesen ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation kilométert tett meg, mint busszal, akkor hány kilométert tett meg busszal?",

                "Egy turista havi túrái során kék, piros vagy zöld jelzésű turistautakon járt. A piros jelzésen megtett kilométerek száma a kéken megtettek ${option1.first}. A zöld jelzésen megtett kilométerek száma a sárgán megtettek ${option2.first}. Ha a zöldön ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation kilométert tett meg, mint a kéken, akkor hány kilométert tett meg a kék jelzésen?",

                "Egy rendezvényre sajtos, krumplis és tepertős pogácsát rendelnek. A krumplis pogácsák száma a sajtosak ${option1.first}. A tepertősek száma pedig a krumplisak ${option2.first}. Ha tepertősből ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation van, mint sajtosból, akkor hány sajtos pogácsát rendeltek?",

                "Egy raktárban papír, bőr és vászonkötésű könyvek találhatók. A bőrkötésűek száma a papíkötésűek ${option1.first}. A vászonkötésűek száma pedig a bőrkötésűek ${option2.first}. Ha vászonkötésűből ${
                    InstrumentalCase(
                        (tészta - hús).absoluteValue
                    )
                } $relation van, mint papírkötésűből, akkor hány papírkötésű könyv van raktárban?"


            )


            val szöveg = szöveglist.random()

            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = hús.toString()

            )


        },

        {//F típus //ellenőrizve

            var x = (20..120).random()

            var n = (3..15).random()

            var k = (2..6).random()

            var m = (3..15).random()

            var q = (2..6).random()

            var resultofoperations = (((x + n) * k) + m) / q


            fun getnewnumbers() {

                x = (20..120).random()

                n = (3..15).random()

                k = (2..6).random()

                m = (3..15).random()

                q = (2..6).random()



                resultofoperations = (((x + n) * k) + m) / q

            }



            while ((((x + n) * k) + m) % q != 0) {//amíg az osztási maradék nem nulla, addig válasszunk másik számokat

                getnewnumbers()//ez nem egy túl elegáns megoldás

            }


            val szöveg =

                "Gondoltam egy számra. Hozzáadtam ${n.accusativeCase()}. Az így kapott eredményt megszoroztam ${

                    InstrumentalCase(k)

                }. A kapott eredményhez hozzáadtam ${m.accusativeCase()}, majd elosztottam ${

                    InstrumentalCase(

                        q

                    )

                } és a végeredmény $resultofoperations lett. Melyik számra gondoltam?"



            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = x.toString()

            )

        },

        {//F2 típus

            var x = (20..120).random()

            var n = (3..15).random()

            var k = (2..6).random()

            var m = (3..15).random()

            var q = (2..6).random()

            var resultofoperations = (((x + n) * k) + m) / q


            fun getnewnumbers() {

                x = (20..120).random()

                n = (3..15).random()

                k = (2..6).random()

                m = (3..15).random()

                q = (2..6).random()



                resultofoperations = (((x + n) / k) + m) * q

            }



            while (((x + n)) % k != 0) {//amíg az osztási maradék nem nulla, addig válasszunk másik számokat

                getnewnumbers()//ez nem egy túl elegáns megoldás

            }


            val szöveg =

                "Gondoltam egy számra. Hozzáadtam ${n.accusativeCase()}. Az így kapott eredményt elosztottam ${

                    InstrumentalCase(k)

                }. A kapott eredményhez hozzáadtam ${m.accusativeCase()}, majd megszoroztam ${

                    InstrumentalCase(

                        q

                    )

                } és a végeredmény $resultofoperations lett. Melyik számra gondoltam?"



            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = x.toString()

            )


        },
        /**/

        {//G0 típus (mert a nehezek közt már elneveztünk egyet korábban G-nek)

            val x = (18..240 step 6).toList().random()

            val n = (1..5).random()//TODO kell n1 és n2?


            var hús = x

            var saláta = 0

            var tészta = 0


            val optionlist1 = listOf<Pair<String, Int>>(

                Pair("számának harmada", x / 3),

                Pair("számának harmadánál ${InstrumentalCase(n)} több", x / 3 + n),

                Pair("számának harmadánál ${InstrumentalCase(n)} kevesebb", x / 3 - n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} több", x * 2 + n),

                Pair("számának kétszeresénél ${InstrumentalCase(n)} kevesebb", x * 2 - n),

                Pair("számának kétszerese", x * 2),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                )

            val option1 = optionlist1.random()

            saláta = option1.second

            val optionlist2 = listOf<Pair<String, Int>>(

                Pair("számának fele", x / 2),

                Pair("számának háromszorosa", x * 3),

                Pair("számánál ${InstrumentalCase(n)} több", x + n),

                Pair("számánál ${InstrumentalCase(n)} kevesebb", x - n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} több", x * 3 + n),

                Pair("számának háromszorosánál ${InstrumentalCase(n)} kevesebb", x * 3 - n),

                Pair("számának felénél ${InstrumentalCase(n)} több", x / 2 + n),

                Pair("számának felénél ${InstrumentalCase(n)} kevesebb", x / 2 - n)

            )

            val option2 = optionlist2.random()

            tészta = option2.second


            val szöveglist = listOf(

                "Egy menzán három féle menüt lehet rendelni, húst, salátát vagy tésztát. A salátát rendelők száma a húst rendelők ${option1.first}. A tésztát rendelők száma a húst kérők ${option2.first}. A menzán összesen ${hús + saláta + tészta} menüt rendeltek. Hányan akartak közülük tésztát enni?",

                "Egy repülőn csak amerikai, német és francia utasok utaznak. A németek száma az amerikaiak ${option1.first}. A francia utasok száma a németek ${option2.first}. A repülőgép utasainak száma ${hús + saláta + tészta} fő. Hány francia utas ül a repülőgépen?",

                "Egy farmon disznókat, marhákat és birkákat tartanak. A marhák száma a disznók ${option1.first}. A birkák száma a marhák ${option2.first}. A farmon összesen ${hús + saláta + tészta} állatot tartanak. Hány birka él a farmon?",

                "A nagymamám pletykalapokat, befőttes üvegeket és asztalterítőket gyűjt. A befőttes üvegek száma a pletykalapok ${option1.first}. Az asztalterítők száma a befőttes üvegek ${option2.first}. A nagymamámnak mindebből összesen ${hús + saláta + tészta} darab van. Hány asztalterítője van a nagymamámnak?",

                "Egy mai szegény legény világgá indul. Hol busszal, hol autóstoppal, hol gyalog megy. Az autóstoppal megtett kilométerek száma a busszal megtettek ${option1.first}. A gyalog megtett kilométerek száma az autóstoppal megtettek ${option2.first}. Ha összesen ${hús + saláta + tészta} kilométert tett meg, akkor ebből mennyit gyalogolt?",

                "Egy turista havi túrái során kék, piros vagy zöld jelzésű turistautakon járt. A piros jelzésen megtett kilométerek száma a kéken megtettek ${option1.first}. A zöld jelzésen megtett kilométerek száma a sárgán megtettek ${option2.first}. Összesen ${hús + saláta + tészta} kilométert tett meg. Hány kilométert tett meg a zöld jelzésen?",

                "Egy rendezvényre sajtos, krumplis és tepertős pogácsát rendelnek. A krumplis pogácsák száma a sajtosak ${option1.first}. A tepertősek száma pedig a krumplisak ${option2.first}. A három fajtából összesen ${hús + saláta + tészta} darabot rendeltek. Hány tepertős pogácsát rendeltek?",

                "Egy antikváriusnál piros, barna és fekete bőrkötésű könyvek találhatók. A barna kötésűek száma a pirosak ${option1.first}. A fekete kötésűek száma pedig a barnák ${option2.first}. Az antikváriusnál összesen ${hús + saláta + tészta} ilyen könyv van. Hány fekete kötésű könyvet tart?"

            )


            val szöveg = szöveglist.random()

            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = tészta.toString()

            )


        },

        {//G típus


            val r =
                (1..10).random()//ezt használjuk arra, hogy válasszunk a specifikus és általános feladattípusok között

            var x = 0

            var n = 0

            var k = 0

            var m = 0


            val specifikuslist = listOf(//konkrét számok

                Gdata(2, 6, 3, 3),

                Gdata(3, 6, 2, 4),

                Gdata(4, 6, 1, 5),

                Gdata(5, 6, 20, 2),

                Gdata(6, 6, 4, 4),

                Gdata(7, 6, 28, 2),

                Gdata(8, 6, 2, 5),

                Gdata(9, 6, 6, 4),

                Gdata(12, 6, 3, 5)

            )

            val specifictask = specifikuslist.random()//választunk belőlük egyet



            if (r in 1..4) {//a feladatok 40%-a kerüljön ki ezekből

                x = specifictask.x

                n = specifictask.n

                k = specifictask.k

                m = specifictask.m

            } else {

                var általánosindex = (1..4).random()//egyébként a négy másikból válasszunk



                x = (1..16).random()



                when (általánosindex) {

                    1 -> {

                        n = 3

                        k = x

                        m = 2

                    }

                    2 -> {

                        n = 4

                        k = 2 * x

                        m = 2

                    }

                    3 -> {

                        n = 5

                        k = x

                        m = 3

                    }

                    4 -> {

                        x = (1..10).random()

                        n = 7

                        k = x

                        m = 4

                    }

                }


            }


            val szöveg =
                "Bea ${n.szor_szer()} annyi idős, mint Ábel. $k év múlva, (természetesen, ha addig mindketten életben maradnak) viszont Bea már csak ${m.szor_szer()} annyi idős lesz, mint Ábel. Hány éves most Ábel?"

            return@listOf Szovegesfeladat(

                taskText = szöveg,

                solution = x.toString()

            )

        },

        {//todo ellenőrzés

            val x = (7..20).random()
            val alma = x*2
            val n =(3..8).random()
            val körte =n *x //körte az n

            val szöveg = "A körték száma ${(n/2).szor_szer() } annyi, mint az almáké. Ha kivennénk $x almát a kosárból, akkor a körték száma ${n.szor_szer()} annyi lenne. Hány alma van most a kosárban?"

            val solution = alma

            return@listOf Szovegesfeladat(
                taskText = szöveg,
                solution = solution.toString())
        },
        {
            val x = (7..20).random()
            val n = listOf(6,9,12).random()
            val alma = x*2
            val körte = n *x

            val szöveg ="A körték száma ${(n/2).szor_szer()} annyi, mint az almáké. Még $x almát a kosárba teszünk. Ekkor már a körték száma csak ${(n/3).szorosa()} az almákénak. Hány körte van a kosárban?"
            val solution = n*x
            return@listOf Szovegesfeladat(
                taskText = szöveg,
                solution = solution.toString())
        },
        {
            val x =(7..30).random()
            val alma =x
            val n = (2..5).random()
            val körte = n*x
            val k = (1..6).random()

            //m az a maximális egész szám, melyre m*(x+k)<nx
            //osszuk el mindkét oldalt (x+k)val. ekkor m< n*x / (x+k)*1.0
            //számoljuk ki, hogy mennyi ez a n*x / (x+k)*1.0, majd váltsuk át egész számmá (nem kerekítve, hanem levágva a tört részt)

            val mfrac: Double =n*x / (x+k)*1.0
            val m = mfrac.toInt()
            val l = n*x - m * (x+k)
            val szöveg = "A körték száma ${n.szor_szer()} annyi, mint az almáké. $k almát még a kosárba teszünk, miközben $l körtét kiveszünk belőle. Ekkor a körték száma már csak ${m.szorosa()} a ládában levő almák számának. Hány alma volt kezdetben a ládában?"
            val solution = alma
            return@listOf Szovegesfeladat(
                taskText = szöveg,
                solution = solution.toString())
        }

        )


    data class Gdata(
        val x: Int,
        val n: Int,
        val k: Int,
        val m: Int,
    )


    fun generateNewTask() {
        var newtask = feladatlista.random()()
        _uiState.update {
            it.copy(
                feladat = newtask.taskText,
                solution = newtask.solution
            )
        }
    }

    suspend fun check() {
        val isCorrect = _uiState.value.userInput == _uiState.value.solution

        val showSolutionIfUserWrong: String =  getString(Res.string.WrongSolution, _uiState.value.solution)

        _uiState.update {
            it.copy(
                isCorrect = isCorrect,
                numberofsolved = it.numberofsolved + 1,
                showsolution = _uiState.value.solution,
            )
        }
        var text = if (isCorrect) getString(Res.string.CorrectSolution) else showSolutionIfUserWrong

        LaTexLogWriteDesktop(
            isCorrect,
            _uiState.value.userInput.toString(),
            showSolutionIfUserWrong,
            tag,
            _uiState.value.feladat,
            taskLaTexWrap = false
        )


        entertext("")
        generateNewTask()
    }

    fun entertext(enteredText: String) {
        _uiState.update {
            it.copy(
                userInput = enteredText
            )
        }
    }

}

data class Szovegesfeladat(
    var taskText: String = "",
    var solution: String = "",
)