package matekit.matekit.masodfokufv


import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.IntrinsicSize
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material3.Button
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.SegmentedButton
import androidx.compose.material3.SegmentedButtonDefaults
import androidx.compose.material3.SingleChoiceSegmentedButtonRow
import androidx.compose.material3.Surface
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.lifecycle.viewmodel.compose.viewModel
import androidx.navigation.NavController
import androidx.navigation.NavHostController
import kotlinx.coroutines.launch

import matekit.matekit.checkmark
import matekit.matekit.taskgenerators.adjustSize

import matekit.matekit.utilityfunctions.formatToDecimals
import matekit.ui.LatexLogButton
import matekit.ui.backButton

import matekit.ui.borderedbasicfontsizeTextView
import matekit.ui.koordinátarendszer
import matekit.ui.theme.matekitsquash1
import matekit_multiplatform.composeapp.generated.resources.Check
import matekit_multiplatform.composeapp.generated.resources.FunctionTask
import matekit_multiplatform.composeapp.generated.resources.NewTask
import matekit_multiplatform.composeapp.generated.resources.Res
import org.jetbrains.compose.resources.stringResource


@Composable
fun masodfokuParent(
    TAG: String,
    fontSize: Float,
    navController: NavHostController
) {

    val vm =
        viewModel { MasodfokuViewModel( TAG,) }
    val uiState by vm.uiState.collectAsState()
    val coroutineScope = rememberCoroutineScope()
    val launched = rememberSaveable{ mutableStateOf( false)}

    val cs = rememberCoroutineScope()

    LaunchedEffect(Unit) {
     if(!launched.value) {
         vm.generateTask()
        launched.value=true
     }
    }

    var gridcolor = MaterialTheme.colorScheme.onBackground
    var textcolor = MaterialTheme.colorScheme.onPrimary

    Surface(
        modifier = Modifier.fillMaxSize(),
        color = MaterialTheme.colorScheme.background,
    ) {
        backButton(navController)
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceBetween
        ) {

            if(uiState.tasktype in listOf(masodfokutasktype.B1,masodfokutasktype.B2,masodfokutasktype.B3)){
                borderedbasicfontsizeTextView(stringResource(Res.string.FunctionTask), )
            }
            var size = remember{ mutableStateOf(12)}

            if(uiState.tasktype in listOf(masodfokutasktype.B1,masodfokutasktype.B2,masodfokutasktype.B3)){
                if(uiState.szélsőérték!=Pair(0f,0f)){
                    Text("(${uiState.szélsőérték.first.formatToDecimals()};${uiState.szélsőérték.second.formatToDecimals()})",color= matekitsquash1)
                }
                Column(Modifier.fillMaxHeight(.6f), horizontalAlignment = Alignment.CenterHorizontally) {
                    koordinátarendszer(
                        gridColor = gridcolor,
                        textcolor = textcolor,
                        onTap = {},
                        quadraticCoeffs = listOf(uiState.a, uiState.b, uiState.c),
                        drawableLines = uiState.lines,
                        szélesség = size.value
                    )

                }

                adjustSize(size, 6f..30f)

            }



            when (uiState.tasktype) {
                masodfokutasktype.A -> masodfokuA(uiState.questions, uiState.userInputs, vm::editAnswer,uiState.solution,uiState.letnext,uiState.results)
                masodfokutasktype.C -> masodfokuC(uiState.questions, uiState.userInputs, vm::editAnswer,uiState.solution,uiState.letnext,uiState.results)
                else->{
                 masodfokuB(uiState.userInputs, vm::editAnswer,uiState.solution,uiState.letnext,uiState.results,uiState.tasktype)
                }

            }

            Row(verticalAlignment = Alignment.CenterVertically) {
                Button(
                    enabled=!uiState.letnext,
                    onClick = {
                     cs.launch { vm.check() }
                    }
                ) {
                    Text(stringResource(Res.string.Check))
                }

                Spacer(modifier = Modifier.width(10.dp))

                if (uiState.letnext) {
                    Button(onClick = {
                        vm.generateTask()
                    }) { Text(stringResource(Res.string.NewTask)) }
                }

                LatexLogButton(navController =navController, TAG)
            }

            if(uiState.tasktype!=masodfokutasktype.B1){
             //   whiteboard(activity,context,darkmode) //todo whiteboard
            }


        }

    }


}


@Composable
fun masodfokuB(
    userinputs: List<String>,
    editAnswer: (String, Int) -> Unit,
    solutions: List<String>,
    ellenőrizve: Boolean,
    results: List<Boolean>,
    tasktype: masodfokutasktype
) {
val modifier = Modifier.width(40.dp)

    var textsegmentsB1 = listOf("","x²+","x+","")
    var textsegmentsB2 = listOf("","(x-",")(x-",")")
    var textsegmentsB3 = listOf("","(x-",")²+","")

    var textsegments = when(tasktype){
        masodfokutasktype.B1 -> textsegmentsB1
        masodfokutasktype.B2 -> textsegmentsB2
        masodfokutasktype.B3 -> textsegmentsB3
            else -> listOf("")
    }
    Row(
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Text("F(x)=")
        Text(textsegments[0])
        matekit.matekit.ui.commonuielements.indexedInputField("",0,userinputs, label =if (ellenőrizve) {
            if (results[0]) {
                checkmark
            } else {
                solutions[0]
            }
        } else "" ,row=false, editText = {editAnswer(it,0) }, boxSize = 90, correct =  results[0], értékelve = ellenőrizve)//a
        Text(textsegments[1])
        matekit.matekit.ui.commonuielements.indexedInputField("",1,userinputs, label =if (ellenőrizve) {
            if (results[1]) {
                checkmark
            } else {
                solutions[1]
            }
        } else "" ,row=false, editText = {editAnswer(it,1) }, boxSize = 90, correct =  results[1], értékelve = ellenőrizve) //b
        Text(textsegments[2])
        matekit.matekit.ui.commonuielements.indexedInputField("",2,userinputs, label =if (ellenőrizve) {
            if (results[2]) {
                checkmark
            } else {
                solutions[2]
            }
        } else "" ,row=false, editText = {editAnswer(it,2) }, boxSize = 90, correct =  results[2], értékelve = ellenőrizve) //c
        Text(textsegments[3])
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun masodfokuA(
    questions: List<String>,
    userinputs: List<String>,
    editAnswer: (String, Int) -> Unit,
    solutions: List<String>,
    ellenőrizve:Boolean,
    results:List<Boolean>
) {
    val modifier = Modifier.width(60.dp)
    Column(Modifier.padding(10.dp),verticalArrangement=Arrangement.SpaceAround,horizontalAlignment = Alignment.CenterHorizontally) {
        Text(questions[0])

        matekit.matekit.ui.commonuielements.indexedInputField("",0,userinputs, label =if (ellenőrizve) {
            if (results[0]) {
                checkmark
            } else {
                solutions[0]
            }
        } else "" ,row=false, editText = {editAnswer(it,0) }, boxSize = 150, correct =  results[0], értékelve = ellenőrizve) //hány közös pont
     //   TextField(value = userinputs[0], onValueChange = { editAnswer(it, 0) },modifier)
        Text(questions[1])
        Row {
            matekit.matekit.ui.commonuielements.indexedInputField("",1,userinputs, label =if (ellenőrizve) {
                if (results[1]) {
                    checkmark
                } else {
                    solutions[1]
                }
            } else "" ,row=false, editText = {editAnswer(it,1) }, boxSize = 150, correct =  results[1], értékelve = ellenőrizve) //x1
            Spacer(modifier = Modifier.width(10.dp))

            matekit.matekit.ui.commonuielements.indexedInputField("",2,userinputs, label =if (ellenőrizve) {
                if (results[2]) {
                    checkmark
                } else {
                    solutions[2]
                }
            } else "" ,row=false, editText = {editAnswer(it,2) }, boxSize = 150, correct =  results[2], értékelve = ellenőrizve) //x2
        }

        Text(questions[2]) //minimum vagy maximum
        var selectedOption by remember { mutableStateOf("minimum") }

        Row(verticalAlignment = Alignment.CenterVertically) {
            SingleChoiceSegmentedButtonRow(
                modifier = Modifier.height(IntrinsicSize.Min)
            ) {
                listOf("minimum", "maximum").forEachIndexed { index, option ->
                    SegmentedButton(
                        shape = SegmentedButtonDefaults.itemShape(
                            index = index,
                            count = 2
                        ),
                        selected = selectedOption == option,
                        onClick = {
                            selectedOption = option
                            editAnswer(option, 3)
                        }
                    ) {
                        Text(option)
                    }
                }
            }

            Spacer(Modifier.size(10.dp))
            Text(  if (ellenőrizve) {
                if (results[3]) {
                    checkmark
                } else {
                    solutions[3]
                }
            } else "",
                color =if (results[3]) Color.Green else Color.Red
            )

        }


        Text(questions[3]) //szélsőérték koordinátái x,y alakban zárójel nélkül
        Row {
            matekit.matekit.ui.commonuielements.indexedInputField("",4,userinputs, label =if (ellenőrizve) {
                if (results[4]) {
                    checkmark
                } else {
                    solutions[4]
                }
            } else "x" ,row=false, editText = {editAnswer(it,4) }, boxSize = 150, correct =  results[4], értékelve = ellenőrizve) //x1
            Spacer(modifier = Modifier.width(10.dp))
            matekit.matekit.ui.commonuielements.indexedInputField("",5,userinputs, label =if (ellenőrizve) {
                if (results[5]) {
                    checkmark
                } else {
                    solutions[5]
                }
            } else "y" ,row=false, editText = {editAnswer(it,5) }, boxSize = 150, correct =  results[5], értékelve = ellenőrizve) //x2
        }

    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun masodfokuC   (questions: List<String>,
userinputs: List<String>,
editAnswer: (String, Int) -> Unit,
solutions: List<String>,
ellenőrizve:Boolean,
results:List<Boolean>){//todo megírni a UIt
    val modifier = Modifier.width(60.dp)
    Column(Modifier.padding(10.dp),verticalArrangement=Arrangement.SpaceAround,horizontalAlignment = Alignment.CenterHorizontally) {
        Text(questions[0])//zérushelyeket megkérdezzük

        Row(verticalAlignment = Alignment.CenterVertically){
            matekit.matekit.ui.commonuielements.indexedInputField(
                "",
                0,
                userinputs,
                label = if (ellenőrizve) {
                    if (results[0]) {
                        checkmark
                    } else {
                        solutions[0]
                    }
                } else "x1",
                row = false,
                editText = { editAnswer(it, 0) },
                boxSize = 150,
                correct = results[0],
                értékelve = ellenőrizve
            ) //zérushely 1
            //   TextField(value = userinputs[0], onValueChange = { editAnswer(it, 0) },modifier)
            Spacer(modifier = Modifier.width(10.dp))
            matekit.matekit.ui.commonuielements.indexedInputField(
                "",
                1,
                userinputs,
                label = if (ellenőrizve) {
                    if (results[1]) {
                        checkmark
                    } else {
                        solutions[1]
                    }
                } else "x2",
                row = false,
                editText = { editAnswer(it, 1) },
                boxSize = 150,
                correct = results[1],
                értékelve = ellenőrizve
            ) //zérushely 2
        }
        Text(questions[1]) //minimum vagy maximum
        var selectedOption by remember { mutableStateOf("minimum") }

        Row( verticalAlignment = Alignment.CenterVertically) {
            SingleChoiceSegmentedButtonRow(//minimum vagy maximum
                modifier = Modifier.height(IntrinsicSize.Min)
            ) {
                listOf("minimum", "maximum").forEachIndexed { index, option ->
                    SegmentedButton(
                        shape = SegmentedButtonDefaults.itemShape(
                            index = index,
                            count = 2
                        ),
                        selected = selectedOption == option,
                        onClick = {
                            selectedOption = option
                            editAnswer(option, 2)
                        }
                    ) {
                        Text(option)
                    }
                }
            }
            Spacer(Modifier.size(10.dp))
            Text(  if (ellenőrizve) {
                if (results[2]) {
                    checkmark//✓  ✔
                } else {
                    solutions[2]
                }
            } else "",
                color =if (results[2]) Color.Green else Color.Red
            )

        }


        Text(questions[2]) //szélsőérték koordinátái x,y alakban zárójel nélkül
        Row {
            matekit.matekit.ui.commonuielements.indexedInputField("",3,userinputs, label =if (ellenőrizve) {
                if (results[3]) {
                    checkmark
                } else {
                    solutions[3]
                }
            } else "x" ,row=false, editText = {editAnswer(it,3) }, boxSize = 150, correct =  results[3], értékelve = ellenőrizve) //x


            Spacer(modifier = Modifier.width(10.dp))
            matekit.matekit.ui.commonuielements.indexedInputField("",4,userinputs, label =if (ellenőrizve) {
                if (results[4]) {
                    checkmark
                } else {
                    solutions[4]
                }
            } else "y" ,row=false, editText = {editAnswer(it,4) }, boxSize = 150, correct =  results[4], értékelve = ellenőrizve) //y
        }

    }
}