package matekit.ui.theme

import androidx.compose.ui.graphics.Color

val Purple200 = Color(0xFFBB86FC)
val Purple500 = Color(0xFF6200EE)
val Purple700 = Color(0xFF3700B3)
val Teal200 = Color(0xFF03DAC5)
val oldGray = Color(0xFF5B5A5D)
val grey = Color(0xFFA5A5A5)
val newGreyPrimarylight =/*Color(0xFF979797)*/ Color(168,168,168)/* */
val newGreyPrimarydark =/*Color(0xFF979797)*/ Color(86,86,86)/* */
val white = Color(0xFFFFFFFF)
val black = Color(0xFF000000)
val matekitcolor = Color(0xFFff7b00)
val matekitsquash1 = Color(0xFF00C6D0)
val matekitsquash2 = Color(0xFFFF42F4)